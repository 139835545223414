/* eslint-disable jsx-a11y/heading-has-content */
import "./style.css";
import React, { Fragment } from "react";
import ReactApexChart from "react-apexcharts";

type Props = {
  total: number;
  cirurgias: number;
  statusNome: string;
  className: string;
};

export default function CardStatus({
  cirurgias,
  statusNome,
  className,
  total,
}: Props): JSX.Element {
  const RadialBar = {
    series: [(cirurgias * 100) / total],
    options: {
      plotOptions: {
        radialBar: {
          hollow: {
            size: "60%",
          },

          dataLabels: {
            showOn: "always",
            name: {
              offsetY: 0,
              show: true,
              color: "var(--primary-blue-default)",
              fontSize: "1.3rem",
              margin: 50,
            },
            value: {
              color: className,
              fontSize: "1rem",
              fontWeight: "bold",
              formatter: function () {
                return statusNome;
              },
              show: true,
            },
          },
        },
      },

      stroke: {
        lineCap: "round" as "round"
      },

      labels: [`${cirurgias} Cirurgias`],

      fill: {
        colors: [className],
      },
    },
  };

  return (
    <Fragment>
      <div className="col-span-1">
        {cirurgias !== undefined ? 
          <ReactApexChart
            options={RadialBar.options}
            series={RadialBar.series}
            type="radialBar"
            width={280}
          /> 
          : null
        }
      </div>
    </Fragment>
  );
}
