import React, { Fragment, useEffect, useState } from "react";
import "./style.css";

import Popup from "reactjs-popup";
import { AiFillCloseCircle } from "react-icons/ai";
// import InputMask from "react-input-mask";
import { toast } from "react-toastify";
import { axiosApi } from "src/services/axiosInstances";
import Loader from "../Loader";
import { TailSpin } from "react-loader-spinner";
import { AuthState, DoutorState } from "src/store/types";
import { connect, ConnectedProps } from "react-redux";
import { setDoutoresAdmin } from "src/store/actions/doutores";
import { FaUserNurse } from "react-icons/fa";
import ModalListDoctor from "../ModalListDoctor";

const mapStateToProps = ({
  auth,

  doutores,
}: {
  auth: AuthState;

  doutores: DoutorState;
}): AuthState & DoutorState => {
  return {
    usuario: auth.usuario,
    doutores: doutores.doutores,
  };
};
const mapDispatchToProps = {
  setDoutoresAdmin,
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
  open?: any;
  close?: any;
  status?: boolean;
  nome?: string;
  onChange?: (event: any) => void;
  toggle?: any;
  updateData?: any;
  id?: any;
  setId?: any;
  id_selecionado?: any;
  setSecretariaId?: any;
};

type Secretaria = {
  id?: number;
  nome?: string;
  telefone?: string;
  whatsapp?: string;
  usuario_id?: number;
  usuario?: Usuario;
  doutores?: Doutores[];
};

export type Doutores = {
  id: number;
  nome: string;
  secretaria_id: number;
};

type Usuario = {
  id: number;
  email: string;
  adm: string;
  fotos_id: string;
  nivel_id: number;
  status_id: number;
};

function ModalCirurgiao({
  open,
  close,
  id,
  setId,
  toggle,
  nome,
  updateData,
  doutores,
  id_selecionado,
  setSecretariaId,
  setDoutoresAdmin,
}: Props): JSX.Element {
  const modalWidth = { width: `50%` };
  const [loader, setLoader] = useState(false);

  const [dadosSecretaria, setDadosSecretaria] = useState<Secretaria>();
  const [mapDoutores, setMapDoutores] = useState<Doutores[]>([]);
  1 < 0 && console.log(mapDoutores);
  const loadDados = async () => {
    try {
      setLoader(true);
      const { data } = await axiosApi.get<Secretaria & Doutores[]>(
        `/show-secretaria/${id}`
      );
      setDadosSecretaria(data);
      setCheck(data.usuario?.status_id === 8 ? "Inativo" : "Ativo");
      setMapDoutores(data);
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };
  useEffect(() => {
    if (id !== undefined) {
      loadDados();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (open === false) {
      setDadosSecretaria({});
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  // const postForm = (event: any) => {
  //   const { name, value } = event.target;
  //   setDadosSecretaria({
  //     ...dadosSecretaria,
  //     [name]: value,
  //   });
  // };

  const [btnLoader, setBtnLoader] = useState(false);

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    try {
      setBtnLoader(true);

      const postDados = id
        ? await axiosApi.put(`/secretaria?id=${id}`, {
            ...dadosSecretaria,
            ativo: document.getElementsByName("statusId")[0]["checked"],
          })
        : await axiosApi.post(`/secretaria`, {
            ...dadosSecretaria,
            ativo: document.getElementsByName("statusId")[0]["checked"],
          });

      if (postDados.data.errorInfo) {
        toast.error('Erro no Formulário', {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          });
      } else {
        if (id) {
          toast.success('Alterações Realizadas com Sucesso!', {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            });
          toggle();
          updateData();
          loadDados();
        } else {
          toast.success('Secretária Cadastrada', {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            });
          toggle();
          updateData();
          loadDados();
        }
      }
    } catch (error: any) {
      if (error.response.data.errorInfo[0] === "23505") {
        toast.error('Erro no Formulário!', {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          });
      }
    } finally {
      setBtnLoader(false);
    }
  };

  const [check, setCheck] = useState<String>();

  const checkChange = () => {
    if (document.getElementsByName("statusId")[0]["checked"] === true) {
      setCheck("Ativo");
    } else {
      setCheck("Inativo");
    }
  };

  const [openList, setOpenList] = useState(false);
  const toggleList = () => setOpenList(!openList);

  return (
    <Fragment>
      <ModalListDoctor
        open={openList}
        toggle={toggleList}
        id={id}
        doutores={doutores}
        nome={dadosSecretaria?.nome}
      >
        {dadosSecretaria?.doutores?.map((dados: Doutores) => {
          return (
            <div>
              <p className="flex justify-start space-x-2 truncate">
                <span className="font-bold">Dr(a)</span>
                <span className="ml-5">{dados.nome}</span>
              </p>
            </div>
          );
        })}
      </ModalListDoctor>
      <Popup
        open={open}
        closeOnDocumentClick={false}
        // onClose={open}
        contentStyle={modalWidth}
        modal
        nested={true}
      >
        <Fragment>
          <div className="bg-white modal py-5 px-10">
            <Loader isActive={loader} hide="mt-64">
              <div className="header pt-5 pb-3 text-left text-3xl font-bold truncate">
                Editar {nome}
                <div className="content px-2">
                  <form onSubmit={handleSubmit}>
                    <div className="text-base gap-x-3">
                      <div className="input-cirurgiao mt-5 px-1">
                        <h4 className="text-base font-bold mb-2">Nome*</h4>
                        {/* <input
                          disabled
                          type="text"
                          className="input-cirurgiao w-full h-10 border border-gray-600 pl-3"
                          name="nome"
                          defaultValue={dadosSecretaria?.nome ?? ""}
                          onChange={postForm}
                          required={true}
                        /> */}
                        <p className="input-cirurgiao w-full h-10 border border-gray-600 pl-3 relative">
                          <span className="mt-2 absolute font-normal">
                            {dadosSecretaria?.nome}
                          </span>
                        </p>
                      </div>
                      <div className="grid grid-cols-12">
                        <div className="col-span-5 input-cirurgiao mt-5 px-1 text-base">
                          <h4 className="text-base font-bold mb-2">Telefone</h4>
                          {/* <InputMask
                            mask="(99)99999-9999"
                            onChange={postForm}
                            disabled
                          >
                            <input
                              type="text"
                              className="input-cirurgiao w-full h-10 border border-gray-600 pl-3"
                              name="telefone"
                              defaultValue={dadosSecretaria?.telefone ?? ""}
                            />
                          </InputMask> */}
                          <p className="input-cirurgiao w-full h-10 border border-gray-600 pl-3 relative">
                            <span className="mt-2 absolute font-normal">
                              {dadosSecretaria?.telefone}
                            </span>
                          </p>
                        </div>
                        <div className="col-span-5 input-cirurgiao mt-5 px-1 text-base">
                          <h4 className="text-base font-bold mb-2">
                            WhatsApp*
                          </h4>
                          {/* <InputMask
                            mask="(99)99999-9999"
                            onChange={postForm}
                            disabled
                          >
                            <input
                              type="text"
                              className="input-cirurgiao w-full h-10 border border-gray-600 pl-3"
                              name="whatsapp"
                              defaultValue={dadosSecretaria?.whatsapp ?? ""}
                              required={true}
                            />
                          </InputMask> */}
                          <p className="input-cirurgiao w-full h-10 border border-gray-600 pl-3 relative">
                            <span className="mt-2 absolute font-normal">
                              {dadosSecretaria?.whatsapp}
                            </span>
                          </p>
                        </div>
                        <div className="col-span-2 ml-5">
                          {id !== undefined ? (
                            <div
                              title={`Cirurgiões de ${dadosSecretaria?.nome}`}
                              onClick={() => setOpenList(true)}
                              className="btn-list-doctors w-12 px-2 py-3 bg-blue rounded-full text-white font-bold mt-12 flex justify-center hover:shadow-lg cursor-pointer"
                            >
                              <FaUserNurse size={"1.5rem"} />
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="grid grid-cols-12">
                        <div className="col-span-8 input-cirurgiao mt-5 px-1">
                          <h4 className="text-base font-bold mb-2">Email*</h4>
                          {/* <input
                            disabled
                            type="text"
                            className="input-cirurgiao w-full h-10 border border-gray-600 pl-3"
                            name="email"
                            defaultValue={dadosSecretaria?.usuario?.email ?? ""}
                            onChange={postForm}
                            required={true}
                          /> */}
                          <p className="input-cirurgiao w-full h-10 border border-gray-600 pl-3 relative">
                            <span className="mt-2 absolute font-normal">
                              {dadosSecretaria?.usuario?.email}
                            </span>
                          </p>
                        </div>
                        <div className="col-span-4 ml-3 input-cirurgiao mt-5 px-1">
                          <h4 className="text-base font-bold mb-2">Status</h4>
                          <div className="flex">
                            <label className="switch mt-1">
                              <input
                                name="statusId"
                                type="checkbox"
                                defaultChecked={
                                  dadosSecretaria?.usuario?.status_id === 8
                                    ? false
                                    : true
                                }
                                onChange={checkChange}
                              />
                              <span className="slider round"></span>
                            </label>
                            <div className="mt-1">
                              <span className="font-light ml-2 text-xl">
                                {check}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="flex justify-center mb-2 pt-10">
                      <button
                        type="submit"
                        className="relative btn-save-vendedor bg-blue text-lg text-white font-bold px-8 py-2 rounded-full shadow-md cursor-pointer hover:shadow-lg"
                      >
                        <div className="flex justify-center space-x-2">
                          <TailSpin
                            
                            color={"white"}
                            visible={btnLoader}
                            height={24}
                            width={20}
                          />
                          <span>
                            {btnLoader === true ? "Salvando" : "Salvar"}
                          </span>
                        </div>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </Loader>
          </div>
          <div
            className="rounded-full cursor-pointer close-btn"
            // eslint-disable-next-line no-sequences
            onClick={() => (toggle(), setId())}
          >
            <AiFillCloseCircle
              size="3rem"
              className="text-blue rounded-full bg-white"
            />
          </div>
        </Fragment>
      </Popup>
    </Fragment>
  );
}
export default connector(ModalCirurgiao);
