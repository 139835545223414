import * as actionTypes from "../types";

const initialState: actionTypes.AuthState = {
  token: localStorage.getItem("token"),
  usuario: JSON.parse(localStorage.getItem("usuario") ?? "null"),
  doutor: JSON.parse(localStorage.getItem("doutor") ?? "null") ?? {
    nome: "Visão Geral",
    id: 0,
  },
};

export default (
  state = initialState,
  { type, payload }: actionTypes.AuthAction
) => {
  switch (type) {
    case actionTypes.UPDATE_TOKEN:
      return { ...state, token: payload.token };

    case actionTypes.UPDATE_DOUTOR:
    case actionTypes.NIVEL_ID_USER:
    case actionTypes.INITIAL_STATE:
    case actionTypes.UPDATE_PERFIL:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};
