import React, { Fragment, useState } from "react";
import Select from "react-select";
import { SelectStyle2 } from "src/helpers/functions";
import "../SelectSimple/style.css";
import { FaAngleRight } from "react-icons/fa";
import { Link } from "react-router-dom";

type Props = {};

type Option = {
  label: string;
  value: string;
};

export default function SelectSimple({ ...rest }: Props) {
  const options: Array<Option> = [
    {
      value: "/solicitacao",
      label: "Paciente",
    },
    {
      value: "/login?nivel_id=1",
      label: "Doutor",
    },
    {
      value: "/login?nivel_id=6",
      label: "Hospital",
    },
    {
      value: "/login?nivel_id=2",
      label: "Fornecedor (a)",
    },  
    {
      value: "/login?nivel_id=3",
      label: "Secretária (o)",
    }
  ];

  const [url, setUrl] = useState<string>("/solicitacao");

  const getValue = (selectedOption: any) => {
    const value = (selectedOption).value;
    setUrl(value);
  };

  return (
    <Fragment>
      <div className="select-simple-select-container div-select mt-2 grid grid-flow-col px-8">
        <Select
          className="react-select text-xl font-bold text-black"
          options={options}
          placeholder="Selecione"
          styles={SelectStyle2}
          defaultValue={options[0]}
          onChange={getValue}
          isSearchable={false}
        />
      </div>
      <div className="login-button-container p-5 mt-3 flex items-center justify-center">
        <Link
          to={url}
          className="button text-white font-bold rounded-full btn-input flex items-center justify-center"
        >
          <FaAngleRight size="4rem" />
        </Link>
      </div>
    </Fragment>
  );
}
