import React from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { ObjectSchema } from "yup";
import "./style.css";

type Props = {
  ShowpasswordClassName?: string;
  HidePasswordClassName?: string;
  inputClassName?: string;
  name?: string;
  schema?: ObjectSchema<any>;
  senhaEffect?: any;
  maxlength?: number;
  label?: string;
  width?: string;
  marginTop?: string;
  onChange?: (event: any) => void;
  onBlur?: (event: any) => void;
  onClickEye?: (event: any) => void;
  pattern?: string;
  inputClass?: string;
  labelClass?: string;
};

export default function InputSenha({
  ShowpasswordClassName = "z-30 text-gray-600 absolute right-0 mr-2 align-middle",
  HidePasswordClassName = "z-30 text-blue absolute right-0 mr-2 align-middle",
  inputClassName = "px-3 mt-2 border-2 border-gray-400 w-full h-10",
  width,
  marginTop,
  onChange,
  onBlur,
  onClickEye,
  senhaEffect,
  maxlength,
  label,
  name,
  pattern,
  inputClass,
  labelClass
}: Props): JSX.Element {
  return (
    <div className={`input-vendedores mb-0 ${width} py-3`}>
      <h4 className={`${labelClass} input-form-login-create-account input-form-login text-gray-800 font-bold text-sm xl:text-lg md:text-base`}>
        {label}
      </h4>
      <div className="flex justify-between relative items-center">
        <input
          type={senhaEffect ? "text" : "password"}
          className={`input-senha-input ${inputClass} pr-8 ${inputClassName} ${width}`}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          maxLength={maxlength}
          required
          style={{ borderRadius: "16px" }}
          pattern={pattern}
          title="A senha precisa ter 6 dígitos"
        />
        {senhaEffect === true ? (
          <AiOutlineEyeInvisible
            onClick={onClickEye}
            size="1.5rem"
            className={`${HidePasswordClassName} ${marginTop}`}
          />
        ) : (
          <AiOutlineEye
            onClick={onClickEye}
            size="1.5rem"
            className={`input-login-eye ${ShowpasswordClassName} ${marginTop}`}
          />
        )}
      </div>
    </div>
  );
}
