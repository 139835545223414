import "./style.css";
import React, { Fragment, useEffect, useState } from "react";
import CardProxCirurgia from "src/components/CardProxCirurgia";
import CardStatus from "src/components/CardStatus";
import CardAutorizacao from "src/components/CardAutorizacoes";
import Titulos from "src/components/Titulos";
import Loader from "../../components/Loader";
import ModalChecklist from "../../components/ModalChecklist";
import { AuthState, ChecklistState, Cirurgias, Fornecedores, FornecedoresState, Hospitais, HospitaisState } from "src/store/types";
import { connect, ConnectedProps } from "react-redux";
import EmptyContent from "../../components/EmptyContent";
import { setUpdateChecklist } from "src/store/actions/checklist";
import { setUpdateFornecedores } from "src/store/actions/fornecedores";
import { setUpdateHospitais } from "src/store/actions/hospitais";
import CardStatsAdmin from "src/components/CardStatsAdmin";
import { FaAngleRight, FaRegHospital, FaUserNurse } from "react-icons/fa";
import { FaHeadset } from "react-icons/fa";
import { RiLuggageCartLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import calendar from '../../assets/MobileFloatingNav/Calendar.svg';
import { axiosApi } from "src/services/axiosInstances";

const mapStateToProps = ({
  auth,
  cirurgias,
  fornecedores,
  hospitais,
}: {
  auth: AuthState;
  cirurgias: ChecklistState;
  fornecedores: FornecedoresState;
  hospitais: HospitaisState;
}): AuthState & ChecklistState & FornecedoresState & HospitaisState => {
  return {
    doutor: auth.doutor,
    usuario: auth.usuario,
    cirurgias: cirurgias.cirurgias,
    fornecedores: fornecedores.fornecedores,
    hospitais: hospitais?.hospitais,
  };
};

const mapDispatchToProps = {
  setUpdateChecklist, setUpdateFornecedores, setUpdateHospitais,
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & {
  gridStatus?: string;
};

// type Status = {
//   id: bigint;
//   descricao: string;
// };

// type Hospital = {
//   id: bigint;
//   nome: string;
// };

// type Cirurgia = {
//   id: number;
//   nome: string;
//   status_id: number;
//   status: Status;
//   hospital: Hospital;
//   cirurgia: string;
//   confirmacao_hora: string;
//   confirm_data: string;
//   doutor: Doutor;
// };

// type Doutor = {
//   id: bigint;
//   nome: string;
// };

type statsAdmin = {
  doutores: number;
  secretarias: number;
  hospitais: number;
  fornecedores: number;
};

type HomeResponse = {
  agendadas: Cirurgias[];
  autorizadas: Cirurgias[];
  pendentes: Cirurgias[];
  concluidas: Cirurgias[];
  desmarcadas: Cirurgias[];
  reportadas: Cirurgias[];
  vencidas: Cirurgias[];
};

type FornecedorResponse = {
  fornecedores: Fornecedores[];
  total: number;
};

type HospitalResponse = {
  hospitais: Hospitais[];
  total: number;
};

function Home({
  doutor,
  gridStatus,
  usuario,
  cirurgias,
  setUpdateChecklist,
  setUpdateFornecedores,
  setUpdateHospitais,
}: Props): JSX.Element {
  const [loader, setLoader] = useState(true);
  const [adminStats, setAdminStats] = useState<statsAdmin>();
  const [agendadas, setAgendadas] = useState<Cirurgias[]>([]);
  const [pendentes, setPendentes] = useState<Cirurgias[]>([]);
  const [autorizadas, setAutorizadas] = useState<Cirurgias[]>([]);
  const [concluidas, setConcluidas] = useState<Cirurgias[]>([]);
  const [desmarcadas, setDesmarcadas] = useState<Cirurgias[]>([]);
  // const [reportadas, setReportadas] = useState<Cirurgias[]>([]);
  const [vencidas, setVencidas] = useState<Cirurgias[]>([]);
  const [idCirurgia, setIdCirurgia] = useState<number>();
  const [statusCirurgia, setStatusCirurgia] = useState<string>();
  const [open, setOpen] = useState(false);
  // const toggle = () => setOpen(!open);
  const loadDados = async () => {
    try {
      setLoader(true);
      const { data } = await axiosApi.get<HomeResponse>(
        `/cirurgias?id=${doutor?.id}`
      );
      setUpdateChecklist(
        data.agendadas ||
        data.autorizadas ||
        data.pendentes ||
        data.concluidas ||
        data.desmarcadas ||
        data.reportadas ||
        data.vencidas
      );
      setAgendadas(data.agendadas);
      setAutorizadas(data.autorizadas);
      setPendentes(data.pendentes);
      setConcluidas(data.concluidas);
      setDesmarcadas(data.desmarcadas);
      // setReportadas(data.reportadas);
      setVencidas(data.vencidas);

      const { data: data2 } = await axiosApi.get<FornecedorResponse>(
        `/list-fornecedores`
      );
      setUpdateFornecedores(data2["ativos"]);

      const { data: data3 } = await axiosApi.get<HospitalResponse>(
        `/list-hospitais`
      );
      setUpdateHospitais(data3["ativos"]);

      // setTimeout(() => setLoader(false), 5000);
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  const loadDados2 = async () => {
    try {
      const { data } = await axiosApi.get<HomeResponse>(
        `/cirurgias?id=${doutor?.id}`
      );
      setUpdateChecklist(
        data.agendadas ||
        data.autorizadas ||
        data.pendentes ||
        data.concluidas ||
        data.desmarcadas ||
        data.reportadas ||
        data.vencidas
      );
      setAgendadas(data.agendadas);
      setAutorizadas(data.autorizadas);
      setPendentes(data.pendentes);
      setConcluidas(data.concluidas);
      setDesmarcadas(data.desmarcadas);
      // setReportadas(data.reportadas);
      setVencidas(data.vencidas);

      const { data: data2 } = await axiosApi.get<FornecedorResponse>(
        `/list-users?selecionado=2`
      );
      setUpdateFornecedores(data2.fornecedores);

      const { data: data3 } = await axiosApi.get<HospitalResponse>(
        `/list-users?selecionado=4`
      );
      setUpdateHospitais(data3.hospitais);
      
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    loadDados();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doutor]);

  const loadStats = async () => {
    try {
      const { data } = await axiosApi.get<statsAdmin>(`/count-users`);
      setAdminStats(data);
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    loadStats();
  }, []);

  if (loader) {
    return <Loader isActive={loader}><div></div></Loader>;
  }

  const dadosCirurgia = (id: number): void => {
    const cirurgiaStatus = [...agendadas, ...autorizadas, ...pendentes].find(
      (element) => element.id === id
    );
    setIdCirurgia(id);
    setStatusCirurgia(cirurgiaStatus?.status.descricao);
    setOpen(true);
  };
  const total: number =
    pendentes?.length +
      agendadas?.length +
      autorizadas?.length +
      concluidas?.length +
      desmarcadas?.length +
      vencidas?.length !==
      0
      ? pendentes?.length +
      agendadas?.length +
      autorizadas?.length +
      concluidas?.length +
      desmarcadas?.length +
      vencidas?.length
      : 1;
  const adminCheck = usuario?.nivel === 5;

  const gridLayout: string =
    usuario?.nivel === 1 || usuario?.nivel === 3 || adminCheck
      ? (gridStatus = "3")
      : (gridStatus = "2");

  const nivelCheck = usuario?.nivel === 1 || usuario?.nivel === 3;

  const paddingLayout: string = adminCheck ? "0" : "10";

  const cirurgiasSemEquipe = cirurgias?.filter((agendada: Cirurgias) =>
    agendada.doutor_requisitado_id == null);

    const parseDate = (dateStr: string): Date => {
      const [year, month, day] = dateStr.split('-').map(Number);
      return new Date(year, month - 1, day);
    };
    
    const cirurgiasOrdenadas = cirurgiasSemEquipe.sort((a: Cirurgias, b: Cirurgias) => {
      if (!a.confirmacao_data || !b.confirmacao_data) {
        return 0;
      }
    
      const dataA = parseDate(a.confirmacao_data);
      const dataB = parseDate(b.confirmacao_data);
    
      return dataA.getTime() - dataB.getTime();
    });

  return (
    <Loader isActive={loader}>
      <div className="p-12">
        <ModalChecklist
          idCirurgia={idCirurgia}
          open={open}
          setOpen={setOpen}
          updateData={loadDados2}
          status={statusCirurgia}
        />

        {adminCheck ? (
          ""
        ) : (
          <Fragment>
            <Titulos titulo="Próximas Cirurgias" />
            {agendadas?.length === 0 ? (
              <EmptyContent
                messageSize="2xl"
                iconSize="2.2rem"
                paddingContent={"py-16"}
                titleContent="Sem Cirurgias Agendadas"
              />
            ) : (
              <div 
              className="flex gap-x-3 overflow-x-auto pb-4"
              // className="grid grid-cols-4 gap-4 gap-x-20 overflow-x-auto pb-4"
              >
                {cirurgiasOrdenadas?.map((agendada: Cirurgias) => {
                  const data = agendada?.confirmacao_data
                    ?.split("-")
                    .reverse()
                    .join("/");
                  return (
                    <CardProxCirurgia
                      onClick={dadosCirurgia}
                      id={agendada.id}
                      key={agendada.id}
                      status={agendada.status.descricao}
                      nome={agendada.nome}
                      doutorNome={agendada.doutor?.nome}
                      nomeCirurgia={agendada.cirurgia}
                      nomeHospital={agendada.hospital?.nome}
                      confirmacao_hora={agendada?.confirmacao_hora}
                      confirmacao_data={agendada?.confirmacao_data}
                      data={data}
                    />
                  );
                })}
              </div>
            )}
          </Fragment>
        )}
        {adminCheck ? (
          <div className="grid grid-cols-4 gap-2 pt-10">
            <CardStatsAdmin
              stats={
                adminStats?.doutores === undefined ? 0 : adminStats?.doutores
              }
              nome={"Cirurgiões"}
            >
              <FaUserNurse size="5rem" className="text-blue mt-2" />
            </CardStatsAdmin>
            <CardStatsAdmin
              stats={
                adminStats?.secretarias === undefined
                  ? 0
                  : adminStats?.secretarias
              }
              nome={"Secretárias"}
            >
              <FaHeadset size="5rem" className="text-blue mt-2" />
            </CardStatsAdmin>
            <CardStatsAdmin
              stats={
                adminStats?.hospitais === undefined
                  ? 0
                  : adminStats?.hospitais
              }
              nome={"Hospitais"}
            >
              <FaRegHospital size="5rem" className="text-blue mt-2" />
            </CardStatsAdmin>
            <CardStatsAdmin
              stats={
                adminStats?.fornecedores === undefined
                  ? 0
                  : adminStats?.fornecedores
              }
              nome={"Fornecedores"}
            >
              <RiLuggageCartLine size="5rem" className="text-blue mt-2" />
            </CardStatsAdmin>
          </div>
        ) : (
          <div className="col-span-1 div-card-autorizacao">
            <Titulos titulo="Novas Autorizações" />

            {(autorizadas?.length || pendentes?.length) === 0 ? (
              <EmptyContent
                messageSize="xl"
                iconSize="2rem"
                paddingContent={
                  usuario?.nivel === 5 ||
                    usuario?.nivel === 1 ||
                    usuario?.nivel === 3
                    ? "py-64"
                    : "py-32"
                }
                titleContent="Sem Novas Autorizações"
              />
            ) : (
              <div className="scrollHome overflow-y-auto overflow-x-hidden scroll-box container px-2 py-1">
                {[...autorizadas, ...pendentes]
                  .slice(0, 30)
                  .map((autorizacoes: Cirurgias) => {
                    const nomeCirurgia: string =
                      autorizacoes?.hospital === null
                        ? "-"
                        : autorizacoes?.cirurgia;
                    return (
                      <CardAutorizacao
                        onClick={dadosCirurgia}
                        key={autorizacoes.id}
                        id={autorizacoes.id}
                        nome={autorizacoes.nome}
                        doutorNome={autorizacoes.doutor?.nome}
                        status={autorizacoes.status.descricao}
                        nomeCirurgia={nomeCirurgia}
                      />
                    );
                  })}
              </div>
            )}
          </div>
        )}

        <div className={`flex flex-col pt-${paddingLayout}`}>
          <div className="col-span-2">
            <div className="mb-5 font-bold text-base">
              <h2 className="title-prox-cirugias">Status de Autorizações</h2>
            </div>
            {(agendadas?.length ||
              autorizadas?.length ||
              pendentes?.length ||
              concluidas?.length ||
              desmarcadas?.length ||
              vencidas?.length) === 0 ? (
              <EmptyContent
                messageSize="2xl"
                iconSize="2.2rem"
                paddingContent={
                  usuario?.nivel === 5 ||
                    usuario?.nivel === 1 ||
                    usuario?.nivel === 3
                    ? "py-64"
                    : "py-32"
                }
                titleContent="Sem Status"
              />
            ) : (
              <div className={`card-status grid grid-cols-${gridLayout} pt-5`}>
                {nivelCheck || adminCheck ? (
                  <Fragment>
                    <CardStatus
                      cirurgias={autorizadas?.length}
                      className="#eb5757"
                      statusNome="Autorizadas"
                      total={total}
                    />

                    <CardStatus
                      cirurgias={pendentes?.length}
                      className="var(--yellow-default)"
                      statusNome="Pendentes"
                      total={total}
                    />

                    <CardStatus
                      cirurgias={agendadas?.length}
                      className="var(--green-default)"
                      statusNome="Agendadas"
                      total={total}
                    />

                    <CardStatus
                      cirurgias={concluidas?.length}
                      className="#54C1FB"
                      statusNome="Concluídas"
                      total={total}
                    />

                    <CardStatus
                      cirurgias={desmarcadas?.length}
                      className="var(--neutral-400)"
                      statusNome="Desmarcadas"
                      total={total}
                    />

                    <CardStatus
                      cirurgias={vencidas?.length}
                      className="#262626"
                      statusNome="Vencidas"
                      total={total}
                    />
                  </Fragment>
                ) : (
                  <Fragment>
                    <CardStatus
                      cirurgias={pendentes?.length}
                      className="var(--yellow-default)"
                      statusNome="Pendentes"
                      total={total}
                    />

                    <CardStatus
                      cirurgias={agendadas?.length}
                      className="var(--green-default)"
                      statusNome="Agendadas"
                      total={total}
                    />
                  </Fragment>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </Loader>
  );
}

const MobileHomeComp = ({
  doutor,
  gridStatus,
  usuario,
  cirurgias,
  setUpdateChecklist,
}: Props) => {
  const [adminStats, setAdminStats] = useState<statsAdmin>();
  const [agendadas, setAgendadas] = useState<Cirurgias[]>([]);
  const [pendentes, setPendentes] = useState<Cirurgias[]>([]);
  const [autorizadas, setAutorizadas] = useState<Cirurgias[]>([]);
  const [concluidas, setConcluidas] = useState<Cirurgias[]>([]);
  const [desmarcadas, setDesmarcadas] = useState<Cirurgias[]>([]);
  const [reportadas, setReportadas] = useState<Cirurgias[]>([]);
  const [vencidas, setVencidas] = useState<Cirurgias[]>([]);
  const [idCirurgia, setIdCirurgia] = useState<number>();
  const [statusCirurgia, setStatusCirurgia] = useState<string>();

  const loadDados = async () => {
    const { data } = await axiosApi.get<HomeResponse>(
      `/cirurgias?id=${doutor?.id}`
    );
    setUpdateChecklist(
      data.agendadas ||
      data.autorizadas ||
      data.pendentes ||
      data.concluidas ||
      data.desmarcadas ||
      data.reportadas ||
      data.vencidas
    );
    setAgendadas(data.agendadas);
    setAutorizadas(data.autorizadas);
    setPendentes(data.pendentes);
    setConcluidas(data.concluidas);
    setDesmarcadas(data.desmarcadas);
    setReportadas(data.reportadas);
    setVencidas(data.vencidas);
  };

  const loadDados2 = async () => {
    const { data } = await axiosApi.get<HomeResponse>(
      `/cirurgias?id=${doutor?.id}`
    );
    setUpdateChecklist(
      data.agendadas ||
      data.autorizadas ||
      data.pendentes ||
      data.concluidas ||
      data.desmarcadas ||
      data.reportadas ||
      data.vencidas
    );
    setAgendadas(data.agendadas);
    setAutorizadas(data.autorizadas);
    setPendentes(data.pendentes);
    setConcluidas(data.concluidas);
    setDesmarcadas(data.desmarcadas);
    setReportadas(data.reportadas);
    setVencidas(data.vencidas);
  };

  useEffect(() => {
    loadDados();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doutor]);

  const loadStats = async () => {
    const { data } = await axiosApi.get<statsAdmin>(`/count-users`);
    setAdminStats(data);
  };

  useEffect(() => {
    loadStats();
  }, []);

  const dadosCirurgia = (id: number): void => {
    const cirurgiaStatus = [...autorizadas, ...pendentes].find(
      (element) => element.id === id
    );
    setIdCirurgia(id);
    setStatusCirurgia(cirurgiaStatus?.status.descricao);
  };
  const total: number =
    pendentes?.length +
      agendadas?.length +
      autorizadas?.length +
      concluidas?.length +
      desmarcadas?.length +
      vencidas?.length !==
      0
      ? pendentes?.length +
      agendadas?.length +
      autorizadas?.length +
      concluidas?.length +
      desmarcadas?.length +
      vencidas?.length
      : 1;

  // const adminCheck = usuario?.nivel === 5;

  // const gridLayout: string =
  //   usuario?.nivel === 1 || usuario?.nivel === 3 || adminCheck
  //     ? (gridStatus = "3")
  //     : (gridStatus = "2");

  // const nivelCheck = usuario?.nivel === 1 || usuario?.nivel === 3;

  // const paddingLayout: string = adminCheck ? "0" : "10";

  1 < 0 && console.log(reportadas, adminStats, idCirurgia, statusCirurgia, loadDados2, loadDados, total, dadosCirurgia);

  return (
    <div className='mobile-home-container'>
      <div className='mobile-home-surgery-container'>
        <span className='mobile-home-surgery-content'>Próximas Cirurgias:</span>
        <div className='mobile-home-surgery-list'>
          {agendadas?.length !== 0 ?
            window.innerWidth <= 414 ?
              <>
                {cirurgias.slice(0, 2).map((agendada: Cirurgias) => {
                  const data = agendada?.confirmacao_data
                    ?.split("-")
                    .reverse()
                    .join("/");
                  return (
                    <CardProxCirurgia
                      key={agendada.id}
                      status={agendada.status.descricao}
                      nome={agendada.nome}
                      doutorNome={agendada.doutor?.nome}
                      nomeCirurgia={agendada.cirurgia}
                      nomeHospital={agendada.hospital?.nome}
                      confirmacao_hora={agendada?.confirmacao_hora}
                      confirmacao_data={agendada?.confirmacao_data}
                      data={data}
                    />
                  );
                })}
                <Link to='/calendario' className='mobile-home-surgery-calendar-button'>
                  <img className='mobile-home-surgery-calendar-button-bg-calendar' src={calendar} alt="calendar-icon" />
                  <div className='mobile-home-surgery-calendar-blur-part' />
                  Ver calendário <br />completo
                </Link>
              </>
              :
              <>
                {cirurgias.slice(0, 4).map((agendada: Cirurgias) => {
                  const data = agendada?.confirmacao_data
                    ?.split("-")
                    .reverse()
                    .join("/");
                  return (
                    <CardProxCirurgia
                      key={agendada.id}
                      status={agendada.status.descricao}
                      nome={agendada.nome}
                      doutorNome={agendada.doutor?.nome}
                      nomeCirurgia={agendada.cirurgia}
                      nomeHospital={agendada.hospital?.nome}
                      confirmacao_hora={agendada?.confirmacao_hora}
                      confirmacao_data={agendada?.confirmacao_data}
                      data={data}
                    />
                  );
                })}
                <Link to='/calendario' className='mobile-home-surgery-calendar-button'>
                  <img className='mobile-home-surgery-calendar-button-bg-calendar' src={calendar} alt="calendar-icon" />
                  <div className='mobile-home-surgery-calendar-blur-part' />
                  Ver calendário <br />completo
                </Link>
              </>
            :
            <span className='mobile-home-empty-content'>Não há cirurgias marcadas.</span>
          }
        </div>
      </div>
      <div className='mobile-home-authorization-container'>
        <span className='mobile-home-authorization-content'>Novas Autorizações:</span>
        <div className='mobile-home-authorization-list-container'>
          {
            autorizadas?.length !== 0 || pendentes?.length !== 0 ?
              window.innerWidth <= 414 ?
                [...autorizadas, ...pendentes]
                  ?.slice(0, 3)
                  .map((autorizacoes: Cirurgias) => {
                    const nomeCirurgia: string =
                      autorizacoes?.hospital === null
                        ? "-"
                        : autorizacoes?.cirurgia;
                    return (
                      <CardAutorizacao
                        onClick={dadosCirurgia}
                        key={autorizacoes.id}
                        id={autorizacoes.id}
                        nome={autorizacoes.nome}
                        doutorNome={autorizacoes.doutor?.nome}
                        status={autorizacoes.status.descricao}
                        nomeCirurgia={nomeCirurgia}
                      />
                    );
                  })
                :
                [...autorizadas, ...pendentes]
                  ?.slice(0, 4)
                  .map((autorizacoes: Cirurgias) => {
                    const nomeCirurgia: string =
                      autorizacoes?.hospital === null
                        ? "-"
                        : autorizacoes?.cirurgia;
                    return (
                      <CardAutorizacao
                        onClick={dadosCirurgia}
                        key={autorizacoes.id}
                        id={autorizacoes.id}
                        nome={autorizacoes.nome}
                        doutorNome={autorizacoes.doutor?.nome}
                        status={autorizacoes.status.descricao}
                        nomeCirurgia={nomeCirurgia}
                      />
                    );
                  })
              :
              <span className='mobile-home-empty-content'>Não há novas autorizações.</span>
          }
          {
            (autorizadas?.length || pendentes?.length) !== 0 &&
            <Link className='mobile-home-authorization-link' to='/autorizacoes'>
              Ir para a página de autorizações
              <FaAngleRight className="right-arrow mr-3" size="40px" />
            </Link>
          }
        </div>
      </div>
    </div>
  );
}

export default connector(Home);
export const MobileHome = connector(MobileHomeComp);