import React from 'react';
import './style.css';
import birdid from '../../../assets/birdid2.png'
import CpfInput from 'src/components/CpfInput';

interface Birdid {
  goToBirdidConfirmation: () => void;
}

const Birdid: React.FC<Birdid> = ({ goToBirdidConfirmation }) => {

  return (
    <>
      <div
        className="flex flex-col justify-center"
      >
        <img className="w-3/4 h-auto mx-auto" src={birdid} alt="logo birdid" />
        <CpfInput />
        <button
          className="button-assinatura-prosseguir font-black text-2xl mt-24"
          onClick={goToBirdidConfirmation}
        >
          Prosseguir
        </button>
      </div>
    </>
  )
}

export default Birdid;