import axios from "axios";

export const viaCepApi = axios.create({
  baseURL: "https://viacep.com.br/ws",
});

// const errorInterceptor = viaCepApi.interceptors.response.use(
//   function (response) {
//     return response;
//   },
//   function (error) {
//     console.log(error.response.status, error.response.data);
//     return Promise.reject(error);
//   }
// );
