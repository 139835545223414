import React from 'react';
import './style.css';
import vidas from '../../../assets/vidas2.png'
import CpfInput from 'src/components/CpfInput';
import Loader from 'src/components/Loader';

interface Vidas {
  goToVidasRequest: () => void;
  goToVidasCancel: () => void;
  vidasRequest: () => void;
  loading: boolean;
}


const Vidas: React.FC<Vidas> = ({ goToVidasRequest, goToVidasCancel, vidasRequest, loading }) => {

  return (
    <>
      <div
        className="flex flex-col justify-center"
      >
        <img className="" src={vidas} alt="logo vidas" />
        <Loader isActive={loading}>
          <CpfInput />
          <button
            onClick={vidasRequest}
            className="button-scale button-assinatura-prosseguir font-black text-2xl mt-24">
            Prosseguir
          </button>
        </Loader>
      </div>
    </>
  )
}

export default Vidas;