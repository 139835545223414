import "./style.css";
import React from "react";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";

type Props = {
  id?: any;
  nome?: string;
  whatsapp?: string;
  usuario?: Usuario;
  onClick?: (id: number, nome: any, whatsapp: any, email: any) => void;
  onDelete?: (id: number, nome: any) => void;
  onChangeVendedor?: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void | undefined;
};

type Usuario = {
  id?: number;
  email?: string;
};

function VendedoresLista({
  id,
  nome,
  whatsapp,
  usuario,
  onClick,
  onDelete,
  onChangeVendedor,
}: Props): JSX.Element {
  return (
    <tbody className='sellers-list-wrapper'>
      <tr className="sellers-list-container text-center cursor-pointer">
        <td
          title={nome}
          className="sellers-list-option-container text-left border px-4 py-2 capitalize"
        >
          <div className={"truncate sellers-list-option-content-container"} onChange={onChangeVendedor}>
            <span className='sellers-list-option-content'>{nome}</span>
          </div>
        </td>
        {/* <td title={whatsapp} className="sellers-list-option-container border px-4 py-2 capitalize">
          <div className={"truncate sellers-list-option-content-container"} onChange={onChangeVendedor}>
            <span className='sellers-list-option-content'>{whatsapp}</span>
          </div>
        </td> */}
        <td title={usuario?.email} className="sellers-list-option-container border px-4 py-2">
          <div className={"truncate sellers-list-option-content-container"} onChange={onChangeVendedor}>
            <span className='sellers-list-option-content'>{usuario?.email}</span>
          </div>
        </td>
        <div className="flex justify-center">
          <td title={"editar"} className="border mr-3 py-2">
            <button
              onClick={() =>
                onClick
                  ? onClick(id, nome, whatsapp, usuario?.email)
                  : undefined
              }
              className="sellers-list-option-button-left btn-vend bg-blue p-2 rounded-md"
            >
              <AiOutlineEdit size="1.3rem" className="text-white" /> 
            </button>
          </td>
          <td title={"deletar"} className="border py-2">
            <button
              onClick={() => (onDelete ? onDelete(id, nome) : undefined)}
              className="sellers-list-option-button-right btn-vend bg-blue p-2 rounded-md"
            >
              <AiOutlineDelete size="1.3rem" className=" text-white" />
            </button>
          </td>
        </div>
      </tr>
    </tbody>
  );
}

export default VendedoresLista;
