import * as actionTypes from "../types";

const initialState: actionTypes.SecretariaState = {
  secretarias: [],
};

export default (
  state = initialState,
  { type, payload }: actionTypes.SecretariaAction
) => {
  switch (type) {
    case actionTypes.LIST_SECRETARIAS:
      return { ...state, ...payload };
    default:
      return state;
  }
};
