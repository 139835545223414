import React from "react";
import { FaAngleRight } from "react-icons/fa";
import { ICardData } from "src/pages/MobileAuxiliaries";
// import FormError from "../FormError";
import "./style.css";

interface IMobileNotifications {
  state: ICardData;
  setState: React.Dispatch<React.SetStateAction<ICardData>>;
  showState: number;
  setShowState: React.Dispatch<React.SetStateAction<number>>;
  onSubmit: React.FormEventHandler<HTMLFormElement>;
  error: boolean;
  setError: React.Dispatch<React.SetStateAction<boolean>>;
}

const MobileNotifications: React.FC<IMobileNotifications> = ({
  showState,
  setShowState,
  state,
  setState,
  onSubmit,
  error,
  setError
}) => {
  React.useEffect(() => {
    if (showState === 0) {
      setTimeout(() => {
        setShowState(-1);
      }, 600);
    }
  }, [showState, setShowState]);

  return (
    <div
      className={`mobile-new-sellers-wrapper${
        showState === -1 ? " none" : showState === 1 ? " show" : " hide"
      }`}
    >
      <div
        onClick={() => {
          setShowState(0);
        }}
        className="mobile-new-sellers-blur-area"
      />
      <div
        className={`mobile-new-sellers-container${
          showState === -1 ? "" : showState === 1 ? " show" : " hide"
        }`}
      >
        <div className="mobile-new-sellers-top-container">
          <div className="mobile-new-sellers-white-bar" />
          <h4 className="mobile-new-sellers-title">{state.idVendedor === -1 ? 'Novo vendedor' : 'Editando Vendedor'}</h4>
        </div>
        <form className="mobile-new-sellers-form-container" onSubmit={onSubmit}>
          <div className="mobile-new-sellers-input-container">
            <label className="mobile-new-sellers-label">Nome</label>
            <input
              value={state.nome}
              onChange={(e) => {
                e.persist();
                setError(false);
                setState((old) => (old = { ...old, nome: e.target.value }));
              }}
              className="mobile-new-sellers-input"
              placeholder="Gilberto"
              type="text"
            />
            {/* <FormError absolute={true} state={!error ? false : state.nome === '' || state.nome === undefined ? true : false}>Campo inválido*</FormError> */}
          </div>
          <div className="mobile-new-sellers-input-container">
            <label className="mobile-new-sellers-label">Whatsapp</label>
            <input
              value={state.whatsapp}
              onChange={(e) => {
                e.persist();
                setError(false);
                let newValue = e.target.value.replace(/[^0-9]/g,'');
                if (newValue.length > 11) {
                  return;
                }
                if (newValue.length === 11) {
                  newValue = newValue.replace(/(\d{2})(\d{5})(\d{4})/, '($1)$2-$3');
                }
                setState((old) => (old = { ...old, whatsapp: newValue }));
              }}
              className="mobile-new-sellers-input"
              placeholder="(99)99999-9999"
              type="text"
            />
            {/* <FormError absolute={true} state={!error ? false : state.whatsapp.length < 11 || state.whatsapp === undefined ? true : false}>Telefone inválido*</FormError> */}
          </div>
          <div className="mobile-new-sellers-input-container">
            <label className="mobile-new-sellers-label">Email</label>
            <input
              value={state.email}
              onChange={(e) => {
                e.persist();
                setError(false);
                setState((old) => (old = { ...old, email: e.target.value }))}
              }
              className="mobile-new-sellers-input"
              placeholder="example@example.com"
              type="email"
            />
            {/* <FormError absolute={true} state={!error ? false : state.email.length < 13 || String(state.email) === 'undefined' ? true : false}>Email inválido*</FormError> */}
          </div>
          <button className="mobile-new-sellers-button">
            {state.idVendedor === -1 ? 'Adicionar Novo Vendedor' : 'Continuar Editando'}
            <FaAngleRight className="right-arrow mr-3" size="40px" />
          </button>
        </form>
      </div>
      <div
        className={`mobile-new-sellers-blur-mask${
          showState === -1 ? "" : showState === 1 ? " show" : " hide"
        }`}
      />
    </div>
  );
};

export default MobileNotifications;
