import * as actionTypes from "../types";

export const setSecretarias = (secretarias: actionTypes.Secretarias[]) => {
  return {
    type: actionTypes.LIST_SECRETARIAS,
    payload: {
      secretarias,
    },
  };
};
