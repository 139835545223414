import "./style.css";
import React, { Fragment, useState } from "react";
import { FaAngleLeft } from "react-icons/fa";
import InputForm from "src/components/Inputs/inputsimple";
import InputFormCompound from "src/components/Inputs/inputCompound";
import ButtonFormSubmit from "src/components/Inputs/buttonSubmit";
import { axiosApi } from "../../services/axiosInstances";
import { Link, useNavigate } from "react-router-dom";
import InputMask from "react-input-mask";
import { object, string } from "yup";
import { checkfield } from "../../helpers/functions";
import { toast, ToastContainer } from "react-toastify";
import InputSenha from "src/components/InputSenha";

type Props =  & {
  onChange?: (event: any) => void;
  onBlur?: (event: any) => void;
  erroClassName?: string;
  inputClassName?: string;
};

type Form = {
  secretaria: Array<string>;
  senha: string;
  confirm_senha: string;
};

const schema = object({
  nome: string().required(),
  email: string().required(),
  telefone: string().required(),
  whatsapp: string().required(),
  senha: string().required(),
  confirm_senha: string().required(),
});
export default function Secretaria({
  onChange,
  onBlur,
  erroClassName = "px-3 mt-2 border-2 border-red-500 bg-red-100 h-10 w-full",
  inputClassName = "px-3 mt-2 border-2 border-gray-400 h-10 w-full",
}: Props): JSX.Element {
  const [form, setForm] = useState<Form>({
    secretaria: [],
    senha: "",
    confirm_senha: "",
  });

  const postForm = (event: any) => {
    postEvent(event.target.name, event.target.value);
  };
  const postEvent = (nome: string, valor: any) => {
    setForm({ ...form, [nome]: valor });
  };

  const [state, setstate] = useState({
    nome: false,
    email: false,
    telefone: false,
    whatsapp: false,
    senha: false,
    confirm_senha: false,
  });

  const [changeMask, setChangeMask] = useState<string>("");

  const onBlurBefore = async (event: any, nome?: string) => {
    let span: boolean = false;
    if (schema) {
      const { name, value } = event.target;
      span = checkfield(name, schema, { [name]: value });
      setstate({ ...state, [name]: !span });
    }

    if (onBlur) {
      onBlur(event);
    }

    const { name, value } = event.target;
    if (name === "telefone") {
      if (value.length === 10) {
        setChangeMask("(99)9999-9999");
      } else if (value.length === 11) {
        setChangeMask("(99)99999-9999");
      } else if (value.length === 0) {
        setChangeMask("");
      }
    }
  };

  const navigate = useNavigate();
  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (state.confirm_senha || form.senha !== form.confirm_senha) {
      toast.error('Confirme a Senha!', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
    } else {
      try {
        await axiosApi.post("/secretaria", form);

        toast.success('Secretário(a) Cadastrada', {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          });
        window.setTimeout(() => {
          navigate("/quem-sou");
        }, 2000);
      } catch (error: any) {
        if (error.response.data.errorInfo[0] === "23505") {
          toast.warn('Esse Email já está cadastrado!', {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            });
        } else {
          toast.error(error.response.data.errorInfo, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            });
        }
      }
    }
  };

  const [senhaEye, setSenhaEye] = useState(false);
  const [confirmSenhaEye, setconfirmSenhaEye] = useState(false);
  const toggleSenha = () => {
    setSenhaEye(senhaEye ? false : true);
  };
  const toggleConfirmSenha = () => {
    setconfirmSenhaEye(confirmSenhaEye ? false : true);
  };

  return (
    <Fragment>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover={false}
      />
      <h1 className="form-title flex py-5">
        <Link title="Voltar" to="/quem-sou">
          <FaAngleLeft className="mr-3" size="40px" />
        </Link>{" "}
        Olá Secretária(o)!
      </h1>

      <p className="form-subtitle px-5 py-1">Novo por aqui? Crie seu perfil</p>

      <form onSubmit={handleSubmit} className="">
        <InputForm
          schema={schema}
          name="nome"
          label="Nome Completo*"
          type="text"
          onChange={postForm}
          onBlur={onBlurBefore}
          inputClassName={
            state.nome
              ? `${erroClassName} capitalize`
              : `${inputClassName} capitalize`
          }
          required={true}
          maxlength={50}
        />
        {state.nome && (
          <span className="text-red-600 text-xs px-5">*Coloque seu nome</span>
        )}

        <div className="flex justify-start w-full">
          <InputMask
            mask={changeMask}
            onChange={postForm}
            onBlur={onBlurBefore}
          >
            <InputFormCompound
              name="telefone"
              label="Telefone"
              type="text"
              maxlength={11}
              onChange={postForm}
              required={false}
            />
          </InputMask>

          <div className="relative">
            <InputMask
              mask="(99) 99999-9999"
              onChange={postForm}
              onBlur={onBlurBefore}
            >
              <InputFormCompound
                schema={schema}
                name="whatsapp"
                label="WhatsApp*"
                type="text"
                onChange={postForm}
                inputClassName={state.whatsapp ? erroClassName : inputClassName}
                required={true}
              />
            </InputMask>
            {state.whatsapp && (
              <span className="text-red-600 text-xs erro-span-2">
                *Coloque seu whatsapp
              </span>
            )}
          </div>
        </div>

        <InputForm
          schema={schema}
          onBlur={onBlurBefore}
          name="email"
          label="Email*"
          type="email"
          onChange={postForm}
          required={true}
          inputClassName={state.email ? erroClassName : inputClassName}
          maxlength={50}
        />
        {state.email && (
          <span className="text-red-600 text-xs px-5">*Coloque seu email</span>
        )}

        <div className="flex justify-start w-full pl-2">
          <div className="relative">
            <InputSenha
              schema={schema}
              name="senha"
              label="Senha*"
              senhaEffect={senhaEye}
              onClickEye={toggleSenha}
              // maxlength={6}
              onChange={postForm}
              onBlur={onBlurBefore}
              marginTop={"mt-2"}
              pattern={".{6,}"}
              inputClassName={state.senha ? erroClassName : inputClassName}
              width={"px-3"}
            />
            {state.senha && (
              <span className="text-red-600 text-xs erro-span-2 absolute right-0 mt-0">
                *Coloque sua senha
              </span>
            )}
          </div>

          <div className="relative">
            <InputSenha
              schema={schema}
              name="confirm_senha"
              label="Confirmar Senha*"
              senhaEffect={confirmSenhaEye}
              onClickEye={toggleConfirmSenha}
              // maxlength={6}
              onChange={postForm}
              onBlur={onBlurBefore}
              marginTop={"mt-2"}
              pattern={".{6,}"}
              inputClassName={
                state.confirm_senha || form.senha !== form.confirm_senha
                  ? erroClassName
                  : inputClassName
              }
              width={"px-3"}
            />
            {state.confirm_senha ? (
              <span className="text-red-600 text-xs erro-span-2 absolute right-0 mt-0">
                *Confirme sua senha
              </span>
            ) : form.senha !== form.confirm_senha ? (
              <span className="text-red-600 text-xs erro-span-2 absolute right-0 mt-0">
                *Não corresponde a senha
              </span>
            ) : (
              ""
            )}
          </div>
        </div>

        <ButtonFormSubmit name="btn-submit" type="submit" />
      </form>
    </Fragment>
  );
}
