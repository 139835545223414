import "./style.css";
import React, { Fragment, useEffect, useState } from "react";
import VendedoresLista from "../../components/CardVendedoresLista";
import ModalVendedores from "src/components/ModalVendedores";
import { axiosApi } from "src/services/axiosInstances";
import Loader from "src/components/Loader";
import ModalDelete from "src/components/ModalDeleteVendedor";
import EmptyContent from "src/components/EmptyContent";
import { BsPersonPlus } from "react-icons/bs";
import { Vendedor, VendedoresState } from "src/store/types";
import { connect, ConnectedProps } from "react-redux";
import { setUpdateTableVendedores } from "src/store/actions/vendedores";

const mapStateToProps = ({
  vendedores,
}: {
  vendedores: VendedoresState;
}): VendedoresState => {
  return {
    vendedores: vendedores.vendedores,
  };
};

const mapDispatchToProps = {
  setUpdateTableVendedores,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
  idVendedor?: number;
  thClassName?: string;
  tableClassName?: string;
};

type HomeResponse = {
  vendedores: Array<Vendedor>;
};

function AuxiliaresPage({
  thClassName = " py-2",
  tableClassName = "table-auto table-autorizacoes",
  vendedores,
  setUpdateTableVendedores,
}: Props): JSX.Element {
  const [loader, setLoader] = useState(true);
  const [idVendedor, setIdVendedor] = useState<number>();
  const [nome, setNome] = useState<string>();
  const [whatsapp, setWhatsapp] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(!open);
  const [openDelete, setOpenDelete] = useState(false);
  const toggleDelete = () => setOpenDelete(!openDelete);

  const loadDados = async () => {
    try {
      setLoader(true);
      const { data } = await axiosApi.get<HomeResponse>(`/vendedores`);
      setUpdateTableVendedores(data.vendedores);
    } catch (error: any) {
      console.log(error);
    }
    setLoader(false);
  };

  useEffect(() => {
    loadDados();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadDados2 = async () => {
    try {
      const { data } = await axiosApi.get<HomeResponse>(`/vendedores`);
      setUpdateTableVendedores(data.vendedores);
    } catch (error: any) {
      console.log(error);
    }
  };

  const dadosVendedor = (
    id: number,
    nome: string,
    whatsapp: string,
    email: string
  ): void => {
    setIdVendedor(id);
    setNome(nome);
    setWhatsapp(whatsapp);
    setEmail(email);
    setOpen(true);
  };

  const deleteVendedor = (id: number, nome: string): void => {
    setIdVendedor(id);
    setNome(nome);
    setOpenDelete(true);
  };

  const novoVendedor = (): void => {
    setIdVendedor(undefined);
    setOpen(true);
  };
  return (
    <Fragment>
      <Loader isActive={loader}>
        <ModalVendedores
          idVendedor={idVendedor}
          loadDados={loadDados2}
          nomeVendedor={nome}
          whatsappVendedor={whatsapp}
          emailVendedor={email}
          open={open}
          toggle={toggle}
        />
        <ModalDelete
          idVendedor={idVendedor}
          nomeVendedor={nome}
          openDelete={openDelete}
          loadDados={loadDados2}
          toggle={toggleDelete}
        />
        <div className="p-12 div-auxiliares">
          <div className="grid grid-flow-col justify-start">
            <h1 className="text-blue text-3xl font-bold">Auxiliares</h1>
          </div>

          <div className="grid grid-cols-full py-16 text-sm">
            <div className="flex justify-between py-0 mb-5">
              <div>
                <h2 className="text-2xl mb-3 ml-5 text-black">Usuários</h2>
              </div>

              <div className="align-middle">
                <button
                  onClick={novoVendedor}
                  className="btn-new-vendedor bg-blue text-white font-bold rounded-full align-middle px-4 py-2 flex mt-1 shadow-md"
                >
                  <BsPersonPlus size="1.3rem" className="mr-2 text-white" />{" "}
                  Novo
                </button>
              </div>
            </div>

            {vendedores.length === 0 ? (
              <EmptyContent
                messageSize="2xl"
                iconSize="2.2rem"
                paddingContent={"py-32"}
                titleContent="Sem Novos Vendedores"
              />
            ) : (
              <table className={tableClassName}>
                <thead>
                  <tr className="text-center">
                    <th className="pl-12 py-2 text-left">Nome</th>
                    <th className={thClassName}>Email</th>
                    <th className={thClassName}></th>
                  </tr>
                </thead>
                {vendedores.map((vendedor: Vendedor) => {
                  return (
                    <VendedoresLista
                      onClick={dadosVendedor}
                      onDelete={deleteVendedor}
                      key={vendedor.id}
                      id={vendedor.id}
                      nome={vendedor.nome}
                      whatsapp={vendedor.whatsapp}
                      usuario={vendedor.usuario}
                    />
                  );
                })}
              </table>
            )}
          </div>
        </div>
      </Loader>
    </Fragment>
  );
}
export default connector(AuxiliaresPage);
