import "./style.css";
import React from "react";
import { AuthState } from "src/store/types";
import { connect, ConnectedProps } from "react-redux";

const mapStateToProps = ({ auth }: { auth: AuthState }): AuthState => {
  return {
    usuario: auth.usuario,
  };
};

const connector = connect(mapStateToProps, {});

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
  id?: any;
  nome: string;
  doutorNome?: string;
  status: string;
  nomeCirurgia: string;
  onClick?: (id: number) => void;
};

function CardAutorizacao({
  nome,
  status,
  nomeCirurgia,
  id,
  onClick,
  usuario,
  doutorNome,
}: Props): JSX.Element {
  const nivelCheck = usuario?.nivel === 1 || usuario?.nivel === 3;
  return (
    <div
      className="card-autorizacao mt-3"
      onClick={() => (onClick ? onClick(id) : undefined)}
    >
      <div title={status} className="flex items-center justify-between ml-2">
        <h5 title={nome} className="text-lg name-paciente capitalize">
          {nivelCheck ? nome : doutorNome}
        </h5>

        <small className="text-sm text-gray-700">
          <div
            title={status}
            className={`state-cirurgia ${status} mr-3 mt-5`}
          ></div>
        </small>
      </div>
      <p className="text-xs card-cirurgias-small mt-0 ml-2 capitalize">
        {nomeCirurgia}
      </p>
    </div>
  );
}

export default connector(CardAutorizacao);
