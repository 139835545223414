// import Popup from "reactjs-popup";
import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import { toast } from "react-toastify";
import Popup from "reactjs-popup";
import { ModalSalvarModeloProps } from "src/pages/SolicitarCirurgia/interfaces";
import { axiosApi } from "src/services/axiosInstances";


const ModalSalvarModelo: React.FC<ModalSalvarModeloProps> = ({ isOpen, closeModal, getModelos, nomeModelo, setNomeModelo }) => {

  const { getValues } = useFormContext();
  const [message, setMessage] = useState(false);

  const novoModeloPost = async () => {
    const formData = getValues();
    const newModelo = {
      ...formData,
      nome_modelo: nomeModelo
    }
    try {
      const response = await axiosApi.post('/solicitacao-cirurgia', newModelo);
      toast.success(`Modelo Salvo com Sucesso`, {
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      console.log(newModelo);
      return response.data;
    } catch (error) {
      console.error('Erro na requisição:', error);
    }
  }

  const newModeloHandle = async () => {
    if (nomeModelo.length >= 3) {
      await novoModeloPost()
      await getModelos();
      setNomeModelo("");
      closeModal();
    }
    else {
      setMessage(true);
    }

  }

  const salvarModeloInputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value.length >= 3) {
      setMessage(false);
    }
    setNomeModelo(value);
  }

  return (
    <Popup open={isOpen}
      closeOnDocumentClick={false}
    >
      <div
        style={{ width: 370, height: 240 }}
        className="p-5 flex flex-col border items-center rounded-3xl bg-white mt-16">
        <h2 className="text-blue font-black text-lg" >Confirmação de Modelo</h2>
        <h4 className="pt-3">Salvar como:</h4>
        <div>
          <input type="text"
            style={{ width: 320 }}
            placeholder="Insira o nome de um novo modelo aqui..."
            className="mt-3 text-lg text-pl-4 font-lato placeholder-gray-600 h-10 border border-black rounded-xl"
            value={nomeModelo}
            onChange={salvarModeloInputHandler}
          />
        </div>
        {message && <div className="pl-5 flex text-red-600 justify-center">Nome do modelo é obrigatório</div>}

        <div style={{ width: 320 }} className={`flex justify-between ${message ? 'mt-2' : 'mt-8'}`}>
          <button
            onClick={closeModal}
            style={{ width: 130 }}
            className="btn-new-vendedor bg-red-700 hover:bg-red-700 text-white py-1 px-3 font-lato font-black rounded-full">
            Cancelar
          </button>
          <button style={{ width: 130 }}
            className={"btn-new-vendedor bg-blue hover:bg-blue-700 text-white py-1 px-3 font-lato font-black rounded-full"}
            onClick={newModeloHandle}
          >
            Salvar
          </button>
        </div>
      </div>
    </Popup>
  )
}


export default ModalSalvarModelo;