import React from "react"
import Relatorio from "../interface";
import useClickOutside from "../useClickOutside";

const SecretariaForm: React.FC<Relatorio> = ({
  handleDataInicialChange,
  handleDataFinalChange,
  setDescritivo,
  handleDoutorInputChange,
  setShowDoutorDropdown,
  doutorInputValue,
  showDoutorDropdown,
  filteredDoutorOptions,
  doutorDropdownRef,
  dropdownHospitalRef,
  setHospitalInputValue,
  setHospitalSuggestions,
  setHospitalId,
  setShowHospitalDropdown,
  setDoutorInputValue,
  setDoutorId,
  handleDoutorSuggestionClick,
  descritivo,
  setFornecedorInputValue,
  fornecedorInputValue,
  fornecedorDropdownRef,
  filteredFornecedorOptions,
  showFornecedorDropdown,
  handleFornecedorInputChange,
  setShowFornecedorDropdown,
  handleFornecedorSuggestionClick,
  setFornecedorId,
  setLoader,
  dataInicial,
  setDataInicial,
  dataFinal,
  setDataFinal,
  hospitalInputValue,
  hospitalId,
  hospitalSuggestions,
  handleHospitalInputChange,
  handleSuggestionHospitalClick,
  setParticipacaoId,
  showHospitalDropdown,
  handleParticipacaoInputChange,
  participacaoInputValue,
  participacaoOptions,
  handleParticipacaoSuggestionClick,
  setFilteredOptions,
  setShowParticipacaoDropdown,
  showParticipacaoDropdown,
  dropdownParticipacaoRef,
  filteredOptions,
  setParticipacaoInputValue,
  doutoresSecretaria,
}) => {

  useClickOutside(dropdownHospitalRef, () => {
    setHospitalInputValue('');
    setHospitalSuggestions([]);
    setHospitalId(null);
    setShowHospitalDropdown(false);
  });

  useClickOutside(doutorDropdownRef, () => {
    setDoutorInputValue('');
    setDoutorId(null);
    setShowDoutorDropdown(false);
  });

  useClickOutside(fornecedorDropdownRef, () => {
    setFornecedorInputValue('');
    setFornecedorId(null);
    setShowFornecedorDropdown(false);
  });

  return (

    <>
        <div className="flex flex-col mt-5 gap-y-5">
          <div className="flex gap-5 items-center">
            <div className="flex flex-col items-center">
              <label className="text-black pb-2" htmlFor="data-inicial">Data inicial*</label>
              <input
                id="data-inicial"
                style={{ width: 195 }}
                className="text-lg pl-2 font-lato placeholder-gray-600 h-10 border border-black rounded-xl"
                type="date"
                onChange={handleDataInicialChange}
                required
              />
            </div>
            <div className="flex flex-col items-center">
              <label className="text-black pb-2" htmlFor="data-final">Data final*</label>
              <input
                id="data-final"
                style={{ width: 195 }}
                className="text-lg pl-2 font-lato placeholder-gray-600 h-10 border border-black rounded-xl"
                type="date"
                onChange={handleDataFinalChange}
                required
              />
            </div>
          </div>
          <div className="flex flex-col">
            <label className="text-black" htmlFor="descritivo">Cirurgia</label>
            <input
              id="descritivo"
              style={{ width: 410 }}
              className="text-lg pl-2 font-lato placeholder-gray-600 h-10 border border-black rounded-xl"
              type="text"
              onChange={(e) => setDescritivo(e.target.value)}
              value={descritivo || ''}
            />
          </div>

          <div className="flex flex-col TypeAheadDropDown">
            <label htmlFor="cirurgiao" className="text-black">Cirurgião*</label>
            <input
              style={{ width: 400 }}
              className="TypeAheadDropDown font-lato placeholder-gray-600 h-10 border border-black rounded-xl"
              onChange={handleDoutorInputChange}
              type="text"
              id="cirurgiao"
              onFocus={() => setShowDoutorDropdown(true)}
              value={doutorInputValue || ''}
              required
            />
            {showDoutorDropdown && doutoresSecretaria.length > 0 && (
              <ul
                ref={doutorDropdownRef}
                className="TypeAheadDropDown" style={{ width: 400 }}>
                {doutoresSecretaria.map((suggestion: any) => (
                  <li
                    className="TypeAheadDropDown"
                    key={suggestion.id}
                    onClick={() => handleDoutorSuggestionClick(suggestion)}>
                    {suggestion.nome}
                  </li>
                ))}
              </ul>
            )}
          </div>

          <div className="flex flex-col TypeAheadDropDown">
            <label htmlFor="hospital_nome" className="text-black">Hospital</label>
            <input
              style={{ width: 400 }}
              className="TypeAheadDropDown font-lato placeholder-gray-600 h-10 border border-black rounded-xl"
              onChange={handleHospitalInputChange}
              type="text"
              id="hospital_nome"
              onFocus={() => setShowHospitalDropdown(true)}
              value={hospitalInputValue}
            />
            {showHospitalDropdown && hospitalSuggestions.length !== 0 && (
              <ul ref={dropdownHospitalRef} className="TypeAheadDropDown" style={{ width: 400 }}>
                {hospitalSuggestions.map((suggestion) => (
                  <li
                    className="TypeAheadDropDown"
                    key={suggestion.id}
                    onClick={() => handleSuggestionHospitalClick(suggestion)}>
                    {suggestion.nome}
                  </li>
                ))}
              </ul>
            )}
          </div>


          <div className="flex flex-col TypeAheadDropDown">
            <label htmlFor="fornecedor" className="text-black">Fornecedor</label>
            <input
              style={{ width: 400 }}
              className="TypeAheadDropDown font-lato placeholder-gray-600 h-10 border border-black rounded-xl"
              onChange={handleFornecedorInputChange}
              type="text"
              id="fornecedor"
              onFocus={() => setShowFornecedorDropdown(true)}
              value={fornecedorInputValue || ''}
            />
            {showFornecedorDropdown && filteredFornecedorOptions.length > 0 && (
              <ul
                ref={fornecedorDropdownRef}
                className="TypeAheadDropDown" style={{ width: 400 }}>
                {filteredFornecedorOptions.map((suggestion: any) => (
                  <li
                    className="TypeAheadDropDown"
                    key={suggestion.id}
                    onClick={() => handleFornecedorSuggestionClick(suggestion)}>
                    {suggestion.razao_social}
                  </li>
                ))}
              </ul>
            )}
          </div>

        </div>
    </>
  )
}

export default SecretariaForm;