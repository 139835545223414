import React, { Fragment, useEffect, useState } from "react";
import "./style.css";
import { AuthState } from "src/store/types";
import { connect, ConnectedProps } from "react-redux";
import { IoMdNotificationsOutline } from "react-icons/io";
import { IoMdNotificationsOff } from "react-icons/io";
import { axiosApi } from "src/services/axiosInstances";
import Loader from "../Loader";

const mapStateToProps = ({ auth }: { auth: AuthState }): AuthState => {
  return {
    usuario: auth.usuario,
  };
};

const connector = connect(mapStateToProps, {});

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & {
  toggleDropdown?: () => void;
  toggleDrop?: boolean;
  dropRef?: any;
  btnNotificationsClassName?: string;
};

type Notifications = {
  id: number;
  cirurgia_id: number;
  etapa_id: number;
  cirurgia: Cirurgia;
  etapa_checklist: EtapaChecklist;
};

type EtapaChecklist = {
  id: number;
  descricao: string;
};

type Cirurgia = {
  id: number;
  nome: string;
  status_id: number;
};

type NotificationCount = {
  total: number;
};

function DropdownNotifications({
  toggleDropdown,
  dropRef,
  toggleDrop,
  btnNotificationsClassName = "btn-notifications mt-1",
  usuario,
}: Props): JSX.Element {
  // const nivelCheck = usuario?.nivel === 1 || usuario?.nivel === 3;
  const [notifications, setNotifications] = useState<Notifications[]>([]);
  const [loader, setLoader] = useState(true);
  const [loadCount, setLoadCount] = useState(true);
  const [count, setCount] = useState<number>(0);

  const loadDados = async () => {
    try {
      setLoader(true);
      const { data } = await axiosApi.get<Notifications[]>(
        `/notificacoes?limit=10`
      );
      setNotifications(data);
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (toggleDrop && !notifications.length) {
      loadDados();
      setCount(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleDrop]);

  const loadCountNotifications = async () => {
    try {
      setLoadCount(true);
      const { data } = await axiosApi.get<NotificationCount>(
        `/count-notificacao`
      );
      setCount(data.total);
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoadCount(false);
    }
  };

  useEffect(() => {
    loadCountNotifications();
  }, []);

  return (
    <Fragment>
      <div className="relative" ref={dropRef}>
        <button
          className={btnNotificationsClassName}
          aria-label="Notifications"
          onClick={toggleDropdown}
        >
          {count === 0 ? (
            <Loader isActive={loadCount} hide="hidden">
              <span> </span>
            </Loader>
          ) : (
            <Loader isActive={loadCount} hide="hidden">
              <div className="absolute text-white font-bold ml-5 bg-blue rounded-full animate-bounce">
                <span className="notification-n flex text-xs lg:text-xs xl:text-xs md:text-xs sm:text-xs">
                  {count > 9 ? "9+" : count?.toString()}
                </span>
              </div>
            </Loader>
          )}

          <IoMdNotificationsOutline
            className="font-light xl:text-4xl lg:text-3xl"
            size=""
          />
        </button>

        {toggleDrop && (
          <div className={`notifications-content absolute z-20 mt-3 h-full`}>
            <div className="flex justify-center ">
              <span className="arrow-up w-10"></span>
            </div>

            <div className={"px-2 py-5 rounded-md bg-blue shadow-xl"}>
              <Loader isActive={loader} hide="hidden">
                {notifications.length === 0 ? (
                  <div className="flex justify-center">
                    <IoMdNotificationsOff className="text-white mr-1" />
                    <span className="font-bold text-xs text-white">
                      Sem novas notificações
                    </span>
                  </div>
                ) : (
                  <Fragment>
                    <Fragment>
                      <div className="overflow-y-auto overflow-x-hidden scroll-box-nf container px-2 py-1">
                        {notifications?.map((notification: Notifications) => {
                          const statusColor =
                            notification?.etapa_checklist?.id === 1
                              ? "Autorizada"
                              : notification?.etapa_checklist?.id === 2 ||
                                notification?.etapa_checklist?.id === 3 ||
                                notification?.etapa_checklist?.id === 4 ||
                                notification?.etapa_checklist?.id === 6 ||
                                notification?.etapa_checklist?.id === 7 ||
                                notification?.etapa_checklist?.id === 9
                              ? "Pendentes"
                              : notification?.etapa_checklist?.id === 5 ??
                                "Agendadas";

                          return (
                            <div className="py-2 px-2">
                              <div className="flex items-center">
                                <span
                                  className={`${statusColor} rounded-full mr-2`}
                                  style={{ padding: "6px" }}
                                />
                                <span
                                  title={
                                    notification?.etapa_checklist?.descricao
                                  }
                                  className="truncate text-white text-base font-bold"
                                >
                                  {notification?.etapa_checklist?.descricao}{" "}
                                  {statusColor}
                                </span>
                              </div>
                              <div className="notification-content">
                                <p
                                  title={notification?.cirurgia?.nome}
                                  className="truncate text-white text-sm"
                                >
                                  Cirurgia de {notification?.cirurgia?.nome}
                                </p>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </Fragment>
                  </Fragment>
                )}
              </Loader>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
}

export default connector(DropdownNotifications);
