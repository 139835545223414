import React from 'react';
import './style.css';
import birdid from '../../assets/birdID.png'
import vidas from '../../assets/vidas.png'
import arrow from '../../assets/Arrow 1.png'

interface IntroAssinatura {
  goToVidas: () => void;
  goToBirdid: () => void;
}



const IntroAssinatura: React.FC<IntroAssinatura> = ({ goToVidas, goToBirdid }) => {

  return (
    <>
      <div className="flex flex-col justify-center text-center">
        <p className="text-xl text-content-color">
          Assine suas solicitações digitais<br />
          e processaremos automaticamente a<br />
          autorização do procedimento.
        </p>

      </div>
      <div className="flex flex-col pt-12 h-auto">

        <p className="text-xl light-text">certificados integrados
        </p>

        <button className="custom-button mt-4 mb-4"
          onClick={goToBirdid}
        >
          <img className="logo" src={birdid} alt="logo birdid" />
          <img className="" src={arrow} alt="arrow para a direita" />
        </button>

        <button className="custom-button"
          onClick={goToVidas}
        >
          <img className="logo" src={vidas} alt="logo vidas" />
          <img className="" src={arrow} alt="arrow para a direita" />
        </button>

        <p className="footer-text text-content-color text-lg"
        >*Em breve, mais opções de certificados digitais
        </p>

      </div>
    </>
  )
}

export default IntroAssinatura;