import React, { useEffect, useState} from 'react';
import { BiCheck } from 'react-icons/bi';
import { BsChevronDown } from 'react-icons/bs';
import './styles.css';
import { axiosApi } from 'src/services/axiosInstances';
import { AiOutlineFileText } from 'react-icons/ai';
import { FaChevronDown } from 'react-icons/fa';

interface Props {
  idCirurgia?: number;
  accordionTitle?: string;
  actAccordion?: boolean;
  mobile?: boolean;
  toggleAccordion?: (event: any) => void;
}

type Anexos = {
  id: number;
  nome: string;
  url: string;
  tipo_mime: string;
};

type Checklist = {
  pos_cirurgia: string;
  pos_cirurgia_anexo1: Anexos;
  pos_cirurgia_anexo2: Anexos;
};

export const AccordionPosCirurgia = ({ 
  idCirurgia,
  actAccordion,
  accordionTitle,
  toggleAccordion,
  mobile,
}: Props) => {
  const [dadosModal, setDadosModal] = useState<Checklist>();
  const active = !actAccordion ? "active-accordion" : " ";
  const height = !actAccordion ? "0px" : "100%";
  const display = !actAccordion ? "none" : "block";
  const icon = !actAccordion ? "accordion-icon" : "accordion-icon-rotate";
  const title = !actAccordion ? "black" : "var(--primary-blue-default)";

  const loadDados = async () => {
    try {
      const { data } = await axiosApi.get<Checklist>(
        `/checklist/${idCirurgia}`
      );
      setDadosModal(data);
    } catch (error: any) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (idCirurgia) {
      loadDados();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idCirurgia]);
  return (
    !dadosModal?.pos_cirurgia ? 
    <div className="accordion-btn shadow-md rounded-lg text-left text-gray-300 text-md font-bold mb-3">
      <div className="flex justify-between cursor-pointer p-3">
        <div className="rounded-full p-1 bg-white border-gray-300 border-2">
          <BiCheck size="1rem" className="text-white" />
        </div>
        <p className="disabled-accordion-title font-bold xl:text-base md:text-sm sm:text-sm">
          {accordionTitle}
        </p>

        <FaChevronDown size="1rem" className={`mt-1 `} />
      </div>
    </div>
    :
    <>
      <div className="accordion-section py-3">
        <div
          className={`accordion-btn text-left ${active}`}
          onClick={toggleAccordion}
        >
          <div className="flex justify-between cursor-pointer p-3">
            <div className="rounded-full p-1 bg-blue border-gray-400 border-2">
              <BiCheck size="1rem" className="text-white" />
            </div>

            <p
              className="accordion-title font-bold xl:text-base md:text-sm sm:text-sm"
              style={{ color: `${title}`, transition: "0.5s" }}
            >
              {accordionTitle}
            </p>
            <BsChevronDown size="1rem" className={`mt-1 ${icon}`} />
          </div>
        </div>
        <div
          className="accordion-content"
          style={{ height: `${height}`, display: `${display}` }}
        >
          <div className={`${mobile ? 'mobile-especificacoes-accordion-content-container ' : ''}container p-2`}>
            <form className="py-2">
              <div className="px-8 py-2">
                <p className="text-base font-semibold text-black mb-1">
                  Observações da Cirurgia
                </p>
                <textarea cols={30} disabled={true} className='accordion-pos-textarea' value={dadosModal?.pos_cirurgia}/>
              </div>
              
              <div className="pb-0 pt-2">
                  <h3 className="text-blue font-bold leading-3 pb-3">Anexos</h3>
                  {dadosModal?.pos_cirurgia_anexo1?.nome &&
                    <div className="pb-2">
                      <h6 className="text-xs text-black font-bold leading-3">
                        Anexo 1
                      </h6>
                      <div className="flex items-center">
                        <AiOutlineFileText
                          className="mt-1 text-blue mr-1"
                          size="1.3rem"
                        />
                        <div className="text-gray-900 text-xs cursor-pointer mt-1 file-span">
                          <a
                            className="border-none outline-none hover:text-blue-500 transition duration-150 ease-in-out"
                            // eslint-disable-next-line react/jsx-no-target-blank
                            target="_blank"
                            
                            title={dadosModal?.pos_cirurgia_anexo1?.nome}
                            href={`${dadosModal?.pos_cirurgia_anexo1?.url}`}
                          >
                            {dadosModal?.pos_cirurgia_anexo1?.nome}
                          </a>
                        </div>
                      </div>
                    </div>
                  }

                  {dadosModal?.pos_cirurgia_anexo2?.nome &&
                    <div className="pb-2">
                      <h6 className="text-xs text-black font-bold leading-3">
                        Anexo 2
                      </h6>
                      <div className="flex items-center">
                        <AiOutlineFileText
                          className="mt-1 text-blue mr-1"
                          size="1.3rem"
                        />
                        <div className="text-gray-900 text-xs cursor-pointer mt-1 file-span">
                          <a
                            className="border-none outline-none hover:text-blue-500 transition duration-150 ease-in-out"
                            // eslint-disable-next-line react/jsx-no-target-blank
                            target="_blank"
                            
                            title={dadosModal?.pos_cirurgia_anexo2?.nome}
                            href={`${dadosModal?.pos_cirurgia_anexo2?.url}`}
                          >
                            {dadosModal?.pos_cirurgia_anexo2?.nome}
                          </a>
                        </div>
                      </div>
                    </div>
                  }
                 
                </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
  // return (
  //   <div className={`accordion-pos-wrapper${state ? ' spacing' : ''} ${active}`}>
  //     <div className={`accordion-pos-title-wrapper ${active}`} onClick={clickAction}>
  //       <div className='accordion-pos-title-container '>
  //         <div className='accordion-pos-check-icon-container'>
  //           <BiCheck size="1rem" className='accordion-pos-check-icon' />
  //         </div>
  //         <span className='accordion-pos-title'>Pós-cirurgia</span>
  //       </div>
  //       <BsChevronDown size="16px" className={`accordion-pos-arrow${state ? ' flip' : ''}`} />
  //     </div>
  //     <div className={`accordion-pos-content-container${state ? ' show' : ''}`}>
  //       <div className='accordion-pos-centerbox-container'>
  //         <span className='accordion-pos-centerbox-title'>Observações da Cirurgia</span>
  //         <textarea disabled={true} className='accordion-pos-textarea' value={dadosModal?.pos_cirurgia}/>
  //       </div>
  //       <div className="pb-0 pt-2">
  //                 <h3 className="text-blue font-bold leading-3 pb-3">Anexos</h3>
                  
                  

  //                 <div className="pb-2">
  //                   <h6 className="text-xs text-black font-bold leading-3">
  //                     Anexo 1
  //                   </h6>
  //                   <div className="flex items-center">
  //                     <AiOutlineFileText
  //                       className="mt-1 text-blue mr-1"
  //                       size="1.3rem"
  //                     />
  //                     <div className="text-gray-900 text-xs cursor-pointer mt-1 file-span">
  //                       <a
  //                         className="border-none outline-none hover:text-blue-500 transition duration-150 ease-in-out"
  //                         // eslint-disable-next-line react/jsx-no-target-blank
  //                         target="_blank"
                          
  //                         title={dadosModal?.pos_cirurgia_anexo1?.nome}
  //                         href={`${dadosModal?.pos_cirurgia_anexo1?.url}`}
  //                       >
  //                         {dadosModal?.pos_cirurgia_anexo1?.nome}
  //                       </a>
  //                     </div>
  //                   </div>
  //                 </div>

  //                 <div className="pb-2">
  //                   <h6 className="text-xs text-black font-bold leading-3">
  //                     Anexo 2
  //                   </h6>
  //                   <div className="flex items-center">
  //                     <AiOutlineFileText
  //                       className="mt-1 text-blue mr-1"
  //                       size="1.3rem"
  //                     />
  //                     <div className="text-gray-900 text-xs cursor-pointer mt-1 file-span">
  //                       <a
  //                         className="border-none outline-none hover:text-blue-500 transition duration-150 ease-in-out"
  //                         // eslint-disable-next-line react/jsx-no-target-blank
  //                         target="_blank"
                          
  //                         title={dadosModal?.pos_cirurgia_anexo2?.nome}
  //                         href={`${dadosModal?.pos_cirurgia_anexo2?.url}`}
  //                       >
  //                         {dadosModal?.pos_cirurgia_anexo2?.nome}
  //                       </a>
  //                     </div>
  //                   </div>
  //                 </div>
                 
  //               </div>
  //     </div>
  //   </div>
  // );
}