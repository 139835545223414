import * as actionTypes from "../types";

export const setDoutoresAdmin = (doutores: actionTypes.DoutoresAdmin[]) => {
  return {
    type: actionTypes.LIST_DOUTORES,
    payload: {
      doutores,
    },
  };
};
