import Popup from "reactjs-popup";
import React from "react"
import { ModalExcluirModeloProps } from "src/pages/SolicitarCirurgia/interfaces";

const ModalExcluirModelo: React.FC<ModalExcluirModeloProps> = ({isOpen, closeModal, nomeModelo, deleteModel, modelId}) => {

  return (
    <Popup
    open={isOpen}
    closeOnDocumentClick={false}
    >
     <div
        style={{ width: 370, height: 180 }}
        className="p-5 flex flex-col border items-center rounded-3xl bg-white mt-16">
        <h2 className="text-blue font-black text-lg" >Deseja confirmar a exclusão do modelo {`${nomeModelo}`} ?</h2>
        <div style={{ width: 320 }} className={`flex justify-between ${'mt-8'}`}>
          <button
            onClick={closeModal}
            style={{ width: 130 }}
            className="btn-new-vendedor bg-red-700 hover:bg-red-700 text-white py-1 px-3 font-lato font-black rounded-full">
            Cancelar
          </button>
          <button style={{ width: 130 }}
            className={"btn-new-vendedor bg-blue hover:bg-blue-700 text-white py-1 px-3 font-lato font-black rounded-full"}
            onClick={() => deleteModel(modelId)}
          >
            Confirmar
          </button>
        </div>
      </div>
    </Popup>
  )
}


export default ModalExcluirModelo;