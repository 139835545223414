import React from "react";
import "./style.css";
import camera from "../../assets/MobileHeaderMenu/MobileProfile/Camera.svg";
import userImage from "../../assets/MobileHeaderMenu/MobileProfile/User-Image.svg";
import { AuthAction, AuthState, Usuario } from "src/store/types";
import { connect, ConnectedProps } from "react-redux";
import * as auth from "src/store/actions/auth";
import { jsonToFormData } from "src/helpers/functions";
import { axiosApi } from "src/services/axiosInstances";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
// import FormError from "../FormError";
import { profileValidator } from "src/helpers/validatorPerfil";
import FormError from "../FormError";
// import userPng from "../../assets/1645620449.png";

type IMobileProfile = {
  state: number;
  setState: React.Dispatch<React.SetStateAction<number>>;
};

const mapStateToProps = ({ auth }: { auth: AuthState }): AuthState => {
  return {
    usuario: auth.usuario,
  };
};

const mapDispatchToProps = (dispatch: React.Dispatch<AuthAction>) => {
  return {
    setUpdatePerfil: (usuario: Usuario) =>
      dispatch(auth.setUpdatePerfil(usuario)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & IMobileProfile;

export type StateForm = {
  razao_social?: string;
  fantasia?: string;
  whatsapp?: string;
  telefone?: string;
  email?: string;
  nome?: string;
  foto?: string;
  senha: string;
  confirm_senha: string;
};

const MobileProfile = ({
  state,
  setState,
  usuario,
  setUpdatePerfil,
}: Props) => {
  const [errorState, setErrorState] = React.useState(false);
  const [showPass, setShowPass] = React.useState({
    pass: false,
    confirmPass: false,
  });
  const [formValue, setFormValue] = React.useState<StateForm>({
    senha: "",
    confirm_senha: "",
  });
  console.log(errorState);
  const nivelCheck = usuario?.nivel === 3;
  const adminCheck = usuario?.nivel === 5;
  const doutorCheck = usuario?.nivel === 1;
  const fornecedorCheck = usuario?.nivel === 2;

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (profileValidator(formValue, setErrorState) !== true) {
      return;
    }    
    const contentType = "multipart/form-data";
    let newData = formValue;
    delete newData['caminho'];
    const dataFormated = await jsonToFormData(newData, "PUT");

    const response = await axiosApi({
      method: "POST",
      headers: { "Content-Type": contentType },
      url: adminCheck
        ? `/administrador`
        : nivelCheck
        ? "/secretaria"
        : fornecedorCheck
        ? "/fornecedor"
        : doutorCheck
        ? "/perfil-doutor?web=true"
        : "/vendedor",
      data: dataFormated,
    });
    setUpdatePerfil(response.data.usuario);
    setState(0);
  };

  const [image, setImage] = React.useState({ preview: "", raw: "" });

  const imageChange = (e: any) => {
    const { name, files } = e.target;
    if (files.length) {
      setImage({
        preview: URL.createObjectURL(files[0]),
        raw: files[0],
      });
      setFormValue({
        ...formValue,
        [name]: files[0],
      });
    }
  };

  const loadDadosAdm = async () => {
    try {
      const { data } = await axiosApi.get<StateForm>(
        `/administrador/${usuario?.id}`
      );
      setFormValue(data);
    } catch (error: any) {
      console.log(error);
    }
  };

  const loadDadosDoutor = async () => {
    try {
      const { data } = await axiosApi.get<StateForm>(`/doutor-perfil-web`);
      setFormValue(data);
    } catch (error: any) {
      console.log(error);
    }
  };

  const loadDados = async () => {
    try {
      const { data } = await axiosApi.get<StateForm>(`/secretaria`);
      setFormValue(data);
    } catch (error: any) {
      console.log(error);
    }
  };

  const loadDadosFornecedor = async () => {
    try {
      const { data } = await axiosApi.get<StateForm>(`/perfil-fornecedor`);
      setFormValue(data);
    } catch (error: any) {
      console.log(error);
    }
  };

  const loadDadosAuxiliar = async () => {
    try {
      const { data } = await axiosApi.get<StateForm>(`/perfil-vendedor`);
      setFormValue(data);
    } catch (error: any) {
      console.log(error);
    }
  };
  
  React.useEffect(() => {
    if (state === 1) {
      if (adminCheck) {
        loadDadosAdm();
      } else {
        if (nivelCheck) {
          loadDados();
        } else if (doutorCheck) {
          loadDadosDoutor();
        } else if (fornecedorCheck) {
          loadDadosFornecedor();
        } else {
          loadDadosAuxiliar();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, nivelCheck, adminCheck, doutorCheck]);

  return (
    <div className={`mobile-profile-wrapper${state === -1 ? '': state === 1? " show" : " hide"}`}>
      <form
        onSubmit={(e) => handleSubmit(e)}
        className="mobile-profile-form-container"
      >
      <div className="mobile-profile-top-container">
        <h2 className="mobile-profile-title">Meu Perfil</h2>
        <div className="mobile-profile-user-image-container">
          {image.preview || usuario?.foto ? (
            image.preview ? (
              <img
                src={image.preview}
                className='mobile-profile-user-image'
                alt=""
              />
            ) : (
              <img
                src={usuario?.foto}
                className='mobile-profile-user-image'
                alt=""
              />
            )
          ) : (
            <img className='mobile-profile-empty-user-image' src={userImage} alt="user-img" />
          )}
          <div className="mobile-profile-photograph">
            <label htmlFor="camera-file-input">
              <img src={camera} alt="camera-icon" />
            </label>
            <input
              name="foto"
              id="camera-file-input"
              type="file"
              accept=".jpg, .png, .jpeg"
              onChange={imageChange}
              style={{ display: "none" }}
            />
          </div>
        </div>
      </div>
        <div className="mobile-profile-input-container">
          <label className="mobile-profile-label">Razão Social</label>
          <input
            value={formValue.razao_social}
            onChange={(e) => {
                e.persist();
              setErrorState(false);
              setFormValue(
                (old) => (old = { ...old, razao_social: e.target.value })
              );
            }}
            className="mobile-profile-input"
            placeholder="Asas"
            type="text"
          />
          <FormError state={errorState && formValue.razao_social === '' ? true : false}>Campo inválido*</FormError>
        </div>
        <div className="mobile-profile-input-container">
          <label className="mobile-profile-label">Nome Fantasia</label>
          <input
            value={formValue.fantasia}
            onChange={(e) => {
                e.persist();
              setErrorState(false);
              setFormValue(
                (old) => (old = { ...old, fantasia: e.target.value })
              );
            }}
            className="mobile-profile-input"
            placeholder="Ortocardio"
            type="text"
          />
          <FormError state={errorState && formValue.fantasia === '' ? true : false}>Campo inválido*</FormError>
        </div>
        <div className="mobile-profile-input-row">
          <div className="mobile-profile-input-container">
            <label className="mobile-profile-label">Whatsapp</label>
            <input
              value={formValue.whatsapp}
              onChange={(e) => {
                e.persist();
                setErrorState(false);
                let newValue = e.target.value.replace(/[^0-9]/g,'');
                if (newValue.length > 11) {
                  return;
                }
                if (newValue.length === 11) {
                  newValue = newValue.replace(/(\d{2})(\d{5})(\d{4})/, '($1)$2-$3');
                }
                setFormValue(old => (old = { ...old, whatsapp: newValue}))
              }}
              className="mobile-profile-input"
              placeholder="(99)99999-9999"
              type="text"
            />
            <FormError state={errorState && String(formValue.whatsapp).length < 11 ? true : false}>Telefone inválido*</FormError>
          </div>
          <div className="mobile-profile-input-container">
            <label className="mobile-profile-label">Telefone</label>
            <input
              value={formValue.telefone}
              onChange={(e) => {
                e.persist();
                setErrorState(false);
                let newValue = e.target.value.replace(/[^0-9]/g,'');
                if (newValue.length > 11) {
                  return;
                }
                if (newValue.length === 11) {
                  newValue = newValue.replace(/(\d{2})(\d{5})(\d{4})/, '($1)$2-$3');
                }
                setFormValue(old => (old = { ...old, telefone: newValue}))
              }}
              className="mobile-profile-input"
              placeholder="(99)99999-9999"
              type="text"
            />
            <FormError state={errorState && String(formValue.telefone).length < 11 && String(formValue.telefone).length !== 0 ? true : false}>Telefone inválido*</FormError>
          </div>
        </div>
        <div className="mobile-profile-input-container">
          <label className="mobile-profile-label">Email</label>
          <input
            value={formValue.email}
            onChange={(e) => {
                e.persist();
              setErrorState(false);
              setFormValue(
                (old) => (old = { ...old, email: e.target.value })
              );
            }}
            className="mobile-profile-input"
            placeholder="example@example.com"
            type="email"
          />
          <FormError state={errorState && String(formValue.email).length < 13 ? true : false}>Email inválido*</FormError>
        </div>
        <div className="mobile-profile-input-row">
          <div className="mobile-profile-input-container">
            <label className="mobile-profile-label">Senha</label>
            <input
              value={formValue.senha}
              onChange={(e) => {
                e.persist();
                setErrorState(false);
                setFormValue(
                  (old) => (old = { ...old, senha: e.target.value })
                );
              }}
              className="mobile-profile-input-password"
              placeholder="·······"
              type={`${!showPass.pass ? "password" : "text"}`}
            />
            <FormError absolute={true} state={!errorState ? false : formValue.senha === undefined ? true : formValue.senha?.length < 3 ? true : false }>{formValue.confirm_senha !== formValue.senha ? 'As senhas não coincidem*' : 'Senha inválida*'}</FormError>
            <AiOutlineEyeInvisible
              onClick={() =>
                setShowPass((old) => (old = { ...old, pass: !old.pass }))
              }
              className={`show-pass-icon ${showPass.pass ? " show" : ""}`}
            />
            <AiOutlineEye
              style={{color: 'var(--neutral-400)'}}
              onClick={() =>
                setShowPass((old) => (old = { ...old, pass: !old.pass }))
              }
              className={`show-pass-icon ${!showPass.pass ? " show" : ""}`}
            />
          </div>
          <div className="mobile-profile-input-container">
            <label className="mobile-profile-label">Confirmar Senha</label>
            <input
              value={formValue.confirm_senha}
              onChange={(e) => {
                e.persist();
                setErrorState(false);
                setFormValue(
                  (old) =>
                    (old = { ...old, confirm_senha: e.target.value })
                );
              }}
              className="mobile-profile-input-password"
              placeholder="·······"
              type={`${!showPass.confirmPass ? "password" : "text"}`}
            />
            <FormError absolute={true} state={!errorState ? false : formValue.confirm_senha === undefined ? true : formValue.confirm_senha?.length < 3 ? true : formValue.confirm_senha !== formValue.senha ? true: false}>{formValue.confirm_senha !== formValue.senha ? 'As senhas não coincidem*' : 'Senha inválida*'}</FormError>
            <AiOutlineEyeInvisible
              onClick={() =>
                setShowPass(
                  (old) => (old = { ...old, confirmPass: !old.confirmPass })
                )
              }
              className={`show-pass-icon${
                showPass.confirmPass ? " show" : ""
              }`}
            />
            <AiOutlineEye
              style={{color: 'var(--neutral-400)'}}
              onClick={() =>
                setShowPass(
                  (old) => (old = { ...old, confirmPass: !old.confirmPass })
                )
              }
              className={`show-pass-icon${!showPass.confirmPass ? " show" : ""}`}
            />
          </div>
        </div>
        <div className="mobile-profile-button-row">
          <div
            className="mobile-profile-button-back"
            onClick={() => {
              setState(0);
            }}
          >
            Voltar
          </div>
          <button type="submit" className="mobile-profile-button-save">
            Salvar
          </button>
        </div>
      </form>
    </div>
  );
};

export default connector(MobileProfile);
