import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import InputMask from 'react-input-mask';
import './style.css';

const CpfInput: React.FC = () => {
  const usuario = useSelector((state: any) => state.auth.usuario);
  const [cpf, setCpf] = useState('');

  useEffect(() => {
    if (usuario && usuario.cpf) {
      setCpf(usuario.cpf);
    }
  }, [usuario]);

  return (
    <div className="input-container pt-10">
      <label htmlFor="cpf" className="input-label">Confirme CPF cadastrado</label>
      <InputMask
        mask="999.999.999-99"
        className="input-field text-black text-center"
        placeholder=""
        value={cpf}
        readOnly
      />
    </div>
  );
};

export default CpfInput;
