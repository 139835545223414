import React, { Fragment } from "react";
import Popup from "reactjs-popup";
import "../ModalGuiaTemplate/style.css";
import imgTemplate from "src/assets/guia_materiais_template.png";

type Props = {
  open?: any;
  close?: any;
  toggle?: any;
};

function ModalGuiaTemplate({ open, close, toggle }: Props): JSX.Element {
  const modalWidth = { width: "50%" };
  return (
    <Popup
      open={open}
      closeOnDocumentClick={true}
      // onClose={close}
      modal
      contentStyle={modalWidth}
    >
      <Fragment>
        <div className="bg-white modal py-5 px-10">
          <div className="header pt-5 pb-3 truncate text-3xl text-center font-bold"></div>
          <div className="content">
            <div className="flex justify-center pb-5">
              <h1 className="text-blue text-sm xl:text-lg md:text-base font-bold">
                A guia de materiais contém a solicitação de todos os materiais
                necessários para a realização de determinada cirurgia. Segue
                abaixo um exemplo de guia de materiais. Lembrando que nem toda
                cirurgia necessita obrigatoriamente de uma guia desta.
              </h1>
            </div>

            <div>
              <img src={imgTemplate} alt="img-template" className="w-full" />
            </div>
            <div className="flex justify-center py-3 mt-3" onClick={close}>
              <div className="px-5 py-2 bg-blue rounded-full text-white font-bold text-xl cursor-pointer">
                <span>Ok, entendi</span>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    </Popup>
  );
}

export default ModalGuiaTemplate;
