import "./style.css";
import React, { Fragment } from "react";
import { AuthState } from "src/store/types";
import { connect, ConnectedProps } from "react-redux";

const mapStateToProps = ({ auth }: { auth: AuthState }): AuthState => {
  return {
    usuario: auth.usuario,
  };
};

const connector = connect(mapStateToProps, {});

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
  nome: string;
  doutorNome?: string;
  nomeCirurgia: string;
  nomeHospital: string;
  confirmacao_hora: string;
  doutorRequisitadoId?: number;
  doutorRequisitadoNome?: string;
};

// eslint-disable-next-line no-empty-pattern
function CardCalendario({
  nome,
  doutorNome,
  nomeCirurgia,
  nomeHospital,
  confirmacao_hora,
  usuario,
  doutorRequisitadoId,
  doutorRequisitadoNome
}: Props): JSX.Element {
  const nivelCheck = usuario?.nivel === 1 || usuario?.nivel === 3;
  const color = doutorRequisitadoNome == null ? "#E0FFED" : "#FFF3CF";
  const cssClass = doutorRequisitadoNome == null ? "div-calendario-pacientes border-calendario border-Agendado mb-8 bg-black" : "div-calendario-pacientes border-calendario border-Agendado-equipe mb-8 bg-black";

  return (
    <div className={cssClass} style={{"backgroundColor": color}} >
      <div className="ml-3">
        <div className="flex justify-between ml-2 mb-0">
          {nivelCheck ? (
            <h1 className="text-2xl font-bold name-paciente capitalize">
              {nome}
            </h1>
          ) : (
            <Fragment>
              <div className="">
                <h1
                  title={doutorNome}
                  className="text-2xl font-bold name-paciente capitalize"
                >
                  {doutorNome}
                </h1>
                <h3
                  title={nome}
                  className="text-md font-semibold text-grey name-paciente capitalize"
                >
                  {nome}
                </h3>
              </div>
            </Fragment>
          )}

          <small className="text-sm text-gray-700">
            <div className="text-blue mr-5 mt-1 text-2xl font-bold">
              {confirmacao_hora}
            </div>
          </small>
        </div>
        {!doutorRequisitadoNome &&
          <p
              title={doutorNome}
              className="text-lg card-cirurgias-small ml-2 mb-3 capitalize font-light"
            >
              Doutor: {doutorNome}
          </p>
        }
       {doutorRequisitadoNome &&
          <p
              title={doutorNome}
              className="text-lg card-cirurgias-small ml-2 mb-3 capitalize font-light"
            >
              Doutor requisitante: {doutorNome}
          </p>
        }
        {doutorRequisitadoNome &&
          <p
            title={doutorRequisitadoNome}
            className="text-lg card-cirurgias-small ml-2 mb-3 capitalize font-light"
          >
            Doutor requisitado: {doutorRequisitadoNome}
          </p>
        }
        <p
          title={nomeCirurgia}
          className="text-lg card-cirurgias-small ml-2 mb-3 capitalize font-light"
        >
          {nomeCirurgia}
        </p>
        <p
          title={nomeHospital}
          className="text-lg card-cirurgias-small ml-2 mb-5 capitalize font-light"
        >
          {nomeHospital}
        </p>
      </div>
    </div>
  );
}

export default connector(CardCalendario);
