import React from "react"
import useClickOutside from "../useClickOutside";
import Relatorio from "../interface";

const FornecedorForm: React.FC<Relatorio> = ({
  dataInicial,
  dataFinal,
  descritivo,
  setDescritivo,
  setDataInicial,
  setDataFinal,
  handleDataInicialChange,
  handleDataFinalChange,
  hospitalInputValue,
  setHospitalInputValue,
  hospitalId,
  setHospitalId,
  hospitalSuggestions,
  setHospitalSuggestions,
  handleHospitalInputChange,
  handleSuggestionHospitalClick,
  setShowHospitalDropdown,
  showHospitalDropdown,
  dropdownHospitalRef,
  doutorId,
  doutorInputValue,
  handleDoutorSuggestionClick,
  doutorSuggestions,
  handleDoutorInputChange,
  showDoutorDropdown,
  setShowDoutorDropdown,
  doutorDropdownRef,
  filteredDoutorOptions,
  setDoutorInputValue,
  setDoutorSuggestions,
  setDoutorId,
}) => {

  useClickOutside(dropdownHospitalRef, () => {
    setHospitalInputValue('');
    setHospitalSuggestions([]);
    setHospitalId(null);
    setShowHospitalDropdown(false);
  });

  useClickOutside(doutorDropdownRef, () => {
    setDoutorInputValue('');
    setDoutorId(null);
    setShowDoutorDropdown(false);
  });

  return (

    <>
        <div className="flex flex-col mt-5 gap-y-5">
          <div className="flex gap-5 items-center">
            <div className="flex flex-col items-center">
              <label className="text-black pb-2" htmlFor="data-inicial">Data inicial*</label>
              <input
                id="data-inicial"
                style={{ width: 195 }}
                className="text-lg pl-2 font-lato placeholder-gray-600 h-10 border border-black rounded-xl"
                type="date"
                onChange={handleDataInicialChange}
                required
              />
            </div>
            <div className="flex flex-col items-center">
              <label className="text-black pb-2" htmlFor="data-final">Data final*</label>
              <input
                id="data-final"
                style={{ width: 195 }}
                className="text-lg pl-2 font-lato placeholder-gray-600 h-10 border border-black rounded-xl"
                type="date"
                onChange={handleDataFinalChange}
                required
              />
            </div>
          </div>
          <div className="flex flex-col">
            <label className="text-black pb-2" htmlFor="descritivo">Cirurgia</label>
            <input
              id="descritivo"
              style={{ width: 410 }}
              className="text-lg pl-2 font-lato placeholder-gray-600 h-10 border border-black rounded-xl"
              type="text"
              onChange={(e) => setDescritivo(e.target.value)}
              value={descritivo || ''}
            />
          </div>

          <div className="flex flex-col TypeAheadDropDown">
            <label htmlFor="cirurgiao" className="text-black">Cirurgião</label>
            <input
              style={{ width: 400 }}
              className="TypeAheadDropDown font-lato placeholder-gray-600 h-10 border border-black rounded-xl"
              onChange={handleDoutorInputChange}
              type="text"
              id="cirurgiao"
              onFocus={() => setShowDoutorDropdown(true)}
              value={doutorInputValue || ""}
            />
            {showDoutorDropdown && filteredDoutorOptions.length > 0 && (
              <ul
                ref={doutorDropdownRef}
                className="TypeAheadDropDown" style={{ width: 400 }}>
                {filteredDoutorOptions.map((suggestion: any) => (
                  <li
                    className="TypeAheadDropDown"
                    key={suggestion.id}
                    onClick={() => handleDoutorSuggestionClick(suggestion)}>
                    {suggestion.nome}
                  </li>
                ))}
              </ul>
            )}
          </div>

          <div className="flex flex-col TypeAheadDropDown">
            <label htmlFor="hospital_nome" className="text-black">Hospital</label>
            <input
              style={{ width: 400 }}
              className="TypeAheadDropDown font-lato placeholder-gray-600 h-10 border border-black rounded-xl"
              onChange={handleHospitalInputChange}
              type="text"
              id="hospital_nome"
              onFocus={() => setShowHospitalDropdown(true)}
              value={hospitalInputValue}
            />
            {showHospitalDropdown && hospitalSuggestions.length > 0 && (
              <ul ref={dropdownHospitalRef} className="TypeAheadDropDown" style={{ width: 400 }}>
                {hospitalSuggestions.map((suggestion) => (
                  <li
                    className="TypeAheadDropDown"
                    key={suggestion.id}
                    onClick={() => handleSuggestionHospitalClick(suggestion)}>
                    {suggestion.nome}
                  </li>
                ))}
              </ul>
            )}
          </div>




          {/* <div className="flex flex-col">
            <label className="text-black pb-2" htmlFor="participacao">Instrumentador</label>
            <input
              id="instrumentador"
              style={{ width: 410 }}
              className="text-lg pl-2 font-lato placeholder-gray-600 h-10 border border-black rounded-xl"
              placeholder="" type="text"
            {...register("nome")}
            />
            {errors.nome && <span className="text-red-600">{errors.nome.message as string}</span>}
          </div> */}

        </div>
    </>
  )
}

export default FornecedorForm;