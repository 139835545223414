import React from "react";

type Props = {
  label: string;
  name: string;
  labelClassName?: string;
  inputClassName?: string;
  htmlFor: string;
  id: string;
  value: string;
  spanClassName?: string;
  required?: boolean;
  onChange?: (event: any) => void;
  // checked?: any;
  checked?: any;
};

export default function InputFormRadio({
  label,
  name,
  required,
  labelClassName = "radio-label flex items-center cursor-pointer text-xs xl:text-sm sm:text-xs",
  inputClassName = "radio-input hidden",
  spanClassName = "radio-span rounded-full bg-gray-400 mr-1 w-3 h-3 xl:w-4 xl:h-4 focus-within:text-red-600",
  htmlFor,
  id,
  value,
  onChange,
  checked,
}: Props) {
  return (
    <div>
      <input
        id={id}
        className={inputClassName}
        type="radio"
        name={name}
        value={value}
        onChange={onChange}
        required={required}
        defaultChecked={checked}
      />
      <label className={labelClassName} htmlFor={id}>
        <span className={spanClassName}></span>
        {label}
      </label>
    </div>
  );
}
