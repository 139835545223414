import React from 'react';
import './style.css'

interface IMobileCalendarCard {
  title: string;
  time: string;
  doctor: string;
  surgery: string;
  hospital: string;
  onClick: () => void;
}

const MobileCalendarCard: React.FC<IMobileCalendarCard> = ({
  hospital,
  doctor,
  surgery,
  time,
  title,
  onClick,
}) => {
  return (
    <div onClick={onClick} className='mobile-calendar-card-wrapper'>
      <div className='mobile-calendar-card-top-container'>
        <div className='mobile-calendar-card-title-container'>
          <h4 className='mobile-calendar-card-title'>{title}</h4>
          <time className='mobile-calendar-card-time'>{time}</time>
        </div>
        <span className='mobile-calendar-card-subtitle'>{doctor}</span>
      </div>
      <div className='mobile-calendar-card-center-container'>
        <span className='mobile-calendar-card-content'>{surgery}</span>
        <span className='mobile-calendar-card-content'>{hospital}</span>
      </div>
    </div>
  );
}

export default MobileCalendarCard;
