import React from "react";
import "./style.css";
import { BsChevronDown } from "react-icons/bs";
import { BiCheck } from "react-icons/bi";

type Props = {
  accordionTitle?: string;
  actAccordion?: boolean;
  toggleAccordion?: (event: any) => void;
  confirmacao_hora?: string;
  confirmacao_data?: string;
  updateTable: () => void;
  mobile?: boolean;
};

export default function Accordion({
  accordionTitle,
  confirmacao_data,
  confirmacao_hora,
  toggleAccordion,
  actAccordion,
  updateTable,
  mobile,
}: Props): JSX.Element {
  const active = !actAccordion ? "active-accordion" : "";
  const height = !actAccordion ? "0px" : "100%";
  const display = !actAccordion ? "none" : "block";
  const icon = !actAccordion ? "accordion-icon" : "accordion-icon-rotate";
  const title = !actAccordion ? "black" : "var(--primary-blue-default)";
  return (
    <>
      <div className="accordion-section py-3">
        <div
          className={`accordion-btn text-left ${active}`}
          onClick={toggleAccordion}
        >
          <div className="flex justify-between cursor-pointer p-3">
            <div className="rounded-full p-1 bg-blue border-gray-400 border-2">
              <BiCheck size="1rem" className="text-white" />
            </div>

            <p
              className="accordion-title font-bold xl:text-base md:text-sm sm:text-sm"
              style={{ color: `${title}`, transition: "0.5s" }}
            >
              {accordionTitle}
            </p>
            <BsChevronDown size="1rem" className={`mt-1 ${icon}`} />
          </div>
        </div>
        <div
          className="accordion-content"
          style={{ height: `${height}`, display: `${display}` }}
        >
          <div className={`${mobile ? 'mobile-especificacoes-accordion-content-container ' : ''}container p-2`}>
            <form className="py-2">
              <div className="px-8 py-2">
                <p className="text-base font-semibold text-black mb-1">
                  Data da Cirurgia
                </p>
                <span className="text-sm">{confirmacao_data}</span>
              </div>
              <div className="px-8 py-2">
                <p className="text-base font-semibold text-black mb-1">
                  Hora da Cirurgia
                </p>
                <span className="text-sm">{confirmacao_hora}</span>
              </div>
              <div className="px-8 py-2">
                <p className="text-sm font-semibold text-black mb-1">
                  Agendamento FINAL Confirmado
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
