/* eslint-disable jsx-a11y/heading-has-content */
import React from "react";
// import { FaUserNurse } from "react-icons/fa";
import "./style.css";

type Props = {
  stats?: number;
  nome?: string;
  children?: Array<JSX.Element> | JSX.Element;
};

export default function CardStatsAdmin({
  stats,
  nome,
  children,
}: Props): JSX.Element {
  return (
    <div className="card-admin my-8">
      <div className="grid grid-cols-5 px-2 pt-5">
        <div className="col-span-1">{children}</div>
        <div className="col-span-4 ml-10">
          <div className="text-5xl font-bold capitalize text-blue">{stats}</div>
          <span
            style={{ marginTop: "-10px" }}
            className="text-2xl ml-2 capitalize text-gray-500 absolute font-semibold"
          >
            {nome}
          </span>
        </div>
      </div>
    </div>
  );
}
