import React
, {
  useCallback,
  useEffect,
  useRef,
  useState
}
  from "react";
import { useFormContext } from "react-hook-form";
import { axiosApi } from "src/services/axiosInstances";
import { FiTrash2 } from "react-icons/fi";
import { opmeList, cbhpmSelectedList, cbhpmSuggestionList, procedimentoProps } from "./interfaces";


const Procedimento: React.FC<procedimentoProps> = ({
  parametros,
  opmeSelectedList,
  cbhpmSelectedList,
  setOpmeSelectedList,
  setCbhpmSelectedList,
}) => {

  const { register, setValue,
    trigger,
    formState: { errors }
  } = useFormContext();

  const [opme, setOpme] = useState("")
  const [quantidadeOpme, setQuantidadeOpme] = useState(1);
  const [opmeSuggestionList, setOpmeSuggestionList] = useState<opmeList[]>([]);


  const [cbhpmId, setCbhpmId] = useState("")
  const [codCbhpm, setCodCbhpm] = useState("")
  const [cbhpm, setCbhpm] = useState("")
  const [quantidadeCbhpm, setQuantidadeCbhpm] = useState(1);
  const [cbhpmDescSuggestionList, setCbhpmDescSuggestionList] = useState<cbhpmSuggestionList[]>([])
  // const [cbhpmCodSuggestionList, setCbhpmCodSuggestionList] = useState<cbhpmSuggestionList[]>([]);

  const [showOpmeDropdown, setShowOpmeDropdown] = useState(false);
  const [showCbhpmDropdown, setShowCbhpmDropdown] = useState(false);
  const opmeRef = useRef<HTMLUListElement>(null);
  const cbhpmRef = useRef<HTMLUListElement>(null);
  const cbhpmCodRef = useRef<HTMLUListElement>(null)

  const fetchCbhpm = async (query: string) => {
    try {
      const { data } = await axiosApi.get(`/solicitacao-cirurgia/cbhpm/${query}`);
      // setCbhpmSuggestionList([])
      setCbhpmDescSuggestionList(data);
    } catch (error) {
      console.error('Error fetching cbhpm:', error);
    }
  };

  const debounce = (func: (...args: any[]) => void, delay: number) => {
    let timeoutId: NodeJS.Timeout;
    return (...args: any[]) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func(...args), delay);
    };
  };

  const debouncedDescCbhpm = useCallback(
    debounce((query: string) => fetchCbhpm(query), 300),
    []
  );

  const debouncedOpme = useCallback(
    debounce((query: string) => fetchOpme(query), 300),
    []
  );

  const handleDescCbhpmInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newQuery = event.target.value;
    setCbhpm(newQuery);
    if (newQuery.length >= 3) {
      debouncedDescCbhpm(newQuery);
    }
  };

  const handleCbhpmClick = (suggestion: any) => {
    setCbhpm(suggestion.descricao);
    setCodCbhpm(suggestion.codigo);
    setCbhpmId(suggestion.id);
    setCbhpmDescSuggestionList([]);
  };

  const handleAddCbhpm = () => {
    const newCbhpm = {
      cbhpm: cbhpm, quantidade: quantidadeCbhpm, codigo: codCbhpm, id: cbhpmId
    }
    if (codCbhpm && cbhpm && quantidadeCbhpm) {
      setCbhpmSelectedList([...cbhpmSelectedList, newCbhpm])
      setQuantidadeCbhpm(1);
      setCbhpm("");
      setCodCbhpm("");
      setTimeout(() => trigger("cbhpm"), 50);
    }
  }

  const handleDeleteCbhpm = (codigo: string) => {
    setCbhpmSelectedList(cbhpmSelectedList.filter((cbhpm) => cbhpm.codigo !== codigo))
  }

  const fetchOpme = async (query: string) => {
    try {
      const { data } = await axiosApi.get(`/solicitacao-cirurgia/opme/${query}`);
      setOpmeSuggestionList(data);
    } catch (error) {
      console.error('Error fetching cbhpm:', error);
    }
  };

  const handleOpmeInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newQuery = event.target.value;
    setOpme(newQuery);
    if (newQuery.length >= 3) {
      debouncedOpme(newQuery);
    }
  };

  const handleAddOpme = () => {
    const idOpme = (num: number) => Math.floor(Math.random() * num)
    const newOpme = { id: idOpme(1023433), descricao: opme, quantidade: quantidadeOpme }
    if (opme && quantidadeOpme) {
      setOpmeSelectedList([...opmeSelectedList, newOpme]);
      setOpme("");
      setOpmeSuggestionList([]);
    }
  }

  const handleOpmeClick = (suggestion: any) => {
    setOpme(suggestion.descricao);
    setOpmeSuggestionList([]);
  };
  const handleDeleteOpme = (id: number) => {
    setOpmeSelectedList(opmeSelectedList.filter((opme) => opme.id !== id))
  }


  const organizeCbhpmData = (data: cbhpmSelectedList[]) => {
    const cbhpmArrayToSend = data.map(({ quantidade, id }: cbhpmSelectedList) => ({
      id, quantidade: quantidade ?? 1
    }))
    setValue("cbhpm", cbhpmArrayToSend)

  }

  const organizeOpmeData = (data: opmeList[]) => {
    const opmeArrayToSend = data.map(({ descricao, quantidade }: opmeList) => ({
      descricao, quantidade
    }))
    setValue("opme", opmeArrayToSend)
  }

  useEffect(() => {
    organizeCbhpmData(cbhpmSelectedList)
  }, [cbhpmSelectedList])

  useEffect(() => {
    organizeOpmeData(opmeSelectedList)
  }, [opmeSelectedList])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (opmeRef.current && !opmeRef.current.contains(event.target as Node)) {
        setShowOpmeDropdown(false);
        setOpmeSuggestionList([]);
      }
      if (cbhpmRef.current && !cbhpmRef.current.contains(event.target as Node)) {
        setShowCbhpmDropdown(false);
        setCbhpmDescSuggestionList([]);
      }

    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [opmeRef, cbhpmRef, cbhpmCodRef]);


  return (
    <>
      <div className="TypeAheadDropDown flex flex-col items-center mt-5">
        <div className="flex items-center">
          <div className="TypeAheadDropDown">
            <label htmlFor="desc-cbhpm" className="text-black">Descritivo CBHPM</label>
            <input name="desc-cbhpm"
              placeholder="Digite um código ou descrição cbhpm"
              id="desc-cbhpm"
              style={{ width: 610 }}
              className="TypeAheadDropDown pl-4 font-lato placeholder-gray-600 h-10 border border-black rounded-xl"
              type="text"
              onChange={handleDescCbhpmInputChange}
              value={cbhpm}
              onFocus={() => setShowCbhpmDropdown(true)}
            />
            {
              showCbhpmDropdown && cbhpmDescSuggestionList.length !== 0 && <ul ref={cbhpmRef} className="TypeAheadDropDown">
                {cbhpmDescSuggestionList && cbhpmDescSuggestionList.map((suggestion) => (
                  <li
                    className="TypeAheadDropDown"
                    key={`${suggestion.id} ${suggestion.descricao}`}
                    onClick={() => handleCbhpmClick(suggestion)}
                  >
                    {suggestion.descricao}
                  </li>
                ))
                }
              </ul>
            }
          </div>

          <div className="ml-5">
            <label htmlFor="qtd-cbhpm" className="text-black">Qtd.</label>
            <input
              name="qtd-cbhpm"
              id="qtd-cbhpm"
              style={{ width: 50 }}
              className="TypeAheadDropDown text-center font-lato placeholder-gray-600 h-10 border border-black rounded-xl"
              type="number"
              onChange={(event) => setQuantidadeCbhpm(event.target.value as any)}
              value={quantidadeCbhpm}
            />
          </div>
          <div className="ml-5 mt-5">
            <button style={{ width: 100 }}
              type="button"
              className="btn-new-vendedor bg-blue hover:bg-blue-700 text-white py-1 px-3 font-lato font-black rounded-full"
              onClick={handleAddCbhpm}
            >Adicionar
            </button>
          </div>
        </div>
        <div className="flex justify-start w-full">
          {errors.cbhpm && <span className="text-red-600">{errors.cbhpm.message as string}</span>}
        </div>
      </div>

      <div className="items-center">
        {
          cbhpmSelectedList && cbhpmSelectedList.map((item, index) =>
            <div key={`${index} ${item.cbhpm}`} className="pt-3 flex justify-start w-full items-center">
              <div style={{ width: 610 }}
                className="font-lato items-center rounded-xl overflow-hidden"
              >
                {item.descricao ? item.descricao : item.cbhpm}
              </div>
              <div style={{ width: 20 }}
                className="pl-10">
                {item['quantidade'] ?? item['pivot']['quantidade']}
              </div>
              <div className="ml-24 flex justify-center">
                <FiTrash2
                  className="rounded-full bg-white"
                  onClick={() => handleDeleteCbhpm(item.codigo)}
                />
              </div>
            </div>
          )}
      </div>


      <div className="TypeAheadDropDown flex items-center mt-8">
        <div className="flex flex-col">
          <label htmlFor="desc-opme" className="z-0 text-black">Descritivo OPME</label>
          <input
            onChange={handleOpmeInputChange}
            name="desc-opme" id="desc-opme" style={{ width: 610 }}
            className="z-0 pl-4 font-lato placeholder-gray-600 h-10 border border-black rounded-xl"
            type="text"
            value={opme}
            onFocus={() => setShowOpmeDropdown(true)}
          />

        </div>
        {
          showOpmeDropdown && opmeSuggestionList.length !== 0 && <ul ref={opmeRef} className="TypeAheadDropDown" style={{ width: 610 }}>
            {opmeSuggestionList && opmeSuggestionList.slice(0, 5).map((suggestion) => (
              <li
                className="TypeAheadDropDown"
                key={`${suggestion.id} ${suggestion.descricao}`}
                onClick={() => handleOpmeClick(suggestion)}
              >
                {suggestion.descricao}
              </li>
            ))
            }
          </ul>
        }
        <div className="ml-5">
          <label htmlFor="qtd-opme" className="text-black">Qtd.</label>
          <input onChange={(event) => setQuantidadeOpme(event.target.value as any)} value={quantidadeOpme} name="qtd-opme" id="qtd-opme" style={{ width: 50 }} className="pl-4 text-center font-lato placeholder-gray-600 h-10 border border-black rounded-xl" type="number" />
        </div>
        <div className="ml-5 mt-5">
          <button
            type="button"
            onClick={handleAddOpme}
            style={{ width: 100 }} className="btn-new-vendedor bg-blue hover:bg-blue-700 text-white py-1 px-3 font-lato font-black rounded-full">Adicionar
          </button>
        </div>
      </div>

      <div className="items-center">
        {
          opmeSelectedList && opmeSelectedList.map((item, index) =>
            <div key={item.id} className="pt-3 flex justify-start w-full items-center">
              <div style={{ width: 610 }}
                className="font-lato items-center rounded-xl overflow-hidden"
              >
                {item.descricao}
              </div>
              <div style={{ width: 20 }} className="pl-10">
                {item.quantidade}
              </div>
              <div className="ml-24 flex justify-center">
                <FiTrash2
                  className="rounded-full bg-white"
                  onClick={() => handleDeleteOpme(item.id)}
                />
              </div>
            </div>
          )}
      </div>


      <div className="flex flex-col mt-8">
        <div>
          <label htmlFor="justificativa_tecnica" className="text-black">Justificativa Técnica</label>
          <textarea id="justificativa_tecnica" style={{ width: 801, height: 134 }} className="font-lato placeholder-gray-600 h-10 border border-black rounded-xl" {...register("justificativa_tecnica")} />
        </div>
        <div className="mt-8">
          <label htmlFor="especificacao_material" className="text-black">Especificação do Material</label>
          <textarea id="especificacao_material" style={{ width: 801, height: 134 }} className="font-lato placeholder-gray-600 h-10 border border-black rounded-xl" {...register("especificacao_material")} />
        </div>
      </div>
    </>
  )
}


export default Procedimento;