import React, { Fragment, useState } from "react";
import { IoIosClose } from "react-icons/io";
import { ObjectSchema } from "yup";
import "./style.css";

type Props = {
  label?: string;
  name: string;
  labelClassName?: string;
  wrapClassName?: string;
  inputClassName?: string;
  required?: boolean;
  schema?: ObjectSchema<any>;
  placeholderFile?: string;
  id?: string;
  ref?: any;
  postEvent: (nome: string, valor: any) => void;
  onBlur?: (event: any) => void;
};

export default function InputFormFile({
  label,
  name,
  required,
  labelClassName = "lb-input",
  wrapClassName = "mt-3 px-5 truncate",
  inputClassName = "px-3 mt-2 capitalize border-2 border-gray-400 w-full h-12 truncate",
  placeholderFile,
  postEvent,
  onBlur,
  ref,
  id,
  schema,
}: Props) {
  const [doc, setDoc] = useState({ path: null, nome: "", name: "" });
  const docChange = (e: any) => {
    const { value, name, files } = e.target;
    if (doc.path !== value.replace(/[A-Za-z]:\\fakepath\\/i, "")) {
      postEvent(name, files ? files[0] : null);
      setDoc({
        nome: value.replace(/[A-Za-z]:\\fakepath\\/i, ""),
        path: value,
        name: name,
      });
    }
  };

  const resetDoc = () => {
    postEvent(doc.name, null);
    setDoc({
      nome: "",
      name: "",
      path: null,
    });
  };

  return (
    <div className={wrapClassName}>
      <label className={labelClassName}>{label}</label>
      <p></p>
      <div className="flex align-middle relative">
        <div
          className={`flex ${inputClassName} pt-2 w-full`}
          style={{ borderRadius: "16px" }}
        >
          <div className="w-32 xl:w-48">
            {doc.path ? (
              <Fragment>
                <div className="flex justify-start relative">
                  <div className="truncate">
                    <span className="text-xs text-black sm:visible visible normal-case">
                      {doc.nome}
                    </span>
                  </div>

                  <span
                    title="Remover Arquivo"
                    onClick={resetDoc}
                    className="delete-file-btn text-xl ml-1 right-0 text-red-500 cursor-pointer"
                  >
                    <IoIosClose />
                  </span>
                </div>
              </Fragment>
            ) : (
              <span className="xl:visible md:visible lg:visible invisible text-xs text-gray-600">
                {placeholderFile}
              </span>
            )}
          </div>

          <label
            className="mr-2 absolute right-0 cursor-pointer"
            htmlFor={name}
          >
            <div className="bg-blue px-3 py-1 text-xs rounded-full text-white">
              {doc.path === null || doc.nome === ""
                ? "Selecionar Arquivo"
                : " Alterar Arquivo"}
            </div>
          </label>
        </div>
      </div>

      <input
        className={`hidden ${inputClassName}`}
        type="file"
        id={name}
        name={name}
        onChange={docChange}
        onBlur={onBlur}
        required={required}
        ref={ref}
        value={doc.path ?? ""}
        accept=".pdf,.png,.jpg,.jpeg"
      />
    </div>
  );
}
