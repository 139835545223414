/* eslint-disable @typescript-eslint/no-unused-vars */
import "./style.css";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { CiUser } from "react-icons/ci";
import { HiOutlineSquare3Stack3D } from "react-icons/hi2";
// import { AiOutlinePieChart } from "react-icons/ai";
import { FiCalendar, FiUsers, FiPieChart, FiLogOut } from "react-icons/fi";
import { HiOutlineClipboardList } from "react-icons/hi";
import { Link, NavLink, useLocation } from "react-router-dom";
import { RiTeamLine } from "react-icons/ri";
import Select from "react-select";
import { selectStyle } from "src/helpers/functions";
import { AuthState, Doutor, AuthAction, Usuario } from "src/store/types";
import * as auth from "src/store/actions/auth";
import { connect, ConnectedProps } from "react-redux";
import img from "src/assets/logo.png";
import { Dispatch } from "redux";
import ModalPerfil from "../ModalPerfil";
import whiteLogo from '../../assets/MobileHeaderMenu/White-Logo.svg';
import myProfile from '../../assets/MobileHeaderMenu/My-Profile.svg';
import auxiliaries from '../../assets/MobileHeaderMenu/Auxiliaries.svg';
import notifications from '../../assets/MobileHeaderMenu/Notifications.svg';
import exit from '../../assets/MobileHeaderMenu/Exit.svg';
import imgProfile from '../../assets/MobileHeaderMenu/Img-Profile.svg';
import MobileProfile from "../MobileProfile";
import MobileFloatingNav from "../MobileFloatingNav";
import MobileNotifications from "../MobileNotifications";
import { FaHeadset } from "react-icons/fa";
// import userPng from "../../assets/1645620449.png";

const mapStateToProps = ({ auth }: { auth: AuthState }): AuthState => {
  return {
    usuario: auth.usuario,
    doutor: auth.doutor,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AuthAction>) => {
  return {
    setDoutor: (doutor: Doutor) => dispatch(auth.setDoutor(doutor)),
    setUpdatePerfil: (usuario: Usuario) =>
      dispatch(auth.setUpdatePerfil(usuario)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Option = {
  value: string | number;
  label: string;
};

type Props = PropsFromRedux & {
  navbarClassName?: string;
  navbarItensClassName?: string;
  navitensFlexClassName?: string;
  navLinkClassName?: string;
  navIconClassName?: string;
  headerBodyClassName?: string;
  contentNavbarClassName?: string;
  logoContentClassName?: string;
  logoImgClassName?: string;
  nomeLogoClassName?: string;
  spanBoldLogoClassName?: string;
  spanLogoClassName?: string;
  selectmedicoClassName?: string;
  displayNoneSelect?: string;
  userContainerClassName?: string;
  btnNotificationsClassName?: string;
  btnUserImgClassName?: string;
  imgUserClassName?: string;
  nomeUserClassName?: string;
  dropdownClassName?: string;
  dropdownContentClassName?: string;
  dropdownLinkClassName?: string;
  id?: any;
  nome?: any;
  tel?: any;
  wp?: any;
  email?: any;
  senha?: any;
  actDropdown?: boolean;
  onOpenPerfil?: () => void;
};

function SideBar({
  // navbarClassName = "",
  navbarItensClassName = "flex justify-between",
  navitensFlexClassName = "flex-col justify-center py-6",
  navLinkClassName = "flex px-4 rounded-md link-nav text-xl lg:text-md md:text-md xl:text-xl py-5",
  navIconClassName = "mr-2 text-3xl lg:text-2xl md:text-lg xl:text-3xl",
  headerBodyClassName = "header-body",
  contentNavbarClassName = "z-10 fixed top-0 left-0 border-l border-r border-black overflow-hidden flex flex-col  items-center h-full w-276 m-0 fixed-width-sidebar",
  logoContentClassName = "bg-white flex justify-start py-8 px-5",
  logoImgClassName = "w-16 h-16 md:w-12 md:h-12 xl:w-16 xl:h-16 mt-1",
  nomeLogoClassName = "ml-4 cursor-default",
  spanBoldLogoClassName = "font-bold mr-2 text-xl lg:text-md md:text-sm xl:text-xl",
  spanLogoClassName = "text-xl lg:text-md md:text-sm xl:text-xl",
  selectmedicoClassName = "text-base lg:text-md md:text-sm xl:text-base font-bold",
  displayNoneSelect = "display-none",
  userContainerClassName = "absolute inset-y-0 right-0 flex items-center pr-0 sm:static sm:inset-auto sm:ml-6 sm:pr-0",
  btnUserImgClassName = "flex text-sm border-2 border-transparent rounded-full",
  imgUserClassName = "h-10 w-10 mx-1 mt-1 rounded-full cursor-default",
  nomeUserClassName = "mt-5 font-bold text-lg lg:text-lg md:text-sm xl:text-xl text-black-500 xs:mt-3 ml-2 lg:mt-2 xl:mt-2 md:mt-5",
  dropdownClassName = "hidden origin-top-right absolute right-0 mt-10 w-48 rounded-md shadow-lg dropdown-content",
  dropdownContentClassName = "px-2 py-5 rounded-md bg-blue shadow-xs",
  dropdownLinkClassName = "flex block px-4 py-2 text-lg leading-5 text-white hover:font-bold focus:outline-none",
  usuario,
  doutor,
  setDoutor,
  onOpenPerfil,
  actDropdown,
}: Props): JSX.Element {


  const usuariosOptions = React.useMemo<Array<Option>>((): Array<Option> => {
    const options: Array<Option> | undefined = usuario?.doutores.map(
      (doutor: Doutor): Option => {
        return {
          value: doutor.id,
          label: doutor.nome,
        };
      }
    );

    return [{ value: 0, label: "Visão geral" }, ...(options ?? [])];
  }, [usuario]);

  const doutorNomeSelect = (event: any) => {
    setDoutor({ nome: event.label, id: event.value });
  };

  const [openPerfil, setOpenPerfil] = useState(false);
  const toggelPerfil = () => setOpenPerfil(!openPerfil);

  const fornecedorCheck = usuario?.nivel === 2;
  const nivelCheck = usuario?.nivel === 3;
  const adminCheck = usuario?.nivel === 5;
  const doutorCheck = usuario?.nivel === 1;
  // const hospitalCheck = usuario?.nivel === 6;

  const [, setToggle] = useState(false);
  const [, setToggleNf] = useState(false);

  const dropdownRef = useRef<HTMLDivElement>(null);
  const dropdownNf = useRef<HTMLDivElement>(null);


  const handleOutsideClick = (event: any) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target))
      setToggle(false);
  };

  const handleOutsideClickNf = (event: any) => {
    if (dropdownNf.current && !dropdownNf.current.contains(event.target))
      setToggleNf(false);
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClickNf, true);
    return () => {
      document.removeEventListener("click", handleOutsideClickNf, true);
    };
  }, [dropdownNf]);

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick, true);
    return () => {
      document.removeEventListener("click", handleOutsideClick, true);
    };
  }, [dropdownRef]);

  return (
    <Fragment>
      <ModalPerfil
        open={openPerfil}
        setOpenModal={setOpenPerfil}
        openModal={openPerfil}
        toggle={toggelPerfil}
      />
      <div className={contentNavbarClassName}>
        <div className={logoContentClassName}>
          <img className={logoImgClassName} src={img} alt="logo" />
          <div className={nomeLogoClassName}>
            <span className={spanBoldLogoClassName}>Cirurgia</span>
            <span className={spanLogoClassName}>Autorizada</span>
            {adminCheck ? (
              <Fragment>
                <p className="text-xl md:text-md text-blue font-bold">
                  Administrador
                </p>
              </Fragment>
            ) : (
              <Fragment>
                {doutorCheck ? (
                  <Fragment>
                    <p className="text-xl md:text-md text-blue font-bold">
                      Doutor
                    </p>
                  </Fragment>
                ) : (
                  <Fragment>
                    {nivelCheck ? (
                      <Select
                        className={selectmedicoClassName}
                        options={usuariosOptions}
                        onChange={doutorNomeSelect}
                        defaultValue={{
                          value: doutor?.id ?? "",
                          label: doutor?.nome ?? "",
                        }}
                        styles={selectStyle}
                      />
                    ) : (
                      <Fragment>
                        {fornecedorCheck ? (
                          <Fragment>
                            <p className="text-xl md:text-md text-blue font-bold">
                              Fornecedor
                            </p>
                          </Fragment>
                        ) : (
                          <Fragment>
                            <p className="text-xl md:text-md text-blue font-bold">
                              Hospital
                            </p>
                          </Fragment>
                        )}
                      </Fragment>
                    )}
                  </Fragment>
                )}
              </Fragment>
            )}
          </div>
        </div>

        <div className={navbarItensClassName}>
          <div className={navitensFlexClassName}>
            <NavLink
              to="/home"
              className={navLinkClassName}
            >
              <FiPieChart className={navIconClassName} size="" />
              Dashboard
            </NavLink>
            {adminCheck ? (
              <NavLink
                to="/usuarios"
                className={navLinkClassName}
              >
                <FiUsers className={navIconClassName} size="" />
                Usuários
              </NavLink>
            ) : (
              <NavLink
                to="/calendario"
                className={navLinkClassName}
              >
                <FiCalendar className={navIconClassName} size="" />
                Calendário
              </NavLink>
            )}

            <NavLink
              to="/autorizacoes"
              className={navLinkClassName}
            >
              <HiOutlineClipboardList
                className={navIconClassName}
                size=""
              />
              Cirurgias
            </NavLink>
            {
              <NavLink
                to="/relatorio"
                className={navLinkClassName}
              >
                <HiOutlineSquare3Stack3D
                  className={navIconClassName}
                  size=""
                />
                Relatório
              </NavLink>
            }
            {
              doutorCheck &&
                <NavLink
                  to="/equipe-medica"
                  className={navLinkClassName}
                >
                  <RiTeamLine className={navIconClassName} size="" />
                  Equipe Médica
                </NavLink>
            }
            { doutorCheck &&
              <NavLink
                to="/secretariado"
                className={navLinkClassName}
              >
                <FaHeadset className={navIconClassName} size="" />
                Secretariado
              </NavLink>
            }
            <button
              // to={""}
              onClick={() => { setOpenPerfil(true) }}
              className="flex px-4 rounded-md meuPerfilButtonClass font-black text-xl lg:text-md md:text-md xl:text-xl py-5"
            >
              <CiUser className={navIconClassName} size="" />
              Meu Perfil
            </button>
            <NavLink
              to="/logout"
              style={{ color: 'var(--neutral-500)', fontWeight: 600, transition: '0.5s' }}
              className="flex px-4 rounded-md text-xl lg:text-md md:text-md xl:text-xl py-5"
            >
              <FiLogOut className={navIconClassName} size="" />
              Sair
            </NavLink>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

const MobileHeaderComponent = ({
  usuario,
}: any) => {
  const location = useLocation();

  const [showMenu, setShowMenu] = React.useState(false);
  const [profileState, setProfileState] = React.useState(-1);
  const [notificationState, setNotificationState] = React.useState(-1);

  return (
    <>
      <div className='mobile-header-container'>
        <div onClick={() => setShowMenu(true)} className='hamburger-container'>
          <div className='hamburger-bar' />
          <div className='hamburger-bar' />
          <div className='hamburger-bar' />
        </div>
        <div className='mobile-header-content-container'>
          <span className='mobile-header-content'>Bem vindo(a) de volta</span>
          <span className='mobile-header-info'>{usuario?.nome}</span>
        </div>
      </div>
      {
        showMenu &&
        <div className='mobile-header-menu-container'>
          <div onClick={() => setShowMenu(false)} className='mobile-header-menu-top-container'>
            <div className='cross-container'>
              <div className='cross-bar-left' />
              <div className='cross-bar-right' />
            </div>
            <span className='mobile-header-menu-close-content'>Fechar</span>
          </div>
          <div className='mobile-header-menu-center-container'>
            <ul className='mobile-header-menu-list-container'>
              <li className='mobile-header-menu-option'>
                <img src={usuario?.foto ? usuario.foto : imgProfile} className="mobile-header-user-img" alt='user-img' />
                <span className='mobile-header-menu-option-user-content'>{usuario?.nome}</span>
              </li>
              <li onClick={() => { setProfileState(1) }} className='mobile-header-menu-option'>
                <img src={myProfile} alt="user-icon" />
                <span className='mobile-header-menu-option-content'>Meu Perfil</span>
              </li>
              {location.pathname !== '/auxiliares' ?
                <Link to='/auxiliares' onClick={() => { setShowMenu(false) }} className='mobile-header-menu-option'>
                  <img src={auxiliaries} alt="gear-icon" />
                  <span className='mobile-header-menu-option-content'>Auxiliares</span>
                </Link>
                :
                <li className='mobile-header-menu-option'>
                  <img src={auxiliaries} alt="gear-icon" />
                  <span className='mobile-header-menu-option-content'>Auxiliares</span>
                </li>
              }
              <li onClick={() => { setNotificationState(1) }} style={{ position: 'relative' }} className='mobile-header-menu-option'>
                <img src={notifications} alt="bell-icon" />
                <div className='mobile-header-menu-notification-badge' />
                <span className='mobile-header-menu-option-content'>Notificações</span>
              </li>
              <Link to='/logout' className='mobile-header-menu-option'>
                <img src={exit} alt="exit-icon" />
                <span className='mobile-header-menu-option-content'>Sair</span>
              </Link>
            </ul>
            <div className='mobile-header-menu-end-logo'>
              <img className='mobile-header-menu-logo' src={whiteLogo} alt="logo" />
            </div>
          </div>
        </div>
      }
      <MobileFloatingNav state={profileState} />
      <MobileNotifications state={notificationState} setState={setNotificationState} />
      <MobileProfile state={profileState} setState={setProfileState} />
    </>
  );
}

export default connector(SideBar);
export const MobileHeader = connector(MobileHeaderComponent);