import React, { useEffect } from "react";
import { navProps } from "./interfaces";


const SolicitacaoNavBar: React.FC<navProps> = ({ index, setIndex }) => {

  useEffect(() => {
  }, [])

  return (
    <>
      <div style={{ width: 453 }} className="pt-2 relative border-b-2">
        <button className={`${index === 0 ? 'active' : null} btn-new-vendedor  px-3 rounded-md link-nav text-xl lg:text-md md:text-md xl:text-xl`} onClick={() => setIndex(0)}>Identificação</button>
        <button className={`${index === 1 ? 'active' : null} btn-new-vendedor  px-3 rounded-md link-nav text-xl lg:text-md md:text-md xl:text-xl`} onClick={() => setIndex(1)}>Informações</button>
        <button className={`${index === 2 ? 'active' : null} btn-new-vendedor  px-3 rounded-md link-nav text-xl lg:text-md md:text-md xl:text-xl`} onClick={() => setIndex(2)}>Procedimento</button>
      </div>
    </>
  );
}

export default SolicitacaoNavBar;

