import "../Calendario/style.css";
import React, { useEffect, useState } from "react";
import { CgSearch } from "react-icons/cg";
import CardAutorizacao from "src/components/CardAutorizacoes";
import Titulos from "src/components/Titulos";
import Loader from "../../components/Loader";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import CardCalendario from "../../components/CalendarioCards";
import ModalChecklist from "../../components/ModalChecklist";
import { AuthState, ChecklistState } from "src/store/types";
import { connect, ConnectedProps } from "react-redux";
import EmptyContent from "src/components/EmptyContent";
import { setUpdateChecklist } from "src/store/actions/checklist";
import { axiosApi } from "src/services/axiosInstances";
import { ptBR } from "date-fns/locale";

const mapStateToProps = ({
  auth,
  cirurgias,
}: {
  auth: AuthState;
  cirurgias: ChecklistState;
}): AuthState & ChecklistState => {
  return {
    doutor: auth.doutor,
    usuario: auth.usuario,
    cirurgias: cirurgias.cirurgias,
  };
};

const mapDispatchToProps = {
  setUpdateChecklist,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & {
  divCalendarioClassname?: string;
  scrollBoxClassName?: string;
};

type Status = {
  id: bigint;
  descricao: string;
};

type Hospital = {
  id: bigint;
  nome: string;
};

export type Cirurgia = {
  id: number;
  nome: string;
  status_id: number;
  status: Status;
  hospital: Hospital;
  cirurgia: string;
  confirmacao_data: string;
  confirmacao_hora: string;
  doutor: Doutor;
  doutor_requisitado_id: number;
  doutor_requisitado_nome: string;
};

type Doutor = {
  id: bigint;
  nome: string;
};

export type HomeResponse = {
  agendadas: Cirurgia[];
  autorizadas: Cirurgia[];
  pendentes: Cirurgia[];
  datas: string[];
  concluidas: Cirurgia[];
};

function CalendarioPage({
  divCalendarioClassname = "pl-4 pt-20 flex justify-center mb-5",
  scrollBoxClassName = "overflow-y-auto overflow-x-hidden scrollbox-calendario px-2 py-1",
  doutor,
  usuario,
}: Props): JSX.Element {
  const [loader, setLoader] = useState(true);
  const [loaderAgendadas, setLoaderAgendadas] = useState(true);
  const [autorizadas, setAutorizadas] = useState<Cirurgia[]>([]);
  const [pendentes, setPendentes] = useState<Cirurgia[]>([]);
  const [agendadas, setAgendadas] = useState<Cirurgia[]>([]);
  const [concluidas, setConcluidas] = useState<Cirurgia[]>([]);
  const [searchAgendadas, setSearchAgendadas] = useState<Cirurgia[]>([]);
  const [search, setSearch] = useState("");
  const [idCirurgia, setIdCirurgia] = useState<number | undefined>();
  const [statusCirurgia, setStatusCirurgia] = useState<string | undefined>();
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(!open);
  const [dataAgendada, setDataAgendada] = useState<string | undefined>();
  const [selectDatas, setselectDatas] = useState<Date>(new Date());
  const [bookedDays, setBookedDays] = useState<Date[]>([]);

  const today = new Date();
  const todayFormatted = new Date(today.getFullYear(), today.getMonth(), today.getDate());

  useEffect(() => {
    const loadDados = async () => {
      try {
        setLoaderAgendadas(true);
        const year = today.getFullYear();
        const mes = today.getMonth() + 1;
        const dia = today.getDate();
        const dataHoje = `${year}-${mes}-${dia}`;
        const { data } = await axiosApi.get<HomeResponse>(
          `/cirurgias?id=${doutor?.id}&data=${dataAgendada ?? dataHoje}`
        );
        setAgendadas(data.agendadas);
        setConcluidas(data.concluidas);
      } catch (error: any) {
        console.log(error);
      } finally {
        setLoaderAgendadas(false);
      }
    };
    loadDados();
  }, [doutor, dataAgendada ]);

  const loadDados = async () => {
    try {
      setLoader(true);
      const { data } = await axiosApi.get<HomeResponse>(
        `/cirurgias?id=${doutor?.id}`
      )
      setAutorizadas(data.autorizadas);
      setPendentes(data.pendentes);

      const allDates = [...data.agendadas, ...data.concluidas];

      const tempBookedDays = allDates.map(function(item) {
          const [year, month, day] = item.confirmacao_data.split('-');
          return new Date(+year, +month - 1, +day);
        });
      setBookedDays(tempBookedDays);
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  const loadDados2 = async () => {
    try {
      const { data } = await axiosApi.get<HomeResponse>(
        `/cirurgias?id=${doutor?.id}`
      );
      setAutorizadas(data.autorizadas);
      setPendentes(data.pendentes);
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    setDataAgendada(todayFormatted.toISOString().substring(0, 10));
  }, []);
  
  useEffect(() => {
    loadDados();
  }, [doutor]);

  useEffect(() => {
    setSearchAgendadas(
      agendadas
        .concat(concluidas)
        .filter((cirurgia) => {
          return (
            cirurgia.nome.toLowerCase().includes(search.toLowerCase()) &&
            cirurgia.confirmacao_data === (dataAgendada ?? `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`)
          );
        })
    );
  }, [search, agendadas, concluidas, dataAgendada ]);

  const dadosCirurgia = (id: number): void => {
    const cirurgiaStatus = [...autorizadas, ...pendentes].find(
      (element) => element.id === id
    );
    setIdCirurgia(id);
    setStatusCirurgia(cirurgiaStatus?.status.descricao);
    setOpen(true);
  };

  const handleDayClick = (day: Date) => {
    setselectDatas(day);
    setDataAgendada(day.toISOString().substring(0, 10));
  };

  const hoje = today.toLocaleDateString("pt-BR");
  const bookedStyle = { border: '2px solid blue' };
  const todayStyle = { backgroundColor: 'orange' };

  return (
    <Loader isActive={loader}>
      <ModalChecklist
        idCirurgia={idCirurgia}
        open={open}
        toggle={toggle}
        setOpen={setOpen}
        updateData={loadDados2}
        status={statusCirurgia}
      />
      <div className="p-12 calendario-page">
        <div className="px-5 grid grid-cols-4 gap-12">
          <div className="col-span-1">
            <div className={divCalendarioClassname}>
              <DayPicker
                locale={ptBR}
                className="div-card-calendario"
                mode="single"
                selected={selectDatas}
                onDayClick={handleDayClick}
                modifiers={{ booked: bookedDays, today: todayFormatted }}
                modifiersStyles={{ booked: bookedStyle, today: todayStyle }}
                // initialMonth={new Date(today.getFullYear(), today.getMonth())}
              />
            </div>

            <div className="div-card-autorizacao">
              <Titulos titulo="Novas Autorizações" />

              {(autorizadas.length || pendentes.length) === 0 ? (
                <EmptyContent
                  messageSize="sm"
                  iconSize="1.2rem"
                  paddingContent={"py-12"}
                  titleContent="Sem Novas Autorizações"
                />
              ) : (
                <div className={scrollBoxClassName}>
                  {[...autorizadas, ...pendentes]?.map(
                    (autorizacoes: Cirurgia) => {
                      const nomeCirurgia: string =
                        autorizacoes.cirurgia === null
                          ? "-"
                          : autorizacoes.cirurgia;
                      return (
                        <CardAutorizacao
                          onClick={dadosCirurgia}
                          id={autorizacoes.id}
                          key={autorizacoes.id}
                          nome={autorizacoes.nome}
                          doutorNome={autorizacoes.doutor?.nome}
                          status={autorizacoes.status.descricao}
                          nomeCirurgia={nomeCirurgia}
                        />
                      );
                    }
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="px-5 pt-20 col-span-3">
            <div className="search-calendario flex justify-around mb-5">
              <CgSearch
                size="1.8rem"
                className="mt-3 ml-10 mr-4 search-icon-c"
              />
              <input
                placeholder="Que autorização você procura? Pesquise pelo nome Paciente"
                type="text"
                className="search-bar px-1 w-full"
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
            <div className="flex justify-between my-10">
              <h1 className="text-4xl font-bold">Cirurgias do Dia</h1>
              <h1 className="text-4xl font-bold data-calendario-style ml-5 flex">
                {dataAgendada === undefined
                  ? hoje.split("-").reverse().join("/")
                  : dataAgendada.split("-").reverse().join("/")}
              </h1>
            </div>
            {searchAgendadas.length === 0 ? (
              <EmptyContent
                messageSize="2xl"
                iconSize="2.2rem"
                paddingContent={"py-24"}
                paddingText={"py-10"}
                titleContent="Sem Cirurgias Agendadas"
              />
            ) : (
              <div>
                <Loader isActive={loaderAgendadas} hide="hidden">
                  {searchAgendadas?.map((cirurgia: Cirurgia) => {
                    return (
                      <CardCalendario
                        key={cirurgia.id}
                        nome={cirurgia.nome}
                        doutorNome={cirurgia.doutor?.nome}
                        nomeCirurgia={cirurgia.cirurgia}
                        nomeHospital={cirurgia.hospital.nome}
                        confirmacao_hora={cirurgia.confirmacao_hora}
                        doutorRequisitadoId={cirurgia.doutor_requisitado_id}
                        doutorRequisitadoNome={cirurgia.doutor_requisitado_nome}
                      />
                    );
                  })}
                </Loader>
              </div>
            )}
          </div>
        </div>
      </div>
    </Loader>
  );
}

export default connector(CalendarioPage);
