import React from "react";
import './media.css';

type Props = {
  label: string;
  name: string;
  labelClassName?: string;
  wrapClassName?: string;
  inputClassName?: string;
  type: string;
  required: boolean;
  onChange?: (event: any) => void;
};
export default function InputAcompanhamento({
  label,
  name,
  type,
  required,
  labelClassName = "lb-input",
  wrapClassName = "p-1",
  inputClassName = "px-2 mt-2 border-2 border-gray-400 w-full h-10 focus:rounded-full",
  onChange,
}: Props) {
  return (
    <div className={`input-form-login-wrapper ${wrapClassName}`}>
      <label className={`input-form-login ${labelClassName}`}>{label}</label>
      <p></p>
      <input
        className={inputClassName}
        type={type}
        name={name}
        onChange={onChange}
        required={required}
        // autoComplete="off"
        style={{ borderRadius: "16px" }}
      />
    </div>
  );
}