import * as actionTypes from "../types";

export const setUpdateTableVendedores = (
  vendedores: actionTypes.Vendedor[]
) => {

  return {
    type: actionTypes.UPDATE_VENDEDORES,
    payload: {
      vendedores,
    },
  };
};
