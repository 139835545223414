import "./style.css";
import React, { Fragment, useEffect, useState } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { FiPlus } from "react-icons/fi";
import { CgSearch } from "react-icons/cg";
import {
  AuthState,
  ChecklistState,
  // Cirurgias,
  DoutoresAdmin,
  DoutorState,
  Fornecedores,
  FornecedoresState,
  Secretarias,
  SecretariaState,
} from "src/store/types";
import { setUpdateFornecedores } from "src/store/actions/fornecedores";
import { connect, ConnectedProps } from "react-redux";
import { axiosApi } from "src/services/axiosInstances";
import Loader from "src/components/Loader";
// import FornecedoresLista from "src/components/CardForncedoresLista";
import TableUsuarios from "src/components/TableUsuarios";
import { setUpdateChecklist } from "src/store/actions/checklist";
import { setDoutoresAdmin } from "src/store/actions/doutores";
import { setSecretarias } from "src/store/actions/secretarias";
import ModalFornecedores from "src/components/ModalFornecedores";
import ModalCirurgiao from "src/components/ModalCirurgiao";
import ModalSecretaria from "src/components/ModalSecretaria";
import ReactPaginate from "react-paginate";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { ToastContainer } from "react-toastify";
import EmptyContent from "src/components/EmptyContent";

const mapStateToProps = ({
  auth,
  fornecedores,
  cirurgias,
  doutores,
  secretarias,
}: {
  auth: AuthState;
  fornecedores: FornecedoresState;
  cirurgias: ChecklistState;
  doutores: DoutorState;
  secretarias: SecretariaState;
}): AuthState &
  FornecedoresState &
  ChecklistState &
  DoutorState &
  SecretariaState => {
  return {
    usuario: auth.usuario,
    fornecedores: fornecedores.fornecedores,
    cirurgias: cirurgias.cirurgias,
    doutores: doutores.doutores,
    secretarias: secretarias.secretarias,
  };
};

const mapDispatchToProps = {
  setUpdateFornecedores,
  setUpdateChecklist,
  setDoutoresAdmin,
  setSecretarias,
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
  id: number;
  thClassName?: string;
  tableClassName?: string;
};

type HomeResponse = {
  fornecedores: Fornecedores[];
  doutores: DoutoresAdmin[];
  secretarias: Secretarias[];
  total: number;
};

function Usuarios({
  id,
  thClassName = " py-2",
  tableClassName = "table-auto table-autorizacoes",
  fornecedores,
  cirurgias,
  doutores,
  secretarias,
  setSecretarias,
  setDoutoresAdmin,
  setUpdateFornecedores,
  setUpdateChecklist,
  usuario,
}: Props): JSX.Element {
  const [offset, setOffset] = useState(0);
  const [perPage] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [filtro, setFiltro] = useState<string>("");
  const [tabIndex, setTabIndex] = useState(0);
  const [loader, setLoader] = useState(false);

  const [idFornecedor, setIdFornecedor] = useState<number>();
  const [openFornecedor, setOpenFornecedor] = useState(false);
  const toggleFornecedor = () => setOpenFornecedor(!openFornecedor);

  const [idSecretaria, setIdSecretaria] = useState<number>();
  const [openSecretaria, setOpenSecretaria] = useState(false);
  const toggleSecretaria = () => setOpenSecretaria(!openSecretaria);

  const [idCirurgiao, setIdCirurgiao] = useState<number>();
  const [openCirurgiao, setOpenCirurgiao] = useState(false);
  const toggleCirurgiao = () => setOpenCirurgiao(!openCirurgiao);

  const [selecionado, setSelecionado] = useState<string>("");
  const onClick = (event: any) => {
    const idSelecionado: any = event.target.getAttribute("name");
    setSelecionado(idSelecionado);
  };

  const loadDados = async () => {
    try {
      if (!filtro) setLoader(true);
      const { data } = await axiosApi.get<HomeResponse>(
        `/list-users?selecionado=${
          !selecionado ? "1" : selecionado
        }&filtro=${filtro}&limit=${perPage}&offset=${offset}`
      );
      if (data.fornecedores) {
        const arr = Math.ceil(data.total / perPage);
        setUpdateFornecedores(data.fornecedores);
        setPageCount(arr);
      }
      if (data.doutores) {
        const arr = Math.ceil(data.total / perPage);
        setDoutoresAdmin(data.doutores);
        setPageCount(arr);
      }
      if (data.secretarias) {
        const arr = Math.ceil(data.total / perPage);
        setSecretarias(data.secretarias);
        setPageCount(arr);
      }
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };
  useEffect(() => {
    loadDados();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selecionado, offset, perPage, filtro]);

  useEffect(() => {
    setFiltro("");
    setOffset(0);
  }, [selecionado]);

  const loadDadosTable = async () => {
    try {
      // setLoader(true);
      const { data } = await axiosApi.get<HomeResponse>(
        `/list-users?selecionado=${
          !selecionado ? "1" : selecionado
        }&filtro=${filtro}&limit=${perPage}&offset=${offset}`
      );
      setUpdateFornecedores(data.fornecedores);
      setDoutoresAdmin(data.doutores);
      setSecretarias(data.secretarias);
    } catch (error: any) {
      console.log(error);
    }
  };

  const adminCheck = usuario?.nivel === 5;

  const dadosModal = (id: number): void => {
    if (selecionado === "2") {
      setIdFornecedor(id);
      setOpenFornecedor(true);
    }
    if (selecionado === "1" || selecionado === "") {
      setIdCirurgiao(id);
      setOpenCirurgiao(true);
    }
    if (selecionado === "3") {
      setIdSecretaria(id);
      setOpenSecretaria(true);
    }
  };

  const novo = (): void => {
    if (selecionado === "2") {
      setIdFornecedor(undefined);
      setOpenFornecedor(true);
    }
    // if (selecionado === "1") {
    //   setIdCirurgiao(undefined);
    //   setOpenCirurgiao(true);
    // }
    // if (selecionado === "3") {
    //   setIdSecretaria(undefined);
    //   setOpenSecretaria(true);
    // }
  };

  const findFornecedores = fornecedores?.find(
    (item) => item.id === idFornecedor
  );

  const findCirurgiao = doutores?.find((item) => item.id === idCirurgiao);
  const findSecretaria = secretarias?.find((item) => item.id === idSecretaria);
  const statusId = findCirurgiao?.usuario?.status_id;

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFiltro(event.target.value);
  };

  const handlePageChange = (select: { selected: number }) => {
    const selectedPage = select.selected;
    setOffset(selectedPage * perPage);
  };

  const theFirst = 0;
  const theLast = pageCount - 1;
  const page = Math.ceil(offset / perPage);

  return (
    <Fragment>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover={false}
      />

      <ModalFornecedores
        open={openFornecedor}
        toggle={toggleFornecedor}
        setIdFornecedor={setIdFornecedor}
        idFornecedor={idFornecedor}
        nome={findFornecedores?.razao_social}
        updateData={loadDadosTable}
        adminCheck={adminCheck}
      />

      <ModalCirurgiao
        open={openCirurgiao}
        toggle={toggleCirurgiao}
        setId={setIdCirurgiao}
        id={idCirurgiao}
        id_selecionado={selecionado}
        nome={findCirurgiao?.nome}
        updateData={loadDadosTable}
        status_id={statusId}
      />
      <ModalSecretaria
        open={openSecretaria}
        toggle={toggleSecretaria}
        setId={setIdSecretaria}
        id={idSecretaria}
        id_selecionado={selecionado}
        nome={findSecretaria?.nome}
        updateData={loadDadosTable}
      />
      <Loader isActive={loader}>
        <div className="p-12">
          <div className="grid grid-flow-col justify-start">
            <h1 className="text-blue text-4xl font-bold px-5 mb-5">Usuários</h1>
          </div>
          <div className="grid grid-cols-3 pb-10">
            <div className="w-full rounded-lg px-5 py-1">
              <div className="flex ">
                <CgSearch
                  size="1.5rem"
                  className="my-3 mr-4 search-icon-c text-blue"
                />
                <input
                  placeholder="Buscar por ..."
                  type="text"
                  className="search-bar-crud px-1 w-full text-sm mb-1"
                  name="filtro"
                  onChange={onChange}
                  autoComplete="off"
                  value={filtro}
                />
              </div>
            </div>
          </div>
          <Tabs
            selectedIndex={tabIndex}
            onSelect={(index) => setTabIndex(index)}
          >
            <div className="flex justify-between ml-5">
              <TabList>
                <div className="bg-white flex justify-center font-semibold text-base divide-x divide-gray-300">
                  <Tab onClick={onClick} name={"1"}>
                    Cirurgiões
                  </Tab>
                  <Tab onClick={onClick} name={"3"}>
                    Secretárias
                  </Tab>
                  <Tab onClick={onClick} name={"2"}>
                    Fornecedores
                  </Tab>
                </div>
              </TabList>
              <div>
                {selecionado === "2" ? (
                  <button
                    onClick={novo}
                    className="btn-new-vendedor bg-blue text-white font-bold rounded-full content-center px-4 py-2 flex mb-5 shadow-md"
                  >
                    <FiPlus size="1.3rem" className="mr-2 text-white" /> Novo
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>

            <TabPanel>
              <div className="grid grid-cols-full text-sm">
                {doutores?.length === 0 ? (
                  <EmptyContent
                    messageSize="2xl"
                    iconSize="2.2rem"
                    paddingContent={"py-32"}
                    titleContent="Sem Novos Cirurgiões"
                  />
                ) : (
                  <Fragment>
                    <table className={tableClassName}>
                      <thead>
                        <tr className="text-center">
                          <th className={thClassName}></th>
                          <th className="pl-12 py-2 text-left">Nome</th>
                          <th className={thClassName}>CRM</th>
                          <th className={thClassName}>WhatsApp</th>
                          <th className={thClassName}>Email</th>
                          <th className={thClassName}>Secretária</th>
                          <th className={thClassName}>Status</th>
                          <th className={thClassName}></th>
                        </tr>
                      </thead>
                      {doutores?.map((dados: DoutoresAdmin) => {
                        const status = dados.usuario ?
                          dados.usuario.status_id === 8
                            ? "Inativo"
                            : dados.usuario.status_id === 7
                            ? "Completo"
                            : "Inativo" : "Deletado";
                        return (
                          <TableUsuarios
                            key={dados.id}
                            id={dados.id}
                            id_selecionado={selecionado}
                            onClick={dadosModal}
                            campo_1={dados?.nome}
                            campo_2={dados?.crm}
                            campo_3={dados?.whatsapp}
                            campo_4={dados?.usuario?.email}
                            campo_5={
                              dados.secretarias?.nome
                                ? dados.secretarias?.nome
                                : "-"
                            }
                            status={status}
                            status_id={dados.usuario ? dados.usuario.status_id : 0}
                          />
                        );
                      })}
                    </table>
                    {pageCount === 1 ? (
                      ""
                    ) : (
                      <ReactPaginate
                        previousLabel={
                          <BiChevronLeft
                            size="1.5rem"
                            className={
                              offset / perPage === theFirst
                                ? "cursor-default text-gray-400 outline-none"
                                : "text-blue outline-none"
                            }
                          />
                        }
                        nextLabel={
                          <BiChevronRight
                            size="1.5rem"
                            className={
                              offset / perPage === theLast
                                ? "cursor-default text-gray-400 outline-none"
                                : "text-blue outline-none"
                            }
                          />
                        }
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={pageCount}
                        forcePage={page}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageChange}
                        containerClassName={
                          "flex justify-center space-x-3 pt-10 text-md"
                        }
                        activeClassName={"active"}
                        activeLinkClassName={"p-1 transform scale-150 "}
                      />
                    )}
                  </Fragment>
                )}
              </div>
            </TabPanel>

            <TabPanel>
              <div className="grid grid-cols-full text-sm">
                {secretarias?.length === 0 ? (
                  <EmptyContent
                    messageSize="2xl"
                    iconSize="2.2rem"
                    paddingContent={"py-32"}
                    titleContent="Sem Novas Secretárias"
                  />
                ) : (
                  <Fragment>
                    <table className={tableClassName}>
                      <thead>
                        <tr className="text-center">
                          <th className={thClassName}></th>
                          <th className="pl-12 py-2 text-left">Nome</th>
                          <th className={thClassName}>Telefone</th>
                          <th className={thClassName}>WhatsApp</th>
                          <th className={thClassName}>Email</th>
                          <th className={thClassName}>Status</th>
                          <th className={thClassName}></th>
                        </tr>
                      </thead>
                      {secretarias?.map((dados: Secretarias) => {
                        const status =
                        dados.usuario ?
                          dados.usuario.status_id === 6
                            ? "Incompleto"
                            : dados.usuario.status_id === 7
                            ? "Completo"
                            : "Inativo" : "Deletado";
                        return (
                          <TableUsuarios
                            key={dados.id}
                            id={dados.id}
                            id_selecionado={selecionado}
                            onClick={dadosModal}
                            campo_1={dados?.nome}
                            campo_2={dados?.telefone ? dados.telefone : "-"}
                            campo_3={dados?.whatsapp}
                            campo_4={
                              dados?.usuario?.email ? dados.usuario?.email : "-"
                            }
                            status={status}
                            status_id={dados.usuario ? dados.usuario.status_id : 0}
                          />
                        );
                      })}
                    </table>
                    {pageCount === 1 ? (
                      ""
                    ) : (
                      <ReactPaginate
                        previousLabel={
                          <BiChevronLeft
                            size="1.5rem"
                            className={
                              offset / perPage === theFirst
                                ? "cursor-default text-gray-400 outline-none"
                                : "text-blue outline-none"
                            }
                          />
                        }
                        nextLabel={
                          <BiChevronRight
                            size="1.5rem"
                            className={
                              offset / perPage === theLast
                                ? "cursor-default text-gray-400 outline-none"
                                : "text-blue outline-none"
                            }
                          />
                        }
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={pageCount}
                        forcePage={page}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageChange}
                        containerClassName={
                          "flex justify-center space-x-3 pt-10 text-md"
                        }
                        activeClassName={"active"}
                        activeLinkClassName={"p-1 transform scale-150 "}
                      />
                    )}
                  </Fragment>
                )}
              </div>
            </TabPanel>

            <TabPanel>
              <div className="grid grid-cols-full text-sm">
                {fornecedores?.length === 0 ||
                fornecedores?.length === undefined ? (
                  <EmptyContent
                    messageSize="2xl"
                    iconSize="2.2rem"
                    paddingContent={"py-32"}
                    titleContent="Sem Novos Fornecedores"
                  />
                ) : (
                  <Fragment>
                    <table className={tableClassName}>
                      <thead>
                        <tr className="text-center">
                          <th className={thClassName}></th>
                          <th className="pl-12 py-2 text-left">Razão Social</th>
                          <th className={thClassName}>Fantasia</th>
                          <th className={thClassName}>CNPJ</th>
                          <th className={thClassName}>WhatsApp</th>
                          <th className={thClassName}>Email</th>
                          <th className={thClassName}>Status</th>
                          <th className={thClassName}></th>
                        </tr>
                      </thead>
                      {fornecedores?.map((dados: Fornecedores) => {
                        const statusFornecedor =
                          dados.usuario === null
                            ? "Incompleto"
                            : dados.usuario?.status_id === 7
                            ? "Completo"
                            : "Inativo";

                        return (
                          <TableUsuarios
                            key={dados.id}
                            id={dados.id}
                            id_selecionado={selecionado}
                            onClick={dadosModal}
                            campo_1={dados?.razao_social}
                            campo_2={dados?.fantasia}
                            campo_3={dados?.cnpj}
                            campo_4={dados?.whatsapp}
                            campo_5={dados?.usuario?.email}
                            status={statusFornecedor}
                            status_id={dados.usuario?.status_id}
                            usuarioId={dados?.usuario}
                          />
                        );
                      })}
                    </table>
                    {pageCount === 1 ? (
                      ""
                    ) : (
                      <ReactPaginate
                        previousLabel={
                          <BiChevronLeft
                            size="1.5rem"
                            className={
                              offset / perPage === theFirst
                                ? "cursor-default text-gray-400 outline-none"
                                : "text-blue outline-none"
                            }
                          />
                        }
                        nextLabel={
                          <BiChevronRight
                            size="1.5rem"
                            className={
                              offset / perPage === theLast
                                ? "cursor-default text-gray-400 outline-none"
                                : "text-blue outline-none"
                            }
                          />
                        }
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={pageCount}
                        forcePage={page}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageChange}
                        containerClassName={
                          "flex justify-center space-x-3 pt-10 text-md"
                        }
                        activeClassName={"active"}
                        activeLinkClassName={"p-1 transform scale-150 "}
                      />
                    )}
                  </Fragment>
                )}
              </div>
            </TabPanel>
          </Tabs>
        </div>
      </Loader>
    </Fragment>
  );
}

export default connector(Usuarios);
