import React from 'react';
import './style.css';
import blueSearch from '../../assets/MobileAuthorizations/Blue-Search.svg'
import { connect, ConnectedProps } from 'react-redux';
import { AuthState, ChecklistState, Cirurgias } from 'src/store/types';
import { setUpdateChecklist } from 'src/store/actions/checklist';
import { axiosApi } from 'src/services/axiosInstances';
import MobileAuthorizationCard from 'src/components/MobileAuthorizationCard';
import MobileAuthorizationModal from 'src/components/MobileAuthorizationModal';

// export const INITIAL_CIRURGIAS = {
//   id: Number(-1),
//   nome: 'asdasd',
//   status_id: -1,
//   telefone: '',
//   whatsapp: '',
//   email: '',
//   datas: [
//     {
//       cirurgia_id: -1,
//       data: '',
//     }
//   ],
//   status: {
//     id: -1,
//     descricao: '',
//   },
//   cirurgia: '',
//   hospital: {
//     id: -1,
//     nome: '',
//   },
//   nascimento: '',
//   sexo: '',
//   hospital_id: -1,
//   vencimento: '',
//   confirmacao_data: '',
//   confirmacao_hora: '',
//   doutor: {
//     id: BigInt(-1),
//     nome: 'asdasd',
//     crm: '',
//   },
//   pre_operatorio: false,
//   alergia: false,
//   materiais: [
//     {
//       id: -1,
//       checklist_id: -1,
//       fornecedor_id: -1,
//       descricao: '',
//       reportar_problema: '',
//       forncedor: {
//         id: -1,
//         razao_social: '',
//       },
//       confirmado: false,
//     }
//   ],
//   material: {
//     id: -1,
//     checklist_id: -1,
//     data_confirmacao: '',
//     fornecedor_id: -1,
//   },
//   guia_internamento: {
//     id: -1,
//     nome: '',
//     url: '',
//     tipo_mime: '',
//   },
//   guia_materiais: {
//     id: -1,
//     nome: '',
//     url: '',
//     tipo_mime: '',
//   },
//   resultados_exames: {
//     id: -1,
//     nome: '',
//     url: '',
//     tipo_mime: '',
//   }
// }

const mapStateToProps = ({
  auth,
  cirurgias,
}: {
  auth: AuthState;
  cirurgias: ChecklistState;
}): AuthState & ChecklistState => {
  return {
    doutor: auth.doutor,
    usuario: auth.usuario,
    cirurgias: cirurgias.cirurgias,
  };
};

const mapDispatchToProps = {
  setUpdateChecklist,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux;

type HomeResponse = {
  cirurgias: Cirurgias[];
  total: number;
};

function MobileAuthorizations({ setUpdateChecklist, doutor, usuario, cirurgias }: Props) {
  const [authModal, setAuthModal] = React.useState(-1);
  const [filter, setFilter] = React.useState('');
  const [idCirurgia, setIdCirurgia] = React.useState<number>(-1);
  const [statusCirurgia, setStatusCirurgia] = React.useState<string>();
  // const dadosCirurgia = (id: number): void => {
  //   const cirurgiaStatus = [...cirurgias].find((element) => element.id === id);
  //   setActualSurgery(old => old = {...old, id: id});
  //   setActualSurgery(old => old = {...old, status: {...old.status, descricao: String(cirurgiaStatus?.status.descricao)}});
  // };

  // const timeLine = (id: number): void => {
  //   setActualSurgery(old => old = {...old, id: id});
  // };

  const loadDados = async () => {
    const { data } = await axiosApi.get<HomeResponse>(
      `/autorizacoes?id=${doutor?.id}&limit=30&offset=$0&filtro=${filter}`
    );
    setUpdateChecklist(data.cirurgias);
  };

  React.useEffect(() => {
    loadDados();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doutor, filter]);

  const loadDadosTable = async () => {
    const { data } = await axiosApi.get<HomeResponse>(
      `/autorizacoes?id=${doutor?.id}&limit=30&offset0&filtro=${filter}`
    );
    setUpdateChecklist(data.cirurgias);
  };

  const dadosCirurgia = (id: number): void => {
    setAuthModal(1);
    const cirurgiaStatus = [...cirurgias].find((element) => element.id === id);
    setIdCirurgia(id);
    setStatusCirurgia(cirurgiaStatus?.status.descricao);
  };
  React.useEffect(() => {
    if (authModal === 0) {
      setTimeout(() => { setAuthModal(-1) }, 400);
    }
  }, [authModal, setAuthModal])

  1 < 0 && console.log(loadDadosTable);

  return (
    <>
      <div className='mobile-authorizations-wrapper'>
        <div className='mobile-authorizations-top-container'>
          <h3 className='mobile-authorizations-title'>Autorizações</h3>
          <div className='mobile-authorizations-search-container'>
            <img className='mobile-authorizations-search-icon' src={blueSearch} alt="" />
            <input value={filter} onChange={(e) => { e.persist(); setFilter(e.target.value) }} className='mobile-authorizations-search-input' placeholder='Busca por nome do paciente, cirurgia...' type="text" />
          </div>
        </div>
        <div className={`mobile-authorizations-list-container${cirurgias?.length !== 0 && String(cirurgias) !== 'undefined' ? ' empty' : ''}`}>
          {
            // cirurgias?.length !== 0 && String(cirurgias) !== 'undefined' ?
            // cirurgias?.map((lista: Cirurgias) => {
            //   const nomeCirurgia: string =
            //     lista.cirurgia === null ? "-" : lista.cirurgia;
            //   const confirmacao_data: string =
            //     lista.confirmacao_data === null
            //       ? "-"
            //       : lista.confirmacao_data
            //           .split("-")
            //           .reverse()
            //           .join("/");
            //   const telefone: string =
            //     lista.telefone === null ? "-" : lista.telefone;
            //   1 < 0 && console.log(nomeCirurgia, confirmacao_data, telefone);
            //   return (
            //     <MobileAuthorizationCard
            //       onClick={() => {
            //         dadosCirurgia(lista.id)
            //       }}
            //       doctor={lista.doutor.nome}
            //       surgery={lista.nome}
            //     />
            //   );
            // })  
            cirurgias?.length !== 0 && String(cirurgias) !== 'undefined' ?
              usuario?.nivel === 2 ?
                cirurgias?.filter((item) => item.status.descricao !== 'Autorizada').map((lista: Cirurgias) => {
                  const nomeCirurgia: string =
                    lista.cirurgia === null ? "-" : lista.cirurgia;
                  const confirmacao_data: string =
                    lista.confirmacao_data === null
                      ? "-"
                      : lista.confirmacao_data
                        .split("-")
                        .reverse()
                        .join("/");
                  const telefone: string =
                    lista.telefone === null ? "-" : lista.telefone;
                  1 < 0 && console.log(nomeCirurgia, confirmacao_data, telefone);
                  return (
                    <MobileAuthorizationCard
                      onClick={() => {
                        if (lista?.status.descricao !== 'Concluída')
                          dadosCirurgia(lista.id)
                      }}
                      status={lista.status.descricao}
                      doctor={lista.doutor.nome}
                      surgery={lista.nome}
                    />
                  );
                }) :
                cirurgias?.map((lista: Cirurgias) => {
                  const nomeCirurgia: string =
                    lista.cirurgia === null ? "-" : lista.cirurgia;
                  const confirmacao_data: string =
                    lista.confirmacao_data === null
                      ? "-"
                      : lista.confirmacao_data
                        .split("-")
                        .reverse()
                        .join("/");
                  const telefone: string =
                    lista.telefone === null ? "-" : lista.telefone;
                  1 < 0 && console.log(nomeCirurgia, confirmacao_data, telefone);
                  return (
                    <MobileAuthorizationCard
                      onClick={() => {
                        dadosCirurgia(lista.id)
                      }}
                      status={lista.status.descricao}
                      doctor={lista.doutor.nome}
                      surgery={lista.nome}
                    />
                  );
                }) :

              <span className='mobile-authorizations-list-empty'>Você ainda não possui autorizações</span>
          }
        </div>
      </div>
      <div className={`mobile-authorization-modal-wrapper${authModal === -1 ? ' none' : authModal === 1 ? ' show' : ' hide'}`}>
        <div onClick={() => { setAuthModal(0) }} className={`mobile-authorization-modal-blur-mask${authModal === -1 ? '' : authModal === 1 ? ' show' : ' hide'}`} />
        <div className={`mobile-authorization-modal-container${authModal === -1 ? '' : authModal === 1 ? ' show' : ' hide'}`}>
          <MobileAuthorizationModal
            closeAction={() => { setAuthModal(0); loadDados(); }}
            idCirurgia={idCirurgia}
            updateData={loadDadosTable}
            status={statusCirurgia}
          />
        </div>
      </div>
    </>
  );
}

export default connector(MobileAuthorizations);