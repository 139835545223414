import React from "react";
import "./style.css";
import Popup from "reactjs-popup";
import Loader from "../Loader";

interface Fotos {
  url: string;
}

interface Usuario {
  fotos: Fotos;
  email: string;
}

interface Secretaria {
  id: number;
  email_secretaria: string;
  secretarias: {
    nome: string;
    usuario: Usuario;
  };

}

interface ModalSecretariado {
  isOpen: boolean;
  email: string;
  modalIndex: number
  userData: {
    nome: string;
    usuario: {
      fotos: {
        url: string;
      };
    };
  } | null,
  loader: boolean;
  closeModal: () => void;
  addSecretario: () => void;
  secretarioToDelete: Secretaria | null;
  deleteSecretario: (id: number) => void;
}

const ModalSecretariado: React.FC<ModalSecretariado> = ({ isOpen, userData, loader, closeModal, addSecretario, modalIndex, email, secretarioToDelete, deleteSecretario }) => {

  return (
    <Popup
      closeOnDocumentClick={false}
      open={isOpen}
      onClose={closeModal}
    >
      <div className="modal-secretariado w-auto h-auto">
        <Loader
          isActive={loader}>
          {
            modalIndex === 0 ? <div className="flex flex-col p-2">
              <div style={{ height: 60 }} className="flex justify-center items-center">
                {userData?.usuario.fotos ? <img className="flex w-12 h-12 rounded-full object-cover"
                  src={userData?.usuario.fotos.url} alt="Foto do secretário" /> : <svg stroke="currentColor" fill="currentColor" viewBox="0 0 496 512" className="text-gray-400" height="48px" width="48px" xmlns="http://www.w3.org/2000/svg">
                  <path d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 96c48.6 0 88 39.4 88 88s-39.4 88-88 88-88-39.4-88-88 39.4-88 88-88zm0 344c-58.7 0-111.3-26.6-146.5-68.2 18.8-35.4 55.6-59.8 98.5-59.8 2.4 0 4.8.4 7.1 1.1 13 4.2 26.6 6.9 40.9 6.9 14.3 0 28-2.7 40.9-6.9 2.3-.7 4.7-1.1 7.1-1.1 42.9 0 79.7 24.4 98.5 59.8C359.3 421.4 306.7 448 248 448z"></path>
                </svg>}
                <h1 className="nome-secretario font-black pl-3">{userData?.nome}</h1>
              </div>
              <p
                style={{ color: '#2460D7' }}
                className="font-black text-center pt-3">Confirma que {userData?.nome} deverá ter {<br></br>} acesso a todas as suas {<br></br>} informações?
              </p>
            </div>
              : modalIndex === 1 ?
                <div>
                  <p
                    style={{ color: '#2460D7' }}
                    className="font-black text-center pt-3">Secretaria nao encontrada na plataforma.{<br></br>} Deseja enviar um convite para {<br></br>} {email} ?
                  </p>
                </div> :
                <div>
                  <p
                    style={{ color: '#EB5757' }}
                    className="font-black text-center pt-3">Tem certeza que deseja desvincular{<br></br>} secretária(o) {secretarioToDelete?.secretarias?.nome || email}?
                  </p>
                </div>
          }
          <div style={{ width: 248 }} className="flex justify-between mx-auto pt-4">
            <button
              style={{ height: 42, width: 110, color: modalIndex === 2 ? '#EB5757' : '#2460D7', borderColor: modalIndex === 2 ? '#EB5757' : '#2460D7' }}
              className="secretariado-buttons rounded-full py-2 px-4 border-2 font-black text-center"
              onClick={closeModal}
            >
              Cancelar
            </button>
            {
              modalIndex !== 2 ?
                <button
                  className="secretariado-buttons rounded-full py-2 px-4 border-2 font-black text-center"
                  style={{ height: 42, width: 110, color: 'white', borderColor: '#2460D7', backgroundColor: '#2460D7' }}
                  onClick={addSecretario}
                >
                  {modalIndex === 0 ? 'Confirmar' : 'Enviar'}
                </button> :
                <button
                  className="secretariado-buttons rounded-full py-2 px-4 border-2 font-black text-center"
                  style={{ height: 42, width: 110, color: 'white', borderColor: '#EB5757', backgroundColor: '#EB5757' }}
                  onClick={() => {
                    if (secretarioToDelete) {
                      deleteSecretario(secretarioToDelete.id);
                    }
                  }}
                >
                  Excluir
                </button>
            }
          </div>
        </Loader>
      </div>
    </Popup>
  )
}

export default ModalSecretariado;
