import "./style.css";
import React from "react";
import { FiEdit } from "react-icons/fi";
import { AuthState } from "src/store/types";
import { connect, ConnectedProps } from "react-redux";
// import { AiFillStar, AiOutlineStar } from "react-icons/ai";

const mapStateToProps = ({ auth }: { auth: AuthState }): AuthState => {
  return {
    usuario: auth.usuario,
  };
};
const connector = connect(mapStateToProps, {});

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
  id?: any;
  nome?: string;
  whatsapp?: string;
  email?: string;
  // usuario?: Usuario;
  status?: string;
  status_id?: number;
  contato?: string;
  onClick?: (id: number, nome: any, cnpj: any) => void;
  onDelete?: (id: number, nome: any) => void;
  onChangeHospital?: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void | undefined;
  cnpj?: string;
};

// type Usuario = {
//   id?: number;
//   email?: string;
// };

function HospitaisLista({
  id,
  nome,
  whatsapp,
  // usuario,
  onClick,
  onDelete,
  onChangeHospital,
  email,
  status_id,
  contato,
  status,
  cnpj,
  usuario,
}: Props): JSX.Element {
  const adminCheck = usuario?.nivel === 5;
  // const [favButton, setFavButton] = useState(false);
  const statusColor =
    status_id === 6 ? "Incompleto" : status_id === 7 ? "Completo" : "Inativo";

  const statusHover =
    status_id === 6
      ? "text-yellow-500"
      : status_id === 7
      ? "text-green-500"
      : "text-gray-400";
  return (
    <tbody>
      <tr className={`text-center cursor-default hover:${statusHover}`}>
        <td className={adminCheck ? "py-0" : "py-2"}>
          <div className="flex justify-end">
            <span className={`w-5 h-5 ${statusColor} rounded-full`}></span>
          </div>
        </td>
        <td>
          <div className={"truncate text-left pl-12 "}>{nome}</div>
        </td>
        <td>
          <div className={"truncate"}>{contato}</div>
        </td>
        <td>
          <div className={"truncate"}>{whatsapp}</div>
        </td>
        <td>
          <div className={"truncate"}>{email}</div>
        </td>
        <td>
          <div className={"truncate"}>{status}</div>
        </td>
        <td>
          <div className="flex justify-center space-x-2">
            {adminCheck ? (
              <button
                onClick={() => (onClick ? onClick(id, nome, cnpj) : undefined)}
                className="btn-vend bg-blue p-2 rounded-md"
              >
                <FiEdit size="1.3rem" className="text-white" />
              </button>
            ) : (
              // <button
              //   className="p-2 rounded-md"
              //   onClick={() => setFavButton(!favButton)}
              // >
              //   {favButton ? (
              //     <AiOutlineStar size="1.5rem" className="text-blue" />
              //   ) : (
              //     <AiFillStar size="1.5rem" className="text-blue" />
              //   )}
              // </button>
              ""
            )}
          </div>
        </td>
      </tr>
    </tbody>
  );
}

export default connector(HospitaisLista);
