import React, { Fragment, useEffect, useState } from "react";
import "./style.css";
import Popup from "reactjs-popup";
import { AiOutlineMessage } from "react-icons/ai";
import ModalReportar from "../ModalReportar";
import { Chrono } from "react-chrono";
import { axiosApi } from "src/services/axiosInstances";
import Loader from "../Loader";

type Props = {
  open?: any;
  close?: any;
  idCirurgia?: number;
  nome?: string;
};

type Report = {
  id: number;
  descricao: string;
  fornecedor_id: number;
  checklist_id: number;
  created_at: string;
  confirmado: boolean;
  reportar_problema: string;
  usuario_id: number;
  data_confirmacao: string;
};

type Log = {
  id: number;
  cirurgias_id: number;
  etapa_id: number;
  usuario_id: number;
  created_at: string;
  updated_at: string;
  paciente: string;
  descricao: string;
  nivel_id: number;
  usuario: string;
  reportar_problema: string;
  reportado: boolean;
};

function ModalTimeline({ open, close, idCirurgia, nome }: Props): JSX.Element {
  const modalWidth = { width: "50%" };
  const [openModalReportar, setOpenModalReportar] = useState(false);
  // const closeModalReportar = () => setOpenModalReportar(!openModalReportar);
  const openReport = (): void => {
    setOpenModalReportar(true);
  };
  const [dadosLog, setDadosLog] = useState<Log[]>([]);
  const [checklistId, setChecklistId] = useState<Number>();
  const [usuarioId, setUsuarioId] = useState<Number>();
  const [loader, setLoader] = useState(true);
  const [loaderReport, setLoaderReport] = useState(true);
  const [dadosReport, setDadosReport] = useState<Report>();

  const loadDados = async () => {
    try {
      setLoader(true);
      const { data } = await axiosApi.get<Log[]>(`/log?id=${idCirurgia}`);
      setDadosLog(data);
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (idCirurgia) {
      loadDados();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idCirurgia]);

  const loadReports = async () => {
    try {
      setLoaderReport(true);
      const { data } = await axiosApi.get<Report>(
        `/reports/${checklistId}/${usuarioId}`
      );
      setDadosReport(data);
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoaderReport(false);
    }
  };

  useEffect(() => {
    if (checklistId && usuarioId) {
      loadReports();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checklistId, usuarioId]);

  const handleIds = (usuario_id: number, checklist_id: number) => {
    setChecklistId(checklist_id);
    setUsuarioId(usuario_id);
  };

  return (
    <Popup
      open={open}
      closeOnDocumentClick={true}
      onClose={close}
      contentStyle={modalWidth}
      modal
      nested={true}
    >
   
        <Fragment>
          <ModalReportar
            checklist_id={checklistId}
            loader={loaderReport}
            usuario_id={usuarioId}
            open={openModalReportar}
            // close={closeModalReportar}
            descricao={dadosReport?.descricao}
            reportar_problema={dadosReport?.reportar_problema}
            created_at={dadosReport?.created_at}
          />
          <div className="bg-white modal py-5 px-5">
            <Loader isActive={loader} hide="mt-64">
              <div className="header pt-5 pb-3 text-center text-3xl font-bold truncate">
                Linha do Tempo de {dadosLog[0]?.paciente}
                <div className="content mt-5">
                  <div className="container mx-auto w-full h-full">
                    <Chrono
                      mode="VERTICAL_ALTERNATING"
                      hideControls
                      disableNavOnKey
                      itemWidth={10}
                      theme={{
                        primary: "#e2e2e2",
                        secondary: "#3460D7",
                        cardBgColor: "white",
                      }}
                    >
                      {dadosLog?.map((dados: Log) => {
                        const formatDate = new Date(
                          dados.created_at
                        ).toLocaleDateString();
                        const formatHour = new Date(
                          dados.created_at
                        ).toLocaleTimeString([], {
                          hour: "2-digit",
                          minute: "2-digit",
                        });
                        const reportColor: string =
                          dados.etapa_id === 6 ? "text-red-500" : "text-blue";
                        return (
                          <div
                            key={dados.id}
                            className="grid grid-cols-1 relative"
                          >
                            <div
                              key={dados.id}
                              className="col-span-1 whitespace-normal w-full"
                            >
                              <p className="mb-3 font-bold text-grey text-lg">
                                {dados.descricao}
                              </p>

                              {dados.etapa_id === 6 ? (
                                <Fragment>
                                  <div className="report-btn flex">
                                    <p
                                      className={`font-bold ${reportColor} text-sm`}
                                    >
                                      {formatDate + " - " + formatHour}
                                    </p>
                                  </div>
                                  <p className="text-gray-800 truncate font-light text-sm">
                                    <span className="font-bold">
                                      Fornecedor:{" "}
                                    </span>
                                    {dados.usuario}
                                  </p>
                                  {dados.reportado === false ? (
                                    ""
                                  ) : (
                                    <div className="grid grid-cols-2">
                                      <div className="col-span-1 cursor-pointer">
                                        <div
                                          className="flex mt-2 justify-center py-1 bg-red-500 rounded-lg"
                                          onClick={openReport}
                                        >
                                          <AiOutlineMessage
                                            size="0.8rem"
                                            className="text-white mt-1 mr-1"
                                          />

                                          <span
                                            onClick={() =>
                                              handleIds(
                                                dados.usuario_id,
                                                dados.cirurgias_id
                                              )
                                            }
                                            className="text-white rounded-full truncate text-xs"
                                          >
                                            ver mais
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </Fragment>
                              ) : (
                                <p
                                  className={`font-bold ${reportColor} text-sm`}
                                >
                                  {formatDate + " - " + formatHour}
                                </p>
                              )}

                              {dados.etapa_id === 6 ? (
                                ""
                              ) : (
                                <p
                                  title={
                                    dados.etapa_id === 1
                                      ? dados.paciente
                                      : dados.usuario
                                  }
                                  className="text-sm text-gray-800 truncate font-light"
                                >
                                  <span className="font-bold">
                                    {dados.nivel_id === null && "Paciente:"}
                                    {dados.nivel_id === 1 && "Doutor:"}
                                    {dados.nivel_id === 2 && "Fornecedor:"}
                                    {dados.nivel_id === 3 && "Secretaria:"}
                                  </span>{" "}
                                  {dados.etapa_id === 1
                                    ? dados.paciente
                                    : dados.usuario}
                                </p>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </Chrono>
                  </div>
                </div>
              </div>
            </Loader>
          </div>
        </Fragment>
      
    </Popup>
  );
}
export default ModalTimeline;
