import React, { useRef, useState, useEffect } from 'react';
import './style.css';
import birdid from '../../../assets/birdid2.png';
import { axiosApi } from "src/services/axiosInstances";
import Loader from '../../../components/Loader';
import { toast } from 'react-toastify';

interface BirdidConfirmationProps {
  goToBirdidCancel: () => void;
  checkCertificate: () => void;
}

const CodeInput: React.FC<{ onComplete: (code: string) => void; resetTrigger: boolean }> = ({ onComplete, resetTrigger }) => {
  const inputRefs = useRef<Array<HTMLInputElement | null>>([]);
  const [code, setCode] = useState<string[]>(Array(6).fill(''));

  useEffect(() => {
    inputRefs.current[0]?.focus();
  }, []);

  useEffect(() => {
    if (resetTrigger) {
      setCode(Array(6).fill(''));
      inputRefs.current[0]?.focus();
    }
  }, [resetTrigger]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { value } = e.target;
    if (/^\d$/.test(value)) {
      const newCode = [...code];
      newCode[index] = value;
      setCode(newCode);

      if (index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1]?.focus();
      } else {
        onComplete(newCode.join(''));
      }
    } else if (value === '') {
      const newCode = [...code];
      newCode[index] = '';
      setCode(newCode);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === 'Backspace') {
      if (code[index]) {
        const newCode = [...code];
        newCode[index] = '';
        setCode(newCode);
      } else if (index > 0) {
        inputRefs.current[index - 1]?.focus();
      }
    }
  };

  return (
    <div className="code-input-container flex justify-center">
      {Array(6).fill('').map((_, index) => (
        <input
          id="code"
          style={{ width: 55, height: 70 }}
          key={index}
          type="text"
          maxLength={1}
          className="input-field text-center mx-1 mt-5"
          onChange={(e) => handleInputChange(e, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          ref={el => inputRefs.current[index] = el}
          value={code[index]}
        />
      ))}
    </div>
  );
};

const BirdidConfirmation: React.FC<BirdidConfirmationProps> = ({ goToBirdidCancel, checkCertificate }) => {
  // const [message, setMessage] = useState<boolean>(false);
  const [resetTrigger, setResetTrigger] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const handleCodeComplete = async (code: string) => {
    setLoading(true);
    const body = {
      certificador_id: 2,
      password: code
    }
    try {
      const response = await axiosApi.post('/certificado', body);
      // setMessage(false);
      checkCertificate();
      goToBirdidCancel();
      console.log(response);
      toast.success(`Assinatura digital verificada com sucesso`, {
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } catch (error) {
      // setMessage(true);
      setResetTrigger(true);
      setTimeout(() => setResetTrigger(false), 0);
      console.error(error);
      toast.error(`Código inválido, tente novamente`, {
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col justify-center">
      <img className="w-3/4 h-auto mx-auto" src={birdid} alt="logo birdid" />
      <div className={`input-container`}>
        <Loader isActive={loading}>
          <label htmlFor="code" className="input-label pt-5">Insira o código gerado no aplicativo:</label>
          <CodeInput onComplete={handleCodeComplete} resetTrigger={resetTrigger} />
          {/* {message && <span className="pt-1 text-red-600">Código inválido, tente novamente</span>} */}
        </Loader>
      </div>
    </div>
  );
};

export default BirdidConfirmation;
