import React, { Fragment, useEffect, useState } from "react";
import "./style.css";

import Popup from "reactjs-popup";
import { AiFillCloseCircle } from "react-icons/ai";
import InputMask from "react-input-mask";
import { toast, ToastContainer } from "react-toastify";
import { axiosApi } from "src/services/axiosInstances";
import { viaCepApi } from "../../services/viacep";
import Loader from "../Loader";
import { TailSpin } from "react-loader-spinner";
type Props = {
  open?: any;
  close?: any;
  idHospital?: number;
  dataCirurgia?: string;
  horaCirurgia?: string;
  nome?: string;
  cnpj?: string;
  cep?: string;
  endereco?: string;
  numero?: string;
  bairro?: string;
  complemento?: string;
  cidade?: string;
  estado?: string;
  telefone?: string;
  whatsapp?: string;
  email?: string;
  contato?: string;
  status?: boolean;
  onChange?: (event: any) => void;
  onBlur?: (event: any) => void;
  blurCep?: (event: React.FocusEvent<HTMLInputElement>) => void;
  toggle?: any;
  updateData?: any;
  setIdHospital?: any;
  adminCheck?: any;
};

type ViaCep = {
  cep?: string;
  logradouro?: string;
  bairro?: string;
  localidade?: string;
  uf?: string;
};

type Hospitais = {
  id?: number;
  nome?: string;
  telefone?: string;
  whatsapp?: string;
  email?: string;
  cnpj?: string;
  endereco?: string;
  numero?: string;
  complemento?: string;
  bairro?: string;
  cep?: string;
  cidade?: string;
  uf?: string;
  status_id?: number;
  contato?: string;
};

function ModalHospitais({
  open,
  close,
  idHospital,
  setIdHospital,
  nome,
  adminCheck,
  toggle,
  updateData,
}: Props): JSX.Element {
  const modalSize: string = adminCheck ? "85%" : "45%";
  const modalWidth = { width: `${modalSize}` };

  const [dadosHospital, setDadosHospital] = useState<Hospitais>();
  const [loader, setLoader] = useState(false);

  const loadDados = async () => {
    try {
      setLoader(true);
      const { data } = await axiosApi.get<Hospitais>(`/hospital/${idHospital}`);
      setDadosHospital(data);
      setCheck(data.status_id === 8 ? "Inativo" : "Ativo");
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };
  useEffect(() => {
    if (idHospital !== undefined) {
      loadDados();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idHospital]);

  useEffect(() => {
    if (open === false) {
      setDadosHospital({});
    }
  }, [open]);

  const postForm = (event: any) => {
    const { name, value } = event.target;
    setDadosHospital({
      ...dadosHospital,
      [name]: value,
    });
  };

  const [btnLoader, setBtnLoader] = useState(false);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (
      !dadosHospital?.cnpj ||
      dadosHospital?.cnpj.includes("_") ||
      dadosHospital?.cnpj === "11.111.111/1111-11" ||
      dadosHospital?.cnpj === "22.222.222/2222-22" ||
      dadosHospital?.cnpj === "33.333.333/3333-33" ||
      dadosHospital?.cnpj === "44.444.444/4444-44" ||
      dadosHospital?.cnpj === "55.555.555/5555-55" ||
      dadosHospital?.cnpj === "66.666.666/6666-66" ||
      dadosHospital?.cnpj === "77.777.777/7777-77" ||
      dadosHospital?.cnpj === "88.888.888/8888-88" ||
      dadosHospital?.cnpj === "99.999.999/9999-99"
    ) {
      toast.error('CNPJ Inválido!', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
    } else {
      try {
        setBtnLoader(true);
        const postDados = idHospital
          ? await axiosApi.put(`/hospital`, {
              ...dadosHospital,
              ativo: document.getElementsByName("statusId")[0]["checked"],
            })
          : await axiosApi.post(`/hospital`, {
              ...dadosHospital,
              ativo: document.getElementsByName("statusId")[0]["checked"],
            });

        if (postDados.data.errorInfo) {
          toast.error('Erro no Formulário', {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            });
        } else {
          if (idHospital) {
            toast.success('Alterações Realizadas com Sucesso!', {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              });
            toggle();
            updateData();
            loadDados();
          } else {
            toast.success('Hospital Cadastrado', {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              });
            toggle();
            updateData();
            loadDados();
          }

          // setTimeout(window.location.reload.bind(window.location), 1000);
        }
      } catch (error: any) {
        if (error.response.data.errorInfo) {
          toast.error('Erro no Formulário!', {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            });
        } else if (error.response.data.errorInfo) {
          toast.error('Preencha o CEP!', {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            });
        }
      } finally {
        setBtnLoader(false);
      }
    }
  };

  const cepId = dadosHospital?.cep;

  const onBlurViaCep = async () => {
    try {
      const { data } = await viaCepApi.get<ViaCep>(`/${cepId}/json`);
      if (data["erro"] === true) {
        toast.error('CEP inexistente!', {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          });
      } else {
        const logradouro = data.logradouro ?? undefined;
        const bairro = data.bairro ?? undefined;
        const cidade = data.localidade ?? undefined;
        const uf = data.uf ?? undefined;
        setDadosHospital({
          ...dadosHospital,
          endereco: logradouro,
          bairro: bairro,
          cidade: cidade,
          uf: uf,
        });
      }
    } catch (error: any) {
      toast.warn('Digite um CEP válido!', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
    }
  };

  const [check, setCheck] = useState<String>();

  const checkChange = () => {
    if (document.getElementsByName("statusId")[0]["checked"] === true) {
      setCheck("Ativo");
    } else {
      setCheck("Inativo");
    }
  };

  const handleSubmitSecretaria = async (event: any) => {
    event.preventDefault();

    try {
      setBtnLoader(true);
      await axiosApi.post(`/hospital`, dadosHospital);

      toast.success('Hospital Cadastrado', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
      toggle();
      updateData();
      loadDados();
    } catch (error: any) {
      toast.error('Erro no Formulário', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
    } finally {
      setBtnLoader(false);
    }
  };

  return (
    <Fragment>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover={false}
      />
      <Popup
        open={open}
        closeOnDocumentClick={false}
        // onClose={open}
        contentStyle={modalWidth}
        modal
        nested={true}
      >
        {/* {(close: any) => ( */}
        <Fragment>
          <div className="bg-white modal py-5 px-10">
            <Loader isActive={loader} hide="mt-64">
              <div className="header pt-5 pb-3 text-left text-3xl font-bold truncate">
                {idHospital ? ` Editar ${nome}` : "Novo Hospital"}

                {adminCheck ? (
                  <div className="content px-2">
                    <form onSubmit={handleSubmit}>
                      <div className="grid grid-cols-12 text-base gap-x-3">
                        <div className="col-span-9 input-vendedores mt-5 px-1">
                          <h4 className="text-base font-bold mb-2">
                            Nome do Hospital*
                          </h4>
                          <input
                            type="text"
                            className="input-vendedores w-full h-10 border border-gray-600 pl-3"
                            name="nome"
                            defaultValue={dadosHospital?.nome ?? ""}
                            onChange={postForm}
                            required={true}
                          />
                        </div>
                        <div className="col-span-3 input-vendedores mt-5 px-1">
                          <h4 className="text-base font-bold mb-2">CNPJ*</h4>
                          <InputMask
                            mask="99.999.999/9999-99"
                            onChange={postForm}
                          >
                            <input
                              type="text"
                              className="input-vendedores w-full h-10 border border-gray-600 pl-3"
                              name="cnpj"
                              defaultValue={dadosHospital?.cnpj ?? ""}
                              required={true}
                            />
                          </InputMask>
                        </div>
                      </div>

                      <div className="grid grid-cols-12 text-base gap-x-3">
                        <div className="col-span-2 input-vendedores mt-5 px-1">
                          <h4 className="text-base font-bold mb-2">CEP*</h4>
                          <InputMask
                            mask="99999-999"
                            onChange={postForm}
                            onBlur={onBlurViaCep}
                          >
                            <input
                              type="text"
                              className="input-vendedores w-full h-10 border border-gray-600 pl-3"
                              name="cep"
                              defaultValue={dadosHospital?.cep ?? ""}
                              required={true}
                            />
                          </InputMask>
                        </div>
                        <div className="col-span-5 input-vendedores mt-5 px-1">
                          <h4 className="text-base font-bold mb-2">
                            Endereço*
                          </h4>
                          <input
                            disabled
                            type="text"
                            className="input-vendedores bg-gray-300 w-full h-10 border border-gray-400 pl-3"
                            name="endereco"
                            value={dadosHospital?.endereco}
                            onChange={postForm}
                            required={true}
                          />
                        </div>
                        <div className="col-span-2 input-vendedores mt-5 px-1">
                          <h4 className="text-base font-bold mb-2">Número*</h4>
                          <input
                            type="text"
                            className="input-vendedores w-full h-10 border border-gray-600 pl-3"
                            name="numero"
                            defaultValue={dadosHospital?.numero ?? ""}
                            onChange={postForm}
                            required={true}
                          />
                        </div>
                        <div className="col-span-3 input-vendedores mt-5 px-1">
                          <h4 className="text-base font-bold mb-2">Bairro*</h4>
                          <input
                            disabled
                            type="text"
                            className="input-vendedores bg-gray-300 w-full h-10 border border-gray-400 pl-3"
                            name="bairro"
                            value={dadosHospital?.bairro ?? ""}
                            onChange={postForm}
                            required={true}
                          />
                        </div>
                      </div>

                      <div className="grid grid-cols-12 text-base gap-x-3">
                        <div className="col-span-7 input-vendedores mt-5 px-1">
                          <h4 className="text-base font-bold mb-2">
                            Complemento
                          </h4>
                          <input
                            type="text"
                            className="input-vendedores w-full h-10 border border-gray-600 pl-3"
                            name="complemento"
                            defaultValue={dadosHospital?.complemento ?? ""}
                            onChange={postForm}
                          />
                        </div>
                        <div className="col-span-4 input-vendedores mt-5 px-1">
                          <h4 className="text-base font-bold mb-2">Cidade*</h4>
                          <input
                            disabled
                            type="text"
                            className="input-vendedores bg-gray-300 w-full h-10 border border-gray-400 pl-3"
                            name="cidade"
                            value={dadosHospital?.cidade ?? ""}
                            onChange={postForm}
                            required={true}
                          />
                        </div>
                        <div className="col-span-1 input-vendedores mt-5 px-1">
                          <h4 className="text-base font-bold mb-2">Estado*</h4>
                          <input
                            disabled
                            type="text"
                            className="input-vendedores bg-gray-300 w-full h-10 border border-gray-400 pl-3"
                            name="uf"
                            value={dadosHospital?.uf}
                            onChange={postForm}
                            required={true}
                            maxLength={2}
                          />
                        </div>
                      </div>

                      <div className="grid grid-cols-12 text-base gap-x-3">
                        <div className="col-span-3 input-vendedores mt-5 px-1">
                          <h4 className="text-base font-bold mb-2">Telefone</h4>
                          <InputMask mask="(99)99999-9999" onChange={postForm}>
                            <input
                              type="text"
                              className="input-vendedores w-full h-10 border border-gray-600 pl-3"
                              name="telefone"
                              defaultValue={dadosHospital?.telefone ?? ""}
                            />
                          </InputMask>
                        </div>
                        <div className="col-span-3 input-vendedores mt-5 px-1">
                          <h4 className="text-base font-bold mb-2">
                            WhatsApp*
                          </h4>
                          <InputMask mask="(99)99999-9999" onChange={postForm}>
                            <input
                              type="text"
                              className="input-vendedores w-full h-10 border border-gray-600 pl-3"
                              name="whatsapp"
                              defaultValue={dadosHospital?.whatsapp ?? ""}
                              required={true}
                            />
                          </InputMask>
                        </div>
                        <div className="col-span-6 input-vendedores mt-5 px-1">
                          <h4 className="text-base font-bold mb-2">Email*</h4>
                          <input
                            type="email"
                            className="input-vendedores w-full h-10 border border-gray-600 pl-3"
                            name="email"
                            defaultValue={dadosHospital?.email ?? ""}
                            onChange={postForm}
                            required={true}
                          />
                        </div>
                      </div>

                      <div className="grid grid-cols-12 text-base gap-x-3">
                        <div className="col-span-6 input-vendedores mt-5 px-1">
                          <h4 className="text-base font-bold mb-2">
                            Pessoa para Contato*
                          </h4>
                          <input
                            type="text"
                            className="input-vendedores w-full h-10 border border-gray-600 pl-3 check-box"
                            name="contato"
                            defaultValue={dadosHospital?.contato ?? ""}
                            onChange={postForm}
                            required={true}
                          />
                        </div>
                        <div className="col-span-3 ml-3 input-vendedores mt-5 px-1">
                          <h4 className="text-base font-bold mb-2">Status</h4>
                          <div className="flex">
                            <label className="switch mt-1">
                              <input
                                name={"statusId"}
                                type="checkbox"
                                defaultChecked={
                                  dadosHospital?.status_id === 8 ? false : true
                                }
                                onChange={checkChange}
                              />
                              <span className="slider round"></span>
                            </label>
                            <div className="mt-1">
                              <span className="font-light ml-2 text-xl">
                                {check}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="flex justify-center mb-2 pt-10">
                        <button
                          type="submit"
                          className="relative btn-save-vendedor bg-blue text-lg text-white font-bold px-8 py-2 rounded-full shadow-md cursor-pointer hover:shadow-lg"
                        >
                          <div className="flex justify-center space-x-2">
                            <TailSpin
                              
                              color={"white"}
                              visible={btnLoader}
                              height={24}
                              width={20}
                            />
                            <span>
                              {btnLoader === true ? "Salvando" : "Salvar"}
                            </span>
                          </div>
                        </button>
                      </div>
                    </form>
                  </div>
                ) : (
                  <div className="p-3">
                    <form onSubmit={handleSubmitSecretaria}>
                      <div className="input-vendedores mt-5 px-1">
                        <h4 className="text-base font-bold mb-2">
                          Nome do Hospital*
                        </h4>
                        <input
                          type="text"
                          className="input-vendedores w-full h-10 border border-gray-600 pl-3 text-base"
                          name="nome"
                          defaultValue={dadosHospital?.nome ?? ""}
                          onChange={postForm}
                          required={true}
                          pattern=".{3,}"
                          title="Esse campo precisa de 3 ou mais caracteres!"
                          autoComplete="off"
                        />
                      </div>
                      <div className="input-vendedores mt-5 px-1">
                        <h4 className="text-base font-bold mb-2">WhatsApp*</h4>
                        <InputMask mask="(99)99999-9999" onChange={postForm}>
                          <input
                            type="text"
                            className="input-vendedores w-full h-10 border border-gray-600 pl-3 text-base"
                            name="whatsapp"
                            defaultValue={dadosHospital?.whatsapp ?? ""}
                            required={true}
                          />
                        </InputMask>
                      </div>
                      <div className="col-span-7 input-vendedores mt-5 px-1">
                        <h4 className="text-base font-bold mb-2">Cidade*</h4>
                        <input
                          type="text"
                          className="input-vendedores w-full h-10 border border-gray-600 pl-3 text-base capitalize"
                          name="complemento"
                          defaultValue={dadosHospital?.complemento ?? ""}
                          onChange={postForm}
                          required={true}
                        />
                      </div>
                      <div className=" input-vendedores mt-5 px-1">
                        <h4 className="text-base font-bold mb-2">Email*</h4>
                        <input
                          type="email"
                          className="input-vendedores w-full h-10 border border-gray-600 pl-3 text-base"
                          name="email"
                          defaultValue={dadosHospital?.email ?? ""}
                          onChange={postForm}
                          required={true}
                        />
                      </div>
                      <div className="flex justify-center mb-2 pt-10">
                        <button
                          type="submit"
                          className="relative btn-save-vendedor bg-blue text-lg text-white font-bold px-8 py-2 rounded-full shadow-md cursor-pointer hover:shadow-lg"
                        >
                          <div className="flex justify-center space-x-2">
                            <TailSpin
                              
                              color={"white"}
                              visible={btnLoader}
                              height={24}
                              width={20}
                            />
                            <span>
                              {btnLoader === true ? "Salvando" : "Salvar"}
                            </span>
                          </div>
                        </button>
                      </div>
                    </form>
                  </div>
                )}
              </div>
            </Loader>
          </div>
          <div
            className="rounded-full cursor-pointer close-btn"
            // eslint-disable-next-line no-sequences
            onClick={() => (toggle(), setIdHospital())}
          >
            <AiFillCloseCircle
              size="3rem"
              className="text-blue rounded-full bg-white"
            />
          </div>
        </Fragment>
        {/* )} */}
      </Popup>
    </Fragment>
  );
}
export default ModalHospitais;
