import "./style.css";
import React, { Fragment } from "react";
import 
Header, 
{ MobileHeader } from "src/components/Header";
import SideBar from "src/components/SideBar";
type Props = {
  children: Array<JSX.Element> | JSX.Element;
};

export default function HomeLayout({ children }: Props): JSX.Element {
  return (
    <Fragment>
      <div className="">
        <Header />
      </div>
      <div>
        <SideBar/> 
          <div className="contentContainer">
            {children}
          </div>
      </div>
    </Fragment>
  );
}

export const MobileHomeLayout = ({ children }: Props) => {

  return (
    <div className='mobile-home-layout-wrapper'>
      <div className='mobile-home-layout-container'>
        <MobileHeader/>
        <div className='mobile-home-main-container'>{children}</div>
      </div>
    </div>
  );
}