import "./style.css";
import React from "react";
import { AuthState } from "src/store/types";
import { connect, ConnectedProps } from "react-redux";
import { AiOutlineClockCircle } from "react-icons/ai";
const mapStateToProps = ({ auth }: { auth: AuthState }): AuthState => {
  return {
    doutor: auth.doutor,
    usuario: auth.usuario,
  };
};
const connector = connect(mapStateToProps, {});

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
  id?: any;
  nome?: string;
  status?: string;
  nomeCirurgia?: string;
  nomeMedico?: string;
  confirmacao_data?: string;
  nomeHospital?: string;
  vencimentoGuia?: string;
  dataCirurgia?: string;
  email?: string;
  telefone?: string;
  whatsapp?: string;
  onClick?: (id: number) => void;
  onClickTimeline?: (id: number) => void;
  onChangeData?: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void | undefined;
};

// eslint-disable-next-line no-empty-pattern
function CardLista({
  nome,
  status,
  nomeCirurgia,
  nomeMedico,
  confirmacao_data,
  email,
  telefone,
  whatsapp,
  id,
  nomeHospital,
  vencimentoGuia,
  dataCirurgia,
  usuario,
  onClick,
  onClickTimeline,
  onChangeData,
}: Props): JSX.Element {
  const nivelCheck = usuario?.nivel === 1 || usuario?.nivel === 3;
  const pointerCheck =
    status === "Desmarcada" || status === "Vencidas"
      ? "cursor-default tr-item-disabled"
      : "cursor-pointer tr-item";
  const fornCheck = status === "Concluída" && usuario?.nivel === 2;
  return (
    <tbody>
      <tr className={`text-center ${!fornCheck ? pointerCheck : "cursor-default tr-item-disabled"}`}>
        <td onClick={() => (onClick ? onClick(id) : undefined)}>
          <div title={status} className="flex justify-center">
            <span className={`w-5 h-5  ${status} rounded-full`}></span>
          </div>
        </td>
        <td
          onClick={() => (onClick ? onClick(id) : undefined)}
          title={nivelCheck ? nome : nomeMedico}
          className="border px-4 py-2 capitalize text-left"
        >
          {nivelCheck ? nome : nomeMedico}
        </td>
        <td
          onClick={() => (onClick ? onClick(id) : undefined)}
          title={nivelCheck ? telefone : nome}
          className="border px-4 py-2 capitalize"
        >
          {nivelCheck ? telefone : nome}
        </td>
        <td
          onClick={() => (onClick ? onClick(id) : undefined)}
          title={nivelCheck ? whatsapp : nomeCirurgia}
          className="border px-4 py-2 capitalize"
        >
          {nivelCheck ? whatsapp : nomeCirurgia}
        </td>
        <td
          onClick={() => (onClick ? onClick(id) : undefined)}
          title={nivelCheck ? email : nomeHospital}
          className="border px-4 py-2"
        >
          {nivelCheck ? email : nomeHospital}
        </td>
        <td
          onClick={() => (onClick ? onClick(id) : undefined)}
          title={nivelCheck ? nomeCirurgia : confirmacao_data}
          className="border px-4 py-2 capitalize"
        >
          {nivelCheck ? nomeCirurgia : confirmacao_data}
        </td>
        <td
          onClick={() => (onClick ? onClick(id) : undefined)}
          title={nivelCheck ? confirmacao_data : vencimentoGuia}
          className="border px-4 py-2"
        >
          {nivelCheck ? confirmacao_data : vencimentoGuia}
        </td>
        <td
          onClick={() => (onClick ? onClick(id) : undefined)}
          title={nivelCheck ? nomeMedico : dataCirurgia}
          className="border px-4 py-2 capitalize"
        >
          {nivelCheck ? nomeMedico : dataCirurgia}
        </td>
        {nivelCheck ? (
          <td
            onClick={() => (onClickTimeline ? onClickTimeline(id) : undefined)}
            title={`Linha do Tempo de ${nome}`}
            className="flex justify-center cursor-pointer"
          >
            <span className="td-item mt-1">
              <AiOutlineClockCircle size="1.3rem" />
            </span>
          </td>
        ) : (
          ""
        )}
      </tr>
    </tbody>
  );
}

export default connector(CardLista);
