import React, { Fragment, useState } from "react";
import {
  AiFillCloseCircle,
  AiOutlineEye,
  AiOutlineEyeInvisible,
} from "react-icons/ai";
import Popup from "reactjs-popup";

type Props = {
  open?: any;
  close?: any;
  userName?: string;
  checkSenha?: any;
  postSenha?: (event: any) => void;
  setSenha?: any;
  toggle?: any;
};

function ModalSenhaAtual({
  open,
  close,
  userName,
  checkSenha,
  postSenha,
  toggle,
}: Props): JSX.Element {
  const modalWidth = { width: "30%" };
  const [confirmSenhaEye, setconfirmSenhaEye] = useState(false);
  const toggleConfirmSenha = () => {
    setconfirmSenhaEye(confirmSenhaEye ? false : true);
  };
  const [senha, setSenha] = useState({
    senha: "",
  });
  const onChange = (event: any) => {
    const { value } = event.target;
    setSenha({ senha: value });
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();
    checkSenha(senha);
  };

  return (
    <Popup
      open={open}
      closeOnDocumentClick={false}
      // onClose={open}
      contentStyle={modalWidth}
      modal
      nested={true}
    >
      <Fragment>
        <div className="bg-white modal py-5 px-10">
          <div className="header pt-5 pb-3 text-xl font-bold truncate">
            Senha Atual de {userName}
          </div>
          <div className="content">
            <form onSubmit={onSubmit}>
              <div className="input-vendedores mb-5">
                <div className="flex justify-between relative">
                  <input
                    type={confirmSenhaEye ? "text" : "password"}
                    className="input-vendedores pr-2 w-full h-10 border border-gray-600 pl-3"
                    name="senha"
                    onChange={onChange}
                    required={true}
                  />

                  {confirmSenhaEye === true ? (
                    <AiOutlineEyeInvisible
                      onClick={toggleConfirmSenha}
                      size="1.5rem"
                      className="mt-2 z-30 text-blue absolute right-0 mr-2 bg-white"
                    />
                  ) : (
                    <AiOutlineEye
                      onClick={toggleConfirmSenha}
                      size="1.5rem"
                      className="mt-2 z-30 text-gray-600 absolute right-0 mr-2 bg-white"
                    />
                  )}
                </div>
              </div>
              <div className="flex justify-center mb-2 pt-5">
                <button
                  type="button"
                  className="btn-save-vendedor bg-blue text-lg text-white font-bold px-8 py-2 rounded-full shadow-md cursor-pointer hover:shadow-lg"
                  onClick={() => checkSenha(senha)}
                >
                  Alterar Perfil
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="rounded-full cursor-pointer close-btn" onClick={toggle}>
          <AiFillCloseCircle
            size="2.5rem"
            className="text-blue rounded-full bg-white"
          />
        </div>
      </Fragment>
    </Popup>
  );
}
export default ModalSenhaAtual;
