import "./style.css";
import React from "react";

type Props = {
  titulo: string;
};

// eslint-disable-next-line no-empty-pattern
export default function Titulos({ titulo }: Props): JSX.Element {
  return (
    <div className="mb-5 font-bold ">
      <h1 className="pt-20 flex title-prox-cirugias">{titulo}</h1>
    </div>
  );
}