export const UPDATE_TOKEN: string = "AUTH_UPDATE_TOKEN";
export const INITIAL_STATE: string = "AUTH_INITIAL_STATE";
export const VALUE_ID: string = "AUTH_VALUE_ID";
export const UPDATE_DOUTOR: string = "UPDATE_DOUTOR";
export const UPDATE_PERFIL: string = "UPDATE_PERFIL";
export const NIVEL_ID_USER: string = "NIVEL_ID_USER";
export const UPDATE_VENDEDORES: string = "UPDATE_VENDEDORES";
export const UPDATE_CHECKLIST: string = "UPDATE_CHECKLIST";
export const UPDATE_HOSPITAIS: string = "UPDATE_HOSPITAIS";
export const UPDATE_FORNECEDORES: string = "UPDATE_FORNECEDORES";
export const LIST_DOUTORES: string = "LIST_DOUTORES";
export const LIST_SECRETARIAS: string = "LIST_SECRETARIAS";
export const SET_ASSINATURA_DIGITAL = 'SET_ASSINATURA_DIGITAL';


// TOKEN REDUX
export type AuthState = {
  token?: string | null;
  usuario?: Usuario | null | any;
  doutor?: Doutor | null;
  nivel?: number | null;
  expires_in?: number;
  assinaturaDigital?: boolean;
};

export type AuthAction = {
  type: string;
  payload: AuthState;
};

export type Usuario = {
  id: number;
  nome: string;
  foto: string;
  doutores: Doutor[];
  nivel: number;
  adm: boolean;
};

export type Doutor = {
  id: number;
  nome: string;
};

// VENDEDORES REDUX
export type VendedoresAction = {
  type: string;
  payload: VendedoresState;
};

export type VendedoresState = {
  vendedores: Vendedor[];
};

export type Vendedor = {
  id: number;
  nome: string;
  whatsapp: string;
  fornecedor_id: number;
  usuario_id: number;
  usuario: Usuarios;
};

type Usuarios = {
  id: number;
  email: string;
};

// DOUTOR REDUX
export type DoutorAction = {
  type: string;
  payload: DoutorState;
};

export type DoutorState = {
  doutores: DoutoresAdmin[];
};

export type DoutoresAdmin = {
  id: number;
  nome: string;
  telefone: string;
  whatsapp: string;
  cpf: string;
  crm: string;
  email_secretaria: string;
  secretaria: boolean;
  endereco: string;
  numero: string;
  complemento: string;
  bairro: string;
  cep: string;
  cidade: string;
  uf: string;
  secretaria_id: number;
  usuario_id: number;
  especialidade: number;
  email: string;
  // status_id: number;
  usuario: UsersDoutor;
  secretarias: SecretariasDoutor;
};

type SecretariasDoutor = {
  id: number;
  nome: string;
};

type UsersDoutor = {
  id: number;
  email: string;
  status_id: number;
};

// SECRETARIA REDUX
export type SecretariaAction = {
  type: string;
  payload: SecretariaState;
};

export type SecretariaState = {
  secretarias: Secretarias[];
};

export type Secretarias = {
  id: number;
  nome: string;
  telefone: string;
  whatsapp: string;
  usuario_id: number;
  email: string;
  // status_id: number;
  usuario: userSecretaria;
};

type userSecretaria = {
  id: number;
  email: string;
  status_id: number;
};

// CHECKLIST REDUX
export type ChecklistAction = {
  type: string;
  payload: ChecklistState;
};

export type ChecklistState = {
  cirurgias: Cirurgias[];
};

export type Cirurgias = {
  id: number;
  nome: string;
  status_id: number | undefined;
  telefone: string;
  whatsapp: string;
  email: string;
  datas: Datas[];
  status: Status;
  cirurgia: string;
  hospital: Hospital;
  nascimento: string;
  sexo: string;
  hospital_id: number | undefined;
  vencimento: string;
  confirmacao_data: string;
  confirmacao_hora: string | undefined;
  doutor: Doutores;
  pre_operatorio: boolean;
  alergia: boolean;
  materiais: Materiais[];
  material: Material;
  guia_internamento: Anexos;
  guia_materiais: Anexos;
  resultados_exames: Anexos | null;
  doutor_requisitado_id: number;
};

type Datas = {
  cirurgia_id: number;
  data: string;
};

type Anexos = {
  id: number;
  nome: string;
  url: string;
  tipo_mime: string;
};

type Status = {
  id: number;
  descricao: string;
};

type Doutores = {
  id: bigint;
  nome: string;
  crm: string;
};

type Hospital = {
  id: number;
  nome: string;
};

type Materiais = {
  id: number;
  checklist_id: number;
  fornecedor_id: number;
  descricao: string;
  reportar_problema: string;
  forncedor: Fornecedor;
  confirmado: boolean;
};

type Fornecedor = {
  id: number;
  razao_social: string;
};

type Material = {
  id: number;
  checklist_id: number;
  data_confirmacao: string;
  fornecedor_id: number;
};

// HOSPITAIS REDUX
export type HospitaisAction = {
  type: string;
  payload: HospitaisState;
};

export type HospitaisState = {
  hospitais: Hospitais[];
};

export type Hospitais = {
  id: number;
  nome: string;
  contato: string;
  whatsapp: string;
  email: string;
  status_id: number;
  status: StatusHospital;
};

type StatusHospital = {
  id: number;
  descricao: string;
};

// FORNECEDORES REDUX
export type FornecedoresAction = {
  type: string;
  payload: FornecedoresState;
};

export type FornecedoresState = {
  fornecedores: Fornecedores[];
};

export type Fornecedores = {
  id: number;
  razao_social: string;
  fantasia: string;
  cnpj: string;
  usuario_id: number;
  status: StatusFornecedor;
  whatsapp: string;
  usuario: UsuarioFornecedor;
  email: string;
};

type UsuarioFornecedor = {
  id: number;
  email: string;
  status_id: number;
};

type StatusFornecedor = {
  id: number;
  descricao: string;
};
