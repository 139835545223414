import "./style.css";
import React, { Fragment } from "react";
import { axiosApi } from "src/services/axiosInstances";
import { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";

type Props = {
  idCirurgia?: number;
  active?: boolean;
  divBtnClassName?: string;
  titleModalClassname?: string;
  updateTable?: (id: number, dados: object) => void;
  updateData?: any;
  toggle?: any;
};

export type ListaMateriais = {
  id: number;
  descricao: string;
  fornecedor_id: number;
  checklist_id: number;
  confirmado: boolean;
  reportar_problema: string;
  data_confirmacao: string;
};

export default function FornecedorPost({
  idCirurgia,
  active,
  divBtnClassName = "bg-blue rounded-full text-white cursor-pointer hover:shadow-md",
  titleModalClassname = "text-blue text-left text-xl xl:text-xl lg:text-xl font-bold md:text-sm mb-2",
  updateTable,
  updateData,
  toggle,
}: Props): JSX.Element {
  const [dadosModal, setDadosModal] = useState<ListaMateriais>();
  const [formActive, setFormActive] = useState<boolean>(false);
  const [reportar, setReportar] = useState<string>("");
  const [getReportar, setGetReportar] = useState<string>();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadDados = async () => {
    try {
      const { data } = await axiosApi.get<ListaMateriais>(
        `/list-material/${idCirurgia}`
      );
      setDadosModal(data);
      setGetReportar(data.reportar_problema);
    } catch (error: any) {
      console.log(error);
    }
  };

  const onChangeTextArea = (
    textArea: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setReportar(textArea.target.value);
  };

  // const showReport = () => {
  //   setGetReportar(reportar);
  // };

  const postReportar = async () => {
    const response = await axiosApi.post(`report/${dadosModal?.id}`, {
      reportar_problema: reportar,
    });

    if (reportar === null || reportar === "") {
      toast.success('Material Confirmado', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
    } else {
      toast.warn('Material Reportado', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
    }

    if (updateTable) {
      const id: number = dadosModal?.id ?? 0;
      const objeto = {
        material: response.data.material,
      };
      updateTable(id, objeto);
    }

    // setTimeout(window.location.reload.bind(window.location), 1500);
    updateData();
    toggle();
  };

  useEffect(() => {
    if (idCirurgia) {
      loadDados();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idCirurgia]);

  return (
    <Fragment>
      <div className="">
        <h1 className={titleModalClassname}>Confirmação de Materiais</h1>
        <div className="flex justify-start">
          <div className="flex">
            <p className="text-sm text-left uppercase break-words">
              {dadosModal?.descricao}
            </p>
          </div>
        </div>
        <form>
          {getReportar === null || getReportar === "" ? (
            <div className="mt-3">
              <p className="text-sm text-left text-grey">
                *Nenhum problema foi reportado
              </p>
            </div>
          ) : (
            <div className="mt-3">
              <p className=" text-sm font-bold">Problema Reportado</p>
              <p className="text-sm text-left break-all">{getReportar}</p>
            </div>
          )}

          {formActive && (
            <div className="reportar-field mt-3">
              <p className="text-sm font-bold mb-1">Reportar Problema</p>
              <textarea
                className="report-text-area w-full rounded-md border-2 border-gray-400 p-3"
                value={reportar}
                onChange={onChangeTextArea}
                style={{ resize: "none", height: "150px" }}
              ></textarea>
            </div>
          )}
        </form>

        {!formActive ? (
          <div className="py-5 flex justify-center">
            <div className="btn-postFornecedor mr-3">
              <div className={divBtnClassName}>
                <button
                  className="px-4 py-2 font-bold"
                  type="submit"
                  onClick={postReportar}
                >
                  Confirmar
                </button>
              </div>
            </div>

            <div className="btn-postFornecedor">
              <div className={divBtnClassName}>
                <button
                  type="submit"
                  onClick={() => setFormActive(true)}
                  className="px-5 py-2 font-bold"
                >
                  Reportar
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="py-5 flex justify-center">
            <div className="btn-postFornecedor">
              <div className={divBtnClassName}>
                <button
                  type="submit"
                  onClick={() => {
                    toggle();
                    setFormActive(false);
                    postReportar();
                  }}
                  className="px-5 py-2 font-bold"
                >
                  Enviar
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
}
