import "./style.css";
import React, { useState } from "react";
import AcompanhamentoWindow from "src/components/Acompanhamento";
// import logo from "src/assets/logo-intro.png";

export default function Acompanhamento(): JSX.Element {

  const [indexAcompanhamento, setIndexAcompanhamento] = useState(0)

  return (
    <div className="select-page-container py-2">
      <div className="select-page-adjust-container px-5">
        {window.innerWidth <= 832 ? (
          <>
          
          </>
        ) : (
          <div>
            {indexAcompanhamento === 0 && 
            <>
              <h4 className="acompanhamento-page-title pt-1 text-4xl font-black">Olá Paciente!</h4>
              <p className="py-5">Insira Seu Código de Acompanhamento:</p>
            </>
            }
            <AcompanhamentoWindow
              setIndexAcompanhamento={setIndexAcompanhamento}
              indexAcompanhamento={indexAcompanhamento}
            />
          </div>
        )}
      </div>
    </div>
  );
}