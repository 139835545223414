import React, { Fragment } from "react";
import { VscError } from "react-icons/vsc";
// import { AiFillCloseCircle } from "react-icons/ai";
import Popup from "reactjs-popup";
import "../ModalDeleteVendedor/style.css";

type Props = {
  open?: any;
  close?: any;
  id?: number;
  doutores?: any;
  setClose?: any;
  toggle?: any;
  toggleChecklist?: any;
  updateData?: any;
  nome?: string;
  children?: Array<JSX.Element> | JSX.Element;
};

function ModalListDoctor({
  open,
  close,
  id,
  doutores,
  setClose,
  toggle,
  toggleChecklist,
  updateData,
  nome,
  children,
}: Props): JSX.Element {
  const modalWidth = { width: `35%` };

  return (
    <Popup
      open={open}
      closeOnDocumentClick={true}
      // onClose={toggle}
      contentStyle={modalWidth}
      modal
      nested={true}
    >
      <Fragment>
        <div
          className={`${doutores ? "bg-white" : "bg-red-400"} modal py-5 px-10`}
        >
          {doutores ? (
            <Fragment>
              <div className="header pt-5 pb-3 truncate text-3xl font-bold text-center">
                Cirurgiões de {nome}
              </div>
              <div className="content px-12 py-2">
                <div className="ml-1 text-lg">{children}</div>
              </div>
            </Fragment>
          ) : (
            <div className="header py-5 truncate text-base font-bold text-center">
              <div className="flex justify-center space-x-0">
                <VscError size={"1.5rem"} className="text-white mr-2" />
                <span
                  className={`${doutores ? "text-gray-800" : "text-white"}`}
                >
                  {nome} não possui Cirurgiões!
                </span>
              </div>
            </div>
          )}
        </div>
      </Fragment>
    </Popup>
  );
}

export default ModalListDoctor;
