import "./style.css";
import React, { Fragment } from "react";
import { AuthState } from "src/store/types";
import { connect, ConnectedProps } from "react-redux";

const mapStateToProps = ({ auth }: { auth: AuthState }): AuthState => {
  return {
    usuario: auth.usuario,
  };
};

const connector = connect(mapStateToProps, {});

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
  id?: any;
  nome: string;
  status: string;
  nomeCirurgia: string;
  nomeHospital: string;
  confirmacao_hora: any;
  confirmacao_data: any;
  doutorNome?: string;
  data: any;
  onClick?: (id: number) => void;
};

// eslint-disable-next-line no-empty-pattern
function CardProxCirurgia({
  id,
  nome,
  status,
  nomeCirurgia,
  nomeHospital,
  confirmacao_hora,
  confirmacao_data,
  doutorNome,
  usuario,
  data,
  onClick,
}: Props): JSX.Element {
  const nivelCheck = usuario?.nivel === 1 || usuario?.nivel === 3;
  const date = new Date();
  const year = date.getFullYear();
  const m = date.getMonth() + 1;
  const d = date.getDate();
  const day = d < 10 ? "0" + d : "";
  const month = m < 10 ? "0" + m : "";
  const today = year + "-" + month + "-" + day;
  const curerntDay = confirmacao_data === today ? "Hoje" : data;
  return (
    <div style={{height: 132}} className="card p bg-black mr-2 lg:px-5 xl:px-0 py-2 cursor-pointer"
    onClick={() => (onClick ? onClick(id) : undefined)}
    >
      <div className="flex items-center justify-between ml-2">
        <h1
          title={confirmacao_hora}
          className="text-xl card-cirurgias-title capitalize"
        >
          {curerntDay} - {confirmacao_hora}
        </h1>
        <small className="text-sm text-gray-700">
          <div title={status} className={`state-cirurgia ${status}`}></div>
        </small>
      </div>
      {nivelCheck ? (
        <h6
          title={nome}
          className="text-lg card-cirurgias-subtitle ml-2 capitalize"
        >
          {nome}
        </h6>
      ) : (
        <Fragment>
          <h6
            title={doutorNome}
            className="text-lg card-cirurgias-subtitle ml-2 capitalize"
          >
            {doutorNome}
          </h6>
          <h6
            title={nome}
            className="text-sm font-semibold card-cirurgias-small ml-2 capitalize"
          >
            {nome}
          </h6>
        </Fragment>
      )}

      <p
        title={nomeCirurgia}
        className="text-sm card-cirurgias-small mt-2 ml-2 capitalize"
      >
        {nomeCirurgia}
      </p>
      <p
        title={nomeHospital}
        className="text-sm card-cirurgias-small ml-2 capitalize"
      >
        {nomeHospital}
      </p>
    </div>
  );
}
export default connector(CardProxCirurgia);
