import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactInputMask from "react-input-mask";
import './style.css';
import { useFormContext } from "react-hook-form";
import { axiosApi } from "src/services/axiosInstances";
import { Suggestion, identificacaoProps } from "./interfaces";


const Identificacao: React.FC<identificacaoProps> = ({ convenioInputValue, setConvenioInputValue, nomeRef, hospitalInputValue, setHospitalInputValue }) => {

  const { register, formState: { errors }, setValue, trigger } = useFormContext();
  const [convenioSuggestions, setConvenioSuggestions] = useState<Suggestion[]>([]);
  const [hospitaSuggestions, setHospitalSuggestions] = useState<Suggestion[]>([]);
  const [showConvenioDropdown, setShowConvenioDropdown] = useState(false);
  const [showHospitalDropdown, setShowHospitalDropdown] = useState(false);
  const [itemSelected, setItemSelected] = useState(false);
  const dropdownConvenioRef = useRef<HTMLUListElement>(null);
  const dropdownHospitalRef = useRef<HTMLUListElement>(null);


  const fetchConvenio = async (query: string) => {
    try {
      const { data } = await axiosApi.get(`/solicitacao-cirurgia/convenio/${query}`);
      setConvenioSuggestions(data);
    } catch (error) {
      console.error('Error fetching convenio:', error);
    }
  };

  const fetchHospitais = async (query: string) => {
    try {
      const { data } = await axiosApi.get(`/solicitacao-cirurgia/hospitais/${query}`);
      setHospitalSuggestions(data);
    } catch (error) {
      console.error('Error fetching hospitais', error);
    }
  };

  const debounce = (func: (...args: any[]) => void, delay: number) => {
    let timeoutId: NodeJS.Timeout;
    return (...args: any[]) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func(...args), delay);
    };
  };

  const debouncedFetchData = useCallback(
    debounce((query: string) => fetchConvenio(query), 300),
    []
  );

  const debouncedFetchData2 = useCallback(
    debounce((query: string) => fetchHospitais(query), 300),
    []
  );

  const handleConvenioInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newQuery = event.target.value;
    setConvenioInputValue(newQuery);
    setItemSelected(false);
    // setValue("solicitacao_cirurgia_convenio_nome", newQuery);
    // trigger("solicitacao_cirurgia_convenio_nome");
    if (newQuery.length >= 3) {
      debouncedFetchData(newQuery);
    } if (newQuery.length <= 1) {
      setConvenioSuggestions([]);
    }
  };


  const handleSuggestionClick = (suggestion: Suggestion) => {
    setConvenioInputValue(suggestion.nome);
    setValue("solicitacao_cirurgia_convenio_id", suggestion.id);
    trigger("solicitacao_cirurgia_convenio_id");
    setConvenioSuggestions([]);
  };

  const handleHospitalInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newQuery = event.target.value;
    setHospitalInputValue(newQuery);
    setValue("hospital_nome", newQuery);
    trigger("hospital_nome");
    if (newQuery.length >= 3) {
      debouncedFetchData2(newQuery);
    } if (newQuery.length <= 1) {
      setHospitalSuggestions([]);
    }
  };


  const handleSuggestionHospitalClick = (suggestion: Suggestion) => {
    setValue("hospital_nome", suggestion.nome);
    setHospitalInputValue(suggestion.nome);
    trigger("hospital_nome");
    setHospitalSuggestions([]);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownConvenioRef.current && !dropdownConvenioRef.current.contains(event.target as Node)) {
        if (!itemSelected) {
          setConvenioInputValue('');
        }
        setShowConvenioDropdown(false);
        setConvenioSuggestions([]);
      }

      if (dropdownHospitalRef.current && !dropdownHospitalRef.current.contains(event.target as Node)) {
        setShowHospitalDropdown(false);
        setHospitalSuggestions([]);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownConvenioRef, dropdownHospitalRef]);

  return (
    <>
      <div className='flex mt-5'>
        <div className="flex flex-col">
          <label className="text-black" htmlFor="nome">Nome Completo</label>
          <input
            id="nome"
            style={{ width: 400 }}
            className="text-lg pl-2 font-lato placeholder-gray-600 h-10 border border-black rounded-xl"
            placeholder="Ex: José Alberto" type="text"
            {...register("nome")}
            onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
              e.target.value = e.target.value.replace(/[^a-zA-ZÀ-ÖØ-öø-ÿ\s]/g, '');
            }}
          />
          {errors.nome && <span className="text-red-600">{errors.nome.message as string}</span>}
        </div>
        <ReactInputMask
          mask="(99)99999-9999">
          <div className="flex flex-col pl-5">
            <label htmlFor="whatsapp" className="text-black">Telefone</label>
            <input id="whatsapp"
              className="text-lg pl-2 font-lato placeholder-gray-600 h-10 border border-black rounded-xl"
              type="tel"
              placeholder="Ex: (99)99999-9999"
              {...register("whatsapp")} />
            {errors.whatsapp && <span className="text-red-600">{errors.whatsapp.message as string}</span>}
          </div>
        </ReactInputMask>
      </div>


      <div className="flex flex-col mt-10">
        <label htmlFor="email" className="text-black">Email*</label>
        <input id="email" style={{ width: 400 }} className="text-lg pl-2   font-lato placeholder-gray-600 h-10 border border-black rounded-xl" type="email" placeholder="Ex: email@exemplo.com" {...register("email")} />
        {errors.email && <span className="text-red-600">{errors.email.message as string}</span>}
      </div>

      <div className="flex flex-col mt-10 TypeAheadDropDown">
        <label htmlFor="hospital_nome" className="text-black">Hospital</label>
        <input
          style={{ width: 400 }}
          className="TypeAheadDropDown font-lato placeholder-gray-600 h-10 border border-black rounded-xl"
          onChange={handleHospitalInputChange}
          type="text"
          id="hospital_nome"
          onFocus={() => setShowHospitalDropdown(true)}
          value={hospitalInputValue}
        />
        {
          showHospitalDropdown && hospitaSuggestions.length !== 0 && <ul ref={dropdownHospitalRef} className="TypeAheadDropDown" style={{ width: 400 }}>
            {hospitaSuggestions.map((suggestion) => (
              <li
                className="TypeAheadDropDown"
                key={`${suggestion.id} ${suggestion.nome}`}
                onClick={() => handleSuggestionHospitalClick(suggestion)}>
                {suggestion.nome}
              </li>
            ))}
          </ul>
        }
        {errors.hospital_nome && <span className="text-red-600">{errors.hospital_nome.message as string}</span>}
      </div>


      <div className="TypeAheadDropDown flex flex-col mt-10">
        <label htmlFor="solicitacao_cirurgia_convenio_nome" className="text-black">Convênio*</label>
        <input
          style={{ width: 400 }}
          onChange={handleConvenioInputChange}
          type="text"
          id="solicitacao_cirurgia_convenio_nome"
          value={convenioInputValue}
          onFocus={() => setShowConvenioDropdown(true)}
          className="TypeAheadDropDown text-lg font-lato placeholder-gray-600 h-10 border border-black rounded-xl"
        />
        {errors.solicitacao_cirurgia_convenio_id && <span className="text-red-600">{errors.solicitacao_cirurgia_convenio_id.message as string}</span>}
        {
          showConvenioDropdown && convenioSuggestions.length !== 0 && <ul ref={dropdownConvenioRef} className="TypeAheadDropDown" style={{ width: 400 }}>
            {convenioSuggestions.map((suggestion) => (
              <li
                className="TypeAheadDropDown"
                key={suggestion.id}
                onClick={() => handleSuggestionClick(suggestion)}>
                {suggestion.nome}
              </li>
            ))
            }
          </ul>
        }
      </div>
    </>
  )
}


export default Identificacao;