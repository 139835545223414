import React from "react";
import { DayPicker } from "react-day-picker";
import { connect, ConnectedProps } from "react-redux";
import { axiosApi } from "src/services/axiosInstances";
import { setUpdateChecklist } from "src/store/actions/checklist";
import { AuthState, ChecklistState } from "src/store/types";
import { Cirurgia, HomeResponse } from "../Calendario";
import "./style.css";
import calendar from "../../assets/MobileFloatingNav/Calendar.svg";
import blueSearch from "../../assets/MobileAuthorizations/Blue-Search.svg";
import MobileCalendarCard from "src/components/MobileCalendarCard";

// const INITIAL_STATE = {
//   id: -1,
//   nome: 'aoisdy',
//   status_id: -1,
//   status: {
//     id: BigInt(-1),
//     descricao: '',
//   },
//   hospital: {
//     id: BigInt(-1),
//     nome: 'asdasdasd',
//   },
//   cirurgia: 'asdasdasdasd',
//   confirmacao_data: '',
//   confirmacao_hora: '12:00',
//   doutor: {
//     id: BigInt(-1),
//     nome: 'zxczcxzcxzczx',
//   }
// }

const mapStateToProps = ({
  auth,
  cirurgias,
}: {
  auth: AuthState;
  cirurgias: ChecklistState;
}): AuthState & ChecklistState => {
  return {
    doutor: auth.doutor,
    usuario: auth.usuario,
    cirurgias: cirurgias.cirurgias,
  };
};

const mapDispatchToProps = {
  setUpdateChecklist,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

function MobileCalendario({ doutor }: Props) : JSX.Element {
  const [agendadas, setAgendadas] = React.useState<Cirurgia[]>([]);
  // const [autorizadas, setAutorizadas] = React.useState<Cirurgia[]>([]);
  // const [pendentes, setPendentes] = React.useState<Cirurgia[]>([]);
  const [dataAgendada, setDataAgendada] = React.useState<string>('');
  const [searchAgendadas, setSearchAgendadas] = React.useState<Cirurgia[]>([]);
  const [filter, setFilter] = React.useState("");
  const [selectDatas, setSelectDatas] = React.useState<String[]>([]);
  const [calendarModal, setCalendarModal] = React.useState(-1);

  React.useEffect(() => {
    loadDados();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadDados = async () => {
    const { data } = await axiosApi.get<HomeResponse>(
      `/cirurgias?id=${doutor?.id}`
    );
    setSelectDatas(data.datas);
  };

  React.useEffect(() => {
    if (dataAgendada !== '') {

    
    const loadDados = async () => {
      const { data } = await axiosApi.get<HomeResponse>(
        `/cirurgias?id=${doutor?.id}&data=${
          dataAgendada === undefined ? hoje : dataAgendada
        }`
      );
      setAgendadas(data.agendadas);
    };
    loadDados();
  }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doutor, dataAgendada]);

  React.useEffect(() => {
    setSearchAgendadas(
      agendadas.filter((agendada) => {
        return agendada.nome.toLowerCase().includes(filter.toLowerCase());
      })
    );
  }, [filter, agendadas]);

  // React.useEffect(() => {
  //   const loadDados = async () => {
  //     const { data } = await axiosApi.get<HomeResponse>(
  //       `/cirurgias?id=${doutor?.id}&data=${
  //         dataAgendada === undefined ? hoje : dataAgendada
  //       }`
  //     );
  //     setAgendadas(data.agendadas);
  //   };
  //   loadDados();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [doutor]);

  const hoje = new Date().toISOString().substring(0, 10);

  const handleDayClick = (day: Date) => {
    setDataAgendada(day.toISOString().substring(0, 10));
  };

  React.useEffect(() => {
    if (calendarModal === 0) {
      setTimeout(() => {
        setCalendarModal(-1);
      }, 400);
    }
  }, [calendarModal]);

  return (
    <div className="mobile-calendar-wrapper">
      <div
        onClick={() => setCalendarModal(1)}
        className="mobile-calendar-top-container"
      >
        <img
          className="mobile-calendar-top-calendar-icon"
          src={calendar}
          alt=""
        />
        <div className="moible-calendar-top-content">
          {dataAgendada === '' ?
          new Date().toLocaleDateString("pt-br", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          }):
          dataAgendada.split('-').reverse().toString().replace(',','/').replace(',','/')}
        </div>
        <div className="mobile-calendar-top-arrow-container">
          <div className="mobile-calendar-top-arrow-bar-left" />
          <div className="mobile-calendar-top-arrow-bar-right" />
        </div>
      </div>
      <div className="mobile-calendar-title-container">
        <h3 className="mobile-calendar-title">Cirurgias do Dia</h3>
        <div className="mobile-calendar-search-container">
          <img
            className="mobile-calendar-search-icon"
            src={blueSearch}
            alt=""
          />
          <input
            value={filter}
            onChange={(e) => {
              e.persist();
              setFilter(e.target.value);
            }}
            className="mobile-calendar-search-input"
            placeholder="Busca por nome do paciente."
            type="text"
          />
        </div>
      </div>
      <div className="mobile-calendar-card-list">
        {agendadas.length === 0 || searchAgendadas.length === 0 
          ? 
            <span>Sem Cirurgias Agendadas</span>
          : searchAgendadas?.map?.((autorizacoes, key) => {
              const nomeCirurgia: string =
                autorizacoes.cirurgia === null ? "-" : autorizacoes.cirurgia;
              return (
                <MobileCalendarCard
                  onClick={() => {}}
                  key={key}
                  title={autorizacoes.nome}
                  time={autorizacoes.confirmacao_hora}
                  doctor={autorizacoes.doutor?.nome}
                  surgery={nomeCirurgia}
                  hospital={autorizacoes.hospital.nome}
                />
              );
            })}
      </div>

      <div
        className={`mobile-calendar-modal-wrapper${
          calendarModal === -1
            ? " none"
            : calendarModal === 1
            ? " show"
            : " hide"
        }`}
      >
        <div
          onClick={() => {
            setCalendarModal(0);
          }}
          className={`mobile-calendar-modal-blur-mask${
            calendarModal === -1 ? "" : calendarModal === 1 ? " show" : " hide"
          }`}
        />
        <div
          className={`mobile-calendar-modal-container${
            calendarModal === -1 ? "" : calendarModal === 1 ? " show" : " hide"
          }`}
        >
          <DayPicker
            className="div-card-calendario"
            selected={selectDatas?.map((data) => {
              const dataSplit = data.split("-");
              return new Date(
                `${dataSplit[0]}-${dataSplit[1]}-${Number(dataSplit[2]) + 1}`
              );
            })}
            onDayClick={handleDayClick}
          />
        </div>
      </div>
    </div>
  );
}

export default connector(MobileCalendario);
