import React from "react";
// import { NavLink } from "react-router-dom";
import ModalBuscarMedico from "src/components/ModalBuscarMedico";


interface navBar {
  indexNavBar?: number;
  setIndexNavBar: (index: number) => void;
  fetchMedicosData: () => void;
  medicosData: Array<object>
}


const NavBarEquipeMedica:React.FC<navBar> = ({indexNavBar, setIndexNavBar, fetchMedicosData, medicosData}) => {

// const [buttonActive, setButtonActive] = useState(false)

// const navButtonStyle = "btn-new-vendedor  px-3 rounded-md link-nav text-xl lg:text-md md:text-md xl:text-xl"
// const minhaEquipeButtonStyle = `${buttonActive ? 'active' : ''} btn-new-vendedor  px-3 rounded-md link-nav text-xl lg:text-md md:text-md xl:text-xl`

// useEffect(()=>{
// const url = window.location.href
// if(url.match(/equipe-medica/)) {
//   setButtonActive(true)
// }
// },[])

  return(
  <>
    <div className="absolute divNavButtons border-b-2">
      <button  onClick={()=>setIndexNavBar(0)} className={`${indexNavBar === 0 ? 'active' : null} btn-new-vendedor  px-3 rounded-md link-nav text-xl lg:text-md md:text-md xl:text-xl`}>Minha Equipe</button>
      <button  onClick={()=>setIndexNavBar(1)} className={`${indexNavBar === 1 ? 'active' : null} btn-new-vendedor  px-3 rounded-md link-nav text-xl lg:text-md md:text-md xl:text-xl`}>Participações</button>
      <button  onClick={()=>setIndexNavBar(2)} className={`${indexNavBar === 2 ? 'active' : null} btn-new-vendedor  px-3 rounded-md link-nav text-xl lg:text-md md:text-md xl:text-xl`}>{medicosData['solicitacoes'] === undefined ? 'Solicitações (0)' :`Solicitações (${medicosData['solicitacoes']?.length})`}</button>
    </div>
    <div style={{top: 124, left: 765}}className="absolute pl-6 pt-1" >
      <ModalBuscarMedico fetchMedicosData={fetchMedicosData}/>
    </div>
  </>
      );
}

export default NavBarEquipeMedica;

