import React, { useEffect } from "react";
// import "../node_modules/react-day-picker/dist/style.css";
import "./App.css";
import "react-day-picker/dist/style.css";
import "./tailwind.output.css";
import Rotas from "./Routes";
// import { BrowserRouter as Router } from "react-router-dom";
import VerificarErroInterno from "./components/Error";
// import { ativarRefreshToken } from "./services/axiosInstances";

import { setDadosAtualizados } from "./store/actions/auth";
import { AuthState } from "./store/types";
import { connect, ConnectedProps } from "react-redux";

const mapStateToProps = ({ auth }: { auth: AuthState }) => {
  return {
    token: auth.token,
    expires_in: auth.expires_in,
  };
};

const mapDispatchToProps = {
  setDadosAtualizados,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {};

function App({ token, setDadosAtualizados }: Props): JSX.Element {
  useEffect(() => {
    if (token) {
      // ativarRefreshToken(setDadosAtualizados);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <VerificarErroInterno>
      {/* <Router basename={process.env.REACT_APP_BROWSER_BASENAME}> */}
        <Rotas />
      {/* </Router> */}
    </VerificarErroInterno>
  );
}

export default connector(App);