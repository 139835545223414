import React, { Fragment } from "react";
import { BiChevronDown } from "react-icons/bi";
import "./style.css";
import { AuthState } from "src/store/types";
import { connect, ConnectedProps } from "react-redux";
import { Link } from "react-router-dom";
import { FaRegHospital, FaRegUser } from "react-icons/fa";
import { FiLogOut, FiSettings } from "react-icons/fi";
import { RiTruckLine } from "react-icons/ri";

const mapStateToProps = ({ auth }: { auth: AuthState }): AuthState => {
  return {
    usuario: auth.usuario,
  };
};

const connector = connect(mapStateToProps, {});

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
  userName?: string;
  toggleDropdown?: () => void;
  openModal?: () => void;
  closeDrop?: () => void;
  dropdownLinkClassName?: string;
  toggleDrop?: boolean;
  dropRef?: any;
};

function Dropdown({
  toggleDropdown,
  userName,
  usuario,
  openModal,
  closeDrop,
  dropRef,
  dropdownLinkClassName = "flex block px-4 py-2 text-lg leading-5 text-white hover:font-bold focus:outline-none",
  toggleDrop,
}: Props): JSX.Element {
  const nivelCheck = usuario?.nivel === 1 || usuario?.nivel === 3;
  const adminCheck = usuario?.nivel === 5;
  const fornecedorCheck = usuario?.nivel === 2;
  return (
    <Fragment>
      <div className="relative" ref={dropRef}>
        <div
          className={`flex menu-container cursor-pointer`}
          onClick={toggleDropdown}
        >
          <div
          
          className="perfil-style">
            <span
              style={{width: 600}}
              className={
                "mt-5 font-bold text-lg lg:text-lg md:text-sm xl:text-xl text-black-500 xs:mt-3 ml-2 lg:mt-2 xl:mt-2 md:mt-5"
              }
              title={userName}
            >
              {userName}
            </span>
          </div>

          <span className="ml-1 font-bold">
            <BiChevronDown
              className="mt-1 md:mt-1 lg:mt-1 text-blue font-bold xl:text-4xl lg:text-4xl"
              size=""
            />
          </span>
        </div>
        {toggleDrop && (
          <div className={`dropdown-content absolute z-20 mt-3 right-0`}>
            <div className="flex justify-end mr-5">
              <span className="arrow-up-2 w-10"></span>
            </div>
            <div className={"px-2 py-5 rounded-md bg-blue shadow-xl"}>
              <Fragment>
                <Link
                  to="#"
                  onClick={openModal}
                  className={dropdownLinkClassName}
                  role="menuitem"
                >
                  <FaRegUser size="1.2rem" className="mr-2 mt-0 text-white" />
                  Ver Perfil
                </Link>

                {adminCheck ? (
                  <Fragment>
                    <Link
                      to="/hospitais"
                      className={dropdownLinkClassName}
                      onClick={closeDrop}
                      role="menuitem"
                    >
                      <FaRegHospital
                        size="1.2rem"
                        className="mr-2 mt-0 text-white"
                      />
                      Hospitais
                    </Link>
                  </Fragment>
                ) : (
                  <Fragment>
                    {nivelCheck ? (
                      <Fragment>
                        <Link
                          to="/hospitais"
                          className={dropdownLinkClassName}
                          onClick={closeDrop}
                          role="menuitem"
                        >
                          <FaRegHospital
                            size="1.2rem"
                            className="mr-2 mt-0 text-white"
                          />
                          Hospitais
                        </Link>
                        <Link
                          to="/fornecedores"
                          className={dropdownLinkClassName}
                          onClick={closeDrop}
                          role="menuitem"
                        >
                          <RiTruckLine
                            size="1.2rem"
                            className="mr-2 mt-0 text-white"
                          />
                          Fornecedores
                        </Link>
                      </Fragment>
                    ) : (
                      <Fragment>
                      {fornecedorCheck ? (
                      <Fragment><Link
                        to="/auxiliares"
                        onClick={closeDrop}
                        className={dropdownLinkClassName}
                        role="menuitem"
                      >
                        <FiSettings
                          size="1.2rem"
                          className="mr-2 mt-0 text-white"
                        />
                        Auxiliares
                      </Link>
                      </Fragment>
                      ) : (
                      <Fragment></Fragment>
                      )}</Fragment>
                    )}
                  </Fragment>
                )}

                <Link
                  to="/logout"
                  className={dropdownLinkClassName}
                  role="menuitem"
                >
                  <FiLogOut size="1.2rem" className="mr-2 mt-0 text-white" />
                  Sair
                </Link>
              </Fragment>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
}
export default connector(Dropdown);
