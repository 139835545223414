import React from "react";
import { useLocation } from "react-router-dom";
import { ObjectSchema } from "yup";
import './media.css';

type Props = {
  label: string;
  name: string;
  labelClassName?: string;
  wrapClassName?: string;
  inputClassName?: string;
  type: string;
  required?: boolean;
  disabled?: boolean;
  maxlength?: number;
  value?: string;
  schema?: ObjectSchema<any>;
  onChange?: (event: any) => void;
  onBlur?: (event: any) => void;
  onKeyPress?: ((e: React.KeyboardEvent<HTMLInputElement>) => void) | undefined;
  id?: string;
  onKeyDown?: any;
  placeHolder?: string;
};

export default function InputFormCompound({
  label,
  name,
  type,
  required,
  disabled,
  labelClassName = "text-gray-800 font-bold text-sm xl:text-lg md:text-base",
  wrapClassName = "px-5 py-3",
  inputClassName = "px-3 mt-2 capitalize border-2 border-gray-400 w-full h-10",
  schema,
  maxlength,
  value,
  onChange,
  onBlur,
  id,
  onKeyDown,
  onKeyPress,
  placeHolder,
}: Props) {
  const location = useLocation();

  return (
    <div className={wrapClassName}>
      <label className={`input-form-compound-label${location.pathname === '/solicitacao' ? ' patient' : ''} ${labelClassName}`} htmlFor="">
        {label}
      </label>
      <p></p>
      <input
        id={id}
        className={`input-form-compound-input ${inputClassName}`}
        type={type}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        maxLength={maxlength}
        required={required}
        autoComplete="off"
        disabled={disabled}
        value={value}
        placeholder={placeHolder}
        style={{ borderRadius: "16px" }}
        onKeyDown={onKeyDown}
        onKeyPress={onKeyPress}
      />
    </div>
  );
}
