import React from "react";
import "./style.css";
import { BiErrorCircle } from "react-icons/bi";

type Props = {
  titleContent: string;
  paddingContent?: string;
  paddingText?: string;
  messageSize?: string;
  iconSize?: string;
};

export default function EmptyContent({
  titleContent,
  paddingContent,
  paddingText,
  messageSize,
  iconSize,
}: Props): JSX.Element {
  return (
    <div
      className={`${paddingContent} border-2 border-gray-300 border-dashed`}
      style={{ borderRadius: "16px" }}
    >
      <div className={`flex justify-center w-full ${paddingText}`}>
        <BiErrorCircle size={`${iconSize}`} className="text-gray-300 mr-1" />
        <h2 className={`font-semibold text-${messageSize} text-gray-300`}>
          {titleContent}
        </h2>
      </div>
    </div>
  );
}
