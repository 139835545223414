import "./style.css";
import React, { Fragment, useEffect, useState } from "react";
import EmptyContent from "src/components/EmptyContent";
import FornecedoresLista from "src/components/CardForncedoresLista";
import { CgSearch } from "react-icons/cg";
import ModalFornecedores from "src/components/ModalFornecedores";
import { axiosApi } from "src/services/axiosInstances";
import Loader from "src/components/Loader";
import ReactPaginate from "react-paginate";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { FiPlus } from "react-icons/fi";
import { AuthState, Fornecedores, FornecedoresState } from "src/store/types";
import { setUpdateFornecedores } from "src/store/actions/fornecedores";
import { connect, ConnectedProps } from "react-redux";

const mapStateToProps = ({
  auth,
  fornecedores,
}: {
  auth: AuthState;
  fornecedores: FornecedoresState;
}): AuthState & FornecedoresState => {
  return {
    usuario: auth.usuario,
    fornecedores: fornecedores.fornecedores,
  };
};

const mapDispatchToProps = {
  setUpdateFornecedores,
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
  idVendedor?: number;
  thClassName?: string;
  tableClassName?: string;
};

type HomeResponse = {
  fornecedores: Fornecedores[];
  total: number;
};

function FornecedoresPage({
  thClassName = " py-2",
  tableClassName = "table-auto table-autorizacoes",
  fornecedores,
  setUpdateFornecedores,
  usuario,
}: Props): JSX.Element {
  const [loader, setLoader] = useState(true);
  const [idFornecedor, setIdFornecedor] = useState<number>();
  const [title, setTitle] = useState<string>();
  const [offset, setOffset] = useState(0);
  const [perPage] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [filtro, setFiltro] = useState<string>("");
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(!open);

  const loadDados = async () => {
    try {
      if (!filtro) setLoader(true);
      const { data } = await axiosApi.get<HomeResponse>(
        `/list-users?selecionado=2&limit=${perPage}&offset=${offset}&filtro=${filtro}`
      );
      const arr = Math.ceil(data.total / perPage);
      setUpdateFornecedores(data.fornecedores);
      setPageCount(arr);
    } catch (error: any) {
      console.log(error);
    }
    setLoader(false);
  };

  useEffect(() => {
    loadDados();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset, perPage, filtro]);

  const loadDadosForn = async () => {
    try {
      const { data } = await axiosApi.get<HomeResponse>(
        `/list-users?selecionado=2&limit=${perPage}&offset=${offset}&filtro=${filtro}`
      );
      setUpdateFornecedores(data.fornecedores);
    } catch (error: any) {
      console.log(error);
    }
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFiltro(event.target.value);
  };

  const dadosFornecedor = (id: number, nome: string): void => {
    setTitle(nome);
    setIdFornecedor(id);
    setOpen(true);
  };

  const novoFornecedor = (): void => {
    setIdFornecedor(undefined);
    setOpen(true);
  };

  const handlePageChange = (select: { selected: number }) => {
    const selectedPage = select.selected;
    setOffset(selectedPage * perPage);
  };

  const theFirst = 0;
  const theLast = pageCount - 1;
  const page = Math.ceil(offset / perPage);

  const nivelCheck = usuario?.nivel === 1 || usuario?.nivel === 3;

  const adminCheck = !nivelCheck ?? usuario?.nivel === 5;

  return (
    <Fragment>
      <Loader isActive={loader}>
        <ModalFornecedores
          open={open}
          toggle={toggle}
          setIdFornecedor={setIdFornecedor}
          idFornecedor={idFornecedor}
          nome={title}
          updateData={loadDadosForn}
          adminCheck={adminCheck}
        />
        <div className="p-12 div-auxiliares">
          <div className="grid grid-flow-col justify-start">
            <h1 className="text-blue text-4xl font-bold px-5">Fornecedores</h1>
          </div>

          <div className="grid grid-cols-full relative py-10 text-sm">
            <div className="grid grid-cols-12 py-0 mb-5">
              <div className="col-span-4">
                <div className="w-full rounded-lg px-5 py-1">
                  <div className="flex ">
                    <CgSearch
                      size="1.5rem"
                      className="my-3 mr-4 search-icon-c text-blue "
                    />
                    <input
                      placeholder="Buscar por Fornecedores..."
                      type="text"
                      className="search-bar-crud px-1 w-full text-sm mb-1"
                      name="filtro"
                      onChange={onChange}
                      autoComplete="off"
                      value={filtro}
                    />
                  </div>
                </div>
              </div>

              <div className="col-span-2 float-right absolute right-0 mt-16">
                <button
                  onClick={novoFornecedor}
                  className="btn-new-vendedor bg-blue text-white font-bold rounded-full content-center px-4 py-2 flex mt-1 shadow-md"
                >
                  <FiPlus size="1.3rem" className="mr-2 text-white" /> Novo
                </button>
              </div>
            </div>

            <div className="grid grid-cols-full text-sm">
              {fornecedores?.length === undefined ? (
                <EmptyContent
                  messageSize="2xl"
                  iconSize="2.2rem"
                  paddingContent={"py-32"}
                  titleContent="Sem Novos Fornecedores"
                />
              ) : (
                <Fragment>
                  <table className={tableClassName}>
                    <thead>
                      <tr className="text-center">
                        <th className={thClassName}></th>
                        <th className="pl-12 py-2 text-left">Razão Social</th>
                        <th className={thClassName}>Fantasia</th>
                        <th className={thClassName}>CNPJ</th>
                        <th className={thClassName}>WhatsApp</th>
                        <th className={thClassName}>Email</th>
                        <th className={thClassName}>Status</th>
                        {adminCheck ? (
                          <th className={thClassName}></th>
                        ) : (
                          <th className={thClassName}></th>
                        )}
                      </tr>
                    </thead>
                    {fornecedores?.map((dados: Fornecedores) => {
                      const statusFornecedor =
                        dados?.usuario?.status_id === 6
                          ? "Incompleto"
                          : dados?.usuario?.status_id === 7
                          ? "Completo"
                          : "Inativo";
                      return (
                        <FornecedoresLista
                          key={dados.id}
                          id={dados.id}
                          onClick={dadosFornecedor}
                          razao_social={
                            dados?.razao_social ? dados.razao_social : "-"
                          }
                          fantasia={dados?.fantasia}
                          cnpj={dados?.cnpj}
                          whatsapp={dados?.whatsapp}
                          email={
                            dados.usuario?.email ? dados.usuario.email : "-"
                          }
                          status={statusFornecedor}
                          status_id={dados?.usuario?.status_id}
                        />
                      );
                    })}
                  </table>
                  {pageCount === 1 ? (
                    ""
                  ) : (
                    <ReactPaginate
                      previousLabel={
                        <BiChevronLeft
                          size="1.5rem"
                          className={
                            offset / perPage === theFirst
                              ? "cursor-default text-gray-400 outline-none"
                              : "text-blue outline-none"
                          }
                        />
                      }
                      nextLabel={
                        <BiChevronRight
                          size="1.5rem"
                          className={
                            offset / perPage === theLast
                              ? "cursor-default text-gray-400 outline-none"
                              : "text-blue outline-none"
                          }
                        />
                      }
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={pageCount}
                      forcePage={page}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageChange}
                      containerClassName={
                        "flex justify-center space-x-3 pt-10 text-md"
                      }
                      activeClassName={"active"}
                      activeLinkClassName={"p-1 transform scale-150 "}
                    />
                  )}
                </Fragment>
              )}
            </div>
          </div>
        </div>
      </Loader>
    </Fragment>
  );
}
export default connector(FornecedoresPage);
