/* eslint-disable jsx-a11y/anchor-is-valid */
import "./style.css";
import React, { Fragment, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { AuthState, Usuario } from "../../store/types";
import { setDadosAtualizados } from "../../store/actions/auth";
import InputFormLogin from "src/components/Inputs/inputLogin";
import ButtonFormSubmit from "src/components/Inputs/buttonSubmit";
import { axiosApi } from "src/services/axiosInstances";
import { Link } from "react-router-dom";
import InputSenha from "src/components/InputSenha";
import { toast, ToastContainer } from "react-toastify";
// import { FaAngleLeft } from "react-icons/fa";
// import logo from "src/assets/logo-intro.png";
// import play from "src/assets/app-google.png";
// import apple from "src/assets/app-apple.png";

const mapStateToProps = ({ auth }: { auth: AuthState }) => {
  return {
    token: auth.token,
    usuario: auth.usuario,
  };
};

const mapDispatchToProps = {
  setDadosAtualizados,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {};

type Token = {
  access_token: string;
  token_type: string;
  expires_in: number;
};
type LoginResponse = {
  token: Token;
  usuario: Usuario;
};
type LoginForm = {
  email: string;
  senha: string;
};

function Admin({ setDadosAtualizados }: Props): JSX.Element {
  const handleForm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const response = await axiosApi.post<LoginResponse>(
        `/auth/login-adm`,
        form
      );
      const dataExpiracao = new Date(
        new Date().getTime() + response.data.token.expires_in
      );
      setDadosAtualizados(
        response.data.token.access_token,
        response.data.usuario,
        response.data.token.expires_in
      );
      localStorage.setItem("token", response.data.token.access_token);
      localStorage.setItem("usuario", JSON.stringify(response.data.usuario));
      localStorage.setItem(
        "expires_in",
        JSON.stringify(response.data.token.expires_in)
      );
      localStorage.setItem("data_expiracao", JSON.stringify(dataExpiracao));

      // ativarRefreshToken(setDadosAtualizados);
    } catch (error: any) {
      toast.error('Usuário ou Senha incorretos!', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
    }
  };

  const [form, setForm] = useState<LoginForm>({ email: "", senha: "" });

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const [senhaEye, setSenhaEye] = useState(false);
  const toggleSenha = () => {
    setSenhaEye(senhaEye ? false : true);
  };

  // const appleCheck = navigator.userAgent.match(/iPhone|iPad|iPod/i);
  // const androidCheck = navigator.userAgent.match(/Android/i);

  return (
    <Fragment>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover={false}
      />
      <div className="form-title justify-center align-middle p-5 flex">
        <img
          className="mr-4 w-20 h-20"
          src={require("src/assets/logo.png")}
          alt=""
        />

        <div className="align-middle text-black text-4xl relative">
          <h1>Cirurgia</h1>
          <h1 className="logo-style">Autorizada</h1>
          <p
            className="absolute text-blue text-sm right-0"
            style={{ marginTop: "-10px" }}
          >
            Administrador
          </p>
        </div>
      </div>

      <div className="justify-center flex">
        <form onSubmit={handleForm}>
          <InputFormLogin
            name="email"
            label="Email"
            type="text"
            onChange={handleInput}
            required={true}
          />

          <div className="relative">
            <InputSenha
              name="senha"
              label="Senha"
              marginTop={"mt-3"}
              width={"px-1"}
              senhaEffect={senhaEye}
              onClickEye={toggleSenha}
              onChange={handleInput}
            />

            <p
              className="text-center text-xs absolute right-0 mr-2"
              style={{ marginTop: "-10px" }}
            >
              <Link
                className="font-bold text-gray-800 ml-1"
                to={"/recuperar-senha"}
              >
                Esqueceu sua senha?
              </Link>
            </p>
          </div>

          {/* <p className="text-center pt-0 text-md mt-5">
            Não Possui Conta?
            <Link className="link-cadastro ml-1" to={getNivel()}>
              Crie uma aqui.
            </Link>
          </p> */}

          <ButtonFormSubmit name="btn-submit" type="submit" />
        </form>
      </div>

      {/* <div className="block xl:hidden lg:hidden md:hidden pt-5">
        <div className="pl-3 mb-5 flex justify-center">
          <img src={logo} alt="logo" className="w-48" />
        </div>
        <div className="flex justify-center px-2 mr-2 mb-2">
          <p className="break-words text-center text-md">
            A página de Login de{" "}
            <span className="text-blue font-bold">Cirurgia Autorizada</span> não
            pode ser acessada por dispositivos móveis, por isso baixe nosso app!
          </p>
        </div>

        <div className="mt-8">
          {appleCheck ? (
            <a href="#" className="flex justify-center">
              <img src={apple} alt="apple" className="w-48" />
            </a>
          ) : androidCheck ? (
            <a href="#" className="flex justify-center">
              <img src={play} alt="play" className="w-56" />
            </a>
          ) : (
            ""
          )}
        </div>

        <div className="flex justify-center mt-8">
          <div className="absolute bottom-0">
            <Link title="Voltar" to="/quem-sou">
              <div className="flex justify-center bg-white rounded-full px-4 py-2 mb-5">
                <FaAngleLeft className="mr-1 text-blue" size="40px" />
                <span className="text-xl font-bold text-blue mt-1 mr-1">
                  Voltar
                </span>
              </div>
            </Link>
          </div>
        </div>
      </div> */}
    </Fragment>
  );
}

export default connector(Admin);
