import React from "react";
import "./style.css";
import calendar from "../../assets/MobileFloatingNav/Calendar.svg";
import chart from "../../assets/MobileFloatingNav/Chart.svg";
import clipboard from "../../assets/MobileFloatingNav/Clipboard.svg";
import blueCalendar from "../../assets/MobileFloatingNav/Blue-Calendar.svg";
import blueChart from "../../assets/MobileFloatingNav/Blue-Chart.svg";
import blueClipboard from "../../assets/MobileFloatingNav/Blue-Clipboard.svg";
import { Link, useLocation } from "react-router-dom";

interface IMobileFloatingNav {
  state: number;
}

const MobileFloatingNav: React.FC<IMobileFloatingNav> = ({ state }) => {
  const { pathname } = useLocation();

  return (
    <nav
      className={`mobile-floating-nav-container${
        state === 1 ? " disabled" : ""
      }`}
    >
      <Link
        to={"/calendario"}
        className={`mobile-floating-nav-option ${pathname === "/calendario" ? " active" : ""}`}
      >
        <img
          src={pathname === "/calendario" ? blueCalendar : calendar}
          alt="calendar"
        />
        <span className="mobile-floating-nav-option-content">Calendário</span>
      </Link>
      <Link
        to={"/home"}
        className={`mobile-floating-nav-option${
          pathname === "/home" || pathname === "/login" ? " active" : ""
        }`}
      >
        <img
          src={pathname === "/home" || pathname === "/login" ? blueChart : chart}
          alt="chart"
        />
        <span className="mobile-floating-nav-option-content">Dashboard</span>
      </Link>
      <Link
        to={"/autorizacoes"}
        className={`mobile-floating-nav-option ${pathname === "/autorizacoes" ? " active" : ""}`}
      >
        <img
          src={pathname === "/autorizacoes" ? blueClipboard : clipboard}
          alt="clipboard"
        />
        <span className="mobile-floating-nav-option-content">Autorizações</span>
      </Link>
    </nav>
  );
};

export default MobileFloatingNav;
