import React, { useEffect, useState } from 'react';

interface TypeaheadDropdownProps {
    options: { id: number; nome: string }[];
    value: string;
    index? : number;
    onChange: (value: string, index: number) => void;
    required: boolean;
    disabled?: boolean;
}

const TypeaheadDropdown: React.FC<TypeaheadDropdownProps> = ({ options, value, index, onChange, required, disabled }) => {
    const [inputValue, setInputValue] = useState(value ?? '');
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        setInputValue(value);
    }, [value]);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);
        onChange(event.target.value, index ?? 0);
        setIsOpen(true);
    };

    const handleOptionClick = (option: { id: number; nome: string }) => {
        setInputValue(option.nome);
        onChange(option.nome, index ?? 0);
        setIsOpen(false);
    };

    const handleClick = () => {
        setIsOpen(true);
    };

    const handleBlur = () => {
        setIsOpen(false);
    }

    return (
        <div className='relative'>
            <input
                className='input-modal text-sm w-full mb-2 border-solid border-b-2 border-gray-400'
                type="text"
                required={required}
                value={inputValue}
                onChange={handleInputChange}
                onClick={handleClick}
                onBlur={handleBlur}
                placeholder="Digite aqui..."
                disabled={disabled}
            />
            {isOpen && (
                <ul className='text-sm w-full mb-2 bg-white absolute overflow-auto h-64 z-10 border border-gray-300 rounded shadow-md'>
                    {options
                        .filter((option) =>
                            inputValue == undefined || inputValue.length === 0 ? true : option.nome?.toLowerCase().includes(inputValue?.toLowerCase())
                        )
                        .map((option) => (
                            <li key={option.id} onMouseDown={() => handleOptionClick(option)}>
                                {option.nome}
                            </li>
                        ))}
                </ul>
            )}
        </div>
    );
};

export default TypeaheadDropdown;