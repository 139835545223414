import React, { useCallback, useEffect, useRef, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { axiosApi } from "src/services/axiosInstances";
import { informacoesProps, apiDATA, Suggestion } from "./interfaces";



const Informacoes: React.FC<informacoesProps> = ({ parametros, cidInputValue, secondCidInputValue, setCidInputValue, setSecondCidInputValue }) => {

  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
  const [secondarySuggestions, setSecondarySuggestions] = useState<Suggestion[]>([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [secondShowDropDown, setSecondShowDropdown] = useState(false);
  const dropdownRef = useRef<HTMLUListElement>(null);
  const secondDropDownRef = useRef<HTMLUListElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const secondInputRef = useRef<HTMLInputElement>(null);


  const { control, register
    , formState: { errors },
    setValue,
    // trigger
  } = useFormContext();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setShowDropdown(false);
        setSuggestions([]);
      } if (secondDropDownRef.current && !secondDropDownRef.current.contains(event.target as Node)) {
        setSecondShowDropdown(false);
        setSecondarySuggestions([]);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef, secondDropDownRef]);

  const fetchCid = async (query: string) => {
    try {
      const { data } = await axiosApi.get(`/solicitacao-cirurgia/cid/${query}`);
      if (inputRef.current && inputRef.current.value === query) {
        setSuggestions(data);
      }
      if (secondInputRef.current && secondInputRef.current.value === query) {
        setSecondarySuggestions(data);
      }
    } catch (error) {
      console.error('Error fetching hospitais', error);
    }
  };

  const debounce = (func: (...args: any[]) => void, delay: number) => {
    let timeoutId: NodeJS.Timeout;
    return (...args: any[]) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func(...args), delay);
    };
  };

  const debouncedFetchData = useCallback(
    debounce((query: string) => fetchCid(query), 300),
    []
  );

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newQuery = event.target.value;
    setCidInputValue(newQuery);
    if (newQuery.length >= 2) {
      debouncedFetchData(newQuery);
    } if (newQuery.length <= 1) {
      setSuggestions([]);
    }
  };

  const handleSecondInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newQuery = event.target.value;
    setSecondCidInputValue(newQuery);
    if (newQuery.length >= 2) {
      debouncedFetchData(newQuery);
    } if (newQuery.length <= 1) {
      setSecondarySuggestions([]);
    }
  };


  const handleSuggestionClick = (suggestion: Suggestion) => {
    setValue("cid_principal_id", suggestion.id);
    setCidInputValue(suggestion.descricao);
    setSuggestions([]);
  };

  const handleSecondarySuggestionClick = (suggestion: Suggestion) => {
    setValue("cid_secundario_id", suggestion.id);
    setSecondCidInputValue(suggestion.descricao);
    setSecondarySuggestions([]);
  };

  return (
    <>
      <div className="flex mt-5 z-0">
        <div className="flex flex-col">
          <label htmlFor="carater_atendimento_id" className="text-black">Caráter</label>
          <Controller
            name="carater_atendimento_id"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <select {...field}
                style={{ width: 165 }}
                id="carater_atendimento_id"
                className="font-lato placeholder-gray-600 h-10 border border-black rounded-xl"
              >
                <option value="" disabled hidden>Selecione uma opção</option>
                {parametros[`caraterAtendimento`] && parametros[`caraterAtendimento`]
                  .map((carater: apiDATA) =>
                    <option
                      value={carater.id}
                      key={carater.id}>{carater.descricao}
                    </option>
                  )}
              </select>
            )}
          />
          {errors.carater_atendimento_id && <span className="text-red-600">{errors.carater_atendimento_id.message as string}</span>}
        </div>

        {/* <div className="flex flex-col ml-10 TypeAheadDropDown">
          <label htmlFor="hospital_nome" className="text-black">Contratado Solicitado</label>
          <input
            className="TypeAheadDropDown font-lato placeholder-gray-600 h-10 border border-black rounded-xl"
            onChange={handleInputChange}
            type="text"
            id="hospital_nome"
            onFocus={() => setShowDropdown(true)}
            value={hospitalInputValue}
          />
          {
            showDropdown && suggestions.length !== 0 && <ul ref={dropdownRef} className="TypeAheadDropDown">
              {suggestions.map((suggestion) => (
                <li
                  className="TypeAheadDropDown"
                  key={`${suggestion.id} ${suggestion.nome}`}
                  onClick={() => handleSuggestionClick(suggestion)}>
                  {suggestion.nome}
                </li>
              ))}
            </ul>
          }
          {errors.hospital_nome && <span className="text-red-600">{errors.hospital_nome.message as string}</span>}
        </div> */}
        <div className="flex flex-col ml-10">
          <label htmlFor="tipo_internacao_id" className="text-black">Tipo de Internação</label>
          <Controller
            name="tipo_internacao_id"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <select {...field}
                id="tipo_internacao_id" style={{ width: 165 }}
                className="font-lato placeholder-gray-600 h-10 border border-black rounded-xl"
              >
                <option value="" disabled hidden>Selecione uma opção</option>
                {parametros[`tipoInternacao`] && parametros[`tipoInternacao`]
                  .map((tipo: apiDATA) => <option
                    value={tipo.id}
                    key={tipo.id}>{tipo.descricao}</option>)}
              </select>
            )}
          />
          {errors.tipo_internacao_id && <span className="text-red-600">{errors.tipo_internacao_id.message as string}</span>}
        </div>
      </div>


      <div className="flex mt-8">
        <div className="flex flex-col">
          <label htmlFor="regime_internacao_id" className="text-black">Regime de Internação</label>
          <Controller
            name="regime_internacao_id"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <select {...field}
                id="regime_internacao_id" style={{ width: 165 }}
                className="font-lato placeholder-gray-600 h-10 border border-black rounded-xl"
              >
                <option value="" disabled hidden>Selecione uma opção</option>
                {parametros[`regimeInternacao`] && parametros[`regimeInternacao`]
                  .map((regime: apiDATA) => <option
                    value={regime.id}
                    key={regime.id}>{regime.descricao}</option>)}
              </select>
            )}
          />
          {errors.regime_internacao_id && <span className="text-red-600">{errors.regime_internacao_id.message as string}</span>}
        </div>
        <div className="flex flex-col ml-10">
          <label htmlFor="quantidade_diarias_solicitadas" className="text-black">Qtd. diárias Solicitadas</label>
          <input type="number" id="quantidade_diarias_solicitadas" style={{ width: 165 }}
            className="font-lato placeholder-gray-600 h-10 border border-black rounded-xl"
            {...register("quantidade_diarias_solicitadas")}
          />
          {errors.quantidade_diarias_solicitadas && <span className="text-red-600">{errors.quantidade_diarias_solicitadas.message as string}</span>}
        </div>
      </div>


      <div style={{ width: 800 }} className="flex mt-8">
        <div className="TypeAheadDropDown flex flex-col">
          <label htmlFor="cid_principal" className="text-black">CID principal</label>
          <input
            ref={inputRef}
            id="cid_principal"
            type="text"
            style={{ width: 390 }}
            onChange={handleInputChange}
            onFocus={() => setShowDropdown(true)}
            value={cidInputValue}
            className="font-lato placeholder-gray-600 h-10 border border-black rounded-xl"
          // {...register("cid_principal"}
          />
          {
            showDropdown && suggestions.length !== 0 && <ul ref={dropdownRef} className="TypeAheadDropDown" style={{ width: 390 }}>
              {suggestions.map((suggestion) => (
                <li
                  className="TypeAheadDropDown"
                  key={`${suggestion.id} ${suggestion.descricao}`}
                  onClick={() => handleSuggestionClick(suggestion)}>
                  {suggestion.descricao}
                </li>
              ))}
            </ul>
          }
        </div>
        <div className="ml-5 TypeAheadDropDown">
          <label htmlFor="cid_secundario" className="text-black">CID secundário</label>
          <input
            ref={secondInputRef}
            id="cid_secundario"
            type="text"
            style={{ width: 390 }}
            onChange={handleSecondInputChange}
            onFocus={() => setSecondShowDropdown(true)}
            value={secondCidInputValue}
            className="font-lato placeholder-gray-600 h-10 border border-black rounded-xl"
          // {...register("cid_secundario")}
          />
          {
            secondShowDropDown && secondarySuggestions.length !== 0 && <ul ref={secondDropDownRef} className="TypeAheadDropDown" style={{ width: 390 }}>
              {secondarySuggestions.map((suggestion) => (
                <li
                  className="TypeAheadDropDown"
                  key={`${suggestion.id} ${suggestion.descricao}`}
                  onClick={() => handleSecondarySuggestionClick(suggestion)}>
                  {suggestion.descricao}
                </li>
              ))}
            </ul>
          }
        </div>
      </div>


      <div className="flex flex-col mt-8">
        <div>
          <label htmlFor="indicacao_clinica" className="text-black">Indicação Clínica</label>
          <textarea id="indicacao_clinica" style={{ width: 801, height: 134 }}
            className="font-lato placeholder-gray-600 h-10 border border-black rounded-xl"
            {...register("indicacao_clinica")}
          />
          {errors.indicacao_clinica && <span className="text-red-600">{errors.indicacao_clinica.message as string}</span>}
        </div>
        <div className="mt-8">
          <label htmlFor="observacao" className="text-black">Observação</label>
          <textarea id="observacao" style={{ width: 801, height: 134 }}
            className="font-lato placeholder-gray-600 h-10 border border-black rounded-xl"
            {...register("observacao")}
          />
        </div>
      </div>
    </>
  )
}


export default Informacoes;