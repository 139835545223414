import "./style.css";
import React from "react";
import img from "src/assets/img-intro.png";

export default function Img(): JSX.Element {
  return (
    <div className="img-index flex justify-center my-10 mx-20">
      <div className="w-full">
        <img
          className="w-full invisible xl:visible lg:visible"
          src={img}
          alt="img-index"
        />

        <div className="text-img">
          <div className="flex justify-center">
            <h1 className="invisible xl:visible lg:visible font-bold img-title xl:text-3xl lg:text-2xl break-words">
              Bem-Vindo(a) ao maior portal de gerenciamento de cirurgias!
            </h1>
          </div>

          <div className="flex justify-center">
            <span className="img-subtitle font-bold invisible text-left xl:visible lg:visible lg:text-sm md:text-xs px-10">
              Informando pacientes, cirurgiões, fornecedores e hospitais sobre
              cada etapa de agendamentos cirúrgicos. Priorizando o cuidado do
              paciente e comodidade para o cirurgião.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
