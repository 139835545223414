import React, { Fragment, useEffect, useState } from "react";
import "./style.css";
import Popup from "reactjs-popup";
import { AiFillCloseCircle } from "react-icons/ai";
import InputMask from "react-input-mask";
import { axiosApi } from "src/services/axiosInstances";
import { toast } from "react-toastify";
import SelectHospital from "../Select";
import { AuthState, ChecklistState } from "src/store/types";
import { setUpdateChecklist } from "src/store/actions/checklist";
import { connect, ConnectedProps } from "react-redux";
import { useLocation } from "react-router-dom";

const mapStateToProps = ({
  auth,
  cirurgias,
}: {
  auth: AuthState;
  cirurgias: ChecklistState;
}): AuthState & ChecklistState => {
  return {
    doutor: auth.doutor,
    usuario: auth.usuario,
    cirurgias: cirurgias.cirurgias,
  };
};

const mapDispatchToProps = {
  setUpdateChecklist,
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
  openEditar?: any;
  toggle?: any;
  toggleChecklist?: any;
  idCirurgia?: number;
  idHospital?: number;
  nomeHospital?: string;
  dataCirurgia?: string;
  horaCirurgia?: string;
  paciente?: string;
  setDadosModal?: any;
  dadosModal?: any;
  closeEditarModal?: any;
  // updateTable?: (id: number, dados: object) => void;
  loadDados?: any;
};

type Hospitais = {
  id: number;
  nome: string;
};

type Option = {
  value: string | number;
  label: string;
};

type Form = {
  hospital_id: number;
  confirmacao_data: string;
  confirmacao_hora: string;
};

type HomeResponse = {
  ativos: Hospitais[];
  inativos: Hospitais[];
};

function ModalEditarAgendadas({
  openEditar,
  toggle,
  idCirurgia,
  idHospital,
  dataCirurgia,
  horaCirurgia,
  paciente,
  nomeHospital,
  setDadosModal,
  dadosModal,
  closeEditarModal,
  loadDados,
  toggleChecklist,
  cirurgias,
  setUpdateChecklist,
}: Props): JSX.Element {
  const { pathname } = useLocation();

  const modalWidth = { width: "40%" };
  const mobileModalWidth = { width: "100%" };
  const mobileOverlayWidth = { width: '100vw', height: '100vh' }

  const [hospitais, setHospitais] = useState<Hospitais[]>([]);
  const [form, setForm] = useState<Form>({
    confirmacao_data: "",
    confirmacao_hora: "",
    hospital_id: 0,
  });

  useEffect(() => {
    const loadDados = async () => {
      try {
        const { data } = await axiosApi.get<HomeResponse>(`/list-hospitais`);
        setHospitais(data.ativos);
      } catch (error: any) {
        console.log(error);
      }
    };
    loadDados();
  }, []);

  useEffect(() => {
    if (dadosModal) {
      setForm({
        ...dadosModal,
        confirmacao_data: dadosModal?.confirmacao_data,
        confirmacao_hora: dadosModal?.confirmacao_hora,
        hospital_id: dadosModal?.hospital_id,
      });
    }
  }, [dadosModal]);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    try {
      await axiosApi.put(`/edit-checklist/${idCirurgia}`, form);
      const prevCirurgias = cirurgias.slice();
      const cirurgiaIndex = prevCirurgias.findIndex(
        (cirurgia) => cirurgia.id === idCirurgia
      );
      prevCirurgias[cirurgiaIndex] = {
        ...prevCirurgias[cirurgiaIndex],
        hospital_id: form.hospital_id,
        confirmacao_data: form?.confirmacao_data,
        confirmacao_hora: form?.confirmacao_hora,
      };
      setUpdateChecklist(prevCirurgias);
      setDadosModal({
        ...dadosModal,
        hospital_id: form.hospital_id,
        confirmacao_data: form?.confirmacao_data,
        confirmacao_hora: form?.confirmacao_hora,
      });
      toast.success('Agendamento Alterado!', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
      toggle();
      toggleChecklist();
    } catch (error: any) {}
  };

  const postForm = (event: any) => {
    const { name, value } = event.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const hospitaisOptions = React.useMemo<Array<Option>>((): Array<Option> => {
    const options: Array<Option> | undefined = hospitais?.map(
      (hospital: Hospitais): Option => {
        return {
          value: hospital.id,
          label: hospital.nome,
        };
      }
    );

    return [...(options ?? [])];
  }, [hospitais]);

  return (
    <Popup
      open={openEditar}
      closeOnDocumentClick={false}
      // onClose={openEditar}
      overlayStyle={window.innerWidth <= 832 ? mobileOverlayWidth : undefined}
      contentStyle={window.innerWidth <= 832 ? mobileModalWidth : modalWidth}
      modal
      nested={true}
    >
      <Fragment>
        <div className="mobile-editar-agendadas-wrapper bg-white modal py-5 px-10">
          <div className="header pt-5 pb-3 text-left text-lg font-light truncate">
            Editar Cirurgia de{" "}
            <span title={paciente} className="text-2xl font-bold">
              {paciente}
            </span>
          </div>
          <div className="content">
            <form onSubmit={handleSubmit}>
              <div className={`input-vendedores mt-5 mb-5 px-1`}>
                <h4 className="text-base font-bold mb-2">Data Cirurgia</h4>
                <InputMask mask="99/99/9999" onChange={postForm}>
                  <input
                    type="text"
                    className={`input-vendedores${pathname === '/autorizacoes' ? ' mobile-edit-agendadas' : ''} w-full h-12 border border-gray-600 pl-3`}
                    name="confirmacao_data"
                    defaultValue={dataCirurgia ? dataCirurgia : ""}
                    required={true}
                  />
                </InputMask>
              </div>
              <div className="input-vendedores mb-5 px-1">
                <h4 className="text-base font-bold mb-2">Hora Cirurgia</h4>
                <InputMask mask="99:99" onChange={postForm}>
                  <input
                    type="text"
                    className={`input-vendedores${pathname === '/autorizacoes' ? ' mobile-edit-agendadas' : ''} w-full h-12 border border-gray-600 pl-3`}
                    name="confirmacao_hora"
                    defaultValue={dadosModal?.confirmacao_hora ?? ""}
                    required={true}
                  />
                </InputMask>
              </div>
              <div className="mb-5 px-1">
                <h4 className="text-base font-bold mb-2">Hospital</h4>
                <SelectHospital
                  defaultValue={hospitaisOptions.find(
                    (hospital) => hospital.value === form.hospital_id
                  )}
                  name={"hospital_id"}
                  options={hospitaisOptions}
                  onChangePost={(value: any, label: any) =>
                    postForm({
                      target: {
                        name: "hospital_id",
                        label: label?.label,
                        value: value?.value,
                      },
                    })
                  }
                />
              </div>
              <div className="flex justify-center mb-2 pt-5">
                <button
                  type={"submit"}
                  className="btn-save-vendedor bg-blue text-lg text-white font-bold px-8 py-2 rounded-full shadow-md cursor-pointer hover:shadow-lg"
                >
                  Salvar
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="rounded-full cursor-pointer close-btn" onClick={toggle}>
          <AiFillCloseCircle
            size="3rem"
            className="text-blue rounded-full bg-white"
          />
        </div>
      </Fragment>
    </Popup>
  );
}
export default connector(ModalEditarAgendadas);
