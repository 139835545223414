import * as actionTypes from "../types";

const initialState: actionTypes.HospitaisState = {
  hospitais: [],
};

export default (
  state = initialState,
  { type, payload }: actionTypes.HospitaisAction
) => {
  switch (type) {
    case actionTypes.UPDATE_HOSPITAIS:
      return { ...state, ...payload };
    default:
      return state;
  }
};
