import React, { Fragment, useEffect, useState } from "react";
import "../ModalPerfil/style.css";
import Popup from "reactjs-popup";
import {
  AiFillCloseCircle,
  AiOutlineEye,
  AiOutlineEyeInvisible,
} from "react-icons/ai";
import { axiosApi } from "src/services/axiosInstances";
import { FiCamera } from "react-icons/fi";
import { FaUserCircle } from "react-icons/fa";
import { object, string } from "yup";
import { checkfield } from "../../helpers/functions";
import { AuthAction, AuthState, Usuario } from "src/store/types";
import { connect, ConnectedProps } from "react-redux";
import ModalSenhaAtual from "../ModalSenhaAtual";
import { jsonToFormData } from "src/helpers/functions";
import * as auth from "src/store/actions/auth";
import { Dispatch } from "redux";
import { toast } from "react-toastify";

const mapStateToProps = ({ auth }: { auth: AuthState }): AuthState => {
  return {
    usuario: auth.usuario,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AuthAction>) => {
  return {
    setUpdatePerfil: (usuario: Usuario) =>
      dispatch(auth.setUpdatePerfil(usuario)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
  open?: any;
  close?: any;
  submitClose?: any;
  onChange?: (event: any) => void;
  onBlur?: (event: any) => void;
  onOpenSenha?: () => void;
  onchangeImage?: (e: any) => void;
  setOpenModal?: any;
  openModal?: boolean;
  imageHook?: any;
  ShowpasswordClassName?: string;
  HidePasswordClassName?: string;
  imageClassName?: string;
  erroClassName?: string;
  inputClassName?: string;
  toggle?: any;
};

type StateForm = {
  id: string;
  razao_social?: string;
  fantasia?: string;
  whatsapp?: string;
  telefone?: string;
  email?: string;
  nome?: string;
  foto?: string;
  senha: string;
  confirm_senha: string;
};

const schema = object({
  razao_social: string().required(),
  fantasia: string().required(),
  whatsapp: string().required(),
  telefone: string().required(),
  email: string().required(),
  nome: string().required(),
  foto: string().required(),
  senha: string().required(),
  confirm_senha: string().required(),
});

function ModalPerfil({
  open,
  close,
  ShowpasswordClassName = "mt-2 z-30 text-gray-600 absolute right-0 mr-2",
  HidePasswordClassName = "mt-2 z-30 text-blue absolute right-0 mr-2",
  imageClassName = "img-profile-modal rounded-full border-solid border-white border-2 object-cover object-center",
  onBlur,
  onOpenSenha,
  usuario,
  setOpenModal,
  openModal,
  setUpdatePerfil,
  submitClose,
  erroClassName = "px-3 mt-2 capitalize border-2 border-red-500 bg-red-100 h-10",
  inputClassName = "px-3 mt-2 capitalize border-2 border-gray-400 h-10",
  toggle,
}: Props): JSX.Element {

  const [errorState, setErrorState] = React.useState(false);

  const modalWidth = {};
  const [dadosUsuario, setDadosUsuario] = useState<StateForm>({
    id: '1',
    senha: "",
    confirm_senha: "",
  });

  const loadDados = async () => {
    try {
      const { data } = await axiosApi.get<StateForm>(`/secretaria`);
      setDadosUsuario(data);
    } catch (error: any) {
      console.log(error);
    }
  };

  const loadDadosFornecedor = async () => {
    try {
      const { data } = await axiosApi.get<StateForm>(`/perfil-fornecedor`);
      
      setDadosUsuario(data);
    } catch (error: any) {
      console.log(error);
    }
  };

  const loadDadosVendedor = async () => {
    try {
      const { data } = await axiosApi.get<StateForm>(`/perfil-vendedor`);
      
      setDadosUsuario(data);
    } catch (error: any) {
      console.log(error);
    }
  };

  const loadDadosAdm = async () => {
    try {
      const { data } = await axiosApi.get<StateForm>(
        `/administrador/${usuario?.id}`
      );
      setDadosUsuario(data);
    } catch (error: any) {
      console.log(error);
    }
  };

  const loadDadosDoutor = async () => {
    try {
      const { data } = await axiosApi.get<StateForm>(`/doutor-perfil-web`);
      setDadosUsuario(data);
    } catch (error: any) {
      console.log(error);
    }
  };

  const loadDadosHospital = async () => {
    try {
      const { data } = await axiosApi.get<StateForm>(`/perfil-hospital`);
      setDadosUsuario(data);
    } catch (error: any) {
      console.log(error);
    }
  };

  const nivelCheck = usuario?.nivel === 3;
  const adminCheck = usuario?.nivel === 5;
  const doutorCheck = usuario?.nivel === 1;
  const fornecedorCheck = usuario?.nivel === 2;
  const hospitalCheck = usuario?.nivel === 6;
  const vendedorCheck = usuario?.nivel === 4;

  const [state, setstate] = useState({
    senha: false,
    confirm_senha: false,
  });

  const onBlurBefore = async (event: any, name?: string) => {
    let span: boolean = false;
    if (schema) {
      const { name, value } = event.target;
      span = checkfield(name, schema, { [name]: value });
      setstate({ ...state, [name]: !span });
    }

    if (onBlur) {
      onBlur(event);
    }
  };

  useEffect(() => {
    if (open) {
      if (adminCheck) {
        loadDadosAdm();
      } else {
        if (nivelCheck) {
          loadDados();
        } else if (doutorCheck) {
          loadDadosDoutor();
        } else if (hospitalCheck) {
          loadDadosHospital();
        } else if (vendedorCheck) {
          loadDadosVendedor();
        } else {
          loadDadosFornecedor();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, nivelCheck, adminCheck, doutorCheck, hospitalCheck]);

  const postForm = (event: any) => {
    const { name, value } = event.target;
    setDadosUsuario({
      ...dadosUsuario,
      [name]: value,
    });
  };

  const [image, setImage] = useState({ preview: "", raw: "" });

  const imageChange = (e: any) => {
    const { files } = e.target;
    if (files.length) {
      setImage({
        preview: URL.createObjectURL(files[0]),
        raw: files[0],
      });
      setDadosUsuario({
        ...dadosUsuario,
        foto: files[0],
      });
    }
  };

  const handleSubmit = async () => {
    const contentType = "multipart/form-data";
    let newData = dadosUsuario;
    delete newData['caminho'];
    const dataFormated = jsonToFormData(newData, "PUT");
    console.log(newData);
    const response = await axiosApi({
      method: "POST",
      headers: { "Content-Type": contentType },
      url: adminCheck
        ? `/administrador`
        : nivelCheck
        ? "/secretaria"
        : fornecedorCheck
        ? "/fornecedor"
        : doutorCheck
        ? "/perfil-doutor?web=true"
        : hospitalCheck
        ? "/hospital"
        : vendedorCheck
        ? "/vendedor"
        : "",
      data: dataFormated,
    });
    toast.success('Perfil Atualizado!', {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      });
    toggle();
    setUpdatePerfil(response.data.usuario);
    // localStorage.setItem("usuario", JSON.stringify(usuario));
  };

  const checkSenha = async (senha: any) => {
    const response = await axiosApi.post("/conferir-senha", senha);
    if (response.data.confirmado === true) {
      handleSubmit();
      togglePerfil();
    } else {
      toast.error('Senha não corresponde!', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
    }
  };

  const [senhaEye, setSenhaEye] = useState(false);
  const [confirmSenhaEye, setconfirmSenhaEye] = useState(false);
  const toggleSenha = () => {
    setSenhaEye(senhaEye ? false : true);
  };
  const toggleConfirmSenha = () => {
    setconfirmSenhaEye(confirmSenhaEye ? false : true);
  };

  const [openSenhaModal, setOpenSenhaModal] = useState(false);
  const togglePerfil = () => setOpenSenhaModal(!openSenhaModal);

  const senhaModalOpen = (): void => {
    if (dadosUsuario.senha === dadosUsuario.confirm_senha) {
      setOpenSenhaModal(true);
      errorState && setErrorState(false);
    } else {
      toast.error('Confirme sua Senha!', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
    }
    if (
      dadosUsuario.nome === "" ||
      dadosUsuario.razao_social === "" ||
      dadosUsuario.email === "" ||
      dadosUsuario.whatsapp === "" ||
      dadosUsuario.telefone === ""
    ) {
      setErrorState(true);
      setOpenSenhaModal(false);
      toast.warn('Esse campo deve ser preenchido!', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
    }
  };

  const resetModal = () => (
    // eslint-disable-next-line no-sequences
    setOpenModal(!openModal), setImage({ preview: "", raw: "" })
  );
  

  return (
    <Fragment>
      <ModalSenhaAtual
        open={openSenhaModal}
        toggle={togglePerfil}
        userName={
          adminCheck
            ? dadosUsuario?.nome
            : nivelCheck || doutorCheck || hospitalCheck
            ? dadosUsuario?.nome
            : dadosUsuario.razao_social
        }
        postSenha={postForm}
        checkSenha={checkSenha}
      />
      <Popup
        // onClose={open}
        open={open}
        closeOnDocumentClick={false}
        contentStyle={modalWidth}
        modal
        nested={true}
      >
        <Fragment>
          <div className="bg-white modal py-5 px-16">
            <div className="header pt-5 pb-3 text-4xl text-center font-bold">
              Meu Perfil
            </div>
            <div className="content grid justify-items-center">
              <form>
                <div className="flex justify-center py-2">
                  <div className="rounded-full">
                    {image.preview || usuario?.foto ? (
                      image.preview ? (
                        <img
                          src={image.preview}
                          className={imageClassName}
                          alt=""
                        />
                      ) : (
                        <img
                          src={usuario?.foto}
                          className={imageClassName}
                          alt=""
                        />
                      )
                    ) : (
                      <FaUserCircle size="150px" className="text-gray-400" />
                    )}
                  </div>
                  <label
                    htmlFor="fileButton"
                    className="file-btn-foto bg-blue border-4 border-white rounded-full mt-10 cursor-pointer"
                    style={{
                      marginLeft: "-35px",
                      width: "64px",
                      height: "64px",
                    }}
                  >
                    <FiCamera size="2rem" className="ml-3 mt-3 text-white" />
                  </label>

                  <input
                    name="foto"
                    id="fileButton"
                    type="file"
                    accept=".jpg, .png, .jpeg"
                    onChange={imageChange}
                    style={{ display: "none" }}
                  />
                </div>
                {adminCheck ? (
                  <Fragment>
                    <div className="input-vendedores mb-5">
                      <h4 className="text-md font-bold mb-2">
                        Nome do Administrador
                      </h4>
                      <input
                        type="text"
                        className="input-vendedores w-full h-10 border-2 border-gray-400 pl-3"
                        name={"nome"}
                        value={dadosUsuario?.nome}
                        onChange={postForm}
                        required
                      />
                    </div>
                    <div className="input-vendedores mb-5">
                      <h4 className="text-md font-bold mb-2">Email</h4>
                      <input
                        type="text"
                        className="input-vendedores w-full h-10 border-2 border-gray-400 pl-3"
                        name="email"
                        value={dadosUsuario?.email}
                        onChange={postForm}
                      />
                    </div>
                  </Fragment>
                ) : (
                  <Fragment>
                    <div className="input-vendedores mb-5">
                      <h4 className="text-md font-bold mb-2">
                        {nivelCheck || doutorCheck || hospitalCheck
                          ? "Nome"
                          : "Razão Social"}
                      </h4>
                      <input
                        type="text"
                        className={`input-vendedores w-full h-10 border-2 border-gray-400 pl-3${errorState && !dadosUsuario.razao_social ? ' error' : ''}`}
                        name={
                          nivelCheck || doutorCheck || hospitalCheck ? "nome" : "razao_social"
                        }
                        value={
                          nivelCheck || doutorCheck || hospitalCheck
                            ? dadosUsuario?.nome
                            : dadosUsuario?.razao_social
                        }
                        onChange={postForm}
                        required
                      />
                      {/* <FormError className={'modal-perfil'} state={errorState && !dadosUsuario.razao_social? true : false} absolute={true}>*Campo Obrigatório</FormError> */}
                    </div>
                    {nivelCheck || doutorCheck || hospitalCheck ? (
                      ""
                    ) : (
                      <div className="input-vendedores mb-5">
                        <h4 className="text-md font-bold mb-2">Fantasia</h4>
                        <input
                          type="text"
                          className={`input-vendedores w-full h-10 border-2 border-gray-400 pl-3${errorState && !dadosUsuario.fantasia ? ' error' : ''}`}
                          name="fantasia"
                          defaultValue={dadosUsuario?.fantasia}
                          onChange={postForm}
                        />
                        {/* <FormError className={'modal-perfil'} state={errorState && !dadosUsuario.fantasia? true : false} absolute={true}>*Campo Obrigatório</FormError> */}
                      </div>
                    )}
                    <div className="flex justify-between">
                      <div className="input-vendedores mb-5 mr-3">
                        <h4 className="text-md font-bold mb-2">Whatsapp</h4>
                        <input
                          type="text"
                          className={`input-vendedores w-full h-10 border-2 border-gray-400 pl-3${errorState && !dadosUsuario.whatsapp ? ' error' : ''}`}
                          name="whatsapp"
                          value={dadosUsuario?.whatsapp}
                          onChange={postForm}
                          required
                        />
                        {/* <FormError className={'modal-perfil'} state={errorState && !dadosUsuario.whatsapp? true : false} absolute={true}>*Telefone Obrigatório</FormError> */}
                      </div>
                      <div className="input-vendedores mb-5">
                        <h4 className="text-md font-bold mb-2">Telefone</h4>
                        <input
                          type="text"
                          className={`input-vendedores w-full h-10 border-2 border-gray-400 pl-3${errorState && !dadosUsuario.telefone ? ' error' : ''}`}
                          name="telefone"
                          value={dadosUsuario?.telefone}
                          onChange={postForm}
                        />
                        {/* <FormError className={'modal-perfil'} state={errorState && !dadosUsuario.telefone? true : false} absolute={true}>*Telefone Obrigatório</FormError> */}
                      </div>
                    </div>

                    <div className="input-vendedores mb-5">
                      <h4 className="text-md font-bold mb-2">Email</h4>
                      <input
                        type="text"
                        className={`input-vendedores w-full h-10 border-2 border-gray-400 pl-3${errorState && !dadosUsuario.email ? ' error' : ''}`}
                        name="email"
                        value={dadosUsuario?.email}
                        onChange={postForm}
                      />
                      {/* <FormError className={'modal-perfil'} state={errorState && !dadosUsuario.email? true : false} absolute={true}>*Email Obrigatório</FormError> */}
                    </div>
                  </Fragment>
                )}
                <div className="flex justify-between">
                  <div className="input-vendedores mb-5 mr-3">
                    <h4 className="text-md font-bold mb-2">Senha</h4>
                    <div className="relative">
                      <div className="flex justify-between relative">
                        <input
                          type={senhaEye ? "text" : "password"}
                          className={`input-vendedores pr-8 w-full h-10 border-2 border-gray-400 pl-3 ${
                            state.senha ? "form-input-sm" : "form-input-sm"
                          }`}
                          name="senha"
                          onChange={postForm}
                          onBlur={onBlurBefore}
                        />
                        {senhaEye === true ? (
                          <AiOutlineEyeInvisible
                            onClick={toggleSenha}
                            size="1.5rem"
                            className={HidePasswordClassName}
                          />
                        ) : (
                          <AiOutlineEye
                            onClick={toggleSenha}
                            size="1.5rem"
                            className={ShowpasswordClassName}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="input-vendedores mb-5">
                    <h4 className="text-md font-bold mb-2">Confirmar Senha</h4>
                    <div className="relative">
                      <div className="flex justify-between relative">
                        <input
                          type={confirmSenhaEye ? "text" : "password"}
                          className={`input-vendedores pr-8 w-full h-10 border-2 border-gray-400 pl-3 ${
                            dadosUsuario.senha !== dadosUsuario.confirm_senha
                              ? "erro-input-sm"
                              : "form-input-sm"
                          }`}
                          name="confirm_senha"
                          onChange={postForm}
                          onBlur={onBlurBefore}
                        />
                        {confirmSenhaEye === true ? (
                          <AiOutlineEyeInvisible
                            onClick={toggleConfirmSenha}
                            size="1.5rem"
                            className={HidePasswordClassName}
                          />
                        ) : (
                          <AiOutlineEye
                            onClick={toggleConfirmSenha}
                            size="1.5rem"
                            className={ShowpasswordClassName}
                          />
                        )}
                      </div>
                      {dadosUsuario.senha !== dadosUsuario.confirm_senha ? (
                        <span className="text-red-600 text-xs erro-span-2 absolute left-0 pt-3">
                          *Não corresponde a senha
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex justify-center mb-2 pt-5">
                  <div
                    className="btn-save-vendedor bg-blue text-lg text-white font-bold px-8 py-2 rounded-full shadow-md cursor-pointer hover:shadow-lg"
                    onClick={senhaModalOpen}
                  >
                    Salvar
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div
            className="rounded-full cursor-pointer close-btn"
            onClick={resetModal}
          >
            <AiFillCloseCircle
              size="3rem"
              className="text-blue rounded-full bg-white"
            />
          </div>
        </Fragment>
      </Popup>
    </Fragment>
  );
}

export default connector(ModalPerfil);
