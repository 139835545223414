import "./style.css";
import React, { Fragment, useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { AuthState } from "../../store/types";
import InputForm from "src/components/Inputs/inputsimple";
import InputFormCompound from "src/components/Inputs/inputCompound";
import InputFormRadio from "src/components/Inputs/InputRadio";
import InputFormFile from "src/components/Inputs/inputFile";
import ButtonFormSubmit from "src/components/Inputs/buttonSubmit";
import InputGuia from "../../components/Inputs/InputGuia";
import { FaAngleLeft } from "react-icons/fa";
import "react-toastify/dist/ReactToastify.css";
import { axiosApi } from "../../services/axiosInstances";
import InputMask from "react-input-mask";
import { Link } from "react-router-dom";
import Select from "react-select";
import { jsonToFormData, selectDoctor } from "src/helpers/functions";
import { object, string } from "yup";
import { checkfield, onlyNumberInput } from "../../helpers/functions";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import ModalConfirmar from "src/components/ModalConfirmarSolicitacao";
import ModalGuiaTemplate from "src/components/ModalGuiaTemplate";
import ModalAlertaSemAnexo from "src/components/ModalAlertaSemAnexo";

const mapStateToProps = ({ auth }: { auth: AuthState }) => {
  return {
    token: auth.token,
  };
};
const connector = connect(mapStateToProps, {});

type Doutor = {
  id: number;
  nome: string;
};

interface Option {
  value: string | number;
  label: string;
}

type HomeResponse = {
  doutores: Doutor[];
};

const schema = object({
  nome: string().required(),
  nascimento: string().required(),
  email: string().required(),
  cod_guia_internamento: string().required(),
  doutor_id: object().shape({
    label: string().required(),
    value: string().required(),
  }),
  telefone: string().required(),
  whatsapp: string().required(),
  data_pretendida: string().required(),
  guia_internamento_id: string().required(),
  guia_materiais_id: string().required(),
  resultados_exames_id: string().required(),
});

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux &
 {
    onChange?: (event: any) => void;
    onBlur?: (event: any) => void;
    isMultipart?: boolean;
    erroClassName?: string;
    inputClassName?: string;
  };

type Form = {
  data_pretendida: Array<string>;
  guia_materiais_id?: File;
  guia_internamento_id?: File;
};

function Paciente({
  token,
  onChange,
  onBlur,
  isMultipart = true,
  erroClassName = "px-3 mt-2 border-2 border-red-500 bg-red-100 h-10",
  inputClassName = "px-3 mt-2 border-2 border-gray-400 h-10",
}: Props): JSX.Element {
  const [form, setForm] = useState<Form>({
    data_pretendida: [],
    guia_materiais_id: undefined,
    guia_internamento_id: undefined,
  });

  const postForm = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    postEvent(event.target.name, event.target.value);
  };

  const postEvent = async (nome: string, valor: any) => {
    setForm({ ...form, [nome]: valor });
  };

  const [changeMask, setChangeMask] = useState<string>("");

  // const postForm = (event: any) => {
  //   const { name, value } = event?.target;
  //   setForm({
  //     ...form,
  //     [name]: value,
  //   });
  // };

  const [nomeDoutor, setNomeDoutor] = useState<Doutor[]>([]);

  useEffect(() => {
    const loadDados = async () => {
      try {
        const { data } = await axiosApi.get<HomeResponse>(`/listdoctor`);
        setNomeDoutor(data.doutores);
      } catch (error: any) {
        console.log(error);
      }
    };
    loadDados();
  }, []);

  const options: Array<Option> | undefined = nomeDoutor?.map(
    (doutor: Doutor): Option => {
      return {
        value: doutor.id,
        label: doutor.nome,
      };
    }
  );

  const [state, setstate] = useState({
    nascimento: false,
    nome: false,
    email: false,
    cod_guia_internamento: false,
    doutor_id: false,
    telefone: false,
    whatsapp: false,
    data_pretendida: false,
    guia_internamento_id: false,
    guia_materiais_id: false,
    resultados_exames_id: false,
  });

  const onBlurBefore = async (event: any, nome?: string) => {
    let span: boolean = false;
    if (nome) {
      if (nome === "data_pretendida") {
        span = form[nome].length !== 0;
        setstate({ ...state, [nome]: !span });
      } else {
        span = form[nome] ? true : false;
        setstate({ ...state, [nome]: !span });
      }
    } else {
      if (schema) {
        const { name, value } = event.target;
        span = checkfield(name, schema, { [name]: value });
        setstate({ ...state, [name]: !span });
      }

      if (onBlur) {
        onBlur(event);
      }
    }
    const { name, value } = event.target;
    if (name === "telefone" || name === "telefone_contato") {
      if (value.length === 10) {
        setChangeMask("(99)9999-9999");
      } else if (value.length === 11) {
        setChangeMask("(99)99999-9999");
      } else if (value.length === 0) {
        setChangeMask("");
      }
    }
  };

  const [loader, setLoader] = useState(false);

  const handleSubmit = async () => {
    setAbrirModaldeConfirmacao(false);
    try {
      setLoader(true);
      const contentType = "multipart/form-data;";
      const dataFormated = jsonToFormData(form, "POST");
      const cirurgia = await axiosApi({
        method: "POST",
        headers: { "Content-Type": contentType },
        url: "/cirurgia",
        data: dataFormated,
      });

      toast.success('Cirurgia Cadastrada com sucesso!', {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      window.setTimeout(() => {
        window.location.replace("https://cirurgiaautorizada.com.br/step/experiencia-cirurgia-premium?cirurgiaId=" + cirurgia.data.id);
      }, 0);
    } catch (error: any) {
      if (error.response.data.errorInfo === "23505") {
        toast.error('Número da Guia já existe!', {
          position: "top-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.error(error.response.data.errorInfo, {
          position: "top-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } finally {
      setLoader(false);
    }
  };

  const [toggleContato, setToggleContato] = useState(false);
  const contatoEnable = () => setToggleContato(true);
  const contatoDisable = () => setToggleContato(false);

  const [toggleAlergia, setToggleAlergia] = useState(false);
  const alergiaEnable = () => setToggleAlergia(true);
  const alergiaDisable = () => setToggleAlergia(false);

  const [guiaMateriaisShow, setGuiaMateriaisShow] = useState(false);
  const materiaisEnable = () => setGuiaMateriaisShow(true);
  const materiaisDisable = () => setGuiaMateriaisShow(false);

  const [abrirModaldeConfirmacao, setAbrirModaldeConfirmacao] = useState(false);
  const [abrirModalAlertaSemAnexo, setabrirModalAlertaSemAnexo] = useState(false);
  const [modalTemplate, setModalTemplate] = useState(false);

  const dadosModalTemplate = () => {
    setModalTemplate(true);
  };

  // console.log(document?.getElementsByName("guia_materiais_id")[0]["value"]);

  return (
    <Fragment>
      <ModalConfirmar
        open={abrirModaldeConfirmacao}
        toggle={() => setAbrirModaldeConfirmacao(!abrirModaldeConfirmacao)}
        isOnClick={true}
        onClick={handleSubmit}
      />
      <ModalAlertaSemAnexo
        open={abrirModalAlertaSemAnexo}
        toggle={() => setabrirModalAlertaSemAnexo(!abrirModalAlertaSemAnexo)}
        isOnClick={true}
        onClick={handleSubmit}
      />
      <ModalGuiaTemplate
        open={modalTemplate}
        close={() => setModalTemplate(false)}
      />
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover={false}
      />

      <h1 className="form-title flex py-5">
        <Link title="Voltar" to="/">
          <FaAngleLeft className="mr-3" size="40px" />
        </Link>
        Olá Paciente!
      </h1>
      <p className="form-subtitle patient-solicitation px-5 py-1">
        Precisamos de mais algumas informações para podermos te ajudar.
      </p>

      <form
        onSubmit={(event) => {
          event.preventDefault();
          if (
            form.guia_internamento_id === undefined ||
            form.guia_internamento_id === null
          ) {
            setabrirModalAlertaSemAnexo(true);
          } else if (
            form.guia_materiais_id === undefined ||
            form.guia_materiais_id === null
          ) {
            setAbrirModaldeConfirmacao(true);
          } else {
            handleSubmit();
          }
        }}
        className="relative"
      >
        <InputForm
          schema={schema}
          name="nome"
          label="Nome Completo*"
          type="text"
          onChange={postForm}
          // pattern="[A-Za-z]"
          title="Não é permitido números nesse campo!"
          onBlur={onBlurBefore}
          inputClassName={
            state.nome
              ? `${erroClassName} w-full capitalize`
              : `${inputClassName} w-full capitalize`
          }
          required={true}
          maxlength={50}
        />
        {state.nome && (
          <span className="text-red-600 text-xs px-5 py-0">
            *Coloque seu nome
          </span>
        )}

        <InputMask mask="99/99/9999" onChange={postForm} onBlur={onBlurBefore}>
          <InputForm
            schema={schema}
            name="nascimento"
            label="Data de Nascimento*"
            type="text"
            inputClassName={
              state.nascimento
                ? `${erroClassName} w-48`
                : `${inputClassName} w-48`
            }
            required={true}
            maxlength={20}
          />
        </InputMask>
        {state.nascimento && (
          <span className="text-red-600 text-xs px-5">
            *Coloque sua data de nascimento
          </span>
        )}

        <div className="px-5 my-5 flex justify-between">
          <label className="text-gray-800 font-bold text-sm xl:text-lg md:text-base">
            Sexo
          </label>
          <div className="flex space-x-2">
            <InputFormRadio
              name="sexo"
              label="Feminino"
              id="radio_fem"
              value="Feminino"
              htmlFor="radio_fem"
              onChange={postForm}
            />

            <InputFormRadio
              name="sexo"
              label="Masculino"
              id="radio_masc"
              value="Masculino"
              htmlFor="radio_masc"
              onChange={postForm}
            />

            <InputFormRadio
              name="sexo"
              label="Não Informado"
              id="radio_n_info"
              value="Nao Informado"
              htmlFor="radio_n_info"
              onChange={postForm}
            />
          </div>
        </div>

        <div className="flex justify-start w-full">
          <div className="relative">
            <InputMask
              mask={changeMask}
              onChange={postForm}
              onBlur={onBlurBefore}
              onKeyPress={onlyNumberInput}
            >
              <InputFormCompound
                id="telefone"
                schema={schema}
                name="telefone"
                label="Telefone"
                maxlength={11}
                type="text"
                required={false}
              />
            </InputMask>
          </div>

          <div className="relative">
            <InputMask
              mask="(99) 99999-9999"
              onChange={postForm}
              onBlur={onBlurBefore}
            >
              <InputFormCompound
                schema={schema}
                name="whatsapp"
                label="Whatsapp*"
                type="text"
                inputClassName={
                  state.whatsapp
                    ? `${erroClassName} w-full`
                    : `${inputClassName} w-full`
                }
                required={true}
              />
            </InputMask>
            {state.whatsapp && (
              <span className="text-red-600 text-xs erro-span-2">
                *Coloque seu whatsapp
              </span>
            )}
          </div>
        </div>

        <InputForm
          schema={schema}
          onBlur={onBlurBefore}
          name="email"
          label="Email*"
          type="email"
          onChange={postForm}
          required={true}
          inputClassName={
            state.email ? `${erroClassName} w-full` : `${inputClassName} w-full`
          }
          maxlength={50}
        />
        {state.email && (
          <span className="text-red-600 text-xs px-5">*Coloque seu email</span>
        )}

        <div className="px-5 my-5 flex justify-between">
          <label
            className="text-gray-800 font-bold text-sm xl:text-lg md:text-base"
            htmlFor=""
          >
            Necessita responsável?
          </label>
          <div className="flex space-x-2 mr-4 mb-2">
            <div onClick={contatoEnable}>
              <InputFormRadio
                name="contato"
                label="Sim"
                id="ct_sim"
                value="1"
                htmlFor="ct_sim"
                checked={toggleAlergia}
              />
            </div>

            <div onClick={contatoDisable}>
              <InputFormRadio
                name="contato"
                label="Não"
                id="ct_nao"
                value="0"
                htmlFor="ct_nao"
                checked={true}
              />
            </div>
          </div>
        </div>

        {toggleContato && (
          <div className="border-2 border-dashed border-gray-300 rounded-lg py-2">
            <InputForm
              name="nome_contato"
              label="Nome Pessoa de Contato"
              type="text"
              onChange={postForm}
              labelClassName={
                "text-gray-800 font-bold text-sm xl:text-md md:text-sm"
              }
              inputClassName={
                "px-3 mt-2 capitalize border-2 border-gray-300 w-full h-8"
              }
              maxlength={50}
            />

            <div className="flex justify-start w-full">
              <InputFormCompound
                name="parentesco_contato"
                label="Grau de Parentesco"
                type="text"
                labelClassName={
                  "text-gray-800 font-bold text-sm xl:text-md md:text-sm"
                }
                inputClassName={
                  "px-3 mt-2 capitalize border-2 border-gray-300 w-full h-8"
                }
                onChange={postForm}
              />

              <InputMask
                mask={changeMask}
                onChange={postForm}
                onBlur={onBlurBefore}
                onKeyPress={onlyNumberInput}
              >
                <InputFormCompound
                  name="telefone_contato"
                  label="Telefone do Contato"
                  type="text"
                  maxlength={11}
                  labelClassName={
                    "text-gray-800 font-bold text-sm xl:text-md md:text-sm"
                  }
                  inputClassName={
                    "px-3 mt-2 capitalize border-2 border-gray-300 w-full h-8"
                  }
                />
              </InputMask>
            </div>
          </div>
        )}

        <div className="px-5 py-4">
          <label
            className="text-gray-800 font-bold text-sm xl:text-lg md:text-base"
            htmlFor=""
          >
            Nome do Cirurgião*
          </label>
          <div className="grid grid-cols-8">
            <div className="col-span-1">
              <span className="font-bold text-base text-gray-500 mt-4 absolute">
                Dr(a)
              </span>
            </div>
            <div className="col-span-7">
              <Select
                onBlur={(event: any) => onBlurBefore(event, "doutor_id")}
                name="doutor_id"
                className={
                  state.doutor_id
                    ? "mt-2 capitalize border-2 border-red-500 bg-red-100 w-full h-10 input-border-radius"
                    : "mt-2 capitalize border-2 border-gray-400 w-full h-10 input-border-radius"
                }
                placeholder="Selecione..."
                onChange={(value: any) => postEvent("doutor_id", value?.value)}
                options={options}
                styles={selectDoctor}
                required={true}
              />
            </div>
          </div>

          {state.doutor_id && (
            <span className="text-red-600 text-xs px-5 absolute right-0">
              *Escolha o médico
            </span>
          )}
        </div>

        <div className="px-5 my-5 flex justify-between">
          <label
            className="text-gray-800 font-bold text-sm xl:text-lg md:text-base"
            htmlFor=""
          >
            Pré-Operatório Solicitado?*
          </label>
          <div className="flex space-x-2 mr-4 mb-4">
            <InputFormRadio
              name="pre_operatorio"
              label="Sim"
              id="po_sim"
              value="1"
              htmlFor="po_sim"
              onChange={postForm}
            />

            <InputFormRadio
              name="pre_operatorio"
              label="Não"
              id="po_nao"
              value="0"
              htmlFor="po_nao"
              onChange={postForm}
            />
          </div>
        </div>

        <div className="px-5 my-5 flex justify-between">
          <label
            className="text-gray-800 font-bold text-sm xl:text-lg md:text-base"
            htmlFor=""
          >
            Possui Alergias?*
          </label>
          <div className="flex space-x-2 mr-4 mb-2">
            <div onClick={alergiaEnable}>
              <InputFormRadio
                name="alergia"
                label="Sim"
                id="pa_sim"
                value="1"
                htmlFor="pa_sim"
                // onChange={postForm}
                checked={toggleAlergia}
              />
            </div>

            <div onClick={alergiaDisable}>
              <InputFormRadio
                name="alergia"
                label="Não"
                id="pa_nao"
                value="0"
                htmlFor="pa_nao"
                // onChange={postForm}
                checked={true}
              />
            </div>
          </div>
        </div>
        {toggleAlergia && (
          <div className="px-5 mb-2">
            <p className="mb-2 font-bold text-gray-500 text-sm">
              Qual Alergia você possui?
            </p>
            <div className="border-2 border-dashed border-gray-300 rounded-lg py-2 px-2">
              <textarea
                className="report-text-area w-full rounded-md border-0 border-gray-400 p-3 focus:outline-none"
                style={{ resize: "none", height: "150px" }}
                placeholder="Especifique aqui..."
                required={true}
                name="alergia"
                onChange={postForm}
              ></textarea>
            </div>
          </div>
        )}

        <h1 className="px-5 mb-3 anx-title">Anexos</h1>

        <InputGuia
          schema={schema}
          name="cod_guia_internamento"
          label="Nº Senha da Guia de Internamento*"
          type="number"
          required={true}
          inputClassName={
            state.cod_guia_internamento
              ? `${erroClassName} w-full`
              : `${inputClassName} w-full`
          }
          onChange={postForm}
          onBlur={onBlurBefore}
        />

        {state.cod_guia_internamento && (
          <span className="text-red-600 text-xs px-5 ">*Coloque sua guia</span>
        )}

        <InputFormFile
          schema={schema}
          name="guia_internamento_id"
          label="Guia de Internamento*"
          postEvent={postEvent}
          required={false}
          placeholderFile="Anexe Guia de Internamento"
        />

        <div className="px-5 my-5 flex justify-between">
          <label
            className="text-gray-800 font-bold text-sm xl:text-lg md:text-base"
            htmlFor=""
          >
            Possui Guia de Materiais?
          </label>
          <div className="flex space-x-2 mr-4 mb-4">
            <div onClick={materiaisEnable}>
              <InputFormRadio
                name="g_materiais"
                label="Sim"
                id="gm_sim"
                value="1"
                htmlFor="gm_sim"
                checked={guiaMateriaisShow}
              />
            </div>

            <div onClick={materiaisDisable}>
              <InputFormRadio
                name="g_materiais"
                label="Não"
                id="gm_nao"
                value="0"
                htmlFor="gm_nao"
                checked={true}
              />
            </div>
          </div>
        </div>

        {guiaMateriaisShow && (
          <Fragment>
            <div className="grid grid-cols-12 border-2 border-dashed border-gray-300 rounded-lg pb-5 px-2">
              <div className="col-span-11">
                <InputFormFile
                  schema={schema}
                  name="guia_materiais_id"
                  postEvent={postEvent}
                  required={false}
                  placeholderFile="Anexe Guia de Materiais"
                />
              </div>
              <div className="col-span-1">
                <span
                  className="mt-6 absolute cursor-pointer"
                  onClick={dadosModalTemplate}
                >
                  <AiOutlineExclamationCircle
                    size="2.3rem"
                    className="text-blue"
                  />
                </span>
              </div>
            </div>
          </Fragment>
        )}

        <InputFormFile
          schema={schema}
          name="resultados_exames_id"
          label="Resultado de Exames"
          postEvent={postEvent}
          required={false}
          placeholderFile="Anexe Resultado de Exames"
        />
        <ButtonFormSubmit loader={loader} name="btn-submit" type="button" />
      </form>
    </Fragment>
  );
}

export default connector(Paciente);
