import "./style.css";
import React from "react";
import { FiEdit } from "react-icons/fi";
import { connect, ConnectedProps } from "react-redux";
import { AuthState } from "src/store/types";
import { setDoutoresAdmin } from "src/store/actions/doutores";
import { setSecretarias } from "src/store/actions/secretarias";

const mapStateToProps = ({ auth }: { auth: AuthState }): AuthState => {
  return {
    usuario: auth.usuario,
  };
};
const mapDispatchToProps = {
  setDoutoresAdmin,
  setSecretarias,
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
  id?: any;
  id_selecionado?: any;
  campo_1?: string;
  campo_2?: string;
  campo_3?: string;
  campo_4?: string;
  campo_5?: string;
  status?: string;
  status_id?: number;
  onClick?: (id: number) => void;
  // onDelete?: (id: number, nome: any) => void;
  onChangeVendedor?: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void | undefined;
  usuarioId?: any;
};

function Table({
  id,
  campo_1,
  campo_3,
  campo_2,
  campo_5,
  campo_4,
  status,
  status_id,
  onClick,
  // onDelete,
  onChangeVendedor,
  usuario,
  usuarioId,
  id_selecionado,
}: Props): JSX.Element {
  const statusColor =
    usuarioId === null
      ? "Incompleto"
      : status_id === 7
      ? "Completo"
      : "Inativo";

  const statusHover =
    usuarioId === null
      ? "text-yellow-500"
      : status_id === 7
      ? "text-green-500"
      : "text-gray-400";

  const secretariaCheck = id_selecionado === "3";

  return (
    <tbody>
      <tr className={`text-center cursor-default hover:${statusHover}`}>
        <td className="py-0">
          <div title={statusColor} className="flex justify-end">
            <span className={`w-5 h-5 ${statusColor} rounded-full`}></span>
          </div>
        </td>
        <td>
          <div title={campo_1} className={"truncate text-left pl-12 "}>
            {campo_1}
          </div>
        </td>
        <td>
          <div title={campo_2} className={"truncate"}>
            {campo_2}
          </div>
        </td>
        <td>
          <div title={campo_3} className={"truncate"}>
            {campo_3}
          </div>
        </td>
        <td>
          <div title={campo_4} className={"truncate"}>
            {campo_4}
          </div>
        </td>

        {secretariaCheck ? (
          ""
        ) : (
          <td>
            <div title={campo_5} className={"truncate"}>
              {campo_5}
            </div>
          </td>
        )}

        <td>
          <div title={status} className={"truncate"}>
            {status}
          </div>
        </td>
        <td>
          <div className="flex justify-center space-x-2">
            <button
              onClick={() => (onClick ? onClick(id) : undefined)}
              className="btn-vend bg-blue p-2 rounded-md"
            >
              <FiEdit size="1.3rem" className="text-white" />
            </button>
          </div>
        </td>
      </tr>
    </tbody>
  );
}

export default connector(Table);
