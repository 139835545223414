import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import LayoutSolicitacao from "./LayoutSolicitacao";


const SolicitarCirurgia: React.FC = () => {

  const [index, setIndex] = useState(0)

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [index]);


  return (
    <>
      <Routes>
        <Route path='/' element={<LayoutSolicitacao setIndex={setIndex} index={index} />} />
      </Routes>
    </>
  )
}


export default SolicitarCirurgia;