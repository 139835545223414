import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import VendedoresLista from 'src/components/CardVendedoresLista';
import MobileNewSeller from 'src/components/MobileNewSeller';
import { axiosApi } from 'src/services/axiosInstances';
import { setUpdateTableVendedores } from 'src/store/actions/vendedores';
import { Vendedor, VendedoresState } from 'src/store/types';
import './style.css';

const INITIAL_CARD_DATA = {
  idVendedor: -1,
  nome: '',
  whatsapp: '',
  email: '',
}

const mapStateToProps = ({
  vendedores,
}: {
  vendedores: VendedoresState;
}): VendedoresState => {
  return {
    vendedores: vendedores.vendedores,
  };
};

const mapDispatchToProps = {
  setUpdateTableVendedores,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux;

type HomeResponse = {
  vendedores: Array<Vendedor>;
};

export interface ICardData {
  idVendedor: number;
  nome: string;
  whatsapp: string;
  email: string;
}

const MobileAuxiliaries = ({
  vendedores,
  setUpdateTableVendedores
}: Props) => {
  const [newSeller, setNewSeller] = React.useState(-1);
  const [confirmModal, setConfirmModal] = React.useState(-1);
  const [errorState, setErrorState] = React.useState(false);
  const [cardData, setCardData] = React.useState<ICardData>(INITIAL_CARD_DATA);

  const loadDados = async () => {
    try {
      const { data } = await axiosApi.get<HomeResponse>(`/vendedores`);
      setUpdateTableVendedores(data.vendedores);
    } catch (error: any) {
      console.log(error);
    }
  };
  
  React.useEffect(() => {
    loadDados();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  // const dadosVendedor = (
  //   id: number,
  //   nome: string,
  //   whatsapp: string,
  //   email: string
  //   ): void => {
  //     setCardData(old => old = {...old, idVendedor: id});
  //     setCardData(old => old = {...old, nome: nome});
  //     setCardData(old => old = {...old, whatsapp: whatsapp});
  //     setCardData(old => old = {...old, email: email});
  //   };
  const deleteVendedorAction = async () => {
    await axiosApi.delete(`/vendedor/${cardData.idVendedor}`);
    loadDados();
  };
    
  const deleteVendedor = (id: number, nome: string): void => {
    setCardData(old => old = {...old, idVendedor: id, nome: nome});
  };
  
  const editVendedor = (id: number, nome: string, email: string, whatsapp: string) => { 
    setCardData({
      idVendedor: id,
      nome: nome,
      whatsapp: whatsapp,
      email: email,
    })
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (cardData.nome === '') {
      setErrorState(true);
      return;
    }
    if (String(cardData.email).length < 13) {
      setErrorState(true);
      return;
    }
    if (String(cardData.whatsapp).length < 11) {
      setErrorState(true);
      return;
    }
    cardData.idVendedor !== -1
      ? await axiosApi.put(`/vendedor/${cardData.idVendedor}`, {...cardData, idVendedor: null})
      : await axiosApi.post(`/vendedor`, {...cardData, idVendedor: null});
    loadDados();
    setNewSeller(0);
  }

  return (
    <>
      <div className={`mobile-auxiliaries-wrapper${newSeller === 1 || confirmModal === 1 ? ' fixed' : ''}`}>
        <div className='mobile-auxiliaries-sellers-container'>
          <div className='mobile-auxiliaries-sellers-top-container'>
            <h4 className='mobile-auxiliaries-sellers-top-title'>Vendedores</h4>
            <div onClick={() =>{setNewSeller(1);setCardData(INITIAL_CARD_DATA)}} className='mobile-auxiliaries-sellers-new-button'>Novo</div>
          </div>
          <div className='mobile-auxiliaries-sellers-list'>
            {
              vendedores?.length !== 0 && String(vendedores) !== 'undefined' ?
              vendedores?.map?.((vendedor: Vendedor) => {
                return (
                  <VendedoresLista
                    onDelete={() => {setConfirmModal(1);deleteVendedor(vendedor.id, vendedor.nome)}}
                    onClick={() => {setNewSeller(1);editVendedor(vendedor.id, vendedor.nome, vendedor.usuario.email, vendedor.whatsapp)}}
                    key={vendedor.id}
                    id={vendedor.id}
                    nome={vendedor.nome}
                    whatsapp={vendedor.whatsapp}
                    usuario={vendedor.usuario}
                  />
                );
              }) 
              : <span className='mobile-auxiliaries-sellers-empty-list'>Sem vendedores adicionados.</span>
            }
          </div>
        </div>
      </div>
      <MobileNewSeller error={errorState} setError={setErrorState} onSubmit={(e) => {handleSubmit(e)}} setState={setCardData} state={cardData} showState={newSeller} setShowState={setNewSeller}/>
      <div className={`mobile-confirm-modal-wrapper${confirmModal === -1 ? ' none' : confirmModal === 1 ? ' show' : ' hide'}`}>
        <div onClick={() => {setConfirmModal(0)}} className={`mobile-confirm-modal-blur-mask${confirmModal === -1 ? '': confirmModal === 1 ? ' show' : ' hide'}`}/>
        <div className={`mobile-confirm-modal-container${confirmModal === -1 ? '' : confirmModal === 1 ? ' show' : ' hide'}`}>
          <h3 className='mobile-confirm-modal-title'>Tem certeza que deseja mesmo excluir o vendedor {cardData.nome}?</h3>
          <div className='mobile-confirm-modal-row-button'>
            <button onClick={() => {setConfirmModal(0)}} className='mobile-confirm-modal-button-cancel'>Não</button>
            <button onClick={() => {setConfirmModal(0);deleteVendedorAction()}} className='mobile-confirm-modal-button-confirm'>Sim</button>
          </div>
        </div>
      </div>
    </>
  );
}

export default connector(MobileAuxiliaries);
