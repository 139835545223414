import React from "react";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { ObjectSchema } from "yup";

type Props = {
  label: string;
  name: string;
  labelClassName?: string;
  wrapClassName?: string;
  inputClassName?: string;
  buttonClassName?: string;
  dropdownClassName?: string;
  required?: boolean;
  type: string;
  schema?: ObjectSchema<any>;
  onChange?: (event: any) => void;
  onBlur?: (event: any) => void;
};

export default function InputGuia({
  label,
  name,
  type,
  required,
  labelClassName = "lb-input",
  wrapClassName = "px-5",
  dropdownClassName = "hidden origin-top-right absolute right-0 mt-0 w-64 rounded-md shadow-lg dropdown-content text-left z-50",
  inputClassName = "px-3 mt-2 capitalize border-2 border-gray-400 w-full h-10",
  buttonClassName = "btn-dropdown",
  schema,
  onChange,
  onBlur,
}: Props) {
  return (
    <div className={wrapClassName}>
      <label className={labelClassName} htmlFor="">
        {label}
      </label>
      <p></p>
      <div className="relative">
        <input
          className={inputClassName}
          type={type}
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          required={required}
          autoComplete="off"
          style={{ borderRadius: "16px" }}
        />
        <div
          className={`absolute ${buttonClassName} right-0 mr-2`}
          style={{ marginTop: "-35px" }}
        >
          <AiOutlineExclamationCircle size="30px" className="text-blue" />

          <div className={dropdownClassName}>
            <div
              className="p-4 rounded-md bg-white shadow-xs w-full"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="user-menu"
            >
              <p className="text-xs font-bold text-blue cursor-default mr-1 break-normal">
                Localizado na Guia de Internamento.{" "}
                <span className="text-black font-light">
                  Geralmente localizado do lado do campo de Data de Autorização
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
