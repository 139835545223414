import React from 'react';
import './style.css';

interface IMobileAuthorizationCard {
  doctor: string;
  surgery: string;
  onClick: () => void;
  status: string;
}

const MobileAuthorizationCard: React.FC<IMobileAuthorizationCard> = ({
  doctor,
  surgery,
  onClick,
  status,
}) => {
  return (
    <div onClick={onClick} className='mobile-authorization-card-container'>
      <div className='mobile-authorization-card-left-container'>
        <strong className='mobile-authorization-card-title'>{doctor}</strong>
        <span className='mobile-authorization-card-subtitle'>{surgery}</span>
      </div>
      <div className={`mobile-authorization-card-status-${status? `${status}`:''}`}/>
    </div>
  );
}

export default MobileAuthorizationCard;
