import React from 'react';
import './style.css'
import archive from '../../assets/MobileAuthorizations/Archive.svg';
import { connect, ConnectedProps } from 'react-redux';
import { setUpdateChecklist } from 'src/store/actions/checklist';
import { AuthState, ChecklistState } from 'src/store/types';
import { axiosApi } from 'src/services/axiosInstances';
import { ListaMateriais } from '../FornecedorPostModal';
import { Line } from 'rc-progress';
import AccordionEspecificacoes from '../AccordionEspecificacoes';
import AccordionAgendadas from '../AccordionAgendadas';
import { AccordionPosCirurgia } from '../AccordionPosCirurgia';
import AccordionAgendamento from '../AccordionAgendamento';
import AccordionDisabled from '../AccordionDisabled';
import ModalEditarAgendadas from '../ModalEditarAgendadas';
// import { CgTrashEmpty } from 'react-icons/cg'
const mapStateToProps = ({
  auth,
  cirurgias,
}: {
  auth: AuthState;
  cirurgias: ChecklistState;
}): AuthState & ChecklistState => {
  return {
    usuario: auth.usuario,
    cirurgias: cirurgias.cirurgias,
  };
};
const mapDispatchToProps = {
  setUpdateChecklist,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
  status?: string;
  closeAction: () => void;
  idCirurgia?: number;
  updateData: () => Promise<void>;
  updateTable?: (id: number, dados: object) => void
};

export interface CheckList {
  id: number;
  status: Status;
  hospital: Hospital;
  doutor: Doutor;
  nome: string;
  cirurgia: string;
  materiais: Array<Material>;
  datas: Array<Datas>;
  confirmacao_cirurgia: boolean;
  nascimento: string;
  vencimento: string;
  data_inicial: string;
  data_final: string;
  sexo: string;
  telefone: string;
  whatsapp: string;
  email: string;
  nome_contato: string;
  parentesco_contato: string;
  telefone_contato: string;
  pre_operatorio: boolean;
  alergia: string;
  confirmacao_hora: string;
  confirmacao_data: string;
  guia_internamento: Anexos;
  guia_materiais: Anexos;
  resultados_exames: Anexos | null;
};

interface Anexos {
  id: number;
  nome: string;
  url: string;
  tipo_mime: string;
};

interface Status {
  id: number;
  descricao: string;
};

interface Hospital {
  id: number;
  nome: string;
};

interface Doutor {
  id: number;
  nome: string;
};

interface Material {
  id: number;
  checklist_id: number;
  fornecedor_id: number;
  descricao: string;
  reportar_problema: string;
  confirmado: boolean;
  fornecedor: Fornecedor;
};

interface Fornecedor {
  id: number;
  razao_social: string;
  usuario_id: number;
};

interface Report {
  id: number;
  fornecedor_id: number;
  descricao: string;
  reportar_problema: string;
};

interface Datas {
  cirurgia_id: number;
  data: string;
};

const MobileAuthorizationModal: React.FC<Props> = ({
  idCirurgia,
  closeAction,
  status,
  updateData,
  updateTable,
  usuario,
}) => {

  const [dadosModal, setDadosModal] = React.useState<ListaMateriais>();
  const [actualData, setActualData] = React.useState<CheckList>();
  const [report, setReport] = React.useState<Report>();
  const [reportField, setReportField] = React.useState<string>('');

  const [authSubModal, setAuthSubModal] = React.useState(-1);
  const [excludeModal, setExcludeModal] = React.useState(-1);
  const [showChecklist, setShowChecklist] = React.useState(false);
  const [activeAccordion, setActiveAccordion] = React.useState("0");
  const [openEditar, setOpenEditar] = React.useState(false);

  const nivelCheck = usuario?.nivel === 1 || usuario?.nivel === 3;
  const adminCheck = usuario?.nivel === 5;

  // const [getReportar, setGetReportar] = React.useState<string>('');

  const material = actualData?.materiais?.find(
    (cirurgia) => cirurgia.checklist_id === idCirurgia
  );

  const loadDados = async () => {
    if (idCirurgia! > 0) {
      const { data } = await axiosApi.get<CheckList>(
        `/checklist/${idCirurgia}`
      );
      setActualData(data);
    }
  };


  const loadDadosMaterials = async () => {
    try {
      const { data } = await axiosApi.get<ListaMateriais>(
        `/list-material/${idCirurgia}`
      );
      setDadosModal(data);
      // setGetReportar(data.reportar_problema);
    } catch (error: any) {
      console.log(error);
    }
  };

  const loadDadosReport = async () => {
    try {
      const { data } = await axiosApi.get<Report>(
        `/list-material/${idCirurgia}`
      );
      setReport(data);
    } catch (error: any) {
      console.log(error);
    }
  };

  const postReportar = async () => {
    const response = await axiosApi.post(`report/${dadosModal?.id}`, {
      reportar_problema: reportField,
    });

    if (updateTable) {
      const id: number = dadosModal?.id ?? 0;
      const objeto = {
        material: response.data.material,
      };
      updateTable(id, objeto);
    }
    loadDados();
    loadDadosMaterials();
    loadDadosReport();
    updateData();
  };

  const excluirGuia = async () => {
    await axiosApi.delete(`cirurgia/${idCirurgia}`);
    handleExcludeModal();
    closeAction();
    return;
  };

  const toggleActiveAccordion = (id: string): void => {
    id = id === activeAccordion ? "0" : id;
    setActiveAccordion(id);
  };

  const confirm_data: any = actualData?.confirmacao_data
    ?.split("-")
    .reverse()
    .join("/");

  const handleExcludeModal = () => {
    excludeModal === 1 ? setExcludeModal(-1) : setExcludeModal(1);
  }

  const handleShowChecklist = () => {
    setShowChecklist(old => !old);
  }

  const handleOpenEditar = () => {
    setOpenEditar(old => !old);
  };


  1 < 0 && console.log(postReportar, setDadosModal);

  React.useEffect(() => {
    if (idCirurgia) {
      loadDadosReport();
    }
    loadDadosMaterials();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idCirurgia]);

  React.useEffect(() => {
    setTimeout(() => {
      loadDados();
    }, 200)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idCirurgia]);

  React.useEffect(() => {
    if (authSubModal === 0) {
      setTimeout(() => { setAuthSubModal(-1) }, 400);
    }
  }, [authSubModal, setAuthSubModal])

  return (
    <>
      <div className={`mobile-exclude-guide-wrapper${excludeModal === -1 ? ' none' : ''}`}>
        <div onClick={handleExcludeModal} className='mobile-exclude-guide-blank-area' />
        <div className='mobile-exclude-guide-container'>
          <span className='mobile-exclude-guide-title'>Tem certeza que deseja {actualData?.status.descricao === 'Agendadas' ? 'desmarcar' : 'excluir'} esta guia?</span>
          <div className='mobile-exclude-guide-button-row'>
            <button onClick={handleExcludeModal} className='mobile-exclude-guide-cancel-button'>Cancelar</button>
            <button onClick={() => { excluirGuia() }} className='mobile-exclude-guide-exclude-button'>Sim</button>
          </div>
        </div>
      </div>
      <div className={`mobile-checklist-wrapper${showChecklist ? ' show' : ''}`}>
        <div onClick={handleShowChecklist} className='mobile-checklist-blur-mask' />
        <div className='mobile-checklist-container'>
          <div onClick={handleShowChecklist} className='mobile-checklist-cross-container'>
            <div className='mobile-checklist-cross-bar-left' />
            <div className='mobile-checklist-cross-bar-right' />
          </div>
          <h3 className='mobile-checklist-title'>Checklist</h3>
          <div>
            <h3 className="mobile-checklist-percent-info flex justify-end pt-2 text-xs font-semibold">
              {
                actualData?.status.descricao === 'Concluída' ? '100%' :
                  actualData?.status.descricao === 'Autorizada' ? '0% Preenchido' :
                    actualData?.status.descricao === 'Pendentes' ? '50% Preenchido' : '100%'
              }
            </h3>
            <Line
              className="mobile-progress-line pt-0 pb-5"
              percent={
                actualData?.status.descricao === 'Concluída' ? 100 :
                  actualData?.status.descricao === 'Autorizada' ? 0 :
                    actualData?.status.descricao === 'Pendentes' ? 50 : 100
              }
              strokeWidth={1}
              strokeColor="var(--primary-blue-default)"
              trailWidth={1}
            />
          </div>
          {
            actualData?.status.descricao === 'Autorizada' &&
            <>
              <div className="mb-3">
                <AccordionDisabled accordionTitle="Especificações Técnicas" />
              </div>
              <div className="mb-3">
                <AccordionDisabled accordionTitle="Agendamento Final" />
              </div>
            </>
          }
          {
            actualData?.status.descricao === 'Pendentes' &&
            <>
              <div className='mb-3'>
                <AccordionEspecificacoes
                  mobile
                  key={actualData?.id}
                  idCirurgia={idCirurgia}
                  updateTable={loadDados}
                  accordionTitle="Especificações Técnicas"
                  actAccordion={activeAccordion === "1"}
                  toggleAccordion={() => toggleActiveAccordion("1")}
                />
              </div>
              <div>
                <AccordionAgendamento
                  // key={idCirurgia}
                  confirmacao_data={confirm_data}
                  confirmacao_hora={actualData?.confirmacao_hora}
                  // toggleChecklist={(toggleChecklist)}
                  materiais={actualData?.materiais}
                  usuario_id={material?.fornecedor?.usuario_id}
                  updateData={updateData}
                  idCirurgia={idCirurgia}
                  confirmacao_cirurgia={
                    actualData?.confirmacao_cirurgia
                  }
                  confirmacao={actualData?.materiais.map(
                    (confirm: Material) => {
                      return confirm.confirmado;
                    }
                  )}
                  accordionTitle="Agendamento Final"
                  actAccordion={activeAccordion === "2"}
                  toggleAccordion={() => toggleActiveAccordion("2")}
                  setDadosModal={setDadosModal}
                  dadosModal={actualData}
                />
              </div>
            </>
          }
          {
            (actualData?.status.descricao === 'Agendadas' || actualData?.status.descricao === 'Concluída') &&
            <>
              <div className="mb-3">
                <AccordionEspecificacoes
                  mobile
                  key={actualData?.id}
                  idCirurgia={idCirurgia}
                  updateTable={loadDados}
                  accordionTitle="Especificações Técnicas"
                  actAccordion={activeAccordion === "1"}
                  toggleAccordion={() => toggleActiveAccordion("1")}
                />
              </div>
              <div>
                <AccordionAgendadas
                  mobile
                  key={actualData?.id}
                  updateTable={loadDados}
                  accordionTitle="Agendamento Final"
                  actAccordion={activeAccordion === "2"}
                  confirmacao_data={confirm_data || ""}
                  confirmacao_hora={actualData?.confirmacao_hora}
                  toggleAccordion={() => toggleActiveAccordion("2")}
                />
              </div>
            </>
          }
          {
            actualData?.status.descricao === 'Concluída' &&
            <div>
            <AccordionPosCirurgia
              idCirurgia={idCirurgia}
              toggleAccordion={() => toggleActiveAccordion('3')}
              key={dadosModal?.id}
              accordionTitle="Pós-Cirurgia"
              actAccordion={activeAccordion === "3"}
            />
          </div> 
          }
          {
            actualData?.status.descricao === 'Agendadas' &&
            <button onClick={handleOpenEditar} className={`mobile-agendadas-edit-button`}>Editar</button>
          }
        </div>
      </div>

      <div className='mobile-authorization-modal-subcontainer'>
        <div className='mobile-authorization-modal-top-container'>
          <div className={`mobile-authorization-modal-top-bar ${actualData?.status?.descricao}`} />
          <div className={`mobile-authorization-modal-status ${actualData?.status.descricao}`}>{actualData?.status.descricao}</div>
          <div onClick={closeAction} className='mobile-authorization-modal-cross-container'>
            <div className='mobile-authorization-modal-cross-bar-left' />
            <div className='mobile-authorization-modal-cross-bar-right' />
          </div>
        </div>

        <ModalEditarAgendadas
          idCirurgia={idCirurgia}
          openEditar={openEditar}
          toggle={handleOpenEditar}
          toggleChecklist={handleShowChecklist}
          paciente={actualData?.nome}
          dataCirurgia={confirm_data}
          dadosModal={actualData}
          setDadosModal={setDadosModal}
        />
        <div className='mobile-authorization-modal-center-container'>
          <h3 className='mobile-authorization-modal-doctor'>{actualData?.status.descricao === 'Concluída' ? actualData.nome : actualData?.doutor?.nome}</h3>
          {
            actualData?.status.descricao === 'Concluída' ?
              <>
                <div className='mobile-authorization-modal-info-wrapper'>
                  <div className='mobile-authorization-modal-info-container'>
                    <span className='mobile-authorization-modal-info'>Data de nascimento</span>
                    <span className='mobile-authorization-modal-info-content'>{actualData?.nascimento?.split('-').reverse().toString().replace(/[,]/g, '/')}</span>
                  </div>
                  <div className='mobile-authorization-modal-info-container'>
                    <span className='mobile-authorization-modal-info'>Sexo</span>
                    <span className='mobile-authorization-modal-info-content'>{actualData?.sexo}</span>
                  </div>
                </div>
                <div className='mobile-authorization-modal-info-wrapper'>
                  <div className='mobile-authorization-modal-info-container'>
                    <span className='mobile-authorization-modal-info'>Telefone</span>
                    <span className='mobile-authorization-modal-info-content'>{actualData.telefone}</span>
                  </div>
                  <div className='mobile-authorization-modal-info-container'>
                    <span className='mobile-authorization-modal-info'>Whatsapp</span>
                    <span className='mobile-authorization-modal-info-content'>{actualData.whatsapp}</span>
                  </div>
                </div>
                <div className='mobile-authorization-modal-info-container'>
                  <span className='mobile-authorization-modal-info'>Email</span>
                  <span className='mobile-authorization-modal-info-content'>{actualData.email}</span>
                </div>
                <div className='mobile-authorization-modal-info-container'>
                  <span className='mobile-authorization-modal-info'>Telefone de contanto</span>
                  <span className='mobile-authorization-modal-info-content'>{actualData.telefone_contato}</span>
                </div>
                <div className='mobile-authorization-modal-info-container'>
                  <span className='mobile-authorization-modal-info'>{actualData.pre_operatorio ? 'Pré operatório solicitado' : 'Pré operatório não solicitado'}</span>
                </div>
                <div className='mobile-authorization-modal-info-container'>
                  <span className='mobile-authorization-modal-info'>{actualData.alergia ?? 'Não possui alergias'}</span>
                </div>
              </> :
              <>
                <div className='mobile-authorization-modal-info-container'>
                  <span className='mobile-authorization-modal-info'>Paciente</span>
                  <span className='mobile-authorization-modal-info-content'>{actualData?.nome}</span>
                </div>
                <div className='mobile-authorization-modal-info-container'>
                  <span className='mobile-authorization-modal-info'>Cirurgia</span>
                  <span className='mobile-authorization-modal-info-content'>{actualData?.cirurgia}</span>
                </div>
                <div className='mobile-authorization-modal-info-container'>
                  <span className='mobile-authorization-modal-info'>Hospital</span>
                  <span className='mobile-authorization-modal-info-content'>{actualData?.hospital?.nome}</span>
                </div>
                <div className='mobile-authorization-modal-info-container'>
                  <span className='mobile-authorization-modal-info'>Data da Cirurgia</span>
                  <span className='mobile-authorization-modal-info-content'>
                    {`${actualData?.data_inicial?.split('-').reverse().toString().replace(/[,]/g, '/') ?? ''} - ${actualData?.data_final?.split('-').reverse().toString().replace(/[,]/g, '/') ?? ''}`}
                  </span>
                </div>
                <div className='mobile-authorization-modal-info-container'>
                  <span className='mobile-authorization-modal-info'>Vencimento da Guia</span>
                  <span className='mobile-authorization-modal-info-content'>{actualData?.vencimento?.split('-').reverse().toString().replace(/[,]/g, '/')}</span>
                </div>
              </>
          }
          <div className='mobile-authorization-modal-attachments-container'>
            <h4 className='mobile-authorization-modal-attachments-title'>Anexos</h4>

            {nivelCheck || adminCheck ? (
              <div className='mobile-authorization-modal-attachments-info-container'>
                <span className='mobile-authorization-modal-attachments-info'>Guia de Internamento</span>
                <a
                  // eslint-disable-next-line react/jsx-no-target-blank
                  target='_blank'
                  className='mobile-authorization-modal-attachments-info-content'
                  href={actualData?.guia_internamento?.url}
                >
                  <img src={archive} alt="archive-icon" />
                  {actualData?.guia_internamento?.nome}
                </a>
              </div>
            ) : ("")
            }
          </div>
          <div className='mobile-authorization-modal-attachments-info-container'>
            <span className='mobile-authorization-modal-attachments-info'>Guia de Materiais</span>
            {
              actualData?.guia_materiais?.nome &&
              <a
                // eslint-disable-next-line react/jsx-no-target-blank
                target='_blank'
                className='mobile-authorization-modal-attachments-info-content'
                href={actualData?.guia_materiais?.url}
              >
                <img src={archive} alt="archive-icon" />
                {actualData?.guia_materiais?.nome}
              </a>
            }
          </div>
          {nivelCheck || adminCheck ? (
            <div className='mobile-authorization-modal-attachments-info-container'>
              <span className='mobile-authorization-modal-attachments-info'>Resultado de Exames</span>
              {
                actualData?.resultados_exames?.nome &&
                <a
                  // eslint-disable-next-line react/jsx-no-target-blank
                  target='_blank'
                  className='mobile-authorization-modal-attachments-info-content'
                  href={actualData?.resultados_exames?.url}
                >
                  <img src={archive} alt="archive-icon" />
                  {actualData?.resultados_exames?.nome}
                </a>
              }
            </div>
          ) : ("")
          }
          <div className='mobile-authorization-modal-materials-container'>
            <h4 className='mobile-authorization-modal-materials-title'>Confirmação de Materiais</h4>
            <div className='mobile-authorization-modal-materials-content'>
              {
                actualData?.materiais.map((e) => e.descricao) ?? ''
              }
            </div>
            <span>
              {
                actualData?.status.descricao !== 'Reportado' ?
                  ("") :
                  <div>
                    <div className='mobile-authorization-modal-materials-title'>
                      Material Reportado
                    </div>
                    <div className='mobile-authorization-modal-report-info'>
                      <span>{report?.reportar_problema}</span>
                    </div>
                  </div>
              }
            </span>
          </div>
          {
            (usuario?.nivel === 2 || usuario?.nivel === 4) && material?.confirmado == false  && material?.reportar_problema == null && (actualData?.status.descricao === 'Pendentes' || actualData?.status.descricao === 'Agendadas') ?
              <div className={`mobile-authorization-modal-button-row`}>
                <button onClick={() => setAuthSubModal(1)} className='mobile-authorization-modal-button-report'>Reportar</button>
                <button onClick={() => { closeAction(); postReportar() }} className={`mobile-authorization-modal-button-confirm ${actualData?.status.descricao}`}>Confirmar</button>
              </div>

              : (usuario?.nivel === 2 || usuario?.nivel === 4) && material?.confirmado == true && (actualData?.status.descricao === 'Pendentes' || actualData?.status.descricao === 'Agendadas') ?
              <div className={`mobile-authorization-modal-button-row`}>
                <div className='mobile-authorization-modal-materials-title'>
                  Material confirmado
                </div>
              </div>

              : actualData?.status.descricao === 'Autorizada' && usuario?.nivel === 3 ?
                <div className={`mobile-authorization-modal-button-row`}>
                  <button onClick={handleExcludeModal} className='mobile-authorization-modal-button-report'>Excluir guia</button>
                  <button onClick={handleShowChecklist} className={`mobile-authorization-modal-button-confirm ${actualData?.status.descricao}`}>Confirmar</button>
                </div>

                : actualData?.status.descricao === 'Agendadas' && usuario?.nivel === 3 ?
                  <div className={`mobile-authorization-modal-button-row`}>
                    <button onClick={handleExcludeModal} className={`mobile-authorization-modal-button-report`}>Desmarcar</button>
                    <button onClick={handleShowChecklist} className={`mobile-authorization-modal-button-confirm ${actualData?.status.descricao}`}>Detalhes</button>
                  </div>

                  : actualData?.status.descricao === 'Concluída' && usuario?.nivel === 3 ?
                    <div className={`mobile-authorization-modal-button-row`}>
                      <button onClick={handleExcludeModal} className='mobile-authorization-modal-button-report'>Excluir guia</button>
                      <button onClick={handleShowChecklist} className={`mobile-authorization-modal-button-confirm ${actualData?.status.descricao}`}>Detalhes</button>
                    </div>

                    : actualData?.status.descricao === 'Pendentes' && usuario?.nivel === 3 &&
                    <div className={`mobile-authorization-modal-button-row`}>
                      <button onClick={handleExcludeModal} className='mobile-authorization-modal-button-report'>Excluir guia</button>
                      <button onClick={handleShowChecklist} className={`mobile-authorization-modal-button-confirm ${actualData?.status.descricao}`}>Continuar</button>
                    </div>
          }
        </div>
      </div>

      <div className={`mobile-authorization-submodal-wrapper${authSubModal === -1 ? ' none' : authSubModal === 1 ? ' show' : ' hide'}`}>
        <div onClick={() => setAuthSubModal(0)} className={`mobile-authorization-submodal-blur-mask${authSubModal === -1 ? '' : authSubModal === 1 ? ' show' : ' hide'}`} />
        <div className={`mobile-authorization-submodal-container${authSubModal === -1 ? '' : authSubModal === 1 ? ' show' : ' hide'}`}>
          <div className='mobile-authorization-submodal-list-container'>
            <div className='mobile-authorization-top-bar' />
            <h3 className='mobile-authorization-submodal-title'>Confirmação de Materiais</h3>
            <div className='mobile-authorization-submodal-list'>
              {
                actualData?.materiais.map((e) => { return e.descricao })
              }
            </div>
          </div>
          <div className='mobile-authorization-submodal-textarea-container'>
            <h4 className='mobile-authorization-submodal-textarea-title'>Reportar Problema</h4>
            <textarea value={reportField} onChange={(e) => { e.persist(); setReportField(e.target.value) }} className='mobile-authorization-submodal-textarea' maxLength={500} id="" cols={30} rows={10} />
          </div>
          <div className={`mobile-authorization-submodal-button-container`}>
            <button onClick={() => { setAuthSubModal(0); closeAction(); postReportar(); }} className='mobile-authorization-submodal-button-send'>Enviar</button>
            <button onClick={() => { setAuthSubModal(0); }} className='mobile-authorization-submodal-button-back'>
              <span>Voltar</span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default connector(MobileAuthorizationModal);
