import * as actionTypes from "../types";

export const setUpdateChecklist = (cirurgias: actionTypes.Cirurgias[]) => {
   return {
    type: actionTypes.UPDATE_CHECKLIST,
    payload: {
      cirurgias,
    },
  };
};
