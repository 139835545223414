import React, { useState }
  from "react";
import { useFormContext } from "react-hook-form";
import { modeloProps } from "./interfaces";
import { toast } from "react-toastify";
import { FiTrash2 } from "react-icons/fi";
import { axiosApi } from "src/services/axiosInstances";
import ModalExcluirModelo from "src/components/ModalExcluirModelo";

const Modelos: React.FC<modeloProps> = ({
  modelos,
  nomeModelo,
  getModelos,
  setOpmeSelectedList,
  setCbhpmSelectedList,
  setHospitalInputValue,
  setNomeModelo,
  setConvenioInputValue,
  setCidInputValue,
  setSecondCidInputValue
}) => {

  const { setValue } = useFormContext();
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);
  const [modelId, setModelId] = useState(0);
  const [toggleRemove, setToggleRemove] = useState(false);

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedModelo = modelos.find((modelo) => modelo.id === parseInt(event.target.value));
    if (selectedModelo) {
      setToggleRemove(true);
      setModelId(selectedModelo.id);
      setValue('cid_principal', selectedModelo["cid_principal_id"]);
      setValue('cid_secundario', selectedModelo["cid_secundario_id"]);
      setValue('regime_internacao_id', selectedModelo["regime_internacao_id"]);
      setValue('observacao', selectedModelo["observacao"]);
      setValue('justificativa_tecnica', selectedModelo["justificativa_tecnica"]);
      setValue('especificacao_material', selectedModelo["especificacao_material"]);
      setValue('carater_atendimento_id', selectedModelo["carater_atendimento_id"]);
      setValue('tipo_internacao_id', selectedModelo["tipo_internacao_id"]);
      setValue("quantidade_diarias_solicitadas", selectedModelo["quantidade_diarias_solicitadas"]);
      setValue("indicacao_clinica", selectedModelo["indicacao_clinica"]);
      setOpmeSelectedList(selectedModelo["solicitacao_cirurgia_opme"]);
      setCbhpmSelectedList(selectedModelo["cbhpm"])
      setNomeModelo(selectedModelo["nome_modelo"])
      if (selectedModelo["hospital"]) {
        setHospitalInputValue(selectedModelo["hospital"]["nome"]);
        setValue("hospital_nome", selectedModelo["hospital"]["nome"]);
      }
      if (selectedModelo["cid_principal"]) {
        setCidInputValue(selectedModelo["cid_principal"]["descricao"])
      } if (selectedModelo["cid_secundario"]) {
        setSecondCidInputValue(selectedModelo["cid_secundario"]["descricao"])
      }
      toast.success('Modelo selecionado com sucesso', {
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const deleteModel = async (id: number) => {
    try {
      await axiosApi.delete(`/solicitacao-cirurgia/${id}`);
      getModelos();
      setToggleRemove(false);
      closeModal();
      toast.success('Modelo excluido com sucesso', {
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } catch (error) {
      console.log(error);
    }

  }

  return (
    <div style={{ right: 0, top: 15 }} className="absolute flex justify-center">
      <label style={{ width: 200 }} className="pt-2 text-blue text-xl border-b-2 border-blue-800">Modelos
        <select
          className="bg-white"
          defaultValue=""
          onChange={handleSelectChange}
        >
          <option value="" disabled hidden>Selecione um modelo</option>
          {modelos && modelos.map((modelo) =>
            <option
              onClick={() => console.log(modelo['nome_modelo'])}
              value={modelo.id}
              key={modelo.id}>{modelo['nome_modelo']}
            </option>)
          }
        </select>
      </label>
      <div className="pt-12 pl-2">
        {toggleRemove ?
          <button
            onClick={openModal}
          >
            <FiTrash2
              className="rounded-full bg-white bg-grey"
              color="red"
              size={24}
            />
          </button> : null
        }
        <ModalExcluirModelo
          nomeModelo={nomeModelo}
          isOpen={isOpen}
          closeModal={closeModal}
          deleteModel={deleteModel}
          modelId={modelId}
        // setToggleRemove={setToggleRemove}
        />
      </div>
    </div>
  );
}

export default Modelos

