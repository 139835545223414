import React from 'react';
import './style.css';

interface IFormError {
  state: boolean;
  children: React.ReactNode;
  absolute?: boolean;
  className?: string;
}

const FormError: React.FC<IFormError> = ({state, absolute, className, children}) => {
  return <span className={`form-error-span${state ? '':' none'} ${absolute ? ' absolute':''} ${className? ` ${className}` : ''}`}>{children}</span>;
}

export default FormError;