import * as actionTypes from "../types";

const initialState: actionTypes.FornecedoresState = {
  fornecedores: [],
};

export default (
  state = initialState,
  { type, payload }: actionTypes.FornecedoresAction
) => {
  switch (type) {
    case actionTypes.UPDATE_FORNECEDORES:
      return { ...state, ...payload };
    default:
      return state;
  }
};
