import React from "react";
import { createRoot } from 'react-dom/client';
import "./index.css";
import './styles/global.css';
import App from "./App";
import "react-day-picker/dist/style.css";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { combineReducers, createStore } from "redux";
import Auth from "./store/reducers/auth";
import Vendedores from "./store/reducers/vendedores";
import Cirurgia from "./store/reducers/checklist";
import Hospitais from "./store/reducers/hospitais";
import Fornecedores from "./store/reducers/fornecedores";
import DoutoresAdmin from "./store/reducers/doutores";
import Secretarias from "./store/reducers/secretarias";
import * as Sentry from "@sentry/react";

const rootReducer = combineReducers({
  auth: Auth,
  vendedores: Vendedores,
  cirurgias: Cirurgia,
  hospitais: Hospitais,
  fornecedores: Fornecedores,
  doutores: DoutoresAdmin,
  secretarias: Secretarias,
});

const store = createStore(rootReducer);

Sentry.init({
  dsn: "https://1238b3d483ff4f9da19006261f6a0bc7@o4505344083165184.ingest.sentry.io/4505344175308800",
  integrations: [
    new Sentry.BrowserTracing({
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [/^https:\/\/app\.cirurgiaautorizada\.com.br\/api/],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

serviceWorker.unregister();
