import React from 'react';
import './Vidas/style.css';
import { axiosApi } from 'src/services/axiosInstances';

interface ExpiresIn {
  days: number;
  hours: number;
}

interface CancelAssinatureProps {
  logo: string;
  brand?: string;
  closeModal: () => void;
  setToggled: (state: boolean) => void;
  setCertificadorId: (state: number) => void;
  expiresIn: ExpiresIn
}

const CancelAssinature: React.FC<CancelAssinatureProps> =
  ({
    logo,
    brand,
    closeModal,
    setToggled,
    setCertificadorId,
    expiresIn = { days: 6, hours: 23 }
  }) => {

    const handleCancelAssinature = async () => {

      try {
        const response = await axiosApi.delete('/certificado');
        setToggled(false);
        setCertificadorId(0);
        closeModal()
        console.log(response);
      } catch (error) {
        console.error(error);
      }
    };

    return (
      <>
        <div
          className="flex flex-col justify-center"
        >
          <img className={`${brand ? 'w-3/4 h-auto mx-auto pb-5' : ''}`} src={logo} alt="logo assinatura" />
          <p className="information-text">
            Assinatura confirmada
          </p>
          <p className="information-text">Expira em:</p>
          <div className="flex justify-center">
            <div
              className="timer-container">
              <h4 className="timer-display">{expiresIn.days}</h4> <span className={`timer-label pb-6`}>dias</span>
            </div>
            <div className="timer-container">
              <h4 className="timer-display">{expiresIn.hours}</h4> <span className={`timer-label pb-6`}>horas</span>
            </div>
          </div>
          <button
            className={`button-scale button-assinatura-desativar font-black text-2xl ${brand === 'vidas' ? 'mt-8' : ''}`}
            onClick={handleCancelAssinature}
          >
            Desativar Certificado
          </button>
        </div>
      </>
    )
  }

export default CancelAssinature;