import React, { Fragment, useEffect, useState } from "react";
import "./style.css";

import Popup from "reactjs-popup";
import { AiFillCloseCircle } from "react-icons/ai";
// import InputMask from "react-input-mask";
import { toast } from "react-toastify";
import { axiosApi } from "src/services/axiosInstances";
import Loader from "../Loader";
import { TailSpin } from "react-loader-spinner";
import { AuthState, DoutorState } from "src/store/types";
import { connect, ConnectedProps } from "react-redux";
import { setDoutoresAdmin } from "src/store/actions/doutores";
// import { viaCepApi } from "src/services/viacep";
// import InputFormRadio from "../Inputs/InputRadio";

const mapStateToProps = ({
  auth,

  doutores,
}: {
  auth: AuthState;

  doutores: DoutorState;
}): AuthState & DoutorState => {
  return {
    usuario: auth.usuario,
    doutores: doutores.doutores,
  };
};
const mapDispatchToProps = {
  setDoutoresAdmin,
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
  open?: any;
  close?: any;
  status?: boolean;
  nome?: string;
  onChange?: (event: any) => void;
  toggle?: any;
  updateData?: any;
  id?: any;
  setId?: any;
  id_selecionado?: any;
  status_id?: number;
};

// type ViaCep = {
//   cep?: string;
//   logradouro?: string;
//   bairro?: string;
//   localidade?: string;
//   uf?: string;
// };

type Doutores = {
  id?: number;
  nome?: string;
  telefone?: string;
  whatsapp?: string;
  cpf?: string;
  crm?: string;
  email_secretaria?: string;
  secretaria?: boolean;
  endereco?: string;
  numero?: string;
  complemento?: string;
  bairro?: string;
  cep?: string;
  cidade?: string;
  uf?: string;
  secretaria_id?: number;
  usuario_id?: number;
  especialidade?: number;
  usuario?: Usuario;
};

type Usuario = {
  id: number;
  email: string;
  adm: string;
  fotos_id: string;
  nivel_id: number;
  status_id: number;
};

function ModalCirurgiao({
  open,
  close,
  id,
  setId,
  toggle,
  nome,
  updateData,
  doutores,
  status_id,
  id_selecionado,
  setDoutoresAdmin,
}: Props): JSX.Element {
  const modalWidth = { width: `85%` };
  const [loader, setLoader] = useState(false);

  const [dadosDoutor, setDadosDoutor] = useState<Doutores>();

  const loadDados = async () => {
    try {
      setLoader(true);
      const { data } = await axiosApi.get<Doutores>(`/show-doutor/${id}`);
      setDadosDoutor(data);
      setCheck(data.usuario?.status_id === 8 ? "Inativo" : "Ativo");
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };
  useEffect(() => {
    if (id !== undefined) {
      loadDados();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (open === false) {
      setDadosDoutor({});
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  // const postForm = (event: any) => {
  //   const { name, value } = event.target;
  //   setDadosDoutor({
  //     ...dadosDoutor,
  //     [name]: value,
  //   });
  // };

  const [btnLoader, setBtnLoader] = useState(false);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (
      !dadosDoutor?.cpf ||
      dadosDoutor?.cpf.includes("_") ||
      dadosDoutor?.cpf === "111.111.111-11" ||
      dadosDoutor?.cpf === "222.222.222-22" ||
      dadosDoutor?.cpf === "333.333.333-33" ||
      dadosDoutor?.cpf === "444.444.444-44" ||
      dadosDoutor?.cpf === "555.555.555-55" ||
      dadosDoutor?.cpf === "666.666.666-66" ||
      dadosDoutor?.cpf === "777.777.777-77" ||
      dadosDoutor?.cpf === "888.888.888-88" ||
      dadosDoutor?.cpf === "999.999.999-99"
    ) {
      toast.error('CPF Inválido!', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
    } else {
      try {
        setBtnLoader(true);

        const postDados = id
          ? await axiosApi.put(`/perfil-doutor?id=${id}`, {
              ...dadosDoutor,
              ativo: document.getElementsByName("statusId")[0]["checked"],
              // secretaria: document.getElementsByName("secretaria")[0][
              //   "checked"
              // ],
              especialidade: 1,
            })
          : await axiosApi.post(`/doutor`, {
              ...dadosDoutor,
              ativo: document.getElementsByName("statusId")[0]["checked"],
              // secretaria: document.getElementsByName("secretaria")[0][
              //   "checked"
              // ],
              especialidade: 1,
            });

        if (postDados.data.errorInfo) {
          toast.error('Erro no Formulário', {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            });
        } else {
          if (id) {
            toast.success('Alterações Realizadas com Sucesso!', {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              });
            toggle();
            updateData();
            loadDados();
          } else {
            toast.success('Cirurgião Cadastrado', {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              });
            toggle();
            updateData();
            loadDados();
          }
        }
      } catch (error: any) {
        if (error.response.data.errorInfo[0] === "23505") {
          toast.error('Erro no Formulário!', {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            });
        } else if (error.response.data.errorInfo) {
          toast.error('Preencha o CEP!', {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            });
        }
      } finally {
        setBtnLoader(false);
      }
    }
  };

  // const cepId = dadosDoutor?.cep;

  // const onBlurViaCep = async () => {
  //   try {
  //     const { data } = await viaCepApi.get<ViaCep>(`/${cepId}/json`);
  //     if (data["erro"] === true) {
  //       toast.error(
  //         <div className="flex">
  //           <VscError size="1.3rem" className="mr-1" />
  //           <span className="font-bold ml-1">CEP inexistente!</span>
  //         </div>
  //       );
  //     } else {
  //       const logradouro = data.logradouro ?? undefined;
  //       const bairro = data.bairro ?? undefined;
  //       const cidade = data.localidade ?? undefined;
  //       const uf = data.uf ?? undefined;
  //       setDadosDoutor({
  //         ...dadosDoutor,
  //         endereco: logradouro,
  //         bairro: bairro,
  //         cidade: cidade,
  //         uf: uf,
  //       });
  //     }
  //   } catch (error: any) {
  //     toast.warning(
  //       <div className="flex">
  //         <FiAlertCircle size="1.3rem" className="mr-1" />
  //         Digite um
  //         <span className="font-bold ml-1">CEP válido!</span>
  //       </div>
  //     );
  //   }
  // };

  const [check, setCheck] = useState<String>();

  const checkChange = () => {
    if (document.getElementsByName("statusId")[0]["checked"] === true) {
      setCheck("Ativo");
    } else {
      setCheck("Inativo");
    }
  };

  // const [isSecretaria, setIsSecretaria] = useState(
  //   dadosDoutor?.secretaria
  //     ? true
  //     : dadosDoutor?.secretaria === undefined ?? false
  // );
  // const emailEnabled = () => setIsSecretaria(true);
  // const emailDisabled = () => setIsSecretaria(false);

  return (
    <Fragment>
      <Popup
        open={open}
        closeOnDocumentClick={false}
        // onClose={open}
        contentStyle={modalWidth}
        modal
        nested={true}
      >
        <Fragment>
          <div className="bg-white modal py-5 px-10">
            <Loader isActive={loader} hide="mt-64">
              <div className="header pt-5 pb-3 text-left text-3xl font-bold truncate">
                Editar Dr(a) {nome}
                <div className="content px-2">
                  <form onSubmit={handleSubmit}>
                    <div className="grid grid-cols-12 text-base gap-x-3">
                      <div className="col-span-4 input-cirurgiao mt-5 px-1">
                        <h4 className="text-base font-bold mb-2">Nome*</h4>
                        {/* <input
                          disabled
                          type="text"
                          className="input-cirurgiao w-full h-10 border border-gray-600 pl-3"
                          name="nome"
                          defaultValue={dadosDoutor?.nome ?? ""}
                          onChange={postForm}
                          required={true}
                        /> */}
                        <p className="input-cirurgiao w-full h-10 border border-gray-600 pl-3 relative">
                          <span className="mt-2 absolute font-normal">
                            {dadosDoutor?.nome}
                          </span>
                        </p>
                      </div>
                      <div className="col-span-4 input-cirurgiao mt-5 px-1">
                        <h4 className="text-base font-bold mb-2">CPF*</h4>
                        {/* <InputMask
                          mask="999.999.999-99"
                          onChange={postForm}
                          disabled
                        >
                          <input
                            type="text"
                            className="input-cirurgiao w-full h-10 border border-gray-600 pl-3"
                            name="cpf"
                            defaultValue={dadosDoutor?.cpf ?? ""}
                            required={true}
                          />
                        </InputMask> */}
                        <p className="input-cirurgiao w-full h-10 border border-gray-600 pl-3 relative">
                          <span className="mt-2 absolute font-normal">
                            {dadosDoutor?.cpf}
                          </span>
                        </p>
                      </div>
                      <div className="col-span-4 input-cirurgiao mt-5 px-1">
                        <h4 className="text-base font-bold mb-2">CRM*</h4>
                        {/* <input
                          disabled
                          type="text"
                          className="input-cirurgiao w-full h-10 border border-gray-600 pl-3"
                          name="crm"
                          defaultValue={dadosDoutor?.crm ?? ""}
                          required={true}
                          onChange={postForm}
                        /> */}
                        <p className="input-cirurgiao w-full h-10 border border-gray-600 pl-3 relative">
                          <span className="mt-2 absolute font-normal">
                            {dadosDoutor?.crm}
                          </span>
                        </p>
                      </div>
                    </div>

                    <div className="grid grid-cols-12 text-base gap-x-3">
                      <div className="col-span-2 input-cirurgiao mt-5 px-1">
                        <h4 className="text-base font-bold mb-2">CEP*</h4>
                        {/* <InputMask
                          mask="99999-999"
                          onChange={postForm}
                          onBlur={onBlurViaCep}
                          disabled
                        >
                          <input
                            type="text"
                            className="input-cirurgiao w-full h-10 border border-gray-600 pl-3"
                            name="cep"
                            defaultValue={dadosDoutor?.cep ?? ""}
                            required={true}
                          />
                        </InputMask> */}
                        <p className="input-cirurgiao w-full h-10 border border-gray-600 pl-3 relative">
                          <span className="mt-2 absolute font-normal">
                            {dadosDoutor?.cep}
                          </span>
                        </p>
                      </div>
                      <div className="col-span-5 input-cirurgiao mt-5 px-1">
                        <h4 className="text-base font-bold mb-2">Endereço*</h4>
                        {/* <input
                          disabled
                          type="text"
                          className="input-cirurgiao bg-gray-300 w-full h-10 border border-gray-400 pl-3"
                          name="endereco"
                          value={dadosDoutor?.endereco ?? ""}
                          onChange={postForm}
                          required={true}
                        /> */}
                        <p className="input-cirurgiao w-full h-10 border border-gray-600 pl-3 relative">
                          <span className="mt-2 absolute font-normal">
                            {dadosDoutor?.endereco}
                          </span>
                        </p>
                      </div>
                      <div className="col-span-2 input-cirurgiao mt-5 px-1">
                        <h4 className="text-base font-bold mb-2">Número*</h4>
                        {/* <input
                          disabled
                          type="text"
                          className="input-cirurgiao w-full h-10 border border-gray-600 pl-3"
                          name="numero"
                          defaultValue={dadosDoutor?.numero ?? ""}
                          onChange={postForm}
                          required={true}
                        /> */}
                        <p className="input-cirurgiao w-full h-10 border border-gray-600 pl-3 relative">
                          <span className="mt-2 absolute font-normal">
                            {dadosDoutor?.numero}
                          </span>
                        </p>
                      </div>
                      <div className="col-span-3 input-cirurgiao mt-5 px-1">
                        <h4 className="text-base font-bold mb-2">Bairro*</h4>
                        {/* <input
                          disabled
                          type="text"
                          className="input-cirurgiao bg-gray-300 w-full h-10 border border-gray-400 pl-3"
                          name="bairro"
                          value={dadosDoutor?.bairro ?? ""}
                          onChange={postForm}
                          required={true}
                        /> */}
                        <p className="input-cirurgiao w-full h-10 border border-gray-600 pl-3 relative">
                          <span className="mt-2 absolute font-normal">
                            {dadosDoutor?.bairro}
                          </span>
                        </p>
                      </div>
                    </div>

                    <div className="grid grid-cols-12 text-base gap-x-3">
                      <div className="col-span-7 input-cirurgiao mt-5 px-1">
                        <h4 className="text-base font-bold mb-2">
                          Complemento
                        </h4>
                        {/* <input
                          disabled
                          type="text"
                          className="input-cirurgiao w-full h-10 border border-gray-600 pl-3"
                          name="complemento"
                          defaultValue={dadosDoutor?.complemento ?? ""}
                          onChange={postForm}
                        /> */}
                        <p className="input-cirurgiao w-full h-10 border border-gray-600 pl-3 relative">
                          <span className="mt-2 absolute font-normal">
                            {dadosDoutor?.complemento}
                          </span>
                        </p>
                      </div>
                      <div className="col-span-4 input-cirurgiao mt-5 px-1">
                        <h4 className="text-base font-bold mb-2">Cidade*</h4>
                        {/* <input
                          disabled
                          type="text"
                          className="input-cirurgiao bg-gray-300 w-full h-10 border border-gray-400 pl-3"
                          name="cidade"
                          value={dadosDoutor?.cidade ?? ""}
                          onChange={postForm}
                          required={true}
                        /> */}
                        <p className="input-cirurgiao w-full h-10 border border-gray-600 pl-3 relative">
                          <span className="mt-2 absolute font-normal">
                            {dadosDoutor?.cidade}
                          </span>
                        </p>
                      </div>
                      <div className="col-span-1 input-cirurgiao mt-5 px-1">
                        <h4 className="text-base font-bold mb-2">Estado*</h4>
                        {/* <input
                          disabled
                          type="text"
                          className="input-cirurgiao bg-gray-300 w-full h-10 border border-gray-400 pl-3"
                          name="uf"
                          value={dadosDoutor?.uf ?? ""}
                          onChange={postForm}
                          required={true}
                          maxLength={2}
                        /> */}
                        <p className="input-cirurgiao w-full h-10 border border-gray-600 pl-3 relative">
                          <span className="mt-2 absolute font-normal">
                            {dadosDoutor?.uf}
                          </span>
                        </p>
                      </div>
                    </div>

                    <div className="grid grid-cols-12 text-base gap-x-3">
                      <div className="col-span-3 input-cirurgiao mt-5 px-1">
                        <h4 className="text-base font-bold mb-2">Telefone</h4>
                        {/* <InputMask
                          mask="(99)99999-9999"
                          onChange={postForm}
                          disabled
                        >
                          <input
                            type="text"
                            className="input-cirurgiao w-full h-10 border border-gray-600 pl-3"
                            name="telefone"
                            defaultValue={dadosDoutor?.telefone ?? ""}
                          />
                        </InputMask> */}
                        <p className="input-cirurgiao w-full h-10 border border-gray-600 pl-3 relative">
                          <span className="mt-2 absolute font-normal">
                            {dadosDoutor?.telefone}
                          </span>
                        </p>
                      </div>
                      <div className="col-span-3 input-cirurgiao mt-5 px-1">
                        <h4 className="text-base font-bold mb-2">WhatsApp*</h4>
                        {/* <InputMask
                          mask="(99)99999-9999"
                          onChange={postForm}
                          disabled
                        >
                          <input
                            type="text"
                            className="input-cirurgiao w-full h-10 border border-gray-600 pl-3"
                            name="whatsapp"
                            defaultValue={dadosDoutor?.whatsapp ?? ""}
                            required={true}
                          />
                        </InputMask> */}
                        <p className="input-cirurgiao w-full h-10 border border-gray-600 pl-3 relative">
                          <span className="mt-2 absolute font-normal">
                            {dadosDoutor?.whatsapp}
                          </span>
                        </p>
                      </div>
                      <div className="col-span-4 input-cirurgiao mt-5 px-1">
                        <h4 className="text-base font-bold mb-2">Email*</h4>
                        {/* <input
                          disabled
                          type="email"
                          className="input-cirurgiao w-full h-10 border border-gray-600 pl-3"
                          name="email"
                          defaultValue={dadosDoutor?.usuario?.email ?? ""}
                          onChange={postForm}
                          required={true}
                        /> */}
                        <p className="input-cirurgiao w-full h-10 border border-gray-600 pl-3 relative">
                          <span className="mt-2 absolute font-normal">
                            {dadosDoutor?.usuario?.email}
                          </span>
                        </p>
                      </div>
                      <div className="col-span-2 ml-3 input-cirurgiao mt-5 px-1">
                        <h4 className="text-base font-bold mb-2">Status</h4>
                        <div className="flex">
                          <label className="switch mt-1">
                            <input
                              name="statusId"
                              type="checkbox"
                              defaultChecked={
                                dadosDoutor?.usuario?.status_id === 8
                                  ? false
                                  : true
                              }
                              onChange={checkChange}
                            />
                            <span className="slider round"></span>
                          </label>
                          <div className="mt-1">
                            <span className="font-light ml-2 text-xl">
                              {check}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div className="grid grid-cols-12 text-base gap-x-3">
                      <div className="col-span-4">
                        <div className="px-5 my-5 flex justify-start space-x-3">
                          <label
                            className="text-gray-800 font-bold text-sm xl:text-lg md:text-base"
                            htmlFor=""
                          >
                            Possui Secretária?
                          </label>
                          <div className="flex space-x-2 mr-4 mb-2 mt-1">
                            <div onClick={emailEnabled}>
                              <InputFormRadio
                                name="secretaria"
                                label="Sim"
                                id="sc_sim"
                                value="1"
                                htmlFor="sc_sim"
                                onChange={postForm}
                                checked={isSecretaria}
                              />
                            </div>

                            <div onClick={emailDisabled}>
                              <InputFormRadio
                                name="secretaria"
                                label="Não"
                                id="sc_nao"
                                value="0"
                                htmlFor="sc_nao"
                                onChange={postForm}
                                checked={
                                  dadosDoutor?.email_secretaria !== undefined
                                    ? false
                                    : true
                                }
                              />
                            </div>
                          </div>
                        </div>
                        {isSecretaria && (
                          <div className="border-2 border-dashed border-gray-300 rounded-lg py-2">
                            <div className="px-4 py-2">
                              <input
                                type="email"
                                className="w-full h-8 border-b border-gray-400 pl-3 input-email"
                                name="email_secretaria"
                                placeholder="Email da Secretária"
                                defaultValue={
                                  dadosDoutor?.email_secretaria ?? ""
                                }
                                onChange={postForm}
                                required={true}
                                autoComplete="off"
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div> */}

                    <div className="grid grid-cols-12 text-base font bold mt-5">
                      <div className="col-span-3">
                        <p
                          className={
                            dadosDoutor?.secretaria === true &&
                            status_id !== 8 &&
                            dadosDoutor?.email_secretaria !== null
                              ? "text-blue"
                              : "text-red-400"
                          }
                        >
                          {dadosDoutor?.secretaria === true &&
                          status_id !== 8 &&
                          dadosDoutor?.email_secretaria !== null
                            ? `*Possui Secretária: ${dadosDoutor.email_secretaria}`
                            : `*Não Possui Secretária`}
                        </p>
                      </div>
                    </div>

                    <div className="flex justify-center mb-2 pt-10">
                      <button
                        type="submit"
                        className="relative btn-save-vendedor bg-blue text-lg text-white font-bold px-8 py-2 rounded-full shadow-md cursor-pointer hover:shadow-lg"
                      >
                        <div className="flex justify-center space-x-2">
                          <TailSpin
                            color={"white"}
                            visible={btnLoader}
                            height={24}
                            width={20}
                          />
                          <span>
                            {btnLoader === true ? "Salvando" : "Salvar"}
                          </span>
                        </div>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </Loader>
          </div>
          <div
            className="rounded-full cursor-pointer close-btn"
            // eslint-disable-next-line no-sequences
            onClick={() => (toggle(), setId())}
          >
            <AiFillCloseCircle
              size="3rem"
              className="text-blue rounded-full bg-white"
            />
          </div>
        </Fragment>
      </Popup>
    </Fragment>
  );
}
export default connector(ModalCirurgiao);
