import React, { Fragment } from "react";
import "../SelectSimple/style.css";
import "./style.css";
import { FaAngleRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import InputAcompanhamento from "../Inputs/inputAcompanhamento";
import AcompanhamentoTimeline from "../AcompanhamentoTimeline";
import EnviarDocumentos from "./EnviarDocumentos";

type Props = {
  setIndexAcompanhamento: (index: number) => void;
  indexAcompanhamento: number;
};

export default function AcompanhamentoWindow({
  indexAcompanhamento,
  setIndexAcompanhamento,
  ...rest
}: Props) {

  return (
    <Fragment>
      {indexAcompanhamento === 0 ? (
        <div>
          <div className="select-simple-select-container div-select mt-2 grid grid-flow-col">
            <InputAcompanhamento
              name="codigo"
              label="CÓDIGO*"
              type="text"
              onChange={() => { }}
              required={true}
            />
          </div>
          <div className="login-button-container p-5 mt-4 flex items-center justify-center">
            <Link
              to={``}
              onClick={() => setIndexAcompanhamento(1)}
              className="button text-white font-bold rounded-full btn-input flex items-center justify-center"
            >
              <FaAngleRight size="4rem" />
            </Link>
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center w-full">
          <div className="mr-24">
            <h4 className="acompanhamento-page-title pt-1 text-4xl font-black">
              Olá nomePaciente!
            </h4>
          </div>
          {
            indexAcompanhamento === 4 ?
              <>
                <p className="py-5 text-xl text-center">
                  Para dar continuidade ao procoesso junto ao<br />
                  hospital solicitado é necessario que inclua a<br />
                  cópia dos documentos nos campos abaixo.</p>
                <EnviarDocumentos
                  setIndexAcompanhamento={setIndexAcompanhamento}
                />
              </> :
              <>
                <p className="py-5 text-xl text-center">
                  Seja bem-vindo ao portal de<br />
                  acompanhamento de Autorizações da<br />
                  Plataforma Cirurgia autorizada.</p>
                <AcompanhamentoTimeline indexAcompanhamento={indexAcompanhamento} setIndexAcompanhamento={setIndexAcompanhamento} />
              </>
          }
        </div>
      )}
    </Fragment>
  );
}