import React from "react";
import { TituloProps } from "./interfaces";


const style = {
  Tittle: 'text-3xl font-black'
}

const Titulo: React.FC<TituloProps> = ({ Content }) => {
  return (
    <>
      <div className={style.Tittle}>
        {Content}
      </div>
    </>
  )
}


export default Titulo;