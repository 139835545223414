import * as actionTypes from "../types";

const initialState: actionTypes.DoutorState = {
  doutores: [],
};

export default (
  state = initialState,
  { type, payload }: actionTypes.DoutorAction
) => {
  switch (type) {
    case actionTypes.LIST_DOUTORES:
      return { ...state, ...payload };
    default:
      return state;
  }
};
