import React, { useEffect, useState }from "react";
import "./style.css"
import NavBarEquipeMedica from "./NavBarEquipeMedica";
import { axiosApi } from "src/services/axiosInstances";
import ListaEquipeMedica from "./ListaEquipeMedica";
import {Routes, Route}from "react-router-dom";
import { ToastContainer } from "react-toastify";


const EquipeMedica: React.FC = () =>{

  const [medicosData, setMedicosData] = useState([])
  const [indexNavBar, setIndexNavBar] = useState(0)
  const [loader, setLoader] = useState(false);

  const fetchMedicosData = async () => {
    try {
      setLoader(true)
      const { data } = await axiosApi.get(`/equipe`);
      setMedicosData(data);
    } catch (error: any) {
      console.log(error);
  } finally {
    setLoader(false);
  }
  };

  useEffect(() => {
    fetchMedicosData()
  }, []);

  return(
  <div>
    <NavBarEquipeMedica medicosData={medicosData} indexNavBar={indexNavBar} setIndexNavBar={setIndexNavBar} fetchMedicosData={fetchMedicosData} />
    <Routes>
      <Route path='/' element={<ListaEquipeMedica medicosData={medicosData} indexNavBar={indexNavBar} fetchMedicosData={fetchMedicosData} loader={loader}/>}/>
      {/* <Route path='participacoes' element={<ListaEquipeMedica medicosData={medicosData} indexNavBar={1} fetchMedicosData={fetchMedicosData} />}/>
      <Route path='solicitacoes'  element={<ListaEquipeMedica medicosData={medicosData} indexNavBar={2} fetchMedicosData={fetchMedicosData} />}/> */}
    </Routes>
      <ToastContainer />
  </div>
  );
}

export default EquipeMedica;