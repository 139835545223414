import * as actionTypes from "../types";

const initialState: actionTypes.VendedoresState = {
  vendedores: [],
};

export default (
  state = initialState,
  { type, payload }: actionTypes.VendedoresAction
) => {
  switch (type) {
    case actionTypes.UPDATE_VENDEDORES:
      return { ...state, ...payload };
    default:
      return state;
  }
};
