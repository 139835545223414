import dateFnsParse from "date-fns/parse";
import dateFnsFormat from "date-fns/format";
// import { DayPickerProps } from "react-day-picker";
import { isDate } from 'date-fns';
import Styles, { CommonProps } from "react-select";
import { ObjectSchema } from "yup";

export const create_UUID = (): string => {
  let dt: number = new Date().getTime();
  const uuid: string = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
    /[xy]/g,
    function (c) {
      const r: number = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
    }
  );
  return uuid;
};

export const cores: string[] = [
  "#017E29", // verde escuro
  "#0049B8", // azul escuro
  "#009EFF", // azul claro
  "#FF000A", // vermelho escuro
  "#FF9400", // laranja Escuro
  "#B35D01", // MARROM

  "#01D140", // verde claro
  "#FF38B0", // rosa
  "#FFC700", //Amarelo claro,
  "#252526",
  "#95a5a6", // cinza claro

  "#B38B4F",
  "#8D02FF", // roxo
  "#626266",
  "#B38B4F",
  "#FFCC75", //laranja claro
  "#00DFFF", // azul Medio
  "#FF5648", // vermelho claro
];

export const convertToSlug = (text: string): string => {
  const a: string = "àáäâãèéëêìíïîòóöôùúüûñçßÿœæŕśńṕẃǵǹḿǘẍźḧ·/_,:;";
  const b: string = "aaaaaeeeeiiiioooouuuuncsyoarsnpwgnmuxzh------";
  const p: RegExp = new RegExp(a.split("").join("|"), "g");
  return text
    .toString()
    .toLowerCase()
    .trim()
    .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special chars
    .replace(/&/g, "-and-") // Replace & with 'and'
    .replace(/[\s\W-]+/g, "-"); // Replace spaces, non-word characters and dashes with a single dash (-)
};

function buildFormData(formData: FormData, data: any, parentKey: string = "") {
  if (
    data &&
    typeof data === "object" &&
    !(data instanceof Date) &&
    !(data instanceof File)
  ) {
    Object.keys(data).forEach((key) => {
      buildFormData(
        formData,
        data[key],
        parentKey ? `${parentKey}[${key}]` : key
      );
    });
  } else {
    const value = data == null ? "" : data;
    formData.append(parentKey, value);
  }
}

export function jsonToFormData(data: object, metodo: string) {
  const formData = new FormData();
  formData.append("_method", metodo);
  buildFormData(formData, data);
  return formData;
}

export const parseDate = (
  str: string = "",
  format: string = formatoData,
  locale?: string
): Date | undefined => {
  const parsed = dateFnsParse(str, format, new Date());
  return isDate(parsed) ? parsed : undefined;
};

export const formatDate = (
  date: Date | undefined,
  format: string = formatoData,
  locale?: string
): string => {
  return date ? dateFnsFormat(date, format) : "";
};

export const formatoData: string = "dd/MM/yyyy";
export const formatLaravelTimestamp: string = "yyyy-MM-dd HH:mm:ss";
export const formatLaravelDate: string = "dd/MM/yyyy";

// export const pt_br: DayPickerProps = {
//   months: [
//     "Janeiro",
//     "Fevereiro",
//     "Março",
//     "Abril",
//     "Maio",
//     "Junho",
//     "Julho",
//     "Agosto",
//     "Setembro",
//     "Outubro",
//     "Novembro",
//     "Dezembro",
//   ],
//   labels: { nextMonth: "Próximo mês", previousMonth: "Mês anterior" },
//   firstDayOfWeek: 1,
//   weekdaysShort: ["dom", "seg", "ter", "qua", "qui", "sex", "sáb"],
//   weekdaysLong: [
//     "Domingo",
//     "Segunda-Feira",
//     "Terça-Feira",
//     "Quarta-Feira",
//     "Quinta-Feira",
//     "Sexta-Feira",
//     "Sábado",
//   ],
// };

export const selectStyle: Partial<Styles> = {
  control: (styles: any, state: CommonProps<any, any, any>) => {
    return {
      ...styles,
      borderRadius: 0,
      borderBottom: "2px solid black",
      borderTop: 0,
      borderLeft: 0,
      borderRight: 0,
      padding: "",
      minHeight: "0px",
      outline: 0,
      boxShadow: "none",
      height: state.getValue().length > 1 ? undefined : "calc(1.5em + .75rem)",
      flexWrap: "unset !important",
      "&:hover": { boxShadow: "none", outline: 0 },
      "&:focus": { boxShadow: "0", outline: 0, border: "none" },
      backgroundColor: false ? "#e9ecef" : styles.backgroundColor,
      opacity: false ? 1 : styles.opacity,
    };
  },
  option: (styles: any) => ({
    ...styles,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    maxWidth: "100%",
    fontSize: "0.8rem",
    overflow: "hidden",
  }),
  indicatorSeparator: (styles: any) => ({
    ...styles,
    display: "none",
  }),
  dropdownIndicator: (styles: any) => ({
    ...styles,
    color: "var(--primary-blue-default)",
    "&:hover": { color: "var(--primary-blue-default)" },
  }),
  valueContainer: (styles: any) => ({
    ...styles,
    width: "100%",
    outline: 0,
    "&:hover": { boxShadow: "none", outline: 0 },
    "&:focus": { boxShadow: "0", outline: 0 },
  }),
  input: (styles: any) => ({
    ...styles,
    height: "",
  }),
  singleValue: (styles: any) => ({
    ...styles,
    color: "var(--primary-blue-default)",
  }),
  menuPortal: (styles: any) => ({
    ...styles,
    zIndex: 999999,
  }),
};
export const checkfield = (
  field: string,
  schema: ObjectSchema<any>,
  form: object
) => {
  if (!form) return false;
  try {
    schema.validateSyncAt(field, form);
    return true;
  } catch (error: any) {
    return false;
  }
};

export const SelectStyle2: Partial<Styles> = {
  control: (styles: any, state: CommonProps<any, any, any>) => {
    return {
      ...styles,
      borderRadius: "16px",
      border: "2px solid #CBD5E0",
      padding: "",
      minHeight: "0px",
      outline: 0,
      boxShadow: "none",
      height: state.getValue().length > 1 ? undefined : "calc(1.5em + .75rem)",
      flexWrap: "unset !important",
      "&:hover": { boxShadow: "none", outline: 0 },
      "&:focus": { boxShadow: "0", outline: 0, border: "none" },
      backgroundColor: false ? "#e9ecef" : styles.backgroundColor,
      opacity: false ? 1 : styles.opacity,
    };
  },
  option: (styles: any) => ({
    ...styles,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    maxWidth: "100%",
    fontSize: "1rem",
    overflow: "hidden",
  }),
  indicatorSeparator: (styles: any) => ({
    ...styles,
    display: "none",
  }),
  dropdownIndicator: (styles: any) => ({
    ...styles,
    color: "var(--primary-blue-default)",
    "&:hover": { color: "var(--primary-blue-default)" },
  }),
  valueContainer: (styles: any) => ({
    ...styles,
    width: "100%",
    outline: 0,
    "&:hover": { boxShadow: "none", outline: 0 },
    "&:focus": { boxShadow: "0", outline: 0 },
  }),
  input: (styles: any) => ({
    ...styles,
    height: "",
  }),
  singleValue: (styles: any) => ({
    ...styles,
    color: "black",
  }),
  menuPortal: (styles: any) => ({
    ...styles,
    zIndex: 999999,
  }),
};

export const styleHospital: Partial<Styles> = {
  control: (styles: any, state: CommonProps<any, any, any>) => {
    return {
      ...styles,
      borderRadius: "16px",
      border: "1px solid rgba(75, 85, 99)",
      padding: "",
      minHeight: "0px",
      outline: 0,
      fontWeight: "200",
      boxShadow: "none",
      fontSize: "1rem",
      height: "3rem",
      flexWrap: "unset !important",
      "&:hover": { boxShadow: "none", outline: 0 },
      "&:focus": { boxShadow: "0", outline: 0, border: "none" },
      backgroundColor: false ? "#e9ecef" : styles.backgroundColor,
      opacity: false ? 1 : styles.opacity,
    };
  },
  option: (styles: any) => ({
    ...styles,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    maxWidth: "100%",
    fontSize: "0.8rem",
    overflow: "hidden",
  }),
  indicatorSeparator: (styles: any) => ({
    ...styles,
    display: "none",
  }),
  dropdownIndicator: (styles: any) => ({
    ...styles,
    color: "var(--primary-blue-default)",
    "&:hover": { color: "var(--primary-blue-default)" },
  }),
  valueContainer: (styles: any) => ({
    ...styles,
    width: "100%",
    outline: 0,
    "&:hover": { boxShadow: "none", outline: 0 },
    "&:focus": { boxShadow: "0", outline: 0 },
  }),
  input: (styles: any) => ({
    ...styles,
    height: "",
  }),
  singleValue: (styles: any) => ({
    ...styles,
    color: "black",
    fontWeight: "200",
  }),
  menuPortal: (styles: any) => ({
    ...styles,
    zIndex: 999999,
  }),
  menuList: (styles: any) => ({
    ...styles,
    height: "150px",

    "::-webkit-scrollbar": {
      width: "5px",
      borderRadius: "",
    },
    "::-webkit-scrollbar-track": {
      background: "white",
    },
    "::-webkit-scrollbar-thumb": {
      backgroundColor: "var(--neutral-500)",
      width: "3px",
      borderRadius: "52px",
    },
    "::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "#b2b2b2",
    },
  }),
};

export const selectDoctor: Partial<Styles> = {
  control: (styles: any, state: CommonProps<any, any, any>) => {
    return {
      ...styles,
      borderRadius: "16px",
      border: "0px",
      padding: "",
      minHeight: "0px",
      outline: 0,
      marginTop: "2px",
      boxShadow: "none",
      height: state.getValue().length > 1 ? undefined : "calc(1.5em + .75rem)",
      flexWrap: "unset !important",
      "&:hover": { boxShadow: "none", outline: 0 },
      "&:focus": {
        outline: "none",
        border: "2px solid var(--primary-blue-default)!important",
        transform: "scale(1.01)!important",
        transition: "0.1s!important",
      },
      backgroundColor: "transparent",
      opacity: false ? 1 : styles.opacity,
    };
  },
  option: (styles: any) => ({
    ...styles,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    maxWidth: "100%",
    fontSize: "1rem",
    overflow: "hidden",
  }),
  indicatorSeparator: (styles: any) => ({
    ...styles,
    display: "none",
  }),
  dropdownIndicator: (styles: any) => ({
    ...styles,
    color: "var(--primary-blue-default)",
    "&:hover": { color: "var(--primary-blue-default)" },
    display: "none",
  }),
  valueContainer: (styles: any) => ({
    ...styles,
    width: "100%",
    outline: 0,
    "&:hover": {
      boxShadow: "none",
      outline: 0,
    },
  }),
  input: (styles: any) => ({
    ...styles,
    height: "",
  }),
  singleValue: (styles: any) => ({
    ...styles,
    color: "black",
  }),
  menuPortal: (styles: any) => ({
    ...styles,
    zIndex: 999999,
  }),
  container: (styles: any) => ({
    ...styles,
    "&:focus": {
      outline: "none",
      border: "2px solid var(--primary-blue-default)!important",
      transform: "scale(1.01)!important",
      transition: "0.1s!important",
    },
  }),
};

export const onlyNumberInput = (e: any) => {
  // eslint-disable-next-line no-lone-blocks
  {
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  }
};
