import * as actionTypes from "../types";

export const setUpdateFornecedores = (
  fornecedores: actionTypes.Fornecedores[]
) => {
  return {
    type: actionTypes.UPDATE_FORNECEDORES,
    payload: {
      fornecedores,
    },
  };
};
