import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "src/components/Loader";
import { axiosApi } from "src/services/axiosInstances";

function Logout() {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);
  useEffect(() => {
    const logout = async () => {
      try {
        await axiosApi.post("auth/logout");
      } catch (error: any) {
      } finally {
        axiosApi.defaults.headers.common["authorization"] = "";
        localStorage.clear();
        sessionStorage.clear();
        setLoader(false);
        window.location.reload();
        navigate("/quem-sou");
      }
    };
    logout();
  }, []);

  return (
    <Loader isActive={loader}>
    </Loader>
  );
}

export default Logout;
