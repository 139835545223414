import React, { useEffect, useState } from 'react';
import './style.css';
import vidas from '../../../assets/vidas2.png'

interface VidasRequest {
  goToVidasCancel: () => void;
  vidasRequest: () => void;
  // errorMessage: boolean;
  // setErrorMessage: (state: boolean) => void;
}

const VidasRequest: React.FC<VidasRequest> = ({ goToVidasCancel, 
  // errorMessage, setErrorMessage, 
  vidasRequest }) => {

  const [time, setTime] = useState(30);

  useEffect(() => {
    const timer = setInterval(() => {
      setTime((prevTime: any) => {
        if (prevTime > 0) {
          return prevTime - 1;
        } else {
          return 0;
        }
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
  };

  return (
    <>
      <div className="flex flex-col justify-center">
        <img className="" src={vidas} alt="logo vidas" />
        <p className="information-text">
          O VIDaaS enviou uma notificação ao seu smartphone. Acesse a aplicação e assine
        </p>
        <h4 className="timer-display">{formatTime(time)}</h4>
        {/* {errorMessage && <p className="text-red-600 text-center text-lg">Tempo esgotado. Por favor, tente novamente.</p>} */}
        <button
          disabled={time > 0}
          className={`${time > 0 ? 'button-assinatura-disabled font-black text-2xl mt-10 bg-gray-400' : 'button-scale button-assinatura-reenviar font-black text-2xl mt-10'}`}
          onClick={() => {
            setTime(30);
            // setErrorMessage(false);
            vidasRequest();
          }}
        >
          Reenviar solicitação
        </button>
      </div>
    </>
  );
}

export default VidasRequest;
