import React from "react";
import { BiCheck } from "react-icons/bi";
import { FaChevronDown } from "react-icons/fa";

type Props = {
  accordionTitle?: string;
};

export default function AccordionDisabled({
  accordionTitle,
}: Props): JSX.Element {
  return (
    <div className="accordion-btn shadow-md rounded-lg text-left text-gray-300 text-md font-bold mb-3">
      <div className="flex justify-between cursor-pointer p-3">
        <div className="rounded-full p-1 bg-white border-gray-300 border-2">
          <BiCheck size="1rem" className="text-white" />
        </div>
        <p className="disabled-accordion-title font-bold xl:text-base md:text-sm sm:text-sm">
          {accordionTitle}
        </p>

        <FaChevronDown size="1rem" className={`mt-1 `} />
      </div>
    </div>
  );
}
