import "../ModalVendedores/style.css";
import React, { Fragment, useState } from "react";
import Popup from "reactjs-popup";
import { AiFillCloseCircle } from "react-icons/ai";
import { toast, ToastContainer } from "react-toastify";
import InputMask from "react-input-mask";
import { axiosApi } from "src/services/axiosInstances";

type Props = {
  open?: any;
  close?: any;
  idVendedor?: number;
  nomeVendedor?: string;
  whatsappVendedor?: string;
  emailVendedor?: string;
  toggle?: any;
  loadDados: () => void;
};

type Form = {
  nome?: string;
};

function ModalVendedores({
  idVendedor,
  open,
  close,
  nomeVendedor,
  whatsappVendedor,
  emailVendedor,
  toggle,
  loadDados,
}: Props): JSX.Element {
  const [form, setForm] = useState<Form>({
    nome: nomeVendedor,
  });

  const postForm = (event: React.ChangeEvent<HTMLInputElement>) => {
    postEvent(event.target.name, event.target.value);
  };

  const postEvent = (nome: string, valor: any) => {
    setForm({ ...form, [nome]: valor });
  };

  const modalWidth = { width: "40%" };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const postDados = idVendedor
      ? await axiosApi.put(`/vendedor/${idVendedor}`, form)
      : await axiosApi.post(`/vendedor`, form);

    if (postDados.data.errorInfo) {
      toast.error('Erro no Formulário', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
    } else {
      toast.success('Vendedor Cadastrado', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
      toggle();
      loadDados();
    }
  };

  return (
    <Fragment>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover={false}
      />
      <Popup
        open={open}
        closeOnDocumentClick={false}
        // onClose={open}
        contentStyle={modalWidth}
        modal
        nested={true}
      >
        <Fragment>
          <div className="bg-white modal py-5 px-16">
            <div
              title={nomeVendedor}
              className="header pt-5 pb-3 truncate text-3xl font-bold"
            >
              {idVendedor ? ` Editar ${nomeVendedor}` : "Novo Vendedor"}
            </div>
            <div className="content ">
              <form onSubmit={handleSubmit}>
                <div className="input-vendedores mb-5">
                  <h4 className="text-lg font-bold mb-2">Nome Completo</h4>
                  <input
                    type="text"
                    className="input-vendedores w-full h-12 border border-gray-600 pl-3"
                    name="nome"
                    defaultValue={idVendedor ? nomeVendedor : ""}
                    onChange={postForm}
                    required={true}
                  />
                </div>
                <div className="input-vendedores mb-5">
                  <h4 className="text-lg font-bold mb-2">WhatsApp</h4>
                  <InputMask mask="(99) 99999-9999" onChange={postForm}>
                    <input
                      type="text"
                      className="w-full h-12 input-vendedores border border-gray-600 pl-3"
                      name="whatsapp"
                      defaultValue={idVendedor ? whatsappVendedor : ""}
                      required={true}
                    />
                  </InputMask>
                </div>
                <div className="input-vendedores mb-5">
                  <h4 className="text-lg font-bold mb-2">Email</h4>
                  <input
                    type="email"
                    className="w-full h-12 input-vendedores border border-gray-600 pl-3"
                    name="email"
                    defaultValue={idVendedor ? emailVendedor : ""}
                    onChange={postForm}
                    required={true}
                  />
                </div>

                <div className="flex justify-center mb-2 pt-5">
                  <button
                    type="submit"
                    className="btn-save-vendedor bg-blue text-lg text-white font-bold px-8 py-2 rounded-full shadow-md"
                  >
                    Salvar
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div
            className="rounded-full cursor-pointer close-btn"
            onClick={toggle}
          >
            <AiFillCloseCircle
              size="3rem"
              className="text-blue rounded-full bg-white"
            />
          </div>
        </Fragment>
      </Popup>
    </Fragment>
  );
}
export default ModalVendedores;
