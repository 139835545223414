import React, { Fragment, useState } from "react";
import "../ModalTimeline/style.css";
import InputFormFile from "../Inputs/inputFile";
import { string, object } from "yup";


type Props = {
  // indexAcompanhamento: number;
  setIndexAcompanhamento: (index: number) => void;
};

// type EnviarDados = {
//   cardTitle: string,
//   cardSubtitle: string,
// }

const schema = object({
  documento: string().required(),
  plano: string().required(),
  laudo: string().required(),
});

export default function EnviarDocumentos({
  setIndexAcompanhamento,
  // indexAcompanhamento
}: Props) {
  const [form, setForm] = useState({
  });


  const postEvent = async (nome: string, valor: any) => {
    setForm({ ...form, [nome]: valor });
  };


  return (
    <Fragment>
      <form className="w-full flex flex-col justify-center">
        <InputFormFile
          schema={schema}
          name="documento"
          label="Documento de Identidade*"
          postEvent={postEvent}
          required={true}
          placeholderFile="Anexe Documento de Identidade"
        />

        <InputFormFile
          schema={schema}
          name="plano"
          label="Carteira do Plano de Saúde*"
          postEvent={postEvent}
          required={true}
          placeholderFile="Anexe Carteira do Plano de Saúde"
        />

        <InputFormFile
          // inputClassName="w-3/4"
          schema={schema}
          name="laudo"
          label="Laudo de Exames*"
          postEvent={postEvent}
          required={true}
          placeholderFile="Anexe Laudo de Exames"
        />

      </form>
      <button
        style={{ width: 320, height: 63 }}
        className="button-bold-24 hover:bg-blue-700 my-8"
        onClick={() => setIndexAcompanhamento(2)}
      >
        SALVAR
      </button>
    </Fragment>
  )
}