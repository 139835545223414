import React from "react";
import { FaAngleRight } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import LoaderButton from "../LoaderButton";
import './media.css'

type Props = {
  name: string;
  wrapClassName?: string;
  buttonClassName?: string;
  type?: string;
  loader?: boolean;
  onClick?: (event: any) => void;
};

export default function ButtonFormSubmit({
  type = "submit",
  name,
  loader,
  wrapClassName = "p-5 mt-5 flex items-center justify-center",
  buttonClassName = "text-white font-bold rounded-full btn-input flex items-center justify-center",
  onClick,
}: Props) {
  const { pathname } = useLocation();

  return (
    <div className={wrapClassName}>
      <button
        disabled={loader ?? false}
        className={`button-form-submit${pathname === '/autorizacoes' ? ' mobile-pendentes-checklist-button' : ''} ${buttonClassName}`}
        name={name}
        typeof={type}
        onClick={onClick}
      >
        <LoaderButton
          isActive={loader ?? false}
          hide={"absolute flex justify-center"}
        >
          <FaAngleRight size="4rem" />
        </LoaderButton>
      </button>
    </div>
  );
}
