import './style.css'
import Popup from 'reactjs-popup'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { AiFillCloseCircle } from 'react-icons/ai'
import { axiosApi } from 'src/services/axiosInstances'
import ModalConfirmacao, { emEquipeStyle } from '../ModalConfirmacao'
import { toast } from 'react-toastify'
import { FiMapPin } from "react-icons/fi";
import Loader from '../Loader'

interface modalBuscarProps {
  fetchMedicosData: () => void;
}

const ModalBuscarMedico: React.FC<modalBuscarProps> = ({ fetchMedicosData }) => {

  const [medicos, setMedicos] = useState([])
  const [adicionar, setAdicionar] = useState(false)
  const [inputValue, setInputValue] = useState<string>('');
  const [isOpen, setIsOpen] = useState(false);
  const [naoEncontrado, setNaoEncontrado] = useState('');
  const [loader, setLoader] = useState(false);

  const openModal = () => {
    setAdicionar(false);
    setMedicos([]);
    setInputValue('');
    setIsOpen(true);
    setNaoEncontrado('');
  }

  const closeModal = async () => {
    await fetchMedicosData();
    setIsOpen(false);
  };


  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleCancel = () => {
    setAdicionar(false);
    buscaMedicos(inputValue);
  };

  const buscaMedicos = async (InputValue: string) => {
    try {
      setLoader(true)
      const { data } = await axiosApi.get(`/buscaDoutor?nome=${InputValue}`);
      setMedicos(data);
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  const confirmAddMedico = async (id: number) => {
    try {
      const reqBody = { doutor_requisitado_id: `${id}` }
      await axiosApi.post(`/equipe`, reqBody);
      toast.success('Solicitação realizada com sucesso!', {
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      handleCancel();
    } catch (error: any) {
      console.log(error);
    }
  };

  const handleFetchData = async () => {
    await buscaMedicos(inputValue);
    setNaoEncontrado(`Não encontramos nenhum doutor: ${inputValue}`);
    setAdicionar(false);
  };


  const handleConfirm = (position: number) => {
    setMedicos([medicos[position]]);
    setAdicionar(true);
  }

  useEffect(() => {
    setInputValue("");
    setMedicos([]);
    setAdicionar(false);
  }, []);

  useEffect(() => {
    setInputValue(inputValue)
  }, [inputValue]);



  return (
    <>
      <button onClick={openModal} className="btn-new-vendedor 
    bg-blue hover:bg-blue-700 text-white py-1 px-3
    font-lato font-black rounded-full m-6">Adicionar Participante
      </button>
      <Popup closeOnDocumentClick={false} open={isOpen} onClose={closeModal} position="right center">
        <div className="flex flex-col items-center modalBuscarMedico border rounded-3xl bg-white mt-16">
          <h3 style={{ paddingRight: 200 }} className="font-lato font-black text-3xl pt-8">Buscar Médico</h3>
          <div className="flex  justify-center items-center ml-6 px-4 w-full">
            <input name="search" style={{ width: '260px' }} onChange={handleInputChange} value={inputValue} placeholder="Pesquisar por nome" className="pl-4 font-lato placeholder-gray-600 h-10 border border-black rounded-xl" type="text" />
            <button onClick={handleFetchData}
              className="btn-new-vendedor 
    bg-blue hover:bg-blue-700 text-white py-1 px-3
    font-lato font-black rounded-full m-6 w-1/5 h-10">Buscar
            </button>
          </div>
          <button>
            <div
              className="mt-16 rounded-full cursor-pointer close-btn"
            >
              <AiFillCloseCircle
                size="3rem"
                className="text-blue rounded-full bg-white"
                onClick={closeModal}
              />
            </div>
          </button>
          <Loader isActive={loader}>
            {medicos.length !== 0 ?
              medicos.map((medico, index) => <div style={{ width: 450 }} className="flex items-center justify-between" key={index}>
                <div className="flex items-center pb-2">
                  <Loader isActive={loader}>
                    {medico['foto'] ?
                      <img alt="Imagem de Perfil" src={medico['foto']} className="flex w-12 h-12 rounded-full object-cover" />
                      : <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 496 512" className="text-gray-400" height="48px" width="48px" xmlns="http://www.w3.org/2000/svg">
                        <path d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 96c48.6 0 88 39.4 88 88s-39.4 88-88 88-88-39.4-88-88 39.4-88 88-88zm0 344c-58.7 0-111.3-26.6-146.5-68.2 18.8-35.4 55.6-59.8 98.5-59.8 2.4 0 4.8.4 7.1 1.1 13 4.2 26.6 6.9 40.9 6.9 14.3 0 28-2.7 40.9-6.9 2.3-.7 4.7-1.1 7.1-1.1 42.9 0 79.7 24.4 98.5 59.8C359.3 421.4 306.7 448 248 448z"></path>
                      </svg>
                    }
                    <div className="pl-4">
                      <h2 className="nomesClass font-lato font-black">
                        {medico['nome']}
                      </h2>
                      {medico['cidade'] ?
                        <div className="flex items-center">
                          <FiMapPin className="pr-1 pinIcon" />
                          <p>
                            {`${medico['cidade']} - ${medico['uf']}`}
                          </p>
                        </div> : null}
                    </div>
                  </Loader>
                </div>
                {!medico['equipe'] ? <div className="flex items-center">
                  <button onClick={() => handleConfirm(index)} style={{ width: 120 }}
                    className="btn-new-vendedor  bg-blue  text-white py-1 font-lato font-black rounded-full">Adicionar</button>
                </div> :
                  <div className="flex items-center">
                    <ModalConfirmacao solicitacaoId={medico['equipe']['solicitacao_status_id']} id={medico['equipe']['id']} fetchMinhaEquipe={handleFetchData} />
                  </div>
                }
              </div>)
              : <h2 style={{ fontWeight: 'bold' }}>{naoEncontrado}</h2>}
          </Loader>
          {adicionar ?
            <div style={{ width: 300 }} className="flex flex-col justify-center items-center pt-4">
              <h1 className="items-center text-center justify-center font-black text-blue-700">Se confirmado, {medicos[0]['nome']} terá acesso a seu calendario.</h1>
              <div style={{ width: 250 }} className="flex justify-between pt-8">
                <button style={{ width: 120 }} onClick={() => confirmAddMedico(medicos[0]['id'])} className="btn-new-vendedor  bg-blue  text-white py-1 font-lato font-black rounded-full" >Confirmar</button>
                <button style={{ width: 120 }} onClick={handleCancel} className={emEquipeStyle}>Cancelar</button>
              </div>
            </div>
            : null}
        </div>
      </Popup>
    </>
  )
}


export default ModalBuscarMedico