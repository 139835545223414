import "./style.css";
import React, { Fragment } from "react";

type Props = {
  fixed?: boolean;
  overlay?: boolean;
  children: JSX.Element | Array<JSX.Element>;
  isActive: boolean;
  hide?: string;
};

const Loader = ({
  fixed,
  overlay,
  children,
  isActive,
  hide,
}: Props): JSX.Element => {
  if (overlay || fixed) {
    return (
      <Fragment>
        {isActive && (
          <div className={`${hide}`}>
            <div className="indicator">
              <svg width="16px" height="12px" className="svg-loader">
                <polyline
                  id="back"
                  points="1 6 4 6 6 11 10 1 12 6 15 6"
                  className="polyline-loader-b poly"
                ></polyline>
                <polyline
                  id="front"
                  points="1 6 4 6 6 11 10 1 12 6 15 6"
                  className="polyline-loader-f poly"
                ></polyline>
              </svg>
            </div>
          </div>
        )}
        {children}
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        {isActive && (
          <div className={`${hide}`}>
            <div className="indicator">
              <svg width="16px" height="12px" className="svg-loader">
                <polyline
                  id="back"
                  points="1 6 4 6 6 11 10 1 12 6 15 6"
                  className="polyline-loader-b poly"
                ></polyline>
                <polyline
                  id="front"
                  points="1 6 4 6 6 11 10 1 12 6 15 6"
                  className="polyline-loader-f poly"
                ></polyline>
              </svg>
            </div>
          </div>
        )}
        {!isActive && children}
      </Fragment>
    );
  }
};

export default Loader;
