import { axiosApi } from "src/services/axiosInstances";
import * as actionTypes from "../types";

export const setDadosAtualizados = (
  token: string,
  usuario: actionTypes.Usuario,
  expires_in: number
): actionTypes.AuthAction => {
  axiosApi.defaults.headers.common["Authorization"] = `bearer ${token}`;
  return {
    type: actionTypes.INITIAL_STATE,
    payload: {
      token,
      usuario,
      expires_in
    },
  };
};

export const setToken= (
  token: string,
  expires_in: number
): actionTypes.AuthAction => {
  axiosApi.defaults.headers.common["authorization"] = `bearer ${token}`;
  return {
    type: actionTypes.UPDATE_TOKEN,
    payload: {
      token,
      expires_in
    },
  };
};

export const setDoutor = (
  doutor: actionTypes.Doutor
): actionTypes.AuthAction => {
  localStorage.setItem("doutor", JSON.stringify(doutor));
  return {
    type: actionTypes.UPDATE_DOUTOR,
    payload: {
      doutor,
    },
  };
};

export const setUpdatePerfil = (
  usuario: actionTypes.Usuario
): actionTypes.AuthAction => {
  localStorage.setItem("usuario", JSON.stringify(usuario));
  return {
    type: actionTypes.UPDATE_PERFIL,
    payload: {
      usuario,
    },
  };
};
