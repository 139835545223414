import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { axiosApi } from 'src/services/axiosInstances';
import { AuthState } from 'src/store/types';
import './style.css';

interface IMobileNotifications extends Props {
  state: number;
  setState: React.Dispatch<React.SetStateAction<number>>;
}

const mapStateToProps = ({ auth }: { auth: AuthState }): AuthState => {
  return {
    usuario: auth.usuario,
  };
};

const connector = connect(mapStateToProps, {});

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux;

type Notifications = {
  id: number;
  cirurgia_id: number;
  etapa_id: number;
  cirurgia: Cirurgia;
  etapa_checklist: EtapaChecklist;
};

type EtapaChecklist = {
  id: number;
  descricao: string;
};

type Cirurgia = {
  id: number;
  nome: string;
  status_id: number;
};

const MobileNotifications: React.FC<IMobileNotifications> = ({state, setState}) => {
  const [notifications, setNotifications] = React.useState<Notifications[]>([]);

  const loadDados = async () => {
    try {
      const { data } = await axiosApi.get<Notifications[]>(
        `/notificacoes?limit=10`
      );
      setNotifications(data);
    } catch (error: any) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    if (state === 0) {
      setTimeout(() => {setState(-1)},600);
    }
  },[state, setState])

  React.useEffect(() => {
    if (state !== 1 && !notifications.length) {
      loadDados();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);
  return (
    <div className={`mobile-notifications-wrapper${state === -1 ? ' none' : state === 1 ? ' show' : ' hide'}`}>
        <div onClick={() => {setState(0)}} className='mobile-notifications-blur-area'/>
        <div className={`mobile-notifications-container${state === -1 ? '' : state === 1 ? ' show' : ' hide'}`}>
          <div className='mobile-notifications-top-container'>
            <div className='mobile-notifications-white-bar'/>
            <h4 className='mobile-notifications-title'>Notificações</h4>
          </div>
          <div className='mobile-notifications-list-container'>
            {
              notifications.length !== 0 && String(notifications) !== 'undefined' ?
              notifications.map((item, key) => {
                return (
                  <div className='mobile-notifications-card-container' key={key}>
                    <div className='mobile-notifications-card-icon'/>
                    <div className='mobile-notifications-card-content-container'>
                      {
                        item.etapa_checklist?.descricao &&
                        <span className='mobile-notifications-card-title'>{item.etapa_checklist?.descricao}</span>
                      }
                      <span className='mobile-notifications-card-content'>{item.cirurgia?.nome}</span>
                    </div>
                  </div>
              );}) : ''
            }
          </div>
        </div>
      <div className={`mobile-notifications-blur-mask${state === -1 ? '' : state === 1 ? ' show' : ' hide'}`}/>
    </div>
  );
}

export default MobileNotifications;