import "./style.css";
import React, { Fragment, useEffect, useState } from "react";
import { axiosApi } from "src/services/axiosInstances";
import Loader from "src/components/Loader";
import { CgSearch } from "react-icons/cg";
import HospitaisLista from "src/components/CardHospitaisLista";
import ModalHospitais from "src/components/ModalHospitais";
import ReactPaginate from "react-paginate";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { FiPlus } from "react-icons/fi";
import EmptyContent from "src/components/EmptyContent";
import { AuthState, Hospitais, HospitaisState } from "src/store/types";
import { setUpdateHospitais } from "src/store/actions/hospitais";
import { connect, ConnectedProps } from "react-redux";

const mapStateToProps = ({
  auth,
  hospitais,
}: {
  auth: AuthState;
  hospitais: HospitaisState;
}): AuthState & HospitaisState => {
  return {
    usuario: auth.usuario,
    hospitais: hospitais?.hospitais,
  };
};

const mapDispatchToProps = {
  setUpdateHospitais,
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
  idVendedor?: number;
  thClassName?: string;
  tableClassName?: string;
};

type HomeResponse = {
  hospitais: Hospitais[];
  total: number;
};

function HospitaisPage({
  thClassName = " py-2",
  tableClassName = "table-auto table-autorizacoes",
  hospitais,
  setUpdateHospitais,
  usuario,
}: Props): JSX.Element {
  const [loader, setLoader] = useState(true);
  const [idHospital, setIdHospital] = useState<number>();
  const [title, setTitle] = useState<string>();
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(!open);
  const [offset, setOffset] = useState(0);
  const [perPage] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [filtro, setFiltro] = useState<string>("");

  const loadDados = async () => {
    try {
      if (!filtro) setLoader(true);
      const { data } = await axiosApi.get<HomeResponse>(
        `/list-users?selecionado=4&limit=${perPage}&offset=${offset}&filtro=${filtro}`
      );
      const arr = Math.ceil(data.total / perPage);
      setUpdateHospitais(data.hospitais);
      setPageCount(arr);
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };
  useEffect(() => {
    loadDados();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset, perPage, filtro]);

  const loadDadosHosp = async () => {
    try {
      const { data } = await axiosApi.get<HomeResponse>(
        `/list-users?selecionado=4&limit=${perPage}&offset=${offset}&filtro=${filtro}`
      );

      setUpdateHospitais(data.hospitais);
    } catch (error: any) {
      console.log(error);
    }
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFiltro(event.target.value);
  };

  const dadosHospital = (id: number, nome: string): void => {
    setTitle(nome);
    setIdHospital(id);
    setOpen(true);
  };

  const novoVendedor = (): void => {
    setIdHospital(undefined);
    setOpen(true);
  };

  const handlePageChange = (select: { selected: number }) => {
    const selectedPage = select.selected;
    setOffset(selectedPage * perPage);
  };

  const theFirst = 0;
  const theLast = pageCount - 1;
  const page = Math.ceil(offset / perPage);

  // const findHospitais = hospitais.find((item) => item.id === idHospital);

  const adminCheck = usuario?.nivel === 5;

  return (
    <Fragment>
      <ModalHospitais
        open={open}
        idHospital={idHospital}
        setIdHospital={setIdHospital}
        nome={title}
        toggle={toggle}
        updateData={loadDadosHosp}
        adminCheck={adminCheck}
      />
      <Loader isActive={loader}>
        <div className="p-12 div-auxiliares">
          <div className="grid grid-flow-col justify-start">
            <h1 className="text-blue text-4xl font-bold px-5">Hospitais</h1>
          </div>

          <div className="grid grid-cols-full relative py-10 text-sm">
            <div className="grid grid-cols-12 py-0 mb-5">
              <div className="col-span-4">
                <div className="w-full rounded-lg px-5 py-1">
                  <div className="flex ">
                    <CgSearch
                      size="1.5rem"
                      className="my-3 mr-4 search-icon-c text-blue "
                    />
                    <input
                      placeholder="Buscar por Hospitais..."
                      type="text"
                      className="search-bar-crud px-1 w-full text-sm mb-1"
                      name="filtro"
                      onChange={onChange}
                      autoComplete="off"
                      value={filtro}
                    />
                  </div>
                </div>
              </div>

              <div className="col-span-2 float-right absolute right-0 mt-16">
                <button
                  onClick={novoVendedor}
                  className="btn-new-vendedor bg-blue text-white font-bold rounded-full content-center px-4 py-2 flex mt-1 shadow-md"
                >
                  <FiPlus size="1.3rem" className="mr-2 text-white" /> Novo
                </button>
              </div>
            </div>

            <div className="grid grid-cols-full text-sm">
              {hospitais?.length === 0 ? (
                <EmptyContent
                  messageSize="2xl"
                  iconSize="2.2rem"
                  paddingContent={"py-32"}
                  titleContent="Sem Novos Hospitais"
                />
              ) : (
                <Fragment>
                  <table className={tableClassName}>
                    <thead>
                      <tr className="text-center">
                        <th className={thClassName}></th>
                        <th className="pl-12 py-2 text-left">
                          Nome do Hospital
                        </th>
                        <th className={thClassName}>Pessoa para Contato</th>
                        <th className={thClassName}>WhatsApp</th>
                        <th className={thClassName}>Email</th>
                        <th className={thClassName}>Status</th>
                        {adminCheck ? <th className={thClassName}></th> : ""}
                      </tr>
                    </thead>
                    {hospitais.map((dados: Hospitais) => {
                      const statusHospital =
                        dados?.status_id === 6
                          ? "Incompleto"
                          : dados?.status_id === 7
                          ? "Completo"
                          : "Inativo";
                      return (
                        <HospitaisLista
                          key={dados?.id}
                          id={dados?.id}
                          nome={dados?.nome}
                          contato={dados?.contato ? dados?.contato : "-"}
                          whatsapp={dados.whatsapp}
                          email={dados?.email}
                          status={statusHospital}
                          status_id={dados?.status_id}
                          onClick={dadosHospital}
                        />
                      );
                    })}
                  </table>
                  {pageCount === 1 ? (
                    ""
                  ) : (
                    <ReactPaginate
                      previousLabel={
                        <BiChevronLeft
                          size="1.5rem"
                          className={
                            offset / perPage === theFirst
                              ? "cursor-default text-gray-400 outline-none"
                              : "text-blue outline-none"
                          }
                        />
                      }
                      nextLabel={
                        <BiChevronRight
                          size="1.5rem"
                          className={
                            offset / perPage === theLast
                              ? "cursor-default text-gray-400 outline-none"
                              : "text-blue outline-none"
                          }
                        />
                      }
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={pageCount}
                      forcePage={page}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageChange}
                      containerClassName={
                        "flex justify-center space-x-3 pt-10 text-md"
                      }
                      activeClassName={"active"}
                      activeLinkClassName={"p-1 transform scale-150 "}
                    />
                  )}
                </Fragment>
              )}
            </div>
          </div>
        </div>
      </Loader>
    </Fragment>
  );
}
export default connector(HospitaisPage);
