import React from "react";
import Select, { Options } from "react-select";
import { styleHospital } from "src/helpers/functions";
import "../SelectComponent/style.css";

type Props = {
  options: Options<any>;
  onChangePost?: any;
  name?: string;
  value?: unknown;
  defaultValue?: unknown;
};

// eslint-disable-next-line no-empty-pattern
export default function SelectHospital({
  options,
  onChangePost,
  name,
  value,
  defaultValue,
}: Props) {
  return (
    <Select
      className="text-xl font-bold text-black"
      options={options}
      onChange={onChangePost}
      name={name}
      value={value}
      defaultValue={defaultValue}
      // menuIsOpen={true}
      placeholder={"Selecione o Hospital..."}
      styles={styleHospital}
    />
  );
}
