import React, { FormEvent } from "react"

interface ButtonGerarRelatorio {
  handleSubmit? : (event: FormEvent<HTMLFormElement>) => void;
}

const ButtonGerarRelatorio: React.FC<ButtonGerarRelatorio> = ({handleSubmit}) => {

  return (

    <>
      <div
        style={{ width: 410 }}
        className="flex justify-end py-10">
        <button
          style={{ width: 160 }}
          className="btn-new-vendedor bg-blue hover:bg-blue-700 text-white py-1 px-3 font-lato font-black rounded-full"
          onClick={() => handleSubmit}
        >
          Gerar Relatório
        </button>
      </div>
    </>
  )
}



export default ButtonGerarRelatorio;