import React, { Fragment } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import Popup from "reactjs-popup";
import { axiosApi } from "src/services/axiosInstances";
import "../ModalDeleteVendedor/style.css";
import { toast } from "react-toastify";

type Props = {
  open?: any;
  close?: any;
  idCirurgia?: number;
  paciente?: string;
  setClose?: any;
  toggle?: any;
  toggleChecklist?: any;
  updateData?: any;
};

function ModalDesmarcarAgendadas({
  open,
  close,
  idCirurgia,
  paciente,
  setClose,
  toggle,
  toggleChecklist,
  updateData,
}: Props): JSX.Element {
  const modalWidth = { width: "35%" };

  const desmarcarHandle = async (event: any) => {
    event.preventDefault();
    try {
      await axiosApi.put(`/desmarcar?id=${idCirurgia}`);
      toast.success('Cirurgia Desmarcada!', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
      toggle();
      toggleChecklist();
      updateData();
    } catch (error: any) {}
  };

  return (
    <Popup
      open={open}
      closeOnDocumentClick={false}
      // onClose={open}
      contentStyle={modalWidth}
      modal
      nested={true}
    >
      {/* {(close: any) => ( */}
      <Fragment>
        <div className="bg-white modal py-5 px-10">
          <div className="header pt-5 pb-3 truncate text-2xl font-bold text-center">
            Desmarcar {paciente}
          </div>
          <div className="content ">
            <h4 className="mb-5 text-center text-md">
              Você realmente deseja desmarcar essa cirurgia?
            </h4>
            <div className="flex justify-center mb-3">
              <button
                type="submit"
                className="btn-save-vendedor bg-blue text-lg text-white font-bold px-8 py-2 rounded-full mr-3 shadow-lg"
                onClick={desmarcarHandle}
              >
                Sim
              </button>
              <button
                type="submit"
                className="btn-save-vendedor bg-red-500 text-lg text-white font-bold px-8 py-2 rounded-full shadow-lg"
                onClick={toggle}
              >
                Não
              </button>
            </div>
          </div>
        </div>
        <div className="rounded-full cursor-pointer close-btn" onClick={toggle}>
          <AiFillCloseCircle
            size="3rem"
            className="text-blue rounded-full bg-white"
          />
        </div>
      </Fragment>
      {/* )} */}
    </Popup>
  );
}

export default ModalDesmarcarAgendadas;
