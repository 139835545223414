import './style.css'
import Popup from 'reactjs-popup'
import React, { useState } from 'react'
import { axiosApi } from 'src/services/axiosInstances';
import { toast } from 'react-toastify';

interface confirmacaoProps {

  id: string;
  solicitacaoId?: number;
  fetchMinhaEquipe: () => void;
  indexNavBar?: number;
}

export const pendenteStyle = 
`btn-new-vendedor text-white py-1 ml-4
font-lato shadow-md
font-black rounded-full pendenteBackGroundClass`

export const emEquipeStyle = `btn-new-vendedor bg-gray-200 text-blue-700 py-1
font-lato shadow-md ml-5
font-black rounded-full`



const ModalConfirmacao: React.FC<confirmacaoProps>= ({id, solicitacaoId, fetchMinhaEquipe, indexNavBar }) => {

const [isOpen, setIsOpen] = useState(false);

const openModal = () => setIsOpen(true);

const closeModal = () => setIsOpen(false);


const handlePendentes = async (idConvite: string, status_id:number) => {
  try {
    const reqBody = {status_id: `${status_id}`};
    await axiosApi.put(`/equipe/${idConvite}`, reqBody);
    toast.success(`${status_id === 2 ? `Convite aceito com sucesso!` : 
    indexNavBar === 2 ? `Convite de participação recusado!` : `Solicitação cancelada com sucesso!`}`, {
      position: "top-center",
      autoClose: 4000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      });
  } catch (error: any) {
    console.log(error);
}
};

const confirmar = async (status_id: number) => {
  await handlePendentes(id, status_id);
  fetchMinhaEquipe();
  closeModal();
}


  return (
    <>{indexNavBar === 2 ?
      <>
        <button  onClick={ () => confirmar(2)} style={{width: 100}} className="btn-new-vendedor  bg-blue  text-white py-1 font-lato font-black rounded-full ml-10">Aceitar</button>
        <button  onClick={ () => confirmar(3)} style={{width: 100}} className={emEquipeStyle}>Recusar</button> 
      </> :
        solicitacaoId === 1 ? 
        <button  onClick={ openModal } style={{width: 120}} className={pendenteStyle}>Pendente X</button> :  
        <button  onClick={ openModal } style={{width: 120}} className={emEquipeStyle}>{`Em equipe    X`}</button>}
      <Popup open={isOpen} onClose={closeModal} position="right center">
        <div className="modalConfirmacao flex flex-col border items-center text-center rounded-3xl bg-white mt-16">
          <h2 className="text-xl font-lato p-3 pl-6 pt-5 font-black text-center">
            {
            solicitacaoId === 1 ? <div><br></br><p>Deseja cancelar o convite enviado ao usuário ?</p></div> : solicitacaoId === 2 && indexNavBar === 1 ?
            `Deseja deixar de participar desta equipe médica? Você deixará de ter acesso ao calendario deste usuário.` : `Confirma desassociação do membro da equipe médica? O usuário deixará de ter acesso ao seu calendario.`
            }
          </h2>
          <div className="flex justify-evenly pr-5 pt-1">
          <button  onClick={()=> confirmar(4)} style={{width:130, height: 50}} className="pt-1 shadow-md backgroundClass text-2xl btn-new-vendedor p-y-4 pb-6 ml-5 hover:bg-red-700 hover:text-white text-white px-2 h-6
            font-lato rounded-full text-center font-black">Confirmar</button>
          <button  onClick={ ()=> closeModal() } style={{width:130, height: 50}} className="pt-1 text-2xl btn-new-vendedor p-y-4 pb-6 ml-5
          bg-gray-200 shadow-md px-2 h-6 font-lato rounded-full text-center font-black">Cancelar</button>
          </div>
        </div> 
</Popup>
</>
  )
}


export default ModalConfirmacao;