import "./style.css";
import React from "react";
import logo from "src/assets/logo-intro.png";
import SelectSimple from "src/components/SelectSimple";

export default function SelectPage(): JSX.Element {
  
  return (
    <div className="select-page-container py-5">
      <div className="select-page-adjust-container px-5">
        {window.innerWidth <= 832 ? (
          <></>
        ) : (
          <div className="pl-3 mb-5 flex justify-center">
            <img src={logo} alt="logo" className="w-56" />
          </div>
        )}
        <label className="select-page-title ml-8 pt-5 text-2xl text-black">Quem Sou?</label>
        <SelectSimple />
      </div>
    </div>
  );
}
