import * as actionTypes from "../types";

export const setUpdateHospitais = (hospitais: actionTypes.Hospitais[]) => {

  return {
    type: actionTypes.UPDATE_HOSPITAIS,
    payload: {
      hospitais,
    },
  };
};
