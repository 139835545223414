import React from "react";
import { StateForm } from "src/components/MobileProfile";

export const profileValidator = (formValue: StateForm, setErrorState: React.Dispatch<React.SetStateAction<boolean>>) => {

  if (formValue.razao_social === '') {
    setErrorState(true);
    return;
  }
  if (formValue.nome === '') {
    setErrorState(true);
    return;
  }
  if (String(formValue.whatsapp).length < 11) {
    setErrorState(true);
    return;
  }
  // if (String(formValue.telefone).length < 11) {
  //   setErrorState(true);
  //   return;
  // }
  if (String(formValue.email).length < 13) {
    setErrorState(true);
    return;
  }
  if (formValue.senha === '' || formValue.senha === undefined) {
    setErrorState(true);
    return;
  }
  if (formValue.confirm_senha === '' || formValue.confirm_senha === undefined) {
    setErrorState(true);
    return;
  }
  if (formValue.senha.length < 3) {
    setErrorState(true);
    return;
  }
  if (formValue.confirm_senha.length < 3 || formValue.senha !== formValue.confirm_senha) {
    setErrorState(true);
    return;
  }
  return true;
}