import React, { Fragment } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import { toast } from "react-toastify";
import Popup from "reactjs-popup";
import { axiosApi } from "src/services/axiosInstances";
import "../ModalDeleteVendedor/style.css";

type Props = {
  nomeVendedor?: string;
  openDelete?: any;
  close?: any;
  idVendedor?: number;
  toggle?: any;
  loadDados: () => void;
};

function ModalDelete({
  openDelete,
  close,
  idVendedor,
  nomeVendedor,
  toggle,
  loadDados,
}: Props): JSX.Element {
  const modalWidth = { width: "35%" };
  const handleSubmit = async (event: any) => {
    event.preventDefault();
    await axiosApi.delete(`/vendedor/${idVendedor}`);
    loadDados();
    toggle();
    toast.success('Vendedor Deletado!', {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      });
  };
  return (
    <Popup
      open={openDelete}
      closeOnDocumentClick={false}
      // onClose={openDelete}
      contentStyle={modalWidth}
      modal
      nested={true}
    >
      <Fragment>
        <div className="bg-white modal py-5 px-10">
          <div className="header pt-5 pb-3 truncate text-3xl text-center font-bold">
            Apagar {nomeVendedor}
          </div>
          <div className="content ">
            <h4 className="mb-5 text-center text-md">
              Você realmente deseja excluir esse vendedor?
            </h4>
            <div className="flex justify-center mb-3">
              <button
                type="submit"
                className="btn-save-vendedor bg-blue text-lg text-white font-bold px-8 py-2 rounded-full mr-3 shadow-lg"
                onClick={handleSubmit}
              >
                Sim
              </button>
              <button
                type="submit"
                className="btn-save-vendedor bg-red-500 text-lg text-white font-bold px-8 py-2 rounded-full shadow-lg"
                onClick={close}
              >
                Não
              </button>
            </div>
          </div>
        </div>
        <div className="rounded-full cursor-pointer close-btn" onClick={toggle}>
          <AiFillCloseCircle
            size="3rem"
            className="text-blue rounded-full bg-white"
          />
        </div>
      </Fragment>
    </Popup>
  );
}

export default ModalDelete;
