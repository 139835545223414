import React, { Fragment, useEffect } from "react";
// import { Chrono } from "react-chrono";
import "../ModalTimeline/style.css";


type Props = {
  indexAcompanhamento: number;
  setIndexAcompanhamento: (index: number) => void;
};

// type dados = {
//   cardTitle: string,
//   cardSubtitle: string,
// }

export default function AcompanhamentoTimeline({
  setIndexAcompanhamento, indexAcompanhamento
}: Props) {
  // const [timelineDados, setTimelineDados] = useState<dados[]>([]);
  const items = [
    {
      // title: "",
      cardTitle: "Formulário Recebido",
      cardSubtitle: "02/09/2020 - 00:00",
      // cardDetailedText: ""
    },
    {
      cardTitle: "Documentos Recebidos",
      cardSubtitle: "03/09/2020 - 10:00",
    },
    {
      cardTitle: "Status Novo",
      cardSubtitle: "02/09/2020 - 00:00",
    },
    {
      cardTitle: "Status YZX",
      cardSubtitle: "3/09/2020 - 10:00"
    },
    {
      cardTitle: "Status XYZ",
      cardSubtitle: "02/09/2020 - 00:00",
    },
    {
      cardTitle: "Procedimento Autorizado",
      cardSubtitle: "03/09/2020 - 10:00",
    },
  ];

  useEffect(() => {
    // if (indexAcompanhamento === 1) {
    //   setTimelineDados(items.slice(0, 1));
    // } else if (indexAcompanhamento === 2) {
    //   setTimelineDados(items.slice(0, 2));
    // } else if (indexAcompanhamento === 3) {
    //   setTimelineDados(items);
    // }
  }, [indexAcompanhamento, items]);

  return (
    <Fragment>
      {
        indexAcompanhamento === 1 &&
        <button
          onClick={() => setIndexAcompanhamento(4)}
          style={{ width: 320 }}
          className="flex justify-center button-bold-24 hover:bg-blue-700 mt-3"
        >
          ENTREGAR<br />
          DOCUMENTOS
        </button>
      }
      <h2 className="py-3 font-lato text-2xl font-black">Status da Autorização</h2>
      {/* <Chrono
        classNames="truncate"
        mode="VERTICAL_ALTERNATING"
        disableToolbar={true}
        allowDynamicUpdate={true}
        disableNavOnKey
        scrollable={{ scrollbar: true }}
        itemWidth={10}
        // cardWidht={200}
        theme={{
          primary: "#eaf0fb",
          secondary: "#2460D7",
          cardBgColor: "white",
          cardTitleColor: "#4f4f4f",
          cardSubtitleColor: "#2460D7"

        }}
        timelinePointDimension={30}
        lineWidth={10}
        items={timelineDados}
      /> */}
      {
        indexAcompanhamento === 2 && <button
          style={{ width: 320 }}
          className="flex justify-center button-bold-24 hover:bg-blue-700 mt-3"
          onClick={() => setIndexAcompanhamento(3)}
        >
          SOLICITAR<br />
          ASSISTENCIA
        </button>
      }
      {indexAcompanhamento === 3 && <div
        className="py-5"
      >
        <button
          style={{ width: 320 }}
          className="flex justify-center button-bold-25 button-bold-24 mt-3"
        >
          Download de<br />
          Autorização
        </button>
        <button
          style={{ width: 320 }}
          className="flex justify-center button-bold-24 hover:bg-blue-700 mt-3"
        >
          SOLICITAR<br />
          ASSISTENCIA
        </button>

      </div>
      }
    </Fragment>
  )
}